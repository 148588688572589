import React from "react";
import { CLOUDFRONT_URL } from "../config";

const S3Image = ({ src, alt, size = "auto", style = {} }) => {
  return (
    <img
      alt={alt}
      {...style}
      src={`${CLOUDFRONT_URL}/${size ? size : "auto"}/${src}`}
    />
  );
};

export default S3Image;
