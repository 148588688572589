import gql from "graphql-tag";

export const ARTWORK_CATEGORIES = gql`
  query artworkCategories($parentID: String, $page: Int, $userID: String) {
    categories: artworkCategories(
      parentID: $parentID
      page: $page
      userID: $userID
    ) {
      id
      key: id
      title
      parentID
      hasChild
      numberOfArtworks
      children {
        id
        key: id
        title
        parentID
        hasChild
        numberOfArtworks
        children {
          id
          key: id
          title
          parentID
          hasChild
          numberOfArtworks
          children {
            id
            key: id
            title
            parentID
            hasChild
            numberOfArtworks
            children {
              id
              key: id
              title
              parentID
              hasChild
              numberOfArtworks
            }
          }
        }
      }
    }
  }
`;

export const ARTWORK_CATEGORY = gql`
  query artworkCategory($ID: String) {
    artworkCategory(ID: $ID) {
      id
      key: id
      title
      parentID
      hasChild
      numberOfArtworks
    }
  }
`;

export const SUBCRIPTION_ARTWORK_CATEGORY = gql`
  subscription artworkCategorySubscription($userId: String!) {
    artworkCategorySubscription(userId: $userId) {
      action
      category {
        id
        key: id
        parentID
        title
        hasChild
        numberOfArtworks
        deletedAt
      }
    }
  }
`;
