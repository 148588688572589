import { Modal } from "antd";
import React, { useState } from "react";
import ChooseArtWorkModal from "../products/ChooseArtWorkModal";
// import S3Image from "../S3Image";

const ArtworkSelector = ({
  visible = false,
  onCancel = () => {},
  onSelect = () => {},
}) => {
  const [artwork, setArtwork] = useState();

  return (
    <Modal
      title="Select Artwork"
      visible={visible}
      onCancel={onCancel}
      width={"80%"}
      bodyStyle={{ maxHeight: "calc(100vh - 250px)", padding: "24px 0" }}
      okButtonProps={{ disabled: !artwork }}
      onOk={() => {
        onSelect(artwork);
      }}
    >
      <ChooseArtWorkModal
        onChange={(value) => {
          setArtwork(value);
        }}
      />
      {/* <Query query={ARTWORKS}>
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <Skeleton
                loading={true}
                active={true}
                title={false}
                paragraph={true}
              />
            );
          }
          if (error) {
            return <Alert message={error.message} type="error" />;
          }
          return (
            <Row gutter={[10, 10]}>
              {data.artworks.hits.map((art) => (
                <Col span={12} md={8} lg={6} key={art.id}>
                  <Card
                    size="small"
                    title={art.title}
                    cover={<S3Image src={get(art, "templates[0].preview")} />}
                    style={{
                      borderColor:
                        artwork && artwork.id === art.id
                          ? "#ff9100"
                          : "#f0f0f0",
                    }}
                    onClick={() => setArtwork(art)}
                    bodyStyle={{ display: "none" }}
                  ></Card>
                </Col>
              ))}
            </Row>
          );
        }}
      </Query> */}
    </Modal>
  );
};

export default ArtworkSelector;
