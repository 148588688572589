/* eslint-disable no-use-before-define */
import { useQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ARTWORK_CATEGORY } from "../../graphql/queries/artwork/artworkCategories";
import { ARTWORK_CATEGORY_SUBCRIPTION } from "../../graphql/subscription/artwork";
import CategoriesCustomize from "../CategoriesCustomize";

const ArtworkCategories = ({
  categoryImportBase = false,
  customBase,
  artworkCustom,
  height,
  categoryID,
  setCategoryID,
  setCategorySelect,
  categorySelect,
}) => {
  const currentUser = useSelector((state) => state.app.currentUser);
  const isSeller = currentUser?.roles?.find((role) => role.name === "Seller");
  const { data, subscribeToMore } = useQuery(ARTWORK_CATEGORY, {
    variables: {
      userID: isSeller ? currentUser.id : null,
    },
    fetchPolicy: "network-only",
  });
  const [newCate, setNewCate] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pushToTree = (tree, node) => {
    if (tree.length === 0) {
      tree.push(node);
      return true;
    }
    for (let i in tree) {
      if (tree[i].parentID === node.parentID) {
        tree.push(node);
        return true;
      } else if (tree[i].id === node.parentID) {
        tree[i].children = [...(tree[i].children || []), node];
        return true;
      } else if (Array.isArray(tree[i].children)) {
        if (pushToTree(tree[i].children, node)) {
          return true;
        }
      }
    }
    return false;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeFromTree = (tree, node) => {
    for (let i in tree) {
      if (tree[i].parentID === node.parentID) {
        tree = tree.filter((el) => el.id !== node.id);
        return true;
      } else if (tree[i].id === node.parentID && tree[i].children) {
        tree[i].children = tree[i].children.filter((el) => el.id !== node.id);
        if (!tree[i].children.length) {
          tree[i].children = null;
          return true;
        }
        return true;
      }
      if (Array.isArray(tree[i].children)) {
        if (removeFromTree(tree[i].children, node)) {
          return true;
        }
      }
    }
    return false;
  };

  const clearCategories = (categories) => {
    const newCategories = [...(categories || [])].filter((el) => !el.deletedAt);
    return newCategories;
  };
  useEffect(() => {
    if (currentUser) {
      subscribeToMore({
        document: ARTWORK_CATEGORY_SUBCRIPTION,
        variables: { userId: currentUser?.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          let newCategories = cloneDeep(prev.artworkCategories);
          if (
            subscriptionData.data.artworkCategorySubscription.action !==
            "update"
          ) {
            if (
              subscriptionData.data.artworkCategorySubscription.action ===
              "delete"
            ) {
              removeFromTree(
                newCategories,
                subscriptionData.data.artworkCategorySubscription.category
              );
            } else {
              setNewCate(
                subscriptionData.data.artworkCategorySubscription.category
              );
              pushToTree(
                newCategories,
                subscriptionData.data.artworkCategorySubscription.category
              );
            }
          }
          return {
            artworkCategories: newCategories,
          };
        },
      });
    }
  }, [
    currentUser,
    currentUser.id,
    pushToTree,
    removeFromTree,
    subscribeToMore,
  ]);

  useEffect(() => {
    if (currentUser) {
      subscribeToMore({
        document: ARTWORK_CATEGORY_SUBCRIPTION,
        variables: { userId: currentUser?.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          let newCategories = cloneDeep(prev.artworkCategories);
          if (
            subscriptionData.data.artworkCategorySubscription.action !==
            "update"
          ) {
            if (
              subscriptionData.data.artworkCategorySubscription.action ===
              "delete"
            ) {
              removeFromTree(
                newCategories,
                subscriptionData.data.artworkCategorySubscription.category
              );
            } else {
              setNewCate(
                subscriptionData.data.artworkCategorySubscription.category
              );
              pushToTree(
                newCategories,
                subscriptionData.data.artworkCategorySubscription.category
              );
            }
          }
          return {
            artworkCategories: newCategories,
          };
        },
      });
    }
  }, [
    currentUser,
    currentUser.id,
    pushToTree,
    removeFromTree,
    subscribeToMore,
  ]);
  return (
    <div className="">
      <CategoriesCustomize
        categories={clearCategories(data?.artworkCategories)}
        height={height ?? 227}
        customBase={customBase}
        categoryImportBase={categoryImportBase}
        artworkCustom={artworkCustom}
        type="artworks"
        expandedKey={newCate ? [newCate] : []}
        categoryID={categoryID}
        setCategoryID={setCategoryID}
        setCategorySelect={setCategorySelect}
        categorySelect={categorySelect}
      />
    </div>
  );
};

export default ArtworkCategories;
