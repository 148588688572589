import React from "react";
import { Select, Form } from "antd";
export default function SelectAspect({ onSelect }) {
  return (
    <Form.Item name="aspect" label="Aspect">
      <Select
        defaultValue="1:1"
        style={{
          width: 120,
        }}
        onChange={(v) => {
          onSelect(v);
        }}
        options={[
          {
            value: "1:1",
            label: "1:1",
          },
          {
            value: "4:3",
            label: "4:3",
          },
          {
            value: "3:4",
            label: "3:4",
          },
          {
            value: "19:6",
            label: "19:6",
          },
        ]}
      />
    </Form.Item>
  );
}
