import React, { useEffect, useState } from "react";
import { CLOUDFRONT_URL } from "../../../../config";
import gql from "../../../../api/gql";
import {
  message,
  Table,
  Input,
  Space,
  Image,
  Pagination,
  Col,
  Row,
  Spin,
} from "antd";
import AddBackground from "./components/AddBackground";
import DeleteBackground from "./components/DeleteBackground";

const BackgroundAutoBanner = ({ category_id, currentUser }) => {
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [backgrounds, setBackgrounds] = useState([]);
  const [categoryID, setCategoryID] = useState(null);

  const getBackgrounds = () => {
    setLoading(true);
    const query = `query bannersFilter($categoryID: [String], $search: String, $page: Int, $pageSize: Int){
			banners(
          categoryID: $categoryID, 
          search: $search,
          page: $page,
          pageSize: $pageSize
      ){
          count
          hits{
            id
            title
            imageUrl
          }
        }
		}`;
    const filter = {
      pageSize,
      page,
    };
    if (search) filter.search = search;
    if (categoryID) filter.categoryID = [categoryID];
    gql
      .request(query, filter)
      .then((res) => {
        setTotal(res.banners.count || 0);
        setBackgrounds(res.banners.hits || []);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again!");
        setLoading(false);
      });
  };

  const onSearch = (v) => {
    setSearch(v);
  };

  useEffect(() => {
    getBackgrounds();
  }, [page, pageSize, search, categoryID]);

  useEffect(() => {
    setCategoryID(category_id);
  }, [category_id]);
  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginBottom: 7,
        }}
      >
        <Search
          placeholder="Search categories"
          onSearch={onSearch}
          enterButton
        />
      </div> */}
      <Spin spinning={loading} tip="Loading...">
        <Row
          style={{
            marginBottom: 10,
          }}
        >
          <Col span={6}>
            <AddBackground
              category_id={category_id}
              currentUser={currentUser}
              onChange={getBackgrounds}
            />
          </Col>
          {backgrounds.map((i) => (
            <Col span={6} key={i.id}>
              <div
                style={{
                  paddingBottom: 5,
                  paddingRight: 5,
                  position: "relative",
                  height: "100%",
                  aspectRatio: '1/1'
                }}
              >
                <div style={{
                  height: "100%",
                  background: "#efefef",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 10,
                    }}
                    src={`${CLOUDFRONT_URL}/200/${i.imageUrl}`}
                  />
                </div>
                
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                  }}
                >
                  <DeleteBackground id={i.id} onChange={getBackgrounds} />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Spin>
      <Pagination
        onChange={setPage}
        total={total}
        defaultPageSize={pageSize}
        current={page}
        showSizeChanger={false}
      />
    </>
  );
};

export default BackgroundAutoBanner;
