import { Col, Form, Input, InputNumber, Row, TreeSelect, Select, Radio } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { debounce, get, omit } from "lodash";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ARTWORK } from "../../../types";

const { Option } = Select;

const TextForm = ({ layer, workspace }) => {
  const dispatch = useDispatch();
  const { artwork, selectedTemplate } = workspace;
  const layers =
    selectedTemplate === -1
      ? get(artwork, "sharedLayers", [])
      : get(artwork, `templates[${selectedTemplate}].layers`) || [];
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...layer,
      ...{
        personalized: {
          enable: false,
          label: layer.title,
          ...(layer.personalized || {}),
        },
      },
    });
  }, [layer]);
  const onValuesChange = (_, values) => {
    Object.assign(layer, omit(values, ["text"]));
    layer.values.forEach((v) => {
      if (v.active) {
        v.text = values.text;
      }
    });
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: layer, //updateLayers(layers, layer),
    });
  };
  const renderTreeNodesHidden = (l) => {
    return (
      <TreeSelect.TreeNode
        value={l.id}
        title={l.personalized?.label || l.title || l.id}
        key={l.id}
        disabled={
          l.id === layer.id ||
          l.type === "Group" ||
          !l.personalized ||
          (l.personalized && l.personalized.enable !== true) ||
          l.parent === layer.id ||
          l.type !== layer.type
        }
      >
        {Array.isArray(l.layers) &&
          l.layers.map((l) => renderTreeNodesHidden(l))}
      </TreeSelect.TreeNode>
    );
  };
  return (
    <Form
      form={form}
      // initialValues={layer}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      onValuesChange={debounce(onValuesChange, 200)}
    >
      <Form.Item
        name="text"
        initialValue={layer.values.find((v) => v.active)?.text}
      >
        <Input.TextArea
          onChange={(value) => {
            let newLayers = layer.parent
              ? layers.search(layer.parent).layers
              : layers;
            if (layer.hidden && layer.dependOnLayer) {
              let newLayer = layers.search(layer.dependOnLayer);
              if (newLayer) {
                dispatch({
                  type: ARTWORK.SET_LAYER,
                  payload: {
                    ...newLayer,
                    values: newLayer.values.map((item) => {
                      return { ...item, text: value.target.value };
                    }),
                  },
                });
              }

              let layerDepend = newLayers.filter(
                (item) =>
                  item.dependOnLayer === layer.dependOnLayer &&
                  item.id !== layer.id
              );
              if (layerDepend.length > 0) {
                for (let index = 0; index < layerDepend.length; index++) {
                  const element = layerDepend[index];
                  dispatch({
                    type: ARTWORK.SET_LAYER,
                    payload: {
                      ...element,
                      values: element.values.map((item) => {
                        return { ...item, text: value.target.value };
                      }),
                    },
                  });
                }
              }
            }
            if (layer.personalized && layer.personalized.enable) {
              let layerDepend = newLayers.filter(
                (item) => item.dependOnLayer === layer.id
              );
              if (layerDepend.length > 0) {
                for (let index = 0; index < layerDepend.length; index++) {
                  const element = layerDepend[index];
                  dispatch({
                    type: ARTWORK.SET_LAYER,
                    payload: {
                      ...element,
                      values: element.values.map((item) => {
                        return { ...item, text: value.target.value };
                      }),
                    },
                  });
                }
              }
            }
          }}
        />
      </Form.Item>
      {get(layer, "shape", "normal") === "normal" && (
        <Form.Item
          name="autofit"
          valuePropName="checked"
          label="Auto resize to fit container"
          labelCol={{ span: 21 }}
          wrapperCol={{ span: 3 }}
          labelAlign="left"
          style={{ textAlign: "right" }}
        >
          <Checkbox />
        </Form.Item>
      )}
      {get(layer, 'autofit') !== true && (
        <>
        <span>Wrap text: </span>
        <Form.Item 
        name={"wrapConfig"}
        labelCol={{ span: 21 }}
        wrapperCol={{ span: 3 }}
        style={{ textAlign: "left" }}
      >
        <Select
        defaultValue={"none"}
        style={{
          width: "90px"
        }}>
          <Option value={"none"}>None</Option>
          <Option value={"word"}>Word</Option>
          <Option value={"char"}>Char</Option>
        </Select>
      </Form.Item>
        </>
      )}

      <div
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center'
          }}
      >
        <span>Text direction: </span>
        <Form.Item
            name={"textDirection"}
            labelCol={{ span: 21 }}
            wrapperCol={{ span: 3 }}
            style={{ textAlign: "left" }}
        >
          <Radio.Group
              defaultValue={'horizontal'}
              style={{
                display: 'flex'
              }}
              onChange={(v) => {
                // console.log(v)
              }}
          >
            <Radio value="horizontal">Horizontal</Radio>
            <Radio value="vertical">Vertical</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <div
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>Hidden layer: </span>
        <Checkbox
          checked={layer.hidden}
          onChange={(value) => {
            dispatch({
              type: ARTWORK.SET_LAYER,
              payload: {
                ...layer,
                dependOnLayer: null,
                personalized: {
                  ...layer.personalized,
                  enable: false,
                },
                hidden: value.target.checked,
              },
            });
          }}
        />
      </div>
      {get(layer, "hidden") && (
        <div style={{ marginBottom: 10 }}>
          <span>Depend on layer : </span>
          <TreeSelect
            value={layer.dependOnLayer}
            style={{ width: "100%" }}
            treeDefaultExpandAll
            onChange={(v) => {
              dispatch({
                type: ARTWORK.SET_LAYER,
                payload: {
                  ...layer,
                  dependOnLayer: v,
                },
              });
            }}
          >
            {layers.map((l) => renderTreeNodesHidden(l))}
          </TreeSelect>
        </div>
      )}
      <Form.Item
        name={["personalized", "enable"]}
        valuePropName="checked"
        label="Allow personalized"
        labelCol={{ span: 21 }}
        wrapperCol={{ span: 3 }}
        labelAlign="left"
        style={{ textAlign: "right" }}
      >
        <Checkbox disabled={layer.hidden} />
      </Form.Item>
      {get(layer, "personalized.enable") && (
        <>
          <Form.Item
            label="Ordering"
            name={"ordering"}
            initialValue={layer.ordering ? layer.ordering : 0}
          >
            <InputNumber min={0} max={99} />
          </Form.Item>
          <Form.Item label="Label" name={["personalized", "title"]}>
            <Input size="small" />
          </Form.Item>
          <Form.Item label="Help text" name={["personalized", "help"]}>
            <Input size="small" placeholder="Field description..." />
          </Form.Item>
          <Form.Item label="Placeholder" name={["personalized", "placeholder"]}>
            <Input size="small" />
          </Form.Item>
          <Form.Item label="Default value" name={"text"}>
            <Input size="small" />
          </Form.Item>
          <Form.Item
            name={["personalized", "required"]}
            valuePropName="checked"
            label="Is required"
            labelCol={{ span: 21 }}
            wrapperCol={{ span: 3 }}
            labelAlign="left"
            style={{ textAlign: "right" }}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name={["personalized", "numberic"]}
            valuePropName="checked"
            label="Is numberic"
            labelCol={{ span: 21 }}
            wrapperCol={{ span: 3 }}
            labelAlign="left"
            style={{ textAlign: "right" }}
          >
            <Checkbox />
          </Form.Item>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item label="Min" name={["personalized", "min"]}>
                <Input size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Max" name={["personalized", "max"]}>
                <Input size="small" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(TextForm);
