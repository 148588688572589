import React, { Component } from "react";
import Orders from "../../components/admin/Orders";
import { setOrdersFilter } from "../../actions";
import { connect } from "react-redux";
import SellerOrder from "../../components/seller/SellerOrder";

class OrdersPage extends Component {
  state = {
    filter: null,
  };
  componentWillMount() {
    const { setOrdersFilter, currentUser } = this.props;
    if (
      currentUser &&
      currentUser.roles.filter((el) => ["Supplier"].includes(el.name)).length >
        0
    ) {
      setOrdersFilter({
        ...this.props.filter,
        site_ids: null,
        status: ["ready", "in_production", "fulfilled", "refunded"],
      });
    }
    if (
      currentUser &&
      currentUser.roles.filter((el) => ["Seller"].includes(el.name)).length > 0
    ) {
      setOrdersFilter({
        ...this.props.filter,
        site_ids: this.props.currentSite ? [this.props.currentSite.id] : null,
        status: null,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.currentSite !== this.props.currentSite &&
      this.props.currentUser.roles.find((role) =>
        ["Seller"].includes(role.name)
      )
    ) {
      this.props.setOrdersFilter({
        ...this.props.filter,
        site_ids: [this.props.currentSite?.id],
      });
    }
  }
  render() {
    const { currentUser } = this.props;
    const isAdmin = currentUser.roles.find((role) =>
      ["Administrator", "Supporter"].includes(role.name)
    );
    // const isSeller = currentUser.roles.find((role) =>
    //   ["Seller"].includes(role.name)
    // );
    return (
      <div>
        {isAdmin ? <Orders {...this.props} /> : <SellerOrder {...this.props} />}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    filter: state.orders.filter,
    currentSite: state.app.currentSite,
  }),
  { setOrdersFilter }
)(OrdersPage);
