import React, { useState, useEffect } from "react";
import { Row, Col, Select, Form, Input, Button, message, Space, Slider, InputNumber, Radio } from "antd";
import UploadImage from "../../midjourney/components/UploadImage";
import axios from "axios";
import { URL_API_KLING_CREATE_VIDEO } from "../../../../config";
import KlingPreview from "../components/KlingPreview";
export default function KlingCreateVideo({
  onChangeLoading,
  onChangeCancel,
  onComplete,
}) {
  const [form] = Form.useForm();
  const [url, setURL] = useState("");
  const [url2, setURL2] = useState("");
  const [extend, setExtend] = useState(false);
  const [data, setData] = useState([]);
  const { TextArea } = Input;

  const uniqueID = (length = 15) => {
    var result = "";
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  useEffect(() => {
    const queryLocal = localStorage.getItem("localStorageKlingCreateVideo");
    if (queryLocal) {
      setData(JSON.parse(queryLocal));
    }
  }, []);

  const handleChangeData = (v) => {
    setData(v);
    localStorage.setItem("localStorageKlingCreateVideo", JSON.stringify(v));
  };

  const onFinish = async (v) => {
    onChangeLoading(true);
    let dataRequest = v;
    dataRequest.url = url ? url : "";
    dataRequest.url2 = url2 ? url2 : "";
    dataRequest.extend = extend ? extend : false;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    onChangeCancel(source);
    try {
      const create = await axios
        .post(URL_API_KLING_CREATE_VIDEO, dataRequest, {
          cancelToken: source.token,
          onDownloadProgress: function (progressEvent) {
            onComplete();
          },
        })
        .then((res) => res.data)
        .catch((thrown) => {
          message.error(thrown.message);
          onChangeLoading(false);
          onComplete();
        });
      const body = create.data;
      if (body.length <= 0) {
        message.error("Please try again, the system is busy or overloaded..!");
      } else {
        const newData = [
          ...data,
          {
            id: uniqueID(),
            data: body,
          },
        ];
        setData(newData);
        localStorage.setItem(
          "localStorageKlingCreateVideo",
          JSON.stringify(newData)
        );
      }
      onChangeLoading(false);
    } catch (error) {
      onChangeLoading(false);
    }
  };
  const onFinishFailed = () => {};

  return (
    <Row>
      <Col
        span={12}
        style={{
          position: "relative",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="text"
            label="Prompt"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          
          <Form.Item name="extend" label="Time">
            <Radio.Group defaultValue={extend} onChange={(e) => setExtend(e.target.value)} value={extend}>
              <Radio value={false}>5s</Radio>
              <Radio value={true}>10s</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Image">
            <Space>
              <UploadImage onChange={(v) => setURL(v)} />
              <UploadImage onChange={(v) => setURL2(v)} />
            </Space>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Generation
            </Button>
          </Form.Item>
        </Form>
      </Col>

      <Col
        span={12}
        style={{
          position: "relative",
        }}
      >
        {data.length > 0 && (
          <KlingPreview
            onChangeData={handleChangeData}
            type="video"
            data={data}
          />
        )}
      </Col>
    </Row>
  );
}
