import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CategoriesList from "../../components/artworks/CategoriesList";
const Container = styled.div`
  // display: grid;
  // grid-template-columns: 315px calc(100% - 315px);
  // position: relative;
  // .ant-tree {
  //   background: transparent !important;
  // }
  // .ant-tree-show-line .ant-tree-switcher {
  //   background: transparent !important;
  // }
`;

const ArtworkCategoriesPage = (props) => {
  // const query = qs.parse(props.location.search, {
  //   ignoreQueryPrefix: true,
  // });
  // const [categoryID, setCategoryID] = useState(get(query, "categoryID", null));
  // useEffect(() => {
  //   setCategoryID(get(query, "categoryID", null));
  // }, [query]);
  return (
    <Container style={{ height: "100%" }}>
      <CategoriesList />
    </Container>
  );
};

export default ArtworkCategoriesPage;
