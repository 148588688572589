import React, { useState, useEffect, useRef } from "react";
import { Modal, Popconfirm } from "antd";
import {
  BsFillClockFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsFillTrashFill,
} from "react-icons/bs";
export default function KlingHistory({
  history,
  currentIndex,
  onSelect,
  onDeleteAll,
  onDeleteItem,
  type,
}) {
  const [open, setOpen] = useState(false);
  const [curent, setCurent] = useState(currentIndex);
  const slider = useRef();

  const updateSlider = (v) => {
    const offset = -v * 500; // 300 is the height of each slide
    slider.current.style.transform = `translateY(${offset}px)`;
  };

  const handlePrev = () => {
    if (curent > 0) {
      const newValue = curent - 1;
      setCurent(newValue);
      updateSlider(newValue);
    }
  };

  const handleNext = () => {
    if (curent < history.length - 1) {
      const newValue = curent + 1;
      setCurent(newValue);
      updateSlider(newValue);
    }
  };

  const handleDeleteAll = () => {
    setOpen(false);
    onDeleteAll();
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      updateSlider(curent);
    }, 1000);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const convertToHtmlLink = (text) => {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/g;
    const htmlText = text.replace(
      pattern,
      '<a  target="_blank" href="$2">$1</a>'
    );
    return htmlText;
  };

  return (
    <>
      <div onClick={showModal} className="clock-history-btn-kling">
        <BsFillClockFill
          size={20}
          style={{
            marginRight: "5px",
            fontWeight: "bold",
          }}
        />{" "}
        History
      </div>
      <Modal
        title="Basic Modal"
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        className="model-history"
        closable={false}
        width={700}
      >
        <div className="slider-container-history">
          <div>
            <button
              onClick={handleCancel}
              style={{
                zIndex: 9999999,
                position: "absolute",
                top: 2,
                right: 2,
                height: "35px",
                width: "35px",
                background: "#fff",
                border: "none",
                outline: "none",
                cursor: "pointer",
                fontSize: "20px",
                boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
                borderRadius: "3px",
              }}
            >
              &#10005;
            </button>
          </div>
          <div className="trash-all-history">
            <Popconfirm
              placement="left"
              title={"Are you sure you want to delete all history?"}
              onConfirm={handleDeleteAll}
              okText="Yes"
              cancelText="No"
            >
              <div className="btn-trash-all-history" title="Delete all history">
                <BsFillTrashFill
                  style={{
                    marginRight: "5px",
                  }}
                />{" "}
                Delete all
              </div>
            </Popconfirm>
          </div>
          <div ref={slider} className="slider-history">
            {history.map((item, index) => (
              <div key={index} className="slide-history">
                <div className="slide-history-item">
                  {type == "image" && (
                    <div
                      style={{
                        aspectRatio: item.ratio.replace(":", "/"),
                      }}
                      className="kling-group kling-group-image-history"
                    >
                      {item.data.map((i, indexItem) => (
                        <div
                          style={{
                            aspectRatio: item.ratio.replace(":", "/"),
                          }}
                          className="kling-group-item"
                          key={indexItem}
                        >
                          <img className="kling-group-item__img" src={i} />
                        </div>
                      ))}
                    </div>
                  )}
                  {type == "video" && (
                    <div className="kling-group-video">
                      {item.data.map((i, indexItem) => (
                        <div className="kling-group-video-item" key={indexItem}>
                          <video
                            className="kling-group-video-item__img"
                            width="370"
                            controls
                          >
                            <source src={i} type="video/mp4" />
                            Your browser does not support HTML video.
                          </video>
                        </div>
                      ))}
                    </div>
                  )}

                  {type == "describe" && (
                    <div className="kling-group-describe">
                      {item.data.map((i, indexItem) => (
                        <div
                          className="kling-group-describe-item"
                          key={indexItem}
                          dangerouslySetInnerHTML={{
                            __html: convertToHtmlLink(i),
                          }}
                        ></div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="box-slide-history-choose">
                  <div
                    onClick={() => {
                      onDeleteItem(index);
                      if (index == 0 && history.length >= 1) {
                        setCurent(0);
                        updateSlider(0);
                      }
                      if (history.length > 1 && index > 0) {
                        setCurent(index - 1);
                        updateSlider(index - 1);
                      }
                    }}
                    className="btn-delet-item-history"
                  >
                    <BsFillTrashFill />
                  </div>
                  <button
                    onClick={() => {
                      onSelect(index);
                      setOpen(false);
                    }}
                  >
                    Choose
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="group-btn-slider">
            {curent > 0 && (
              <button onClick={handlePrev} id="prev">
                <BsFillCaretUpFill />
              </button>
            )}
            {curent < history.length - 1 && (
              <button onClick={handleNext} id="next">
                <BsFillCaretDownFill />
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
