import {connect, useDispatch} from "react-redux";
import React, { useEffect } from "react";
import {Form, Col, Input, InputNumber, Row, Select, TreeSelect, Tooltip} from "antd";
import { debounce, get, omit } from "lodash";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {ARTWORK} from "../../../types";

const LabelForm = ({ layer, workspace }) => {
    const dispatch = useDispatch();
    const { artwork, selectedTemplate } = workspace;

    const [form] = Form.useForm();
    const layers =
        selectedTemplate === -1
            ? get(artwork, "sharedLayers", [])
            : get(artwork, `templates[${selectedTemplate}].layers`) || [];

    const onValuesChange = (_, values) => {
        Object.assign(layer, omit(values, ["text"]));
        layer.values.forEach((v) => {
            if (v.active) {
                v.text = values.text;
            }
        });
        dispatch({
            type: ARTWORK.SET_LAYER,
            payload: layer, //updateLayers(layers, layer),
        });
    };
    return (
        <Form
        form={form}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        layout="horizontal"
        onValuesChange={debounce(onValuesChange, 200)}
        >
            <Form.Item
                name="text"
                label="Content"
                initialValue={layer.values.find((v) => v.active)?.text}
            >
                <Input.TextArea
                    onChange={(value) => {
                        let newLayers = layer.parent
                            ? layers.search(layer.parent).layers
                            : layers;
                        if (layer.hidden && layer.dependOnLayer) {
                            let newLayer = layers.search(layer.dependOnLayer);
                            if (newLayer) {
                                dispatch({
                                    type: ARTWORK.SET_LAYER,
                                    payload: {
                                        ...newLayer,
                                        values: newLayer.values.map((item) => {
                                            return { ...item, text: value.target.value };
                                        }),
                                    },
                                });
                            }

                            let layerDepend = newLayers.filter(
                                (item) =>
                                    item.dependOnLayer === layer.dependOnLayer &&
                                    item.id !== layer.id
                            );
                            if (layerDepend.length > 0) {
                                for (let index = 0; index < layerDepend.length; index++) {
                                    const element = layerDepend[index];
                                    dispatch({
                                        type: ARTWORK.SET_LAYER,
                                        payload: {
                                            ...element,
                                            values: element.values.map((item) => {
                                                return { ...item, text: value.target.value };
                                            }),
                                        },
                                    });
                                }
                            }
                        }
                        if (layer.personalized && layer.personalized.enable) {
                            let layerDepend = newLayers.filter(
                                (item) => item.dependOnLayer === layer.id
                            );
                            if (layerDepend.length > 0) {
                                for (let index = 0; index < layerDepend.length; index++) {
                                    const element = layerDepend[index];
                                    dispatch({
                                        type: ARTWORK.SET_LAYER,
                                        payload: {
                                            ...element,
                                            values: element.values.map((item) => {
                                                return { ...item, text: value.target.value };
                                            }),
                                        },
                                    });
                                }
                            }
                        }
                    }}
                />
            </Form.Item>
        </Form>
    )
}

export default connect((state) => ({
    workspace: state.artwork.workspace,
}))(LabelForm);