import gql from "graphql-tag";

export const CREATE_FILE = gql`
  mutation createFile($input: NewFile!) {
    createFile(input: $input) {
      id
      key
      file_name
      file_mime
      file_size
      url
      sku
    }
  }
`;
