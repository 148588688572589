import React, { useState, useEffect } from "react";
import Upload from "../../../../components/upload/Upload";
import { Collapse, message, Card, Progress, Popconfirm } from "antd";
import { ASSET_URL, CLOUDFRONT_URL } from "../../../../config";
import { MdDeleteForever } from "react-icons/md";
import { RiUploadCloudFill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import UploadMultiple from "../../components/UploadMultiple";
const ChooseImage = ({
  onChangeDraggableImageUrl,
  values,
  onChange,
  selectedBackground,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [keyImg, setKeyImg] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [filesData, setFileData] = useState([]);
  const { Panel } = Collapse;

  const handleContextMenu = (e, keyimg) => {
    e.preventDefault();
    setKeyImg(keyimg);
    setPosition({ x: e.clientX, y: e.clientY });
    setMenuVisible(true);
  };

  const handleHideMenu = () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  const handleSetImage = () => {
    if (keyImg) {
      const fidFileData = filesData.find(e => e.key == keyImg);
      onChangeDraggableImageUrl(fidFileData);
    }
    setMenuVisible(false);
  };

  const handleDeleteFile = (index, data) => {
    const findFile = files.find((e, i) => i == index);
    setFileData(prev => prev.filter(e => e.key != findFile.key));
    setFiles(prev => prev.filter((e, i) => i != index));
  };

  function getImageSize(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function() {
        reject('Error loading image');
      };
      img.src = url;
    });
  }

  const checkUploadFile = async () => {
    var newFileData = [];
    const checkfiles = files.filter((e) => (e.percent == "100" && !filesData.map(m => m.key).includes(e.key)));
    for(var a = 0; a < checkfiles.length; a++){
      const getSize = await getImageSize(ASSET_URL + checkfiles[a].key);
      const newFileItem = {
        ...getSize,
        key: checkfiles[a].key,
        url: ASSET_URL + checkfiles[a].key
      }
      newFileData  = [...newFileData, newFileItem];
    }
    setFileData(prev => [...prev, ...newFileData]);
    const key = "uploading_photos";
    setTimeout(() => {
      message.success({
        content: "Uploading image successfully!",
        key,
        duration: 2,
      });
    }, 1000);
    setLoadingFile(false);
  }

  useEffect(() => {
    onChange(filesData);
  }, [filesData]);
  return (
    <>
      <Card title="Images" style={{ width: "100%", marginBottom: 5 }}>
        <Collapse
          defaultActiveKey={["1"]}
          ghost
          className="collapse-background-auto-banner"
        >
         
            <ul
              style={{
                listStyle: "none",
                margin: 0,
                padding: 0,
                display: "flex",
                flexWrap: "wrap",
                marginBottom: 5,
              }}
            >
              <li className="li-select-background-autobanner">
                <UploadMultiple 
                   disabled={
                    loadingFile ||
                    loading ||
                    values.length >=
                      Math.floor(20 / selectedBackground.length)
                  }
                  accept=".png, .jpg" 
                  onChange={(file) => {
                    setLoadingFile(true);
                    setFiles(file);
                  }} 
                  onUpload={(file) => {
                    setFiles(file);
                    checkUploadFile();
                  }}
                >
                  <div
                    style={{
                      padding: "10px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaPlus size={20} />
                  </div>
                </UploadMultiple>
              </li>
              {files
                .map((i, index) => {
                  return (
                    <li key={index} className="li-select-background-autobanner">
                      <div
                        style={{
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        {i.status == "done" ? (
                          <div 
                          onContextMenu={(e) => handleContextMenu(e, i.key)}
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                          }}>
                            <img
                              style={{
                                width: "100%",
                              }}
                              
                              src={`${CLOUDFRONT_URL}/100/${i.key}`}
                            />
                          </div>
                        ) : (
                          <Progress
                            percent={Math.floor(i.percent)}
                            status="active"
                            width={70}
                            type="circle"
                          />
                        )}
                      </div>
                      {i.percent == 100 && (
                        <div
                          style={{
                            position: "absolute",
                            top: "3px",
                            right: "3px",
                          }}
                        >
                          <Popconfirm
                            placement="topLeft"
                            title="Are you sure to delete?"
                            onConfirm={() => handleDeleteFile(index, i)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div
                              style={{
                                fontSize: "18px",
                                color: "red",
                                cursor: "pointer",
                                background: "rgb(239 239 239 / 85%)",
                                width: "22px",
                                aspectRatio: "1 / 1",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "5px",
                              }}
                            >
                              <MdDeleteForever />
                            </div>
                          </Popconfirm>
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
        </Collapse>
      </Card>

      {menuVisible && (
        <>
          <div
            onClick={handleHideMenu}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          ></div>
          <ul
            id="customContextMenu"
            style={{
              position: "fixed",
              top: position.y,
              left: position.x,
              backgroundColor: "white",
              border: "1px solid #ccc",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
              listStyleType: "none",
              padding: "0",
              margin: "0",
              zIndex: 1000,
            }}
          >
            <li
              onClick={handleSetImage}
              style={{ padding: "8px 12px", cursor: "pointer" }}
            >
              set Image
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default ChooseImage;
