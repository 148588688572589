import { useMutation } from "@apollo/client";
import { Progress, message } from "antd";
import React, { useState } from "react";
import { CREATE_FILE } from "../../graphql/mutation/createFile";
import Upload from "../upload/Upload";

const PrintFileMockup = (props) => {
  const { dragger = true, onChange = () => {}, accept = "image/*" } = props;
  const [createFile] = useMutation(CREATE_FILE);
  const [fileUpload, setFileUploads] = useState();
  const handleChange = (file) => {
    createFile({
      variables: {
        input: {
          key: file.key,
          file_name: file.name,
          file_mime: file.type,
          file_size: file.size,
          source: "aws",
        },
      },
    })
      .then((res) => {
        onChange(res.data.createFile);
        setTimeout(() => {
          setFileUploads();
        }, 1000);
      })
      .catch();
  };
  return (
    <>
      <Upload
        onChange={(file) => {
          setFileUploads(file);
          const key = 'uploading_photos';
          setTimeout(() => {
            message.success({ content: 'Uploading image successfully!', key, duration: 2 });
          }, 1000);
        }
      }
        onUpload={(file) => handleChange(file)}
        dragger={dragger}
        accept={accept}
        showUploadList={false}
      >
        {fileUpload ? (
          <Progress
            percent={Math.round(fileUpload.percent)}
            type="circle"
            size="small"
            width={50}
          />
        ) : (
          props.children
        )}
      </Upload>
    </>
  );
};

export default PrintFileMockup;
