import { Button, Card, Checkbox, DatePicker, Form, Input } from "antd";
import React from "react";
import moment from "moment";
import { history } from "../../history";

const UserForm = ({ onSubmitAdd, loading, users, onSubmitUpdate, roles }) => {
  const onhandleSubmit = (value) => {
    if (users) {
      onSubmitUpdate(value);
    } else {
      onSubmitAdd(value);
    }
  };
  return (
    <Form layout="vertical" onFinish={onhandleSubmit}>
      <Card title="Detail" style={{ marginBottom: 20, maxWidth: "800px" }}>
        <Form.Item
          label="First Name"
          initialValue={users ? users.first_name : ""}
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input first name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          initialValue={users ? users.last_name : ""}
          rules={[
            {
              required: true,
              message: "Please input last name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          initialValue={users ? users.email : ""}
          rules={[
            {
              type: "email",
              message: "E-mail is not valid!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        {!users ? (
          <Form.Item
            label="Password"
            name="pass"
            rules={[
              {
                required: true,
                message: "Please input password",
              },
            ]}
          >
            <Input.Password type="password" />
          </Form.Item>
        ) : null}
        {!users ? (
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["pass"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("pass") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        ) : null}
        <Form.Item
          label="Birthday"
          name="birthday"
          initialValue={users ? users.birthday && moment(users.birthday) : null}
        >
          <DatePicker format={"DD/MM/YYYY"} style={{ width: "300px" }} />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          initialValue={users ? users.address : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          initialValue={users ? users.phone : ""}
          rules={[
            {
              required: true,
              message: "Please input phone",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Subscription"
          name="is_subscription"
          valuePropName="checked"
          initialValue={users ? users.is_subscription : false}
        >
          <Checkbox>
            Receive updates about new products and email marketing
          </Checkbox>
        </Form.Item> */}
        <Form.Item
          label="Roles"
          name="role"
          initialValue={users ? users.roles.map((role) => role.name) : []}
          rules={[{ required: true, message: "Please choose user roles" }]}
        >
          <Checkbox.Group
            options={roles?.map((role) => {
              return { label: role.name, value: role.name };
            })}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 5 }}>
            Save
          </Button>
          <Button
            onClick={() => {
              history.push("/admin/users-manager");
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UserForm;
