import React from "react";
import gql from "graphql-tag";
import gql_request from "../../api/gql";
import { Query } from "@apollo/client/react/components";
import {
  Button,
  Input,
  Modal,
  notification,
  Switch,
  Table,
  Skeleton,
  Dropdown,
  Menu,
  InputNumber,
} from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import styled from "styled-components";
import ProductTypeVariantSelectModal from "./ProductTypeVariantSelectModal";
import ProductVariantCostField from "./ProductVariantCostField";
import { history } from "../../history";
import _, { cloneDeep, omit, orderBy } from "lodash";
// // import PrintFilesProductTypeVariant from "./PrintFilesProductTypeVariant";
import ProductTypePrintFiles from "../product_type/PrintFiles";
import {
  DownOutlined,
  EditOutlined,
  PicLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import ChangePriceVariant from "./ChangePriceVariant";

const Q = gql`
  query get($productTypeId: String!) {
    productTypeById(id: $productTypeId) {
      title
      sku
      description
      # print_files {
      #   key
      #   title
      #   mockup
      #   width
      #   height
      #   note
      #   variant_ids
      # }
      printAreas {
        id
        name
        width
        height
        mockupId
        variant_ids
        note
      }
      attributes {
        name
        slug
        options
      }
    }
    productTypeVariants(product_type_id: $productTypeId) {
      id
      sku
      attributes {
        option
        name
        slug
      }
      print_sizes {
        print_area_id
        width
        height
      }
      retail_cost
      base_cost
      sale_cost
      is_active
    }
  }
`;

const Container = styled.div`
  .actions {
    display: flex;
    .left-action {
      flex-grow: 1;
    }
    span {
      margin: 5px;
    }
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
  .supplier-cost-container {
    display: flex;
    .ant-form-item {
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 540px) {
    .actions {
      display: grid;
      padding: 0 5px;
      .left-action {
        margin-bottom: 15px;
        .ant-btn {
          padding: 0 5px;
        }
      }
    }
  }
`;
const FormContainer = styled.div`
  .sku-container {
    @media (min-width: 992px) {
      min-width: 250px;
    }
  }
  div.cost {
    margin-top: 27px;
  }
`;

let permutationArr = [];
let draggingIndex = -1;

const JOIN = ":";

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > draggingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < draggingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    draggingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const DraggableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

class ProductTypeVariants extends React.Component {
  state = {
    attributes: [],
    dataSource: [],
    loading: false,
    productTypeSKU: "",
    modal: {
      visible: false,
      selected: [],
      missingVariants: [],
    },
    updateproductTypeById: {},
    printAreas: [],
    selectedRowKeys: [],
    changePrice: false,
  };
  components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { dataSource } = this.state;
    const dragRow = dataSource[dragIndex];

    this.setState(
      update(this.state, {
        dataSource: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      })
    );
  };

  heapPermutation = (options = [], size = 0) => {
    if (size === 1) {
      // print
      let items = [];
      for (let i = 0; i < options.length; i++) {
        items.push(options[i]);
      }
      permutationArr.push(items.join(JOIN));
      return permutationArr;
    }

    for (let i = 0; i < size; i++) {
      this.heapPermutation(options, size - 1, options.length);
      if (size % 2 === 1) {
        const tmp = options[0];
        options[0] = options[size - 1];
        options[size - 1] = tmp;
      } else {
        const tmp = options[i];
        options[i] = options[size - 1];
        options[size - 1] = tmp;
      }
    }
  };
  getCombine = () => {
    const { attributes, productTypeSKU } = this.state;
    let res = [];
    const n = attributes.length;
    let indices = [];
    let sortingIncrement = this.state.dataSource.length;
    // fill zero
    for (let i = 0; i < n; i++) {
      indices[i] = 0;
    }
    while (true) {
      let options = [productTypeSKU];
      let attrs = [];
      for (let i = 0; i < n; i++) {
        const option = attributes[i].options[indices[i]];
        options.push(option);
        attrs.push({
          name: attributes[i].name,
          slug: attributes[i].slug,
          option: option,
        });
      }
      const sku = options.join(JOIN);
      sortingIncrement++;
      res.push({
        sku: sku,
        attributes: attrs,
        base_cost: null,
        retail_cost: null,
        sale_cost: null,
        is_active: true,
        sorting: sortingIncrement,
      });

      let next = n - 1;
      while (
        next >= 0 &&
        indices[next] + 1 >= attributes[next].options.length
      ) {
        next--;
      }

      if (next < 0) {
        break;
      }
      indices[next]++;
      for (let i = next + 1; i < n; i++) {
        indices[i] = 0;
      }
    }

    return res;
  };
  getMissingVariants = () => {
    const { dataSource, attributes, productTypeSKU } = this.state;

    if (attributes.length === 0) {
      notification.warning({ message: "There is no attribute." });
      return [];
    }
    let dataSourceByOptionsMap = {};
    for (let i = 0; i < dataSource.length; i++) {
      let options = [productTypeSKU];
      for (let j = 0; j < dataSource[i].attributes.length; j++) {
        options.push(dataSource[i].attributes[j].option);
      }
      permutationArr = [];
      this.heapPermutation(options, options.length);
      for (let j = 0; j < permutationArr.length; j++) {
        const key = permutationArr[j];
        dataSourceByOptionsMap[key] = dataSource[i];
      }
    }
    const combine = this.getCombine();
    let missingVariants = [];
    for (let i = 0; i < combine.length; i++) {
      if (typeof dataSourceByOptionsMap[combine[i].sku] === "undefined") {
        missingVariants.push(combine[i]);
      }
    }

    return missingVariants;
  };

  addMissingVariants = () => {
    const { dataSource, newProductTypeId } = this.state;
    const missingVariants = this.getMissingVariants();
    if (missingVariants.length > 0) {
      let addSelectAll = missingVariants.map((item) => {
        return {
          ...item,
          print_sizes: newProductTypeId.printAreas?.map((i) => {
            return {
              print_area_id: i.id,
              width: 0,
              height: 0,
            };
          }),
        };
      });
      this.setState({
        dataSource: [...dataSource, ...addSelectAll],
      });
    }
  };

  handleSave = (client) => {
    const { dataSource, carriers } = this.state;
    const newSource = cloneDeep(dataSource);
    const productTypeId = this.props.match.params.id;
    const args = [];
    const mutationArr = [];
    let variables = {};
    for (let i = 0; i < newSource.length; i++) {
      var item = omit(newSource[i], "__typename");
      item.attributes = item.attributes.map((it) => omit(it, "__typename"));
      if (item.carrier_costs && item.carrier_costs.length) {
        item.carrier_costs = item.carrier_costs.map((cc) => {
          omit(cc, "__typename");
          // if (typeof cc.__typename !== "undefined") {
          //   delete cc.__typename;
          // }
          if (cc.cost === null) {
            cc.cost = carriers.filter(
              (f) => f.carrier_id === cc.carrier_id
            )[0].price;
          }
          return cc;
        });
      }
      item.productTypeId = productTypeId;
      item.sorting = i + 1;
      variables[`input_${i}`] = item;
      args.push(`$input_${i}: NewProductTypeVariant!`);
      mutationArr.push(`m_${i}: createProductTypeVariant(input: $input_${i}){
      id
      sku
      attributes{
          option
          name
          slug
      }
      retail_cost
      base_cost
      sale_cost
      is_active
      }`);
    }
    const q = `mutation execute(${args.join(",")}){
      ${mutationArr.join("\n")}
    }`;
    client
      .mutate({
        mutation: gql`
          ${q}
        `,
        variables,
      })
      .then((res) => {
        notification.success({ message: "Variants has been saved!" });
        history.push("/admin/product-types");
        if (res.data) {
          for (let i = 0; i < newSource.length; i++) {
            newSource[i] = res.data[`m_${i}`];
          }
          this.setState(
            {
              loading: false,
              newSource,
            },
            () => {
              // handle save cache
              const cache = client.readQuery({
                query: Q,
                variables: {
                  productTypeId: this.props.match.params.id,
                },
              });

              client.writeQuery({
                query: Q,
                variables: {
                  productTypeId: this.props.match.params.id,
                },
                data: {
                  ...cache,
                  productTypeVariants: newSource,
                },
              });
            }
          );
          // this.saveProductTypePrintFiles(res.data);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        notification.error({ message: e.toString() });
      });
  };

  saveProductTypePrintFiles = (result) => {
    const { id } = this.props.match.params;
    if (id) {
      var query = `mutation updateProductTypePrintAreas($product_type_id: String! $print_areas: [ProductTypePrintAreaInput!]){
        updateProductTypePrintAreas(product_type_id: $product_type_id, print_areas: $print_areas)
          }`;
    }
    this.setState({ loading: true });
    gql_request
      .request(query, {
        product_type_id: id,
        print_areas: this.state.printAreas.map((item) => {
          delete item.__typename;
          return {
            ...item,
            variant_ids: item.variant_ids.map((i) => i.id),
          };
        }),
      })
      .then((res) => {
        notification["success"]({
          message: "Product type has been update",
        });
        this.setState({ loading: false });
        history.push("/admin/product-types");
      })
      .catch((err) => {
        notification["error"]({
          message: "ERROR",
          description: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };
  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };
  render() {
    const {
      attributes,
      dataSource,
      updateproductTypeById,
      printAreas,
      selectedRowKeys,
      changePrice,
    } = this.state;
    let newProductTypeId = cloneDeep(updateproductTypeById);
    newProductTypeId &&
      newProductTypeId.printAreas &&
      newProductTypeId.printAreas.forEach((print_file, index) => {
        let init_variants =
          print_file.variant_ids &&
          print_file.variant_ids.map((variant_id) => {
            return dataSource.find((el) => el.id === variant_id);
          });
        init_variants &&
          init_variants.forEach((init_variant, key) => {
            if (
              !_.isUndefined(init_variant) &&
              newProductTypeId.printAreas.length
            ) {
              newProductTypeId.printAreas[index].variant_ids[
                key
              ] = init_variant;
            }
          });
      });

    const { match } = this.props;
    const { id } = match.params;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      selections:
        dataSource &&
        _.concat(...dataSource.map((item) => item.attributes)).reduce(
          (b, c) => {
            if (
              b.filter(
                (item) => item.option === c.option && item.name === c.name
              ).length === 0
            ) {
              b.push({
                key: `${c.name} : ${c.option}`,
                option: c.option,
                name: c.name,
                text: `${c.name} : ${c.option}`,
                onSelect: (changableRowKeys) => {
                  let newSelectedRowKeys = [];
                  newSelectedRowKeys = dataSource.filter(
                    (key) =>
                      key.attributes.filter(
                        (item) =>
                          item.option === c.option && item.name === c.name
                      ).length > 0
                  );
                  this.setState({
                    selectedRowKeys: newSelectedRowKeys.map((el) => el.sku),
                  });
                },
              });
            }
            return orderBy(b, ["name", "option"], ["asc", "asc"]);
          },
          []
        ),
    };
    return (
      <Query
        fetchPolicy="network-only"
        onCompleted={(data) => {
          this.setState({
            attributes: data.productTypeById.attributes
              ? data.productTypeById.attributes
              : [],
            dataSource: data.productTypeVariants.map((pr) => {
              if (pr.print_sizes) {
                let idPr = pr.print_sizes.map((item) => item.print_area_id);
                // return pr;
                return {
                  ...pr,
                  print_sizes: data.productTypeById.printAreas.map((item) => {
                    if (idPr.includes(item.id)) {
                      let data = pr.print_sizes.find(
                        (i) => i.print_area_id === item.id
                      );
                      return _.omit(data, "__typename");
                    } else {
                      return {
                        print_area_id: item.id,
                        width: 0,
                        height: 0,
                      };
                    }
                  }),
                };
              } else {
                return {
                  ...pr,
                  print_sizes: data.productTypeById.printAreas.map((item) => {
                    return {
                      print_area_id: item.id,
                      width: 0,
                      height: 0,
                    };
                  }),
                };
              }
            }),
            productTypeSKU: data.productTypeById.sku,
            newProductTypeId: data.productTypeById,
            // printAreas: data.productTypeById.printAreas
            //   ? data.productTypeById.printAreas.map((item) => {
            //       return {
            //         ...item,
            //         variant_ids: item.variant_ids.map((variantId) =>
            //           data.productTypeVariants.find((i) => i.id === variantId)
            //         ),
            //       };
            //     })
            //   : [],
            updateproductTypeById: data.productTypeById,
          });
        }}
        query={Q}
        variables={{ productTypeId: id }}
      >
        {({ error, loading, client }) => {
          if (error) return <div>{error.toString()}</div>;
          if (loading)
            return (
              <div>
                <Skeleton />
              </div>
            );
          let columns = [
            {
              width: 250,
              title: "Variant[SKu]",
              key: "Variant[SKu]",
              render: (_, record, index) => (
                <div key={index}>
                  {record.attributes.map((attr) => attr.option).join("/")}
                  <Input
                    style={{ marginTop: 5 }}
                    onChange={(e) => {
                      let newData = cloneDeep(dataSource);
                      newData[index].sku = e.target.value;
                      this.setState({
                        dataSource: newData,
                      });
                    }}
                    defaultValue={record.sku}
                  />
                </div>
              ),
            },
          ];
          columns.push({
            dataIndex: "base_cost",
            key: "base_cost",
            title: "Base Cost",
            width: 100,
            render: (_, record, index) => (
              <div>
                <FormContainer>
                  <div className={"cost base-cost"}>
                    <div style={{ position: "relative" }}>
                      <ProductVariantCostField
                        value={record.base_cost}
                        checkValue={
                          record.retail_cost <= 0 ||
                          ((record.sale_cost === null ||
                            record.sale_cost === 0) &&
                            record.base_cost > record.retail_cost) ||
                          record.base_cost > record.retail_cost ||
                          record.sale_cost > record.retail_cost ||
                          (record.sale_cost &&
                            record.base_cost > record.sale_cost)
                        }
                        // message="Base cost is smaller regular price"
                        onChange={(value) => {
                          let newData = cloneDeep(dataSource);
                          newData[index].base_cost = value;
                          this.setState({
                            dataSource: newData,
                          });
                        }}
                      />
                    </div>
                    <small
                      style={{
                        position: "absolute",
                        color: "red",
                        whiteSpace: "nowrap",
                        zIndex: 100,
                      }}
                      className="error-price"
                    >
                      {record.retail_cost <= 0
                        ? "Regular price must be greater than 0"
                        : (record.sale_cost === null ||
                            record.sale_cost === 0) &&
                          record.base_cost > record.retail_cost
                        ? " Regular price must be greater or equals to base cost"
                        : record.base_cost > record.retail_cost
                        ? "Please follow this rule: base cost <= sale price <= regular price"
                        : record.sale_cost > record.retail_cost
                        ? "Please follow this rule: base cost <= sale price <= regular price"
                        : record.sale_cost &&
                          record.base_cost > record.sale_cost
                        ? "Please follow this rule: base cost <= sale price <= regular price"
                        : null}
                    </small>
                  </div>
                </FormContainer>
              </div>
            ),
          });
          columns.push({
            dataIndex: "retail_cost",
            key: "retail_cost",
            title: "Regular price",
            width: 100,
            render: (_, record, index) => (
              <FormContainer>
                <div className={"cost retail-cost"}>
                  <ProductVariantCostField
                    value={record.retail_cost}
                    checkValue={
                      record.retail_cost <= 0 ||
                      ((record.sale_cost === null || record.sale_cost === 0) &&
                        record.base_cost > record.retail_cost) ||
                      record.base_cost > record.retail_cost ||
                      record.sale_cost > record.retail_cost ||
                      (record.sale_cost && record.base_cost > record.sale_cost)
                    }
                    // message="regular price be greater than 0"
                    onChange={(value) => {
                      let newData = cloneDeep(dataSource);
                      newData[index].retail_cost = value;
                      this.setState({
                        dataSource: newData,
                      });
                    }}
                  />
                </div>
              </FormContainer>
            ),
          });
          columns.push({
            dataIndex: "sale_cost",
            key: "sale_cost",
            title: "Sale Price",
            width: 100,
            render: (_, record, index) => (
              <div>
                <FormContainer>
                  <div className={"cost sale-cost"}>
                    <ProductVariantCostField
                      checkValue={
                        record.retail_cost <= 0 ||
                        ((record.sale_cost === null ||
                          record.sale_cost === 0) &&
                          record.base_cost > record.retail_cost) ||
                        record.base_cost > record.retail_cost ||
                        record.sale_cost > record.retail_cost ||
                        (record.sale_cost &&
                          record.base_cost > record.sale_cost)
                      }
                      // message={
                      //   record.sale_cost > record.retail_cost
                      //     ? "Sale price is smaller regular"
                      //     : "Sale price is bigger base cost"
                      // }
                      value={record.sale_cost}
                      onChange={(value) => {
                        let newData = cloneDeep(dataSource);
                        newData[index].sale_cost = value;
                        this.setState({
                          dataSource: newData,
                        });
                      }}
                    />
                  </div>
                </FormContainer>
              </div>
            ),
          });
          columns.push({
            dataIndex: "print_sizes",
            key: "print_sizes",
            width: 300,
            title: "Print size (Pixel)",
            render: (print_sizes, record, index) => (
              <div>
                {print_sizes ? (
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                      marginBottom: 8,
                    }}
                  >
                    <div style={{ width: 60 }}></div>
                    <div style={{ width: 90 }}>Width</div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      X
                    </div>
                    <div>Height</div>
                  </div>
                ) : null}

                {print_sizes?.map((print) => (
                  <div
                    style={{ display: "flex", gap: 5, marginBottom: 5 }}
                    key={print.print_area_id}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: 60,
                      }}
                    >
                      {
                        newProductTypeId.printAreas?.find(
                          (item) => item.id === print.print_area_id
                        ).name
                      }
                      :
                    </div>
                    <InputNumber
                      value={print.width}
                      onChange={(e) => {
                        let newData = cloneDeep(dataSource);
                        newData[index].print_sizes = newData[
                          index
                        ].print_sizes.map((value) => {
                          if (value.print_area_id === print.print_area_id) {
                            return { ...value, width: e };
                          } else {
                            return value;
                          }
                        });
                        this.setState({ dataSource: newData });
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      X
                    </div>
                    <InputNumber
                      value={print.height}
                      onChange={(e) => {
                        let newData = cloneDeep(dataSource);
                        newData[index].print_sizes = newData[
                          index
                        ].print_sizes.map((value) => {
                          if (value.print_area_id === print.print_area_id) {
                            return { ...value, height: e };
                          } else {
                            return value;
                          }
                        });
                        this.setState({ dataSource: newData });
                      }}
                    />
                  </div>
                ))}
              </div>
            ),
          });
          columns.push({
            dataIndex: "actions",
            key: "actions",
            width: 100,
            title: "Status",
            align: "center",
            render: (_, record, index) => (
              <Switch
                size="small"
                onChange={(value) => {
                  const newSource = cloneDeep(dataSource);
                  newSource[index].is_active = value;
                  this.setState({ dataSource: newSource });
                }}
                checked={record.is_active}
              />
            ),
          });

          const tableWidth = _.sum(columns.map((c) => c.width));

          return (
            <Container>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Dropdown
                  placement="bottomRight"
                  disabled={selectedRowKeys.length === 0}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.setState({ changePrice: "All Price" });
                        }}
                      >
                        All price
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          this.setState({ changePrice: "Base code" });
                        }}
                      >
                        Base code
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          this.setState({ changePrice: "Regular price" });
                        }}
                      >
                        Regular price
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          this.setState({ changePrice: "Sale price" });
                        }}
                      >
                        Sale price
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="link" icon={<EditOutlined theme="twoTone" />}>
                    Edit <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <DndProvider backend={HTML5Backend}>
                <Table
                  rowSelection={rowSelection}
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  components={this.components}
                  rowKey={(record) => record.sku}
                  locale={{
                    emptyText: (
                      <div>
                        <p>No variants added.</p>
                      </div>
                    ),
                  }}
                  scroll={{ x: tableWidth }}
                  pagination={false}
                  dataSource={dataSource}
                  columns={columns}
                  className={"product-variants"}
                  footer={() => (
                    <div>
                      <div className={"left-action"}>
                        <Button
                          onClick={() => this.addMissingVariants()}
                          icon={<PicLeftOutlined />}
                        >
                          Add all missing variants
                        </Button>
                        <span> Or </span>
                        <Button
                          onClick={() => {
                            const missingVariants = this.getMissingVariants();
                            if (missingVariants.length > 0) {
                              this.setState({
                                modal: {
                                  ...this.state.modal,
                                  visible: true,
                                  missingVariants,
                                },
                              });
                            } else {
                              notification.info({
                                message: "No missing variants",
                              });
                            }
                          }}
                        >
                          Select Variants
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </DndProvider>
              {/* <Card style={{ marginTop: 20 }} title="Print Files"> */}
              {/* <Form onFinish={(value) => console.log(value)}>
                  <Form.Item
                    name="printAreas"
                    initialValue={
                      updateproductTypeById
                        ? updateproductTypeById.printAreas
                        : []
                    }
                  > */}
              {/* <ProductTypePrintFiles
                  variantActive={dataSource}
                  value={printAreas}
                  onChange={(data) => {
                    this.setState({ printAreas: data });
                  }}
                  productTypeId={id}
                /> */}
              {/* </Form.Item>
                </Form> */}
              {/* </Card> */}
              {changePrice && (
                <Modal
                  // className="product-type-modal"
                  title={changePrice}
                  onCancel={() => {
                    this.setState({
                      changePrice: false,
                    });
                  }}
                  visible={changePrice ? true : false}
                  footer={false}
                >
                  <ChangePriceVariant
                    changePrice={changePrice}
                    dataSource={dataSource}
                    selectedRowKeys={selectedRowKeys}
                    handleCancel={() => this.setState({ changePrice: false })}
                    setSelectedRowKeys={() => {
                      this.setState({ selectedRowKeys: [] });
                    }}
                    setDataSource={(data) => {
                      this.setState({ dataSource: data });
                    }}
                  />
                </Modal>
              )}
              {this.state.modal.visible && (
                <Modal
                  className="product-type-modal"
                  width={700}
                  title={"Select variants"}
                  onOk={() => {
                    if (this.state.modal.selected.length) {
                      let addSelect = this.state.modal.selected.map((item) => {
                        return {
                          ...item,
                          print_sizes: this.state.newProductTypeId.printAreas?.map(
                            (i) => {
                              return {
                                print_area_id: i.id,
                                width: 0,
                                height: 0,
                              };
                            }
                          ),
                        };
                      });
                      this.setState({
                        modal: {
                          ...this.state.modal,
                          visible: false,
                          selected: [],
                        },
                        dataSource: [...dataSource, ...addSelect],
                      });
                    }
                  }}
                  onCancel={() => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        visible: false,
                      },
                    });
                  }}
                  visible={this.state.modal.visible}
                >
                  <ProductTypeVariantSelectModal
                    onChange={(variants) => {
                      this.setState({
                        modal: {
                          ...this.state.modal,
                          selected: variants,
                        },
                      });
                    }}
                    variants={this.state.modal.missingVariants}
                    attributes={attributes}
                  />
                </Modal>
              )}
              <div style={{ marginTop: 20 }}></div>
              <Button
                loading={this.state.loading}
                // disabled={this.state.printAreas.find(
                //   (item) => item.name.length === 0
                // )}
                onClick={() => {
                  if (!dataSource.length) {
                    return;
                  }
                  this.setState({ loading: true });

                  this.handleSave(client);
                }}
                htmlType="submit"
                type={"primary"}
                icon={<SaveOutlined />}
                disabled={dataSource.find(
                  (item) =>
                    !item.retail_cost ||
                    item.sale_cost > item.retail_cost ||
                    (item.sale_cost < item.base_cost && item.sale_cost) ||
                    item.base_cost > item.retail_cost
                )}
              >
                Save
              </Button>

              <Button
                onClick={() => history.goBack()}
                style={{ marginLeft: 10 }}
              >
                Cancel
              </Button>
            </Container>
          );
        }}
      </Query>
    );
  }
}
export default ProductTypeVariants;
