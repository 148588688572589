import React, { Component } from "react";
import { Form, Input, Button, notification, InputNumber } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import { PropTypes } from "prop-types";
import styled from "styled-components";

const Container = styled.div``;
class UpdateUserLevel extends Component {
  state = {
    loading: false,
    userlevel: this.props.userlevel,
  };
  onClose = () => {
    this.props.onClose();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.userlevel.id !== this.props.userlevel.id) {
      this.props.form.setFieldsValue({
        ...this.props.userlevel,
      });
    }
  }

  onUpdate = (values) => {
    const mutation = `mutation updateUserLevel( $id: Int!, $input: EditUserLevel! ){
          updateUserLevel(input: $input, id:$id){
            id
            name
            order_total
            discount
          }
        }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        input: values,
        id: this.props.userlevel.id,
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.onLoad();
        this.props.onClose();
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { userlevel } = this.state;
    return (
      <Container>
        <Form onFinish={this.onUpdate}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                initialValue: userlevel.name,
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="OrderTotal"
            name="order_total"
            rules={[
              {
                initialValue: userlevel.order_total,
                required: true,
              },
            ]}
          >
            <InputNumber
              type="number"
              min={0}
              max={100000}
              style={{ width: "100%" }}
              step={1}
            />
          </Form.Item>
          <Form.Item
            label="Discount"
            name="discount"
            rules={[
              {
                initialValue: userlevel.discount,
                required: true,
              },
            ]}
          >
            <InputNumber
              type="number"
              min={0}
              max={100000}
              style={{ width: "100%" }}
              step={0.01}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              Save
            </Button>
            <Button
              icon="undo"
              style={{ marginLeft: 10 }}
              onClick={() => {
                this.onClose();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}
UpdateUserLevel.propTypes = {
  userlevel: PropTypes.object,
};
export default UpdateUserLevel;
