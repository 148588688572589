import React from "react";
import {
  Row,
  Form,
  Col,
  Input,
  Card,
  Button,
  notification,
  Skeleton,
} from "antd";
import Wysiwyg from "../../components/wysiwyg";
import ProductTypeAttributes from "../../components/admin/ProductTypeAttributes";
import gql from "../../api/gql";
import _ from "lodash";
import { history } from "../../history";
import CategoriesSelect from "../../components/product_type/CategoriesSelect";
import Images from "../../components/product_type/Images";
import ItemProduct from "./itemProduct";
import { emitter } from "../../routes";
import { SaveOutlined } from "@ant-design/icons";
import MokupsProductType from "../../components/product_type/MokupsProductType";
import PrintAreas from "../../components/product_type/PrintAreas";

class ProductTypeForm extends React.Component {
  state = {
    productType: null,
    attributes: [],
    loading: false,
    showModal: false,
    items: [],
    onShow: false,
    data: "",
    visible: false,
    printAreasCheck: false,
    attributeCheck: false,
  };

  onFocus = () => {
    this.setState({ onShow: true });
  };

  addItem = () => {
    const { items } = this.state;
    this.setState({
      items: [...items, this.state.data],
      data: "",
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      const query = `query productTypeById ($id: String!)
      {productTypeById(id: $id)
      {
        id, title, description, sku, mockup
        categories{
          id
        }
        attributes{
          name, slug, options
        }
        printAreas{
          id,name,width,height,dpi
        }
        product_type_variants{
          id
          product_type_id
          attributes{
            name
            slug
            option
          }
          is_active
        }
        images
        mockup
      }
    }`;
      gql
        .request(query, {
          id: id,
        })
        .then((res) => {
          this.setState({
            productType: res.productTypeById || {},
            attributes: res.productTypeById.attributes || [],
          });
        });
    }

    this.subcriber = emitter.addListener("saveProductType", () => {
      this.saveProductType();
    });
  }

  componentWillUnmount() {
    if (this.subcriber) {
      this.subcriber.remove();
    }
  }

  saveProductType = (values) => {
    if (values.printAreas.length === 0) {
      notification["error"]({
        message: "Please input your print file!",
      });
    } else if (values.attributes.length === 0) {
      notification["error"]({
        message: "Please input your attribute! ",
      });
    } else {
      const { id } = this.props.match.params;
      var query = `mutation createProductType($input: NewProductType!){
          createProductType(input: $input){
            id
          }
        }`;
      this.setState({ loading: true });
      if (id) {
        query = `mutation updateProductType($id: String! ,$input: NewProductType!){
        updateProductType(id: $id, input: $input){
          id
        }
      }`;
        gql
          .request(query, {
            id: id,
            input: values,
          })
          .then((res) => {
            notification["success"]({
              message: "Product type has been " + (id ? "updated" : "created"),
            });
            this.setState({ loading: false });
          })
          .catch((err) => {
            notification["error"]({
              message: "ERROR",
              description: _.get(err, "[0].message"),
            });
            this.setState({ loading: false });
          });
      } else {
        gql
          .request(query, {
            input: values,
          })
          .then((res) => {
            notification["success"]({
              message: "Product type has been " + (id ? "updated" : "created"),
            });
            this.setState({ loading: false });
            history.push(
              `/admin/product-types/edit/${res.createProductType.id}`
            );
          })
          .catch((err) => {
            notification["error"]({
              message: "ERROR",
              description: _.get(err, "[0].message"),
            });
            this.setState({ loading: false });
          });
      }
    }
  };

  showModal = (action) => {
    this.setState({
      showModal: action,
    });
  };

  onAddAttr = (formData) => {
    const { attributes } = this.state;
    this.setState({
      attributes: [...attributes, formData],
    });
  };

  onShow = (name, slug) => this.setState({ showModal: true, name, slug });

  render() {
    const { productType, attributes, loading } = this.state;
    const { id } = this.props.match.params;
    if (id && !productType) return <Skeleton />;
    return (
      <Form layout="vertical" onFinish={this.saveProductType}>
        <Row gutter={20}>
          <Col span={24} lg={16}>
            <Card title="Detail" style={{ marginBottom: 20 }}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input title" }]}
                initialValue={productType ? productType.title : ""}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="SKU"
                name="sku"
                rules={[{ required: true, message: "Please input sku" }]}
                initialValue={productType ? productType.sku : ""}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Mockup Link"
                name="mockup"
                initialValue={productType ? productType.mockup : ""}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                initialValue={productType ? productType.description : ""}
              >
                <Wysiwyg />
              </Form.Item>
            </Card>
            <Card title="Attributes" style={{ marginBottom: 20 }}>
              <Form.Item name="attributes" initialValue={attributes}>
                <ProductTypeAttributes
                  values={this.state.attributes}
                  onChange={(attributes) => {
                    let checkvalue = attributes.find(
                      (attri) =>
                        !attri.name || !attri.slug || attri.options.length === 0
                    );
                    if (checkvalue) {
                      this.setState({ attributeCheck: true });
                    } else {
                      this.setState({ attributeCheck: false });
                    }
                  }}
                />
              </Form.Item>

              <ItemProduct
                onSubmit={this.onAddAttr}
                onCancel={() => this.showModal(false)}
                showModal={this.state.showModal}
              />
            </Card>
            <Card title="Frint Files">
              <Form.Item
                name="printAreas"
                initialValue={productType ? productType.printAreas : ""}
              >
                <PrintAreas
                  setPrintAreasForm={(value) => {
                    let checkvalue = value.find(
                      (item) => !item.width || !item.height || !item.name
                    );
                    if (checkvalue) {
                      this.setState({ printAreasCheck: true });
                    } else {
                      this.setState({ printAreasCheck: false });
                    }
                  }}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card title="Categories" style={{ marginBottom: 20 }}>
              <Form.Item
                name="categories"
                initialValue={
                  productType ? productType.categories.map((cat) => cat.id) : []
                }
                rules={[{ required: true, message: "Please input categories" }]}
              >
                <CategoriesSelect />
              </Form.Item>
            </Card>
            {productType ? (
              <Card title={`Mockups`} style={{ marginBottom: 20 }}>
                <MokupsProductType productType={productType} />
              </Card>
            ) : null}

            <Card title="Images">
              <Form.Item
                name="images"
                initialValue={productType ? productType.images : []}
              >
                <Images />
              </Form.Item>
            </Card>
          </Col>

          <Col span={24} style={{ marginTop: 10 }}>
            <Button
              type="primary"
              loading={loading}
              icon={<SaveOutlined />}
              htmlType="submit"
              disabled={this.state.printAreasCheck || this.state.attributeCheck}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                history.push(`/admin/product-types`);
              }}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ProductTypeForm;
