import gql from "graphql-tag";

export const CLIPART_CATEGORIES = gql`
  query clipartCategories {
    categories: clipartCategories {
      numberOfCliparts
      hits {
        id
        key: id
        title
        parentID

        numberOfCliparts
      }
    }
  }
`;

export const CLIPART_CATEGORY = gql`
  query clipartCategory($id: String!) {
    clipartCategory(id: $id) {
      id
      key: id
      title
      parentID
      numberOfCliparts
    }
  }
`;
export const CLIPART_CATEGORY_SUBCRIPTION = gql`
  subscription($teamID: String!) {
    clipartCategory(teamID: $teamID) {
      action
      category {
        id
        key: id
        parentID
        title
        numberOfCliparts
        hasChild
      }
    }
  }
`;
