import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import SongLyric from "./SongLyric";
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import {AWS_API, URL_API_UPLOAD_FILE} from "../../../config";

const UploadFontLyric = ({
     value,
     onChange,
     onChangeLoading
 }) => {
    const [file, setFile] = useState(null);
    const [valueUrl, setValueUrl] = useState(value ? value : "");
    const [loading, setLoading] = useState(false);
    const [nameFont, setNameFont] = useState("Upload fonts");
    const refInput = useRef();

    useEffect(()=> {
        onChange(valueUrl)
    }, [valueUrl]);

    useEffect(() => {
        if(value !== ""){
            setNameFontDefault();
        }
    }, [value])

    useEffect(() => {
        onChangeLoading(loading)
    }, [loading])

    const setNameFontDefault = () => {
        setLoading(true)
        axios.get(value).then(res => {
            const name_font = res.data['font-family'];
            setNameFont(name_font);
        });
        setLoading(false);
    }

    const handleRemoveFile = () => {
        setValueUrl("");
        setNameFont("Upload fonts")
    }

    const uploadFile = async (files) => {
        var formData = new FormData();
        formData.append("file", files);
        const upload = await axios.post(URL_API_UPLOAD_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (res){
            if(res.status == 200){
                return {
                    status: true,
                    url: res.data.url
                }
            }else {
                return {
                    status: false
                }
            }
        });
        return upload;
    }

    const getNameFont = (string) => {
        const replace = string.replace(/ /g, "-");
        const stringConvert = replace.replace(/.ttf/g, '')
        return stringConvert;
    }

    const handleChangeFile = async (value) => {
        setLoading(true);
        if(value.target.files.length > 0){
            const files = value.target.files[0];
            const checkType = files.name.toLowerCase().indexOf('.ttf');
            if(checkType >= 0){
                setFile(files);
                const file_upload = await uploadFile(files);
                if(file_upload.status){
                    const getJson = await getUrlFontLyricToJson(file_upload.url, getNameFont(files.name));
                    const upJson = await uploadFontToJson(getJson, getNameFont(files.name));
                    if(upJson.status){
                        setValueUrl(upJson.url);
                    }
                }
            }else {
                alert('Please upload the correct font format (.ttf)!');
            }
        }
        setTimeout(()=>{
            setLoading(false);
        }, 2000)
    }
    const OnClickUpload = () => {
        refInput.current.click();
    }

    const getUrlFontLyricToJson = async (fontUrl, nameFont) => {
        const font_url = fontUrl;
        const font_name = nameFont;
        const myFont = new FontFace(font_name, `url(${font_url})`);
        const valid_character = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
            'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y',
            'z', ' ', '.', ',', "?", '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const canvas = document.createElement('canvas'); // create canvas for title font
        const measure_canvas = document.createElement('canvas');
        const loadJson = myFont.load().then(
            (font) => {
                /**
                 * Load Custom Font here and give the title of font family name
                 */
                document.fonts.add(font);
                canvas.id = "titleFontFamily";
                canvas.width = window.innerWidth;
                canvas.height = 150;

                const ctx = canvas.getContext("2d");
                ctx.font = `30px ${font_name}`;
                ctx.fillText(`Font-Family: ${font_name}!`, 10, 50);
            }
        ).then(
            () => {
                measure_canvas.id = 'measure_canvas';
                measure_canvas.width = 0;
                measure_canvas.height = 0;
                measure_canvas.style.display = 'none';
                const m_ctx = measure_canvas.getContext('2d');
                m_ctx.font = `1px ${font_name}`;
                const myMap = new Map();
                myMap.set('font-family', font_name);
                myMap.set('font-family-url', font_url);
                let width;
                let width_upper;
                for (let i = 0; i < valid_character.length; i++) {
                    let character = valid_character[i];
                    let character_upper = character.toUpperCase();
                    width = m_ctx.measureText(character).width;
                    width_upper = m_ctx.measureText(character_upper).width;
                    myMap.set(character, width);
                    myMap.set(character_upper, width_upper);
                }
                const obj = Object.fromEntries(myMap);
                const character_data = JSON.stringify(obj);
                return character_data;
            }
        ).then((character_data) => {
            return character_data;
        });
        return loadJson;
    }

    const uploadFontToJson = async (character_data, nameFont) => {
        let objJsonB64 = Buffer.from(character_data).toString("base64");
        const base = 'data:application/json;base64,'+ objJsonB64;

        var file = await fetch(base)
            .then(res => res.arrayBuffer())
            .then(buf => new File([buf], nameFont +'.json', { type: 'application/json' }));
        const FileJson = await uploadFile(file);
        return FileJson;
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <Button loading={loading} onClick={OnClickUpload} icon={<UploadOutlined />}>
                {nameFont}
            </Button>
            <input style={{display: "none"}} type={"file"} ref={refInput} multiple={false} onChange={handleChangeFile}/>
            {valueUrl !== "" ?
                <div onClick={handleRemoveFile} className="button-close-upload-file"><CloseOutlined /></div>
            :null}
        </div>
    )
}

export default UploadFontLyric;