import {  Form, Input, TreeSelect } from "antd";
import { get } from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";
const Container = styled.div`
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  margin-right: -8px;
}`;
const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const CategoryForm = ({
  showAddNewTo,
  form,
  showRename,
  onSubmit,
  categories,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      title: "",
      parentID: get(showAddNewTo, "id", null),
    });
  }, [showAddNewTo]);
  useEffect(() => {
    form.setFieldsValue({
      title: showRename ? showRename.title : "",
      parentID: get(showRename, "parentID", null),
    });
  }, [showRename]);
  const getTreeData = (treeData) => {
    if (!treeData) {
      return null;
    }
    return treeData.map((cat) => {
      return {
        title: cat.title,
        value: cat.id,
        key: cat.id,
        children: getTreeData(cat.children),
      };
    });
  };
  return (
    <Container>
      <Form {...formLayout} form={form} onFinish={(values) => onSubmit()}>
        {showAddNewTo ? (
          <Form.Item
            label="Parent"
            name="parentID"
            initialValue={get(showAddNewTo, "id", null)}
          >
            <TreeSelect
              showSearch
              // disabled
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={getTreeData(categories)}
              placeholder="Select a parent category"
              treeDefaultExpandAll
              allowClear
            />
          </Form.Item>
        ) : showRename ? (
          <Form.Item label="Parent" name="parentID">
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={getTreeData(categories)}
              placeholder="Select a parent category"
              treeDefaultExpandAll
            />
          </Form.Item>
        ) : null}
        <Form.Item
          initialValue={showRename ? showRename.title : ""}
          rules={[{ required: true, message: "Please input title!" }]}
          label="Title"
          name="title"
        >
          <Input onPressEnter={() => form.submit()} placeholder="Title" />
        </Form.Item>
      </Form>
    </Container>
  );
};

export default CategoryForm;
