import React, { useState } from "react";
import gql from "../../api/gql";
import {
  Tabs,
  Input,
  InputNumber,
  Row,
  Col,
  Card,
  Form,
  Table,
  Menu,
  Dropdown,
  Modal,
  Button,
  Skeleton,
  notification,
  Avatar,
  Switch,
} from "antd";
import shortuuid from "short-uuid";
import styled from "styled-components";
import Wysiwyg from "../wysiwyg";
import CurrencyFormat from "react-currency-format";
import _, { get, orderBy } from "lodash";
import UploadImages from "../UploadImages";
import { connect } from "react-redux";
import DraggableTabs from "./DraggableTabs";
import { DraggableBodyRow } from "./DraggableRow";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { setProduct } from "../../actions";
import { randomString } from "../../utils";
import {
  DownOutlined,
  DollarOutlined,
  PictureOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { CREATE_PRODUCT } from "../../graphql/mutation/product/createproduct";
import ColectionSelect from "./ColectionSelect";
import TagsSelect from "./TagsSelect";
import ProductPrintFile from "./ProductPrintFile";
import BundledProducts from "./BundledProducts";
import ImagesProduct from "./ImagesProduct";
import { CLOUDFRONT_URL } from "../../config";
import { history } from "../../history";
import { CgToggleOff, CgToggleOn } from "react-icons/cg";

const { TabPane } = Tabs;

const Container = styled.div`
  #seconddiv.coolclass {
    max-height: 100%;
    background: purple;
  }
`;
class PushStepTwo extends React.Component {
  formRef = React.createRef();
  state = {
    productTypes: [],
    current: 0,
    images: [],
    data: [],
    visible: false,
    printFiles: [],
    printAreas: [],
    categories: [],
    tags: [],
    variations: [],
    showPrintfile: false,
    showButtonUpload: 1,
    loading: true,
    selectedVariants: [], // Check here to configure the default column
    editVariantsImage: false,
    editVariantsPrice: false,
    selectedImage: null,
    bulkPrices: {
      retail_cost: null,
      sale_cost: null,
    },
    productTypeIndex: 0,
    selectedVariations: [],
    type: null,
    is_custom_design: false,
    permalink: "",
    storeProductType: "",
    weight: 0,
    weightUnit: "kg",
    newId: shortuuid.generate().slice(0, 9),
    loadingRenderMockup: false,
    excludeMockups: [],
    loopProducts: []
  };

  components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { selectedVariations } = this.state;

    const dragRow = selectedVariations[dragIndex];

    this.setState(
      update(this.state, {
        selectedVariations: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      })
    );
  };

  onSubmit = async (values) => {
    const { currentSite } = this.props;
    const { variations, printAreas, newId, excludeMockups, loopProducts } = this.state;
    this.setState({ loading: true });
    const product_type_ids = this.props.match.params.id.split(",");
    let print_areas = [];
    printAreas.forEach((element) => {
      element.printAreas.forEach((item) => {
        print_areas = [
          ...print_areas,
          {
            product_type_print_area_id: item.id,
            name: item.name,
            product_type_id: element.product_type_id,
            artwork_id: item.artwork_id,
          },
        ];
      });
    });
    const Bundled_Products = JSON.stringify(loopProducts)
    gql
      .request(CREATE_PRODUCT, {
        input: {
          id: newId,
          title: values.title,
          description: values.description,
          bundled_products: Bundled_Products,
          variants: variations.map((item, index) => {
            return {
              product_type_id: item.product_type_id,
              product_type_variant_id: item.id,
              regular_price: item.retail_cost,
              price: item.sale_cost,
              active: item.active,
              sorting: index,
              mockup_id: item.image?.mockup_id,
            };
          }),
          print_areas: print_areas,
          product_type_ids: product_type_ids,
          site_id: currentSite.id,
          collection_ids: values.collection_ids?.map((collection, index) => {
            return { entity_id: collection, sorting: index };
          }),
          tag_ids: values.tag_ids?.map((tag, index) => {
            return { entity_id: tag, sorting: index };
          }),
          excludeMockups: excludeMockups
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        notification["success"]({
          message: "Product created",
        });
        this.props.history.push("/products/pushing");
      })
      .catch((err) => {
        notification["error"]({
          message: "Error",
          description: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });

    this.setState({ loading: false });
    //   });
  };
  componentDidMount() {
    if (this.props.match.path === "/products/clone/:product_id/:id") {
      this.setState({ type: "clone" });
      this.loadSelectedProduct();
    } else {
      this.loadSelectedProductTypes();
    }
  }

  onChangeImage = (images) => {
    this.setState({ images });
  };

  loadSelectedProductTypes() {
    // const id = JSON.parse("[" + this.props.match.params.id + "]");
    const id = this.props.match.params.id.split(",");
    const query = `query productTypeByIds($ids: [String!]!){
      productTypeByIds(ids: $ids){
        id
        sku
        title
        description
        images
        categories{
          id, 
          title
          description
        }
        print_files{
          key
          mockup
          title
          width
          height
          note
          variant_ids
        }
        printAreas{
          id
          name
          width
          height
          mockupId
          mockup{
            id
            title
            productID
            width
            height
            preview
            layers
            settings{
              defaultBgColor
              changeBgColorByVariantColor
              applyToVariants
            }
            ordering
            status
          }
          variant_ids
          note
        }
        product_type_variants{
          id
          product_type_id
          sku
          base_cost
          sale_cost
          retail_cost
          attributes{
            name, slug, option
          }
          active:is_active
        }
      }
    }`;
    this.setState({ loading: true });
    gql.request(query, { ids: id }).then((res) => {
      this.setState({
        productTypes: res.productTypeByIds || [],
        variations: [].concat.apply(
          [],
          res.productTypeByIds.map((type) => type.product_type_variants)
        ),
        printAreas: res.productTypeByIds.map((type) => ({
          product_type_id: type.id,
          title: type.title,
          printAreas: type.printAreas,
        })),
        loading: false,
        selectedVariations:
          (res.productTypeByIds &&
            res.productTypeByIds.length &&
            res.productTypeByIds[0].product_type_variants) ||
          [],
      });
      this.genPermalink();
    });
  }

  loadSelectedProduct() {
    // const id = JSON.parse("[" + this.props.match.params.id + "]");
    const id = this.props.match.params.id.split(",");
    const product_id = this.props.match.params.product_id;
    const query = `query productByID($id: String!, $ids: [String!]!){
      productByID(id: $id){
        id
        title
        description
        store_product_type
        weight
        weight_unit
        variants{
          id
          price
          regular_price
          product_type_id
          active
          product_type{
            title
          }
          print_files{
            key
            mockup
            title
            width
            height
            note
            file_id
            variant_ids
          }
          product_type_variant{
            id
            product_type_id
            sku
            attributes{
              name
              slug
              option
            }
            retail_cost
            base_cost
            sale_cost
            active:is_active
          }
        }
      }
      productTypeByIds(ids: $ids){
        id
        sku
        product_type_variants{
          id
          sku
          product_type_id
          active:is_active
          attributes{
            name
            slug
            option
          }
          retail_cost
          base_cost
          sale_cost
        }
        print_files{
          key
          mockup
          title
          width
          height
          note
          variant_ids
        }
        printAreas{
          id
          name
          width
          height
          mockupId
          variant_ids
          note
        }
      }
    }`;
    this.setState({ loading: true });
    gql.request(query, { id: product_id, ids: id }).then((res) => {
      const product = res.productByID;
      this.setState({ title: product.title });
      const productVariants = product.variants;
      const product_type_ids = [
        ...new Set(product.variants.map((el) => el.product_type_id)),
      ];
      let initProductType = [];
      product_type_ids.forEach((el) => {
        let product_type_variants = [];
        let type = res.productTypeByIds.find((type) => type.id === el);
        type.product_type_variants.forEach((v) => {
          let productv = productVariants.find(
            (variant) => variant.product_type_variant.id === v.id
          );
          if (productv) {
            product_type_variants.push({
              ...v,
              retail_cost: productv.regular_price
                ? productv.regular_price
                : v.retail_cost,
              retail: productv.regular_price ? false : true,
              sale_cost: productv.price ? productv.price : v.sale_cost,
              sale: productv.price ? false : true,
              active: productv.active,
            });
          }
        });
        const title = productVariants.find((p) => p.product_type_id === el);
        const item = {
          id: el,
          title: title.product_type.title,
          description: product.description,
          images: [],
          categories: [],
          printAreas: type.printAreas,
          product_type_variants: product_type_variants,
          sku: type.sku,
        };
        initProductType.push(item);
      });
      this.genPermalink();
      this.setState({
        productTypes: initProductType,
        variations: [].concat.apply(
          [],
          initProductType.map((type) => type.product_type_variants)
        ),
        printAreas: initProductType.map((type) => ({
          product_type_id: type.id,
          printAreas: type.printAreas,
        })),
        loading: false,
        selectedVariations:
          (initProductType &&
            initProductType.length &&
            initProductType[0].product_type_variants) ||
          [],
        storeProductType: product.store_product_type,
        weight: product.weight,
        weightUnit: product.weight_unit,
      });
    });
  }

  changeChecked = (e) => {
    if (e.target.checked === true) {
      this.setState({ is_custom_design: true });
    } else this.setState({ is_custom_design: false });
  };

  genPermalink() {
    let permalink = "";
    const { productTypes } = this.state;
    if (productTypes && productTypes.length > 0) {
      const type = productTypes[0];
      const randomSrt = randomString(8);
      permalink = `${type.sku}-${type.id}-${randomSrt}`;
      this.setState({ permalink: permalink });
    }
  }
  getWeightUnitValue = (value) => {
    switch (value) {
      case "GRAMS":
        return "g";
      case "OUNCES":
        return "oz";
      case "POUNDS":
        return "lb";
      default:
        return "kg";
    }
  };
  render() {
    const {
      images,
      productTypes,
      variations,
      selectedVariants,
      editVariantsImage,
      editVariantsPrice,
      selectedImage,
      bulkPrices,
      loading,
      selectedVariations,
      type,
      is_custom_design,
      loadingRenderMockup,
      excludeMockups,
    } = this.state;
    const { currentSite } = this.props;
    if (productTypes.length === 0 || !currentSite) return <Skeleton />;
    const columns = [
      {
        title: "Image",
        dataIndex: "id",
        key: "image",
        render: (id, variant) => (
          <Avatar
            shape="square"
            icon={<PictureOutlined />}
            size={50}
            style={{ cursor: "pointer" }}
            // src={variant.image ? variant.image.url : null}
            src={
              variant.image
                ? `${CLOUDFRONT_URL}/100/${get(variant.image.image, 'key') ? get(variant.image.image, 'key') : variant.image.image}`
                : null
            }
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                selectedVariants: [id],
                editVariantsImage: true,
              });
            }}
          />
        ),
      },
      {
        title: "Variant",
        dataIndex: "sku",
        key: "sku",
        render: (sku, { attributes }) => {
          return (
            <div>
              {attributes
                .map((attr) => `${attr.name}: ${attr.option}`)
                .join(", ")}{" "}
              <br />
              <span style={{ color: "#999" }}>SKU: {sku}</span>
            </div>
          );
        },
      },
      {
        title: "Base Cost",
        dataIndex: "base_cost",
        key: "base_cost",
        render: (base_cost) => `$${base_cost}`,
        align: "center",
      },
      {
        title: "Regular Price",
        dataIndex: "retail_cost",
        key: "retail_cost",
        render: (retail_cost, { id, base_cost, retail }) => (
          <Form.Item
          // validateStatus={retail ? "error" : null}
          // help={retail ? "Default regular price" : null}
          >
            <InputNumber
              formatter={(value) => `$${value}`}
              min={base_cost}
              value={retail_cost}
              onChange={(v) => {
                const { variations } = this.state;
                variations.find((v) => v.id === id).retail_cost = v;
                this.setState({
                  variations: variations,
                });
              }}
            />
          </Form.Item>
        ),
      },
      {
        title: "Sale Price",
        dataIndex: "sale_cost",
        key: "sale_cost",
        render: (sale_cost, { base_cost, id, sale }) => (
          <Form.Item
          // validateStatus={sale ? "error" : null}
          // help={sale ? "Default sale price" : null}
          >
            <InputNumber
              formatter={(value) => `$${value}`}
              min={0}
              value={sale_cost ? sale_cost : 0}
              onChange={(v) => {
                const { variations } = this.state;
                variations.find((v) => v.id === id).sale_cost = v;
                this.setState({
                  variations: variations,
                });
              }}
            />
          </Form.Item>
        ),
      },
      {
        title: "Profit",
        dataIndex: "id",
        key: "profit",
        render: (id, { sale_cost, retail_cost, base_cost }) => (
          <CurrencyFormat
            value={
              (sale_cost && sale_cost > 0 ? sale_cost : retail_cost) - base_cost
            }
            displayType="text"
            prefix={"$"}
            decimalScale={2}
          />
        ),
      },
      {
        title: "status",
        dataIndex: "active",
        key: "active",
        render: (active, { id }) => (
          <div>
            <Switch
              checked={active}
              onChange={(e) => {
                const { variations, selectedVariations } = this.state;
                variations.find((v) => v.id === id).active = e;
                selectedVariations.find((v) => v.id === id).active = e;
                this.setState({
                  variations: variations,
                  selectedVariations: selectedVariations,
                });
              }}
            ></Switch>
          </div>
        ),
      },
    ];
    const changeStatus = (status) => {
      let NewVariantions = variations.map((item) => {
        if (_.includes(selectedVariants, item.id)) {
          return { ...item, active: status };
        } else {
          return item;
        }
      });
      let newSelectedVariations = selectedVariations.map((item) => {
        if (_.includes(selectedVariants, item.id)) {
          return { ...item, active: status };
        } else {
          return item;
        }
      });
      this.setState({
        variations: NewVariantions,
        selectedVariants: [],
        selectedVariations: newSelectedVariations,
      });
    };
    

    const callbackLoopProducts = (data) =>{
      this.setState({
        loopProducts: data
      });
    }

    return (
      <Container is_custom_design={is_custom_design}>
        <Form layout="vertical" onFinish={this.onSubmit}>
          <Row gutter={20}>
            <Col span={24} xl={16}>
              <Card title="Detail">
                <Form.Item
                  label="Title"
                  name="title"
                  initialValue={
                    this.state.title ? this.state.title : productTypes[0].title
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input product title!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Permalink"
                  name="permalink"
                  initialValue={
                    this.state.permalink
                      ? this.state.permalink
                      : `${productTypes[0].sku}-${
                          productTypes[0].id
                        }-${randomString(8)}`
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item name="collection_ids" label="Colections">
                  <ColectionSelect />
                </Form.Item>
                <Form.Item name="tag_ids" label="Tags">
                  <TagsSelect />
                </Form.Item>
                <Form.Item
                  label="Decription"
                  name="description"
                  initialValue={
                    type === "clone"
                      ? productTypes[0].description
                      : productTypes.reduce(
                          (init, value, index) =>
                            (index === 0
                              ? value.categories[0].description
                              : "") +
                            init +
                            value.description,
                          ""
                        )
                  }
                >
                  <Wysiwyg />
                </Form.Item>
              </Card>
              <DraggableTabs
                style={{ marginTop: 20 }}
                defaultActiveKey={`${productTypes[0].id}`}
                onChange={(e) => {
                  this.setState({
                    selectedVariants: [],
                    productTypeIndex: e,
                    selectedVariations: variations.filter(
                      (v) => v.product_type_id === e
                    ),
                  });
                }}
                // onChangeTabs={([dragKey, hoverKey]) => {
                //   this.setState({
                //     productTypes: productTypes.map((type) =>
                //       type.id === dragKey
                //         ? productTypes.find((pt) => pt.id === hoverKey)
                //         : type.id === hoverKey
                //         ? productTypes.find((pt) => pt.id === dragKey)
                //         : type
                //     ),
                //   });
                // }}
                tabBarStyle={{
                  display: productTypes.length === 1 ? "none" : "block",
                }}
              >
                {productTypes.map((productType) => (
                  <TabPane tab={productType.title} key={productType.id}>
                    <Card
                      title="Variants"
                      extra={
                        <Dropdown
                          placement="bottomRight"
                          disabled={selectedVariants.length === 0}
                          overlay={
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  const {
                                    selectedVariants,
                                    selectedVariations,
                                  } = this.state;
                                  if (selectedVariants.length === 1) {
                                    let v = selectedVariations.find(
                                      (v) => v.id === selectedVariants[0]
                                    );
                                    this.setState(
                                      {
                                        bulkPrices: {
                                          retail_cost: v.retail_cost,
                                          sale_cost: v.sale_cost,
                                        },
                                      },
                                      () =>
                                        this.setState({
                                          editVariantsPrice: true,
                                        })
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        bulkPrices: {
                                          retail_cost: null,
                                          sale_cost: null,
                                        },
                                      },
                                      () =>
                                        this.setState({
                                          editVariantsPrice: true,
                                        })
                                    );
                                  }
                                }}
                              >
                                <DollarOutlined /> Price
                              </Menu.Item>
                              <Menu.Item
                                onClick={() =>
                                  this.setState({ editVariantsImage: true })
                                }
                              >
                                <PictureOutlined /> Image
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  changeStatus(true);
                                }}
                              >
                                <CgToggleOn /> Enable
                              </Menu.Item>
                              <Menu.Item onClick={() => changeStatus(false)}>
                                <CgToggleOff /> Disable
                              </Menu.Item>
                              {/* <Menu.Item
                                onClick={() => {
                                  const {
                                    selectedVariants,
                                    selectedVariations,
                                  } = this.state;
                                  this.setState({
                                    selectedVariations: selectedVariations.filter(
                                      (v) => !selectedVariants.includes(v.id)
                                    ),
                                    variations: variations.filter(
                                      (v) => !selectedVariants.includes(v.id)
                                    ),
                                    selectedVariants: [],
                                  });
                                }}
                              >
                                <DeleteOutlined style={{ color: "red" }} />{" "}
                                Remove
                              </Menu.Item> */}
                            </Menu>
                          }
                        >
                          <a
                            className="ant-dropdown-link"
                            href="/#"
                            onClick={(e) => e.preventDefault()}
                          >
                            <EditOutlined theme="twoTone" /> Edit{" "}
                            <DownOutlined />
                          </a>
                        </Dropdown>
                      }
                    >
                      <DndProvider backend={HTML5Backend}>
                        <Table
                          size="middle"
                          rowSelection={{
                            selectedRowKeys: selectedVariants,
                            onChange: (selectedVariants) =>
                              this.setState({ selectedVariants }),
                            selections:
                              selectedVariations &&
                              _.concat(
                                ...selectedVariations.map(
                                  (item) => item.attributes
                                )
                              ).reduce((b, c) => {
                                if (
                                  b.filter(
                                    (item) =>
                                      item.option === c.option &&
                                      item.name === c.name
                                  ).length === 0
                                ) {
                                  b.push({
                                    key: `${c.name} : ${c.option}`,
                                    option: c.option,
                                    name: c.name,
                                    text: `${c.name} : ${c.option}`,
                                    onSelect: (changableRowKeys) => {
                                      let newSelectedRowKeys = [];
                                      newSelectedRowKeys = selectedVariations.filter(
                                        (key) =>
                                          key.attributes.filter(
                                            (item) =>
                                              item.option === c.option &&
                                              item.name === c.name
                                          ).length > 0
                                      );
                                      this.setState({
                                        selectedVariants: newSelectedRowKeys.map(
                                          (el) => el.id
                                        ),
                                      });
                                    },
                                  });
                                }
                                return orderBy(
                                  b,
                                  ["name", "option"],
                                  ["asc", "asc"]
                                );
                              }, []),
                          }}
                          onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                          })}
                          components={this.components}
                          dataSource={
                            selectedVariations && selectedVariations.length
                              ? selectedVariations
                              : variations.filter(
                                  (v) => v.product_type_id === productType.id
                                )
                          }
                          columns={columns}
                          rowKey="id"
                          pagination={false}
                        />
                      </DndProvider>
                    </Card>
                    {/* <ProductPrintFile
                      images={images}
                      selectedVariations={selectedVariations}
                      setSelectedVariations={(value) => {
                        this.setState({ selectedVariations: value });
                      }}
                      variations={variations}
                      setvariations={(variations) => {
                        this.setState({ variations });
                      }}
                      subImages={(files) => {
                        this.onChangeImage(files);
                      }}
                      productType={productType}
                      printAreas={this.state.printAreas}
                      setPrintAreas={(printAreas) => {
                        this.setState({ printAreas });
                      }}
                      newId={this.state.newId}
                    /> */}
                  </TabPane>
                ))}
              </DraggableTabs>
            </Col>
            <Col span={24} xl={8}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="large"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{}}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                  // disabled={this.state.printAreas.find((item) => {
                  //   return item.printAreas.find((i) => !i.artwork_id);
                  // })}
                  disabled={
                    this.state.printAreas.find((item) => {
                      return item.printAreas.find((i) => !i.artwork_id);
                    }) ||
                    images.length === 0 ||
                    loadingRenderMockup
                  }
                >
                  Submit
                </Button>
              </div>
              {/* bundled products */}

              <BundledProducts CallbackLoopProducts={callbackLoopProducts} currentSite={currentSite} />

              <ProductPrintFile
                excludeMockups={excludeMockups}
                changeExcludeMockups={(value) => {
                  this.setState({ excludeMockups: value });
                }}
                images={images}
                selectedVariations={selectedVariations}
                setSelectedVariations={(value) => {
                  this.setState({ selectedVariations: value });
                }}
                variations={variations}
                setvariations={(variations) => {
                  this.setState({ variations });
                }}
                subImages={(files) => {
                  this.onChangeImage(files);
                }}
                printAreas={this.state.printAreas}
                setPrintAreas={(printAreas) => {
                  this.setState({ printAreas });
                }}
                newId={this.state.newId}
                loadingRenderMockup={loadingRenderMockup}
                setLoadingRenderMockup={(value) =>
                  this.setState({ loadingRenderMockup: value })
                }
              />

              <Card title="Images" style={{ marginTop: 20 }}>
                <Form.Item>
                  {/* <UploadImages
                    value={images}
                    onChange={this.onChangeImage}
                    showButtonUpload={this.state.showButtonUpload}
                    accept=".jpg, .png, .jpeg"
                  /> */}
                  <ImagesProduct
                    id={this.state.newId}
                    value={images}
                    onChange={(files) => {
                      this.onChangeImage(files);
                    }}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <div style={{ display: "flex" }}>
            <Button
              size="large"
              style={{ marginRight: 10 }}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
              disabled={
                this.state.printAreas.find((item) => {
                  return item.printAreas.find((i) => !i.artwork_id);
                }) ||
                images.length === 0 ||
                loadingRenderMockup
              }
              // disabled={images.length === 0}
            >
              Submit
            </Button>
          </div>
        </Form>
        <Modal
          title="Select variant image"
          visible={editVariantsImage}
          onCancel={() => this.setState({ editVariantsImage: false })}
          okButtonProps={{ disabled: selectedImage === null }}
          onOk={() => {
            const {
              selectedImage,
              selectedVariants,
              variations,
              selectedVariations,
            } = this.state;
            variations.forEach((variant) => {
              if (selectedVariants.includes(variant.id)) {
                variant.image = selectedImage;
              }
            });
            selectedVariations.forEach((variant) => {
              if (selectedVariants.includes(variant.id)) {
                variant.image = selectedImage;
              }
            });

            this.setState({
              variations,
              selectedImage: null,
              editVariantsImage: false,
              selectedVariations,
              selectedVariants: [],
            });
          }}
        >
          <UploadImages
            selectMode={true}
            value={images}
            onChange={this.onChangeImage}
            onSelect={(image) => this.setState({ selectedImage: image })}
            showButtonUpload={this.state.showButtonUpload}
            accept=".jpg, .png, .jpeg"
          />
        </Modal>
        <Modal
          title="Update variants price"
          visible={editVariantsPrice}
          onCancel={() => this.setState({ editVariantsPrice: false })}
          onOk={(e) => {
            e.preventDefault();
            const { variations, selectedVariants } = this.state;
            if (bulkPrices.retail_cost) {
              variations.forEach((v) => {
                if (selectedVariants.includes(v.id)) {
                  v.retail = false;
                  v.retail_cost = bulkPrices.retail_cost;
                }
              });
            }
            if (bulkPrices.sale_cost) {
              variations.forEach((v) => {
                if (selectedVariants.includes(v.id)) {
                  v.sale = false;
                  v.sale_cost = bulkPrices.sale_cost;
                }
              });
            }
            this.setState({
              variations,
              editVariantsPrice: false,
              selectedVariants: [],
            });
          }}
        >
          <Form.Item label="Regular Price">
            <InputNumber
              formatter={(value) => `$${value}`}
              value={bulkPrices.retail_cost}
              onChange={(v) =>
                this.setState({
                  bulkPrices: { ...bulkPrices, retail_cost: v },
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Sale Price"
            style={{ display: "grid", gridTemplateColumns: "100px 1px" }}
          >
            <InputNumber
              formatter={(value) => `$${value}`}
              value={bulkPrices.sale_cost}
              onChange={(v) =>
                this.setState({
                  bulkPrices: { ...bulkPrices, sale_cost: v },
                })
              }
            />
          </Form.Item>
        </Modal>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    currentSite: state.app.currentSite,
    product_id: state.product,
  }),
  { setProduct }
)(PushStepTwo);
