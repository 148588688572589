import gql from "graphql-tag";

export const TAGS = gql`
  query tags($filter: TagFilter) {
    tags(filter: $filter) {
      count
      hits {
        id
        title
        description
      }
    }
  }
`;
