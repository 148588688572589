import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, notification } from "antd";
import React from "react";
import { CREATE_ARTWORK_CATEGORY } from "../../graphql/mutation/artworkCategoryMutation";

const ItemAddCategories = ({ refetch }) => {
  const [form] = Form.useForm();
  const [createCategory] = useMutation(CREATE_ARTWORK_CATEGORY);
  const onSubmit = (value) => {
    createCategory({ variables: { title: value.title } })
      .then((res) => {
        form.resetFields();
        refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  return (
    <Form onFinish={onSubmit} form={form}>
      <div style={{ display: "flex" }}>
        <Form.Item
          name="title"
          rules={[{ required: true, message: "Please input your title!" }]}
        >
          <Input style={{ width: 280, marginLeft: 10 }} />
        </Form.Item>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 10,marginRight:10 }}
        >
          {" "}
          Add categories
        </Button>
      </div>
    </Form>
  );
};

export default ItemAddCategories;
