import { useQuery } from "@apollo/client";
import { Card, Image, Skeleton, Space, Switch } from "antd";
import React, { useEffect } from "react";
import { CLOUDFRONT_URL } from "../../config";
import mockupsByProductType from "../../graphql/queries/mockupsByProductType";
import Grid from "../Grid";
import _ from "lodash";

const BaseMockup = (props) => {
  const {
    idBase,
    excludeMockups,
    changeExcludeMockups,
    setProductTypeMockup,
  } = props;
  const { data, loading } = useQuery(mockupsByProductType, {
    variables: { productTypeID: idBase },
  });
  useEffect(() => {
    if (data) {
      setProductTypeMockup(data, idBase);
    }
  }, [data]);
  if (loading) return <Skeleton.Image />;
  return (
    <Grid width={120} gap={20}>
      {data.mockups.map((mk) => (
        <Card
          key={mk.id}
          size="small"
          style={{ height: 120 }}
          cover={
            <div>
              <Image
                src={`${CLOUDFRONT_URL}200x200/${mk.preview}`}
                preview={{
                  src: `${CLOUDFRONT_URL}autoxauto/${mk.preview}`,
                }}
                style={{ objectFit: "contain", padding: 1 }}
                fallback="/no-preview.jpg"
                height={120}
                width="100%"
              />
              <Space
                className="mockup-toolbar"
                style={{ position: "absolute", right: 5, top: 5, zIndex: 10 }}
              >
                <Switch
                  size="small"
                  defaultChecked={!_.includes(excludeMockups, mk.id)}
                  onChange={(e) => {
                    if (e) {
                      changeExcludeMockups(
                        _.difference(excludeMockups, [mk.id])
                      );
                    } else {
                      changeExcludeMockups([...excludeMockups, mk.id]);
                      // excludeMockups.push(mk.id);
                    }
                  }}
                />
              </Space>
            </div>
          }
          bodyStyle={{ display: "none" }}
        />
      ))}
    </Grid>
  );
};

export default BaseMockup;
