import gql from "graphql-tag";

export default gql`
  mutation createSignedUrl(
    $filename: String!
    $fileMime: String!
    $fileKey: String
  ) {
    createSignedUrl(
      filename: $filename
      fileMime: $fileMime
      fileKey: $fileKey
    ) {
      key
      url
    }
  }
`;
