import React, { useState, useEffect } from "react";
import KlingHistory from "./KlingHistory";
import { Skeleton } from "antd";
import ModelImageDetait from "./ModelImageDetait";
export default function KlingPreview({ type, data, onChangeData }) {
  const [indexCurent, setIndexCurent] = useState(null);
  const [dataCurent, setDataCurent] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (data.length > 0) {
      setIndexCurent(data.length - 1);
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    if (indexCurent != null) {
      const find = data.find((e, i) => i == indexCurent);
      if (find) {
        setDataCurent(find.data);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [indexCurent]);

  const handleChangeCurrentIndex = (v) => {
    setIndexCurent(v);
  };

  const handleDeleteItem = (index) => {
    const newData = data.filter((e, i) => i != index);
    onChangeData(newData);
  };

  const handleDeleteAll = () => {
    onChangeData([]);
  };

  const convertToHtmlLink = (text) => {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/g;
    const htmlText = text.replace(
      pattern,
      '<a  target="_blank" href="$2">$1</a>'
    );
    return htmlText;
  };

  return (
    <div
      style={{
        padding: 20,
        minHeight: 500,
        position: "relative",
        width: 550,
        maxWidth: "100%",
      }}
    >
      {!loading ? (
        <>
          {indexCurent !== null && (
            <KlingHistory
              type={type}
              history={data}
              onDeleteItem={handleDeleteItem}
              onDeleteAll={handleDeleteAll}
              currentIndex={indexCurent}
              onSelect={handleChangeCurrentIndex}
              onChangeCurrentIndex={handleChangeCurrentIndex}
            />
          )}
          {type == "image" && (
            <div className="kling-group">
              {dataCurent.map((i, index) => (
                <ModelImageDetait key={index} src={i}></ModelImageDetait>
              ))}
            </div>
          )}
          {type == "video" && (
            <div className="kling-group-video">
              {dataCurent.map((i, index) => (
                <div className="kling-group-video-item" key={index}>
                  <video
                    className="kling-group-video-item__img"
                    width="100%"
                    controls
                  >
                    <source src={i} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </div>
              ))}
            </div>
          )}

          {type == "describe" && (
            <div className="kling-group-describe">
              {dataCurent.map((i, index) => (
                <div
                  className="kling-group-describe-item"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: convertToHtmlLink(i),
                  }}
                ></div>
              ))}
            </div>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
    </div>
  );
}
