import React from "react";
import styled from "styled-components";
import { Form, Card, Input, Button, message } from "antd";
import gql from "../../api/gql";
import * as _ from "lodash";
import { history } from "../../history";

const Container = styled.div``;

class UserChangePassword extends React.Component {
  formRef = React.createRef();
  state = {
    loading: false,
  };

  changePassword(id, current_password, new_password) {
    this.setState({ loading: true });
    const mutation = `mutation changePassword($id:String! $current_password: String, $new_password: String!){
      changePassword(id: $id current_password: $current_password new_password: $new_password )
      }`;
    gql
      .request(mutation, { id, current_password, new_password })
      .then((res) => {
        this.setState({ loading: false });
        message["success"]("Your password updated");
        history.goBack();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message["error"](_.get(err, "[0].message"));
      });
  }
  compareToFirstPassword = (rule, values, callback) => {
    if (
      values &&
      values !== this.formRef.current.getFieldValue("new_password")
    ) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };
  onSubmit = (values) => {
    const { id } = this.props;
    if (id) {
      this.changePassword(id, values.current_password, values.new_password);
    }
  };
  render() {
    const { id, currentUser } = this.props;
    return (
      <Container>
        <Card title="Change Password">
          <Form layout="vertical" onFinish={this.onSubmit} ref={this.formRef}>
            {id === currentUser.id && (
              <Form.Item
                label="Current Password"
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter current password",
                  },
                ]}
              >
                <Input.Password autoComplete="current_password" />
              </Form.Item>
            )}
            <Form.Item
              label="New Password"
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Please enter new password",
                },
              ]}
            >
              <Input.Password autoComplete="new_password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm"
              rules={[
                {
                  required: true,
                  message: "Please enter confirm password",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ]}
            >
              <Input.Password autoComplete="confirm" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Container>
    );
  }
}

export default UserChangePassword;
