import gql from "graphql-tag";

export const CREATE_PRODUCT = `
  mutation createProduct($input: NewProduct!) {
    createProduct(input: $input) {
      id
      title
      description
      sku
      images
      bundled_products
      variants {
        id
        product_id
        product_type_id
      }
      created_at
      updated_at
      is_custom_design
      store_product_type
      weight
      weight_unit
      product_stores {
        id
        site_id
        origin_id
        permalink
      }
      print_areas {
        id
        product_type_id
        name
        product_type_print_area_id
        artwork_id
      }
    }
  }
`;
export const GENERATE_PRODUCT_MOCKUPS = `
  mutation generateProductMockups($input: [ProductMockupInput!]) {
    generateProductMockups(input: $input) 
  }
`;
export const GENERATE_PRODUCT_MOCKUP = gql`
  mutation generateProductMockups(
    $input: [ProductMockupInput!]
    $excludeMockups: [String!]
  ) {
    generateProductMockups(input: $input, excludeMockups: $excludeMockups)
  }
`;
export const SUBCRIPTION_GENERATE_PRODUCT_MOCKUP = gql`
  subscription {
    generateMockupProduct {
      id
      mockupId
      productId
      variantIds
      image
      sorting
      productTypeVariantIds
      allDone
    }
  }
`;
