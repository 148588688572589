import React from "react";
import { Form, Input, Card, notification, Button, Skeleton } from "antd";
import Wysiwyg from "../wysiwyg";
import _ from "lodash";
import { history } from "../../history";
import gql from "../../api/gql";
import { SaveOutlined } from "@ant-design/icons";
class CategoryForm extends React.Component {
  state = {
    category: null,
    loading: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      const query = `query categoryById($id:String!){
        categoryById(id:$id){
          id title description
        }
      }`;
      this.setState({ loading: true });
      gql
        .request(query, { id: id })
        .then((res) =>
          this.setState({ category: res.categoryById || [], loading: false })
        )
        .catch((err) => {
          notification["error"]({
            message: "ERROR",
            description: _.get(err, "[0].message"),
          });
          this.setState({ loading: false });
        });
    }
  }

  saveCategory = (values) => {
    const { id } = this.props.match.params;
    this.setState({ loading: true });
    var mutation = `mutation createCategory($input: NewCategory!){
          createCategory(input: $input){
            id 
          }
        }`;
    if (id) {
      mutation = `mutation updateCategory($id:String! $input: EditCategory!){
            updateCategory(id: $id,input: $input)
              {
                id
              }
            }`;
      gql
        .request(mutation, { id: id, input: values })
        .then((res) => {
          notification["success"]({
            message: "Catygory has been " + (id ? "updated" : "created"),
          });
          this.setState({ loading: false });
          history.goBack();
        })
        .catch((err) => {
          notification["error"]({
            message: "ERROR",
            description: _.get(err, "[0].message"),
          });
          this.setState({ loading: false });
        });
    } else {
      gql
        .request(mutation, { input: values })
        .then((res) => {
          notification["success"]({
            message: "Catygory has been " + (id ? "updated" : "created"),
          });
          this.setState({ loading: false });
          history.goBack();
        })
        .catch((err) => {
          notification["error"]({
            message: "ERROR",
            description: _.get(err, "[0].message"),
          });
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { category, loading } = this.state;
    const { id } = this.props.match.params;
    if (id && !category) return <Skeleton />;
    return (
      <div>
        <Form layout="vertical" onFinish={this.saveCategory}>
          <Card title="Detail" style={{ marginBottom: 20 }}>
            <Form.Item
              label="Title"
              name="title"
              initialValue={category ? category.title : ""}
              rules={[
                {
                  required: true,
                  message: "Please input title",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              initialValue={category ? category.description : ""}
            >
              <Wysiwyg />
            </Form.Item>
            <Button
              style={{ marginRight: 5 }}
              type="primary"
              loading={loading}
              icon={<SaveOutlined />}
              htmlType="submit"
            >
              Save
            </Button>
            <Button onClick={() => history.goBack()}>Cancel</Button>
          </Card>
        </Form>
      </div>
    );
  }
}

export default CategoryForm;
