import React, { useState, useEffect } from "react";
import { Form, Spin } from "antd";
import axios from "axios";
import { URL_API_MIDJOURNEY } from "../../../../config";
const SelectType = ({ onSelect }) => {
  const [types, setTypes] = useState([]);
  const [type, setType] = useState(null);
  const [loadingType, setLoadingType] = useState(false);
  const getTypes = () => {
    setLoadingType(true);
    axios
      .post(URL_API_MIDJOURNEY, {
        caseFunction: "types",
      })
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setTypes(res.data);
        setLoadingType(false);
      })
      .catch((errpr) => {
        setLoadingType(false);
      });
  };

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {
    onSelect(type);
  }, [type]);

  return (
    <>
      <Form.Item label={`Type: ${type ? type : ""}`}>
        <Spin spinning={loadingType}>
          <div
            className="list-type-image-generation"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "start",
              overflow: "auto",
              minHeight: 125,
            }}
          >
            {types.map((item, index) => (
              <div
                style={{
                  width: "20%",
                  minWidth: "20%",
                  aspectRatio: "1/1",
                  marginRight: 10,
                  cursor: "pointer",
                  borderRadius: 5,
                  border: type == item.label ? "1px solid #ff9100" : "none",
                  padding: type == item.label ? "5px" : "0px",
                }}
                onClick={() => {
                  if (type == item.label) {
                    setType(null);
                  } else {
                    setType(item.label);
                  }
                }}
                key={item.id}
              >
                <div>
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 4,
                    }}
                    src={item.src}
                  />
                </div>
                <div
                  style={{
                    height: 20,
                    overflow: "hidden",
                  }}
                >
                  {item.label}
                </div>
              </div>
            ))}
          </div>
        </Spin>
      </Form.Item>
    </>
  );
};
export default SelectType;
