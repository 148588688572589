import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setOrdersFilter, setOrderId } from "../../actions";
import {
  Button,
  Popconfirm,
  Drawer,
  Tabs,
  Badge,
  message,
  Dropdown,
  Menu,
  notification,
  Alert,
  Timeline,
  Image,
  Modal,
  Tag,
} from "antd";
import Table from "../Table";
import moment from "moment";
import gql from "../../api/gql";
import ButtonGroup from "antd/lib/button/button-group";
import CustomerInfo from "../orders/CustomerInfo";
import Comments from "../orders/Comments";
import UploadDesigns from "../products/UploadDesigns";
import _ from "lodash";
import SellerOrderFilter from "./SellerOrderFilter";
import OrdersToolbar from "../orders/OrdersToolbar";
import FetchOrders from "../orders/FetchOrders";
import { ORDER_FIELD } from "../admin/Orders";
import styled from "styled-components";
import UploadCustomDesigns from "../products/UploadCustomDesigns";
import {
  DownOutlined,
  CloudDownloadOutlined,
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  ContactsOutlined,
  EyeOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { GET_ORDER_HISTORY } from "../../graphql/queries/orders/orders";
import { CLOUDFRONT_URL } from "../../config";
import { BsCardChecklist, BsFileCheck } from "react-icons/bs";
import CustomerAddress from "../orders/CustomerAddress";
import AcceptDesign from "../orders/AcceptDesign";
import CustomizationNew from "../orders/CustomizationNew";
import ModalPreviewimage from "../ModalPreviewimage";
import SubOrder from "../admin/SubOrder";
import qs from "query-string";

const Container = styled.div`
  .seller-order {
    display: flex;
    justify-content: space-between;
  }
  .toolbar {
    display: flex;
    justify-content: flex-end;
    .ant-btn.ant-btn-primary {
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: 1140px) {
    .seller-order {
      display: flex;
      flex-direction: column-reverse;
    }
    .toolbar {
      display: flex;
      justify-content: flex-end;
      .ant-btn.ant-btn-primary {
        margin-left: 5px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .seller-order {
      display: flex;
      flex-direction: column-reverse;
    }
    .toolbar {
      display: flex;
      justify-content: flex-end;
      .ant-btn.ant-btn-primary {
        margin-left: 5px;
      }
    }
  }
`;

const PER_PAGE = 20;
const checkStatusDesign = (design) => {
  const status = design?.map((item) => item.status);
  if (_.includes(status, "Error")) {
    return {
      color: "error",
      status: "Error",
    };
  }
  if (_.includes(status, "Processing")) {
    return {
      color: "processing",
      status: "Processing",
    };
  }
  if (_.includes(status, "Pending")) {
    return {
      status: "Pending",
      color: "warning",
    };
  }
  return {
    color: "success",
    status: "View",
  };
};
class Orders extends Component {
  state = {
    orders: [],
    aggs: [],
    selectedOrder: null,
    total: 0,
    filter: {
      ...this.props.filter,
      offset: 0,
      limit: PER_PAGE,
      aggs_by: "status",
    },
    page: 1,
    loading: false,
    showUpdateCustomer: false,
    showOrderComments: false,
    showUploadDesign: false,
    showFetchOrders: false,
    selectedOrderCarrier: null,
    carriers: [],
    loadingMenuIcon: "down",
    initSetting: [],
    productTypes: [],
    orderHistory: [],
    showHistory: false,
    showAcceptDesign: false,
    showCustomization: false,
    previewImage: false,
  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (this.props.filter.site_ids) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            ...query,
            product_type_ids: query.product_type_ids
              ? [query.product_type_ids]
              : null,
            field: query.field ? query.field : this.state.filter.field,
            seller_ids: null,
            site_ids: this.props.filter.site_ids,
          },
        },
        () => this.loadOrders()
      );
      this.loadOrders();
    }
    this.loadAppSetting();
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter.site_ids && prevProps.filter !== this.props.filter) {
      this.setState(
        {
          page: 1,
          filter: {
            ...this.props.filter,
            offset: 0,
            limit: PER_PAGE,
            aggs_by: "status",
          },
        },
        () => this.loadOrders()
      );
    }
  }

  loadOrders() {
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        filter: this.state.filter,
      })
      .then((res) => {
        this.setState({
          orders: res.orders.hits || [],
          aggs: res.orders.aggs || [],
          total: res.orders.count,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  loadOrderCarriers = (order_id) => {
    let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        variant{
          product_type_variant{
            id
            carrier_costs{
              id
              carrier_id
              cost
              default
            }
          }
          product_type{
            state
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`;
    this.setState({ loadingMenuIcon: "loading" });
    gql
      .request(query)
      .then((res) => {
        let carriers = res.order.variant.product_type.product_type_carriers;
        let variantCarrierCosts =
          res.order.variant.product_type_variant.carrier_costs;
        carriers.map((cr) => {
          const cost = variantCarrierCosts.filter(
            (c) => c.carrier_id === cr.carrier.id
          );
          if (cost && cost.length > 0) {
            cr.price = cost[0].cost;
          }
          return cr;
        });
        this.setState({
          carriers: carriers,
          loadingMenuIcon: "down",
        });
      })
      .catch((err) => {
        this.setState({
          loadingMenuIcon: "down",
        });
        notification.error({
          message: _.get(err, "[0].message"),
        });
      });
  };

  statusFilter = (status) => {
    const { setOrdersFilter } = this.props;
    setOrdersFilter({
      ...this.state.filter,
      status: status === "all" ? null : [status],
    });
  };

  pauseOrResumeOrder = (id, status) => {
    const name = status === "pending" ? "resumeOrder" : "pauseOrder";
    const mutation = `mutation ${name}($order_id: String!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](
          `Order has been ${status === "pending" ? "resumed " : "paused"}`
        );
        // this.setState({ loading: false }),
        // this.loadOrders()
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            if (name === "pauseOrder") {
              return res.pauseOrder;
            } else {
              return res.resumeOrder;
            }
          } else return el;
        });
        this.setState({ orders, loading: false });
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  cancelOrder = (id) => {
    const mutation = `mutation cancelOrder($order_id: String!){
      cancelOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](`Order has been cancel`);
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.cancelOrder;
          } else return el;
        });
        this.setState({ orders, loading: false });
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  enterPayment = (id) => {
    const mutation = `mutation enterPayment($order_id: String!){
      enterPayment(order_id: $order_id)
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: +id })
      .then((res) => {
        this.setState({ loading: false });
        message["success"](`Order has been enter payment`);
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  fetchOrdersHandle = () => {
    this.setState({ showFetchOrders: true });
  };
  loadAppSetting() {
    const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`;
    const input = ["seller_message"];
    this.setState({ loading: true });
    gql
      .request(query, { input: input })
      .then((res) => {
        this.setState({
          initSetting: res.getAppSettings || [],
          loading: false,
        });
      })
      .catch((err) => {
        notification["error"]({
          message: "Error",
          description: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  }
  loadOrderHistory() {
    this.setState({ loading: true });
    gql
      .request(GET_ORDER_HISTORY, {
        order_id: this.state.selectedOrder.id,
      })
      .then((res) => {
        this.setState({
          orderHistory: res.getOrderHistory || [],
          loading: false,
        });
      })
      .catch((err) => {
        notification.error({ message: _.get(err, "[0].message") });
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      orders,
      aggs,
      total,
      filter,
      loading,
      page,
      selectedOrder,
      showUpdateCustomer,
      showOrderComments,
      showUploadDesign,
      showFetchOrders,
      initSetting,
      orderHistory,
      showHistory,
      previewImage,
    } = this.state;
    const setting =
      initSetting &&
      initSetting.reduce(
        (init, item) => ({ ...init, [item.name]: item.value }),
        {}
      );
    const columns = [
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
        width: 250,
        render: (id, order) => (
          <div>
            <p>
              <b>App ID: </b>
              {id}
            </p>
            <p>
              {" "}
              <b>Origin Id: </b>
              {`${order.origin_id} `}
            </p>
            <p>
              <b>Origin Number: </b>
              {order.name ? `${order.name}` : null}
            </p>
          </div>
        ),
      },

      {
        title: "Product / Variant",
        dataIndex: "product",
        key: "product",
        width: 350,
        render: (id, order) => {
          // let image = order.product.mockups?.find((item) =>
          //   _.includes(item.variantIds, order.variant.id)
          // );
          let image = order.variant.mockup?.image ? order.variant.mockup : null;
          if (!image) {
            image = order.product.mockups
              ? { image: order.product.mockups[0].image }
              : null;
          }
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Image.PreviewGroup>
                {image ? (
                  <Image
                    style={{
                      backgroundColor: "#f5f5f5",
                      objectFit: "cover",
                      width: 120,
                      height:"auto",
                    }}
                    preview={{
                      src: `${CLOUDFRONT_URL}/autoxauto/${image.image}`,
                    }}
                    src={`${CLOUDFRONT_URL}/100/${image.image}`}
                    fallback={`/no-preview.jpg`}
                  />
                ) : (
                  <Image
                    style={{
                      backgroundColor: "#f5f5f5",
                      objectFit: "contain",
                      height: "auto",
                      width: 120,
                    }}
                    preview={{
                      src: `${CLOUDFRONT_URL}/autoxauto/`,
                    }}
                    src={`${CLOUDFRONT_URL}/100/`}
                    fallback={`/no-preview.jpg`}
                  />
                )}
              </Image.PreviewGroup> */}
              <div className="cp-image" style={{ width: 120 }}>
                <Image
                  style={{
                    backgroundColor: "#f5f5f5",
                    objectFit: `${image?.image ? "cover" : "contain"}`,
                    height: 120,
                    width: 100,
                  }}
                  preview={false}
                  // preview={{
                  //   src: `${CLOUDFRONT_URL}/autoxauto/${mockup.image}`,
                  // }}
                  src={`${CLOUDFRONT_URL}/100/${image?.image}`}
                  fallback={`/no-preview.jpg`}
                />
                {image ? (
                  <div
                    style={{ height: 120 }}
                    className="cp-prewview-image"
                    onClick={() => {
                      this.setState({ previewImage: [image.image] });
                    }}
                  >
                    <EyeOutlined style={{ marginRight: 5 }} />
                    Preview
                  </div>
                ) : null}
              </div>
              <div style={{ paddingLeft: 10 }}>
                <span>{`${order.product.title}`}</span>
                <div>
                  {order.variant.product_type_variant.attributes &&
                    order.variant.product_type_variant.attributes.map(
                      (a, index) => {
                        return (
                          <Fragment key={index}>
                            <span
                              style={{ marginBottom: 0, fontStyle: "italic" }}
                            >
                              <b>{a.slug}</b>: {a.option}{" "}
                            </span>
                          </Fragment>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Designs",
        dataIndex: "id",
        key: "id-design",
        width: 150,
        render: (_, order) => {
          const designStatus = checkStatusDesign(order.designs);
          return (
            <Tag
              color={designStatus.color}
              icon={
                designStatus.status === "View" ? (
                  <CheckOutlined />
                ) : designStatus.status === "Processing" ? (
                  <SyncOutlined spin />
                ) : designStatus.status === "Error" ? (
                  <CloseCircleOutlined />
                ) : null
              }
              // type="primary"
              // ghost
              style={{
                cursor: "pointer",
                minWidth: 100,
                padding: 5,
                textAlign: "center",
                fontSize: 14,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showAcceptDesign: order.id,
                  selectedOrder: order,
                });
              }}
            >
              {designStatus.status}
            </Tag>
          );
        },
      },
      {
        title: "QTY / COST",
        dataIndex: "base_cost",
        key: "base_cost",
        width: 150,
        render: (id, order) => (
          <div>
            <span>{`${order.quantity} x $${order.base_cost}`}</span>
            <br />
            <span style={{ marginBottom: 0, fontStyle: "italic" }}>
              {moment(order.created_at).format("MMM DD, YYYY")}
            </span>
          </div>
        ),
      },
      // {
      //   title: 'Seller',
      //   dataIndex: 'seller',
      //   key: 'site',
      //   width: 200,
      //   render: (id, order) =>
      //     <div>
      //       <p>{`${order.site && order.site.user ? order.site.user.first_name : ''} ${order.site && order.site.user ? order.site.user.last_name : ''}`}</p>
      //       <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.site.title}</p>
      //     </div>
      // },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 250,
        render: (id, order) => (
          <div>
            <span>{`${order.customer.first_name} ${order.customer.last_name}`}</span>
            <br />
            <span style={{ marginBottom: 0, fontStyle: "italic" }}>
              {order.customer.email}
            </span>
          </div>
        ),
      },
      {
        title: "Status",
        width: 150,
        // fixed: "right",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          const item = orderStatus.find((el) => [status].includes(el.key));
          return (
            item && <span style={{ color: `${item.color}` }}>{item.title}</span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        align: "right",
        fixed: "right",
        width: 100,
        render: (id, order) => (
          <div>
            <ButtonGroup>
              {/* {["pending_review", "accepted"].includes(order.status) && (
                <Fragment>
                  <Popconfirm
                    title="Are you sure to cancel this order?"
                    onConfirm={() => this.cancelOrder(id)}
                  >
                    <Button
                      style={{ padding: 0, borderRadius: 4, color: "red" }}
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Popconfirm>
                  <Divider type="vertical" style={{ marginTop: "0.45em" }} />
                </Fragment>
              )} */}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 0,
                        }}
                        icon={<ContactsOutlined />}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showUpdateCustomer: true,
                            selectedOrder: order,
                          });
                        }}
                      >
                        Customer Info
                      </Button>
                    </Menu.Item>
                    {order.status.includes("pending_review") && (
                      <Menu.Item key="4">
                        <Button
                          type="link"
                          style={{
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: 0,
                          }}
                          onClick={() =>
                            this.setState({ showAcceptDesign: id })
                          }
                          icon={<BsFileCheck className="anticon" />}
                        >
                          {order.status === "pending_review"
                            ? "Accepted Design"
                            : "View Desings"}
                        </Button>
                      </Menu.Item>
                    )}
                    <Menu.Item key="6">
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 0,
                        }}
                        onClick={() => this.setState({ showCustomization: id })}
                        icon={<BsCardChecklist className="anticon" />}
                      >
                        Customization
                      </Button>
                    </Menu.Item>
                    {!order.status.includes("cancelled") && (
                      <Menu.Item key="7">
                        <Popconfirm
                          title={`Are you sure to cancel this order?`}
                          onConfirm={() => this.cancelOrder(id)}
                        >
                          <Button
                            type="link"
                            style={{
                              paddingLeft: 0,
                            }}
                            size="small"
                            icon={<CloseOutlined />}
                          >
                            Cancel Order
                          </Button>
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {
                      <Menu.Item key="3">
                        <Button
                          type="link"
                          style={{
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: 0,
                          }}
                          icon={<HistoryOutlined />}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState(
                              {
                                showHistory: true,
                                selectedOrder: order,
                              },
                              () => this.loadOrderHistory()
                            );
                          }}
                        >
                          History
                        </Button>
                      </Menu.Item>
                    }
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                // style={{ borderRadius: 4, padding: 0 }}
                // size="small"
                >
                  Action <DownOutlined />
                </Button>
              </Dropdown>
            </ButtonGroup>
          </div>
        ),
      },
    ];
    const orderStatus = [
      {
        key: "pending_review",
        title: "Pending Review",
        color: "orange",
      },
      {
        key: "accepted",
        title: "Accepted Design",
        color: "blue",
      },
      {
        key: "exported",
        title: "Exported",
        color: "green",
      },
      {
        key: "rejected",
        title: "Rejected design",
        color: "#f78783",
      },
      {
        key: "cancelled",
        title: "Cancelled",
        color: "#fa5b55",
      },
      {
        key: "error",
        title: "Error",
        color: "red",
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadOrders()
        );
      },
    };
    const alert = (
      <div dangerouslySetInnerHTML={{ __html: setting["seller_message"] }} />
    );
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.setOrderId(selectedRowKeys);
        this.setState({ orderSelect: selectedRows });
      },
    };
    return (
      <Container>
        {setting && setting["seller_message"] && (
          <Alert style={{ marginBottom: 20 }} type="error" message={alert} />
        )}
        <div className="seller-order">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "-webkit-fill-available",
            }}
          >
            <SellerOrderFilter {...this.props} />
          </div>
          <div style={{ textAlign: "right" }}>
            <div className="toolbar">
              <OrdersToolbar />
              {this.props.currentSite?.type !== "offline" && (
                <Button
                  onClick={this.fetchOrdersHandle}
                  type="primary"
                  icon={<CloudDownloadOutlined />}
                >
                  Fetch Orders
                </Button>
              )}
            </div>
          </div>
        </div>
        <Tabs
          onChange={this.statusFilter}
          style={{ marginBottom: 0 }}
          activeKey={filter.status ? filter.status[0] : "all"}
        >
          <Tabs.TabPane
            tab={
              <Badge
                overflowCount={9999999}
                count={_.sum(aggs.map((agg) => agg.doc_count))}
                style={{
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #f5f5f5",
                  right: -20,
                }}
              >
                All
              </Badge>
            }
            key="all"
          ></Tabs.TabPane>
          {orderStatus.map((s) => (
            <Tabs.TabPane
              key={s.key}
              tab={
                <Badge
                  overflowCount={9999999}
                  count={_.sum(
                    aggs
                      .filter((agg) => agg.key === s.key)
                      .map((a) => a.doc_count)
                  )}
                  style={{ backgroundColor: s.color }}
                >
                  {s.title}
                </Badge>
              }
            />
          ))}
        </Tabs>
        <SubOrder
          orders={orders}
          setOrders={(values) => this.setState({ orders: values })}
        />
        <Table
          scroll={{ x: tableWidth }}
          // rowSelection={
          //   filter.status?.includes("accepted") ? rowSelection : false
          // }
          rowSelection={rowSelection}
          columns={columns}
          dataSource={orders}
          pagination={pagination}
          rowKey={({ id }) => id}
          loading={loading}
        />
        {this.state.showAcceptDesign && (
          <Modal
            title={
              <>
                Order Designs <Tag>{this.state.showAcceptDesign}</Tag>
              </>
            }
            width={800}
            visible={this.state.showAcceptDesign}
            onCancel={() => this.setState({ showAcceptDesign: false })}
            footer={false}
          >
            <AcceptDesign
              id={this.state.showAcceptDesign}
              onCancel={() => this.setState({ showAcceptDesign: false })}
              refetchOrders={() => {
                this.setState({ showAcceptDesign: false });
                this.loadOrders();
              }}
            />
          </Modal>
        )}
        {this.state.showCustomization && (
          <Modal
            title="Customization"
            visible={this.state.showCustomization}
            onCancel={() => this.setState({ showCustomization: false })}
            footer={false}
          >
            <CustomizationNew
              id={this.state.showCustomization}
              refetch={() => this.loadOrders()}
              onCancel={() => this.setState({ showCustomization: false })}
            />
          </Modal>
        )}
        <Drawer
          title={`Update customer info #${
            selectedOrder ? selectedOrder.origin_id : ""
          }`}
          onClose={() =>
            this.setState({
              showUpdateCustomer: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showUpdateCustomer}
        >
          {selectedOrder && (
            <CustomerAddress
              order={selectedOrder}
              onCancel={() => this.setState({ showUpdateCustomer: false })}
              refetch={() => {
                this.loadOrders();
              }}
            />
            // <CustomerInfo
            //   onClose={() =>
            //     this.setState({
            //       showUpdateCustomer: false,
            //       selectedOrder: null,
            //     })
            //   }
            //   onUpdate={() =>
            //     this.setState(
            //       { showUpdateCustomer: false, selectedOrder: null },
            //       () => this.loadOrders()
            //     )
            //   }
            //   order={selectedOrder}
            // />
          )}
        </Drawer>
        <Drawer
          title={`Comments #${selectedOrder ? selectedOrder.origin_id : ""}`}
          onClose={() =>
            this.setState({
              showOrderComments: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showOrderComments}
        >
          {selectedOrder && <Comments order_id={selectedOrder.id} />}
        </Drawer>
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={(e) =>
            this.setState({ selectedOrder: null, showUploadDesign: false })
          }
          visible={showUploadDesign}
        >
          {selectedOrder && selectedOrder.product.is_custom_design !== true && (
            <UploadDesigns
              product_id={selectedOrder.product.id}
              order={selectedOrder}
              variant_type_id={selectedOrder.variant.product_type_variant.id}
              onClose={(e) =>
                this.setState(
                  { selectedOrder: null, showUploadDesign: false },
                  () => this.loadOrders()
                )
              }
            />
          )}
          {selectedOrder && selectedOrder.product.is_custom_design === true && (
            <UploadCustomDesigns
              product_id={selectedOrder.product.id}
              variant_type_id={selectedOrder.variant.product_type_variant.id}
              order={selectedOrder}
              onClose={(e) =>
                this.setState(
                  { selectedOrder: null, showUploadDesign: false },
                  () => this.loadOrders()
                )
              }
            />
          )}
        </Drawer>
        {this.props.currentSite?.type !== "offline" && (
          <Drawer
            width="500px"
            title="Fetch Orders"
            onClose={(e) => {
              this.setState({ showFetchOrders: false });
              this.loadOrders();
            }}
            visible={showFetchOrders}
          >
            <FetchOrders
              site_id={this.props.currentSite?.id}
              onClose={(e) => this.setState({ showFetchOrders: false })}
            />
          </Drawer>
        )}
        <Drawer
          width="800px"
          title="Order History"
          onClose={(e) =>
            this.setState({ selectedOrder: null, showHistory: false })
          }
          visible={showHistory}
        >
          <Timeline>
            {orderHistory.map((his, index) => (
              <Timeline.Item color="orange">
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "1 1" }}>{his.message}</div>
                  <div className="mr-7">
                    {moment(his.updated_at).format("MMM DD, YYYY h:mm:ss a")}
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        </Drawer>
        {previewImage && (
          <Modal
            width={800}
            title="Preview Image"
            visible={previewImage ? true : false}
            onCancel={() => this.setState({ previewImage: false })}
            footer={false}
          >
            <ModalPreviewimage keys={previewImage} />
          </Modal>
        )}
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    filter: state.orders.filter,
  }),
  { setOrdersFilter, setOrderId }
)(Orders);
