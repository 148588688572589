import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import ButtonUnlockUser from "../ButtonUnlockUser";
import { USER_ID_BLOCKED } from "../../config";
import { useMutation } from "@apollo/client";
import { Button, Divider, notification, Popconfirm, Tooltip } from "antd";
import React from "react";
import {
  DELETE_ARTWORK,
  DUPLICATE_ARTWORK,
  CHANGE_LOCK_ARTWORK,
} from "../../graphql/mutation/artworkMutation";
import { history } from "../../history";

const ArtworkAction = ({ record, refetch, currentUser, lockAction = false }) => {
  const [DeleteArtwork] = useMutation(DELETE_ARTWORK);
  const [DuplicateArtwork] = useMutation(DUPLICATE_ARTWORK);
  const [ChangeLockArtwork] = useMutation(CHANGE_LOCK_ARTWORK);
  const onConfirmDelete = () => {
    DeleteArtwork({
      variables: {
        id: record.id,
      },
    })
      .then(() => {
        refetch();
        notification.success({ message: "Delete Artwork Success!" });
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  const onConfirmDuplicate = () => {
    DuplicateArtwork({
      variables: {
        id: record.id,
      },
    })
      .then(() => {
        refetch();
        notification.success({ message: "Duplicate Artwork Success!" });
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  return (
    <div>
      {lockAction ? (
        <>
        {(currentUser.id == USER_ID_BLOCKED && record.lock) ?
          <ButtonUnlockUser onConfirm={() => {
            ChangeLockArtwork({
              variables: { id: record.id, status: !record.lock },
            })
              .then(() => {
                notification.success({
                  message: "The Artwork Changed Successfully!",
                });
                refetch();
              })
              .catch((err) => notification.error({ message: err.message }));
          }} propsButton={{
            icon: (record.lock ? <LockOutlined /> : <UnlockOutlined />),
            size: "small"
          }}>
        </ButtonUnlockUser> 
        :
        <Popconfirm
          title="Are you sure change lock artwork?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            ChangeLockArtwork({
              variables: { id: record.id, status: !record.lock },
            })
              .then(() => {
                notification.success({
                  message: "The Artwork Changed Successfully!",
                });
                refetch();
              })
              .catch((err) => notification.error({ message: err.message }));
          }}
        >
          <Button
            type="link"
            icon={record.lock ? <LockOutlined /> : <UnlockOutlined />}
          />
        </Popconfirm>
        }
        </>
      ) : (
        <>
          {(currentUser.id == USER_ID_BLOCKED && record.lock) ? 
            <ButtonUnlockUser 
              onConfirm={() => {
              ChangeLockArtwork({
                variables: { id: record.id, status: !record.lock },
              })
                .then(() => {
                  notification.success({
                    message: "The Artwork Changed Successfully!",
                  });
                  refetch();
                })
                .catch((err) => notification.error({ message: err.message }));
            }} propsButton={{
              icon: (record.lock ? <LockOutlined /> : <UnlockOutlined />),
              size: "small"
            }}>
          </ButtonUnlockUser> : 
          <Tooltip title={record.lock ? "unlock artwork" : "lock artwork"}>
            <Popconfirm
              title="Are you sure change lock artwork?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                ChangeLockArtwork({
                  variables: { id: record.id, status: !record.lock },
                })
                  .then(() => {
                    notification.success({
                      message: "The Artwork Changed Successfully!",
                    });
                    refetch();
                  })
                  .catch((err) => notification.error({ message: err.message }));
              }}
            >
              <Button
                // type="link"
                size="small"
                icon={record.lock ? <LockOutlined /> : <UnlockOutlined />}
              />
            </Popconfirm>
          </Tooltip>
          }

          <Divider type="vertical" />
          {record.lock ? null : (
            <>
              <Tooltip title="Edit artwork">
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => history.push(`/artworks/${record.id}/design`)}
                  disabled={record.lock ? true : false}
                />
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}

          <Tooltip title="Duplicate artwork">
            <Popconfirm
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={onConfirmDuplicate}
              title="Are you sure duplicate artwork?"
            >
              <Button icon={<CopyOutlined />} size="small" />
            </Popconfirm>
          </Tooltip>
          {record.lock ? null : (
            <>
              {(currentUser.id == USER_ID_BLOCKED) ? <ButtonUnlockUser
              propsButton={{
                icon: (<DeleteOutlined style={{ color: "red" }} />),
                size:"small"
              }}
              onConfirm={onConfirmDelete}
              /> : <>
              <Divider type="vertical" />
              <Tooltip title="Delete artwork">
                <Popconfirm
                  placement="left"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={onConfirmDelete}
                  title="Are you sure delete artwork?"
                >
                  <Button
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    size="small"
                  />
                </Popconfirm>
              </Tooltip>
              </>
              }
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ArtworkAction;
