import React from "react";
import gql from "../../api/gql";
import { LoadingOutlined } from "@ant-design/icons";
export default class PrintFilePreview extends React.Component {
  state = {
    fileUrl: null,
    error: false,
  };

  componentDidMount() {
    const { file_id } = this.props;
    let query = `query getFileThumbnailUrl{
      getFileThumbnailUrl(file_id: ${file_id})
    }`;
    gql
      .request(query)
      .then((res) => {
        this.setState({
          fileUrl: encodeURI(res.getFileThumbnailUrl),
        });
      })
      .catch((err) => {
        this.setState({
          error: true,
        });
      });
  }

  render() {
    const { fileUrl, error } = this.state;
    if (fileUrl === null && !error)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            backgroundSize: "contain",
          }}
        >
          <LoadingOutlined style={{ fontSize: 30, color: "#1890ff" }} />
        </div>
      );
    return (
      <div
        style={{
          backgroundImage: `url(${fileUrl ? fileUrl : "/no-preview.png"})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "100%",
          width: "100%",
        }}
      />
    );
  }
}
