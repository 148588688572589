import React from "react";
import { Form, notification, Input, Button } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import styled from "styled-components";

const Container = styled.div`
  // .ant-form-item-label{
  //   margin-top:7px
  // }
  // .checkbox{
  //   margin: 0px 30px 10px
  // }
`;

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
    //const billing = this.props.order.billing
    //const shipping = this.props.order.shipping
    //const defaultChecked = shipping.id == billing.id
    this.state = {
      order: this.props.order,
      customer: this.props.order.customer,
      billing: this.props.order.billing,
      shipping: this.props.order.shipping,
      shippingSameBilling:
        this.props.order.billing.id === this.props.order.shipping.id,
      loading: false,
      //defaultChecked : defaultChecked,
    };
  }

  onClose = () => {
    this.props.onClose(false);
  };

  onUpdate = (values) => {
    const {
      b_address1,
      b_address2,
      b_state,
      b_state_code,
      b_zip,
      b_country,
      b_city,
      b_country_code,
      address1,
      address2,
      state,
      state_code,
      zip,
      country,
      city,
      country_code,
      email,
      firstName,
      lastName,
      phone,
    } = values;
    const billing = {
      address1: b_address1,
      address2: b_address2,
      state: b_state,
      state_code: b_state_code,
      zip: b_zip,
      country: b_country,
      city: b_city,
      country_code: b_country_code,
    };
    const shipping = {
      address1,
      address2,
      city,
      zip,
      state,
      state_code,
      country,
      country_code,
    };
    const customer_info = {
      customer: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: phone ? phone : "",
      },
      billing: { ...billing },
    };
    if (!this.state.shippingSameBilling) {
      customer_info.shipping = shipping;
    }
    const mutation = `mutation updateCustomerInfo($order_id: String!, $customer_info: CustomerInfo!){
          updateCustomerInfo(order_id: $order_id, customer_info:$customer_info){id}
        }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        order_id: this.props.order.id,
        customer_info,
      })
      .then((res) => {
        notification["success"]({
          message: "Customer infor has been updated",
        });
        this.setState({ loading: false });
        this.props.onUpdate();
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { customer, billing, order } = this.state;
    const { currentUser } = this.props;
    const isSupporter =
      currentUser &&
      currentUser.roles.find((el) => ["Support"].includes(el.name));
    const isAdmin =
      currentUser &&
      currentUser.roles.find((role) => ["Administrator"].includes(role.name));
    const isEditAble =
      isAdmin ||
      isSupporter ||
      [
        "pending",
        "pending_design",
        "processing",
        "ready",
        "sup_failed",
      ].includes(order.status);
    return (
      <Container>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={this.onUpdate}
        >
          <div>
            <h3>Customer:</h3>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  initialValue: customer ? customer.first_name : "",
                  required: true,
                  message: "Please input first name",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  initialValue: customer ? customer.last_name : "",
                  required: true,
                  message: "Please input last name",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
          </div>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                initialValue: customer ? customer.email : "",
                required: true,
                message: "Please input email",
              },
            ]}
          >
            <Input readOnly={!isEditAble} />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                initialValue: customer ? customer.phone : "",
              },
            ]}
          >
            <Input readOnly={!isEditAble} />
          </Form.Item>
          <div>
            <h3> Billing:</h3>

            <Form.Item
              label="Address 1"
              name="b_address1"
              rules={[
                {
                  initialValue: billing ? billing.address1 : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Address 2"
              name="b_address2"
              rules={[
                {
                  initialValue: billing ? billing.address2 : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="City"
              name="b_city"
              rules={[
                {
                  initialValue: billing ? billing.city : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="State"
              name="b_state"
              rules={[
                {
                  initialValue: billing ? billing.state : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="State Code"
              name="b_state_code"
              rules={[
                {
                  initialValue: billing ? billing.state_code : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Country"
              name="b_country"
              rules={[
                {
                  initialValue: billing ? billing.country : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Country code"
              name="b_country_code"
              rules={[
                {
                  initialValue: billing ? billing.country_code : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Zip"
              name="b_zip"
              rules={[
                {
                  initialValue: billing ? billing.zip : "",
                },
              ]}
            >
              <Input readOnly={!isEditAble} />
            </Form.Item>
          </div>
          {/* <div style={{ paddingBottom: 53 }}>
            <h3> Shipping: </h3>
            <Form.Item {...tailFormItemLayout}>
              <Checkbox
                defaultChecked={shippingSameBilling}
                disabled={!isEditAble}
                onChange={(e) => {
                  this.setState({
                    shippingSameBilling: e.target.checked,
                  });
                }}
              >
                Same as billing address
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Address 1"
              rules={[
                {
                  initialValue: shipping ? shipping.address1 : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Address 2"
              rules={[
                {
                  initialValue: shipping ? shipping.address2 : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  initialValue: shipping ? shipping.city : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  initialValue: shipping ? shipping.state : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="State Code "
              name="state_code"
              rules={[
                {
                  initialValue: shipping ? shipping.state_code : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  initialValue: shipping ? shipping.country : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Country code"
              name="country_code"
              rules={[
                {
                  initialValue: shipping ? shipping.country_code : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
            <Form.Item
              label="Zip "
              name="zip"
              rules={[
                {
                  initialValue: shipping ? shipping.zip : "",
                },
              ]}
            >
              <Input disabled={shippingSameBilling} readOnly={!isEditAble} />
            </Form.Item>
          </div> */}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e8e8e8",
              padding: "10px 16px",
              textAlign: "right",
              left: 0,
              background: "#fff",
              borderRadius: "0 0 4px 4px",
            }}
          >
            <Button
              onClick={() => this.props.onClose()}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
              disabled={!isEditAble}
            >
              Save
            </Button>
          </div>
        </Form>
      </Container>
    );
  }
}
export default CustomerInfo;
