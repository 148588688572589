import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Popconfirm } from "antd";
import { ASSET_API_AUTO_BANNER } from "../../../../config";
import {
  BsFillClockFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsFillTrashFill,
} from "react-icons/bs";

const HistoryDesign = ({
  historyData,
  indexActive,
  onSelect,
  onDeleteAll,
  onDeleteItem,
}) => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(historyData.length - 1);
  const slider = useRef();

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const updateSlider = (v) => {
    const offset = -v * 500; // 300 is the height of each slide
    slider.current.style.transform = `translateY(${offset}px)`;
  };

  const showModal = () => {
    setOpen(true);
    setCurrentIndex(historyData.length - 1);
    setTimeout(() => {
      updateSlider(indexActive);
    }, 1000);
  };

  const confirm = () => {
    setOpen(false);
    onDeleteAll(true);
  };

  const handleNext = () => {
    if (currentIndex < historyData.length - 1) {
      const newValue = currentIndex + 1;
      setCurrentIndex(newValue);
      updateSlider(newValue);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      const newValue = currentIndex - 1;
      setCurrentIndex(newValue);
      updateSlider(newValue);
    }
  };
  return (
    <>
      {historyData.length > 0 && (
        <>
          <Button
            onClick={showModal}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
               height: 35,
              width: "30%",
              marginRight: 5
            }}
          >
            <BsFillClockFill /> <span style={{ marginLeft: 5 }}>History</span>
          </Button>
          <Modal
            title="Basic Modal"
            visible={open}
            onOk={handleOk}
            onCancel={handleCancel}
            className="model-history"
            closable={false}
            width={700}
          >
            <div className="slider-container-history">
              <div>
                <button
                  onClick={handleCancel}
                  style={{
                    zIndex: 9999999,
                    position: "absolute",
                    top: 2,
                    right: 2,
                    height: "35px",
                    width: "35px",
                    background: "#fff",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    fontSize: "20px",
                    boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
                    borderRadius: "3px",
                  }}
                >
                  &#10005;
                </button>
              </div>
              <div className="trash-all-history">
                <Popconfirm
                  placement="left"
                  title={"Are you sure you want to delete all history?"}
                  onConfirm={confirm}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className="btn-trash-all-history"
                    title="Delete all history"
                  >
                    <BsFillTrashFill
                      style={{
                        marginRight: "5px",
                      }}
                    />{" "}
                    Delete all
                  </div>
                </Popconfirm>
              </div>
              <div ref={slider} className="slider-history autobanner">
                {historyData.map((item, index) => (
                  <div key={index} className="slide-history">
                    <div className="slide-history-item">
                      <ul
                        className="slide-history-list-autobanner">
                        {item?.keys.map((e, inx) => (
                          <li
                            key={inx}
                            style={{
                              width: "25%",
                              aspectRatio: "1/1",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                padding: 5,
                              }}
                            >
                              <img
                                style={{
                                  height: "100%",
                                  borderRadius: 5,
                                }}
                                src={ASSET_API_AUTO_BANNER + e}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="box-slide-history-choose">
                      <div
                        onClick={() => {
                          onDeleteItem(index);
                          if (index == 0 && historyData.length >= 1) {
                            setCurrentIndex(0);
                            updateSlider(0);
                          }
                          if (historyData.length > 1 && index > 0) {
                            setCurrentIndex(index - 1);
                            updateSlider(index - 1);
                          }
                        }}
                        className="btn-delet-item-history"
                      >
                        <BsFillTrashFill />
                      </div>
                      <button
                        onClick={() => {
                          onSelect(index);
                          setOpen(false);
                        }}
                      >
                        Choose
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="group-btn-slider">
                {currentIndex > 0 && (
                  <button onClick={handlePrev} id="prev">
                    <BsFillCaretUpFill />
                  </button>
                )}
                {currentIndex < historyData.length - 1 && (
                  <button onClick={handleNext} id="next">
                    <BsFillCaretDownFill />
                  </button>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default HistoryDesign;
