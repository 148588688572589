import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import { PhotoshopPicker } from "react-color";

const ColorPicker = ({ onChange, value }) => {
  const [color, setColor] = useState(value);
  const [isModalOpenColor, setIsModalOpenColor] = useState(false);

  const handleChange = (color) => {
    setColor(color.hex);
  };
  const handleChangeComplete = () => {
    onChange(color);
    setIsModalOpenColor(false);
  };
  const handleCancelColor = () => {
    setIsModalOpenColor(false);
  };

  const showModalColor = () => {
    setIsModalOpenColor(true);
  };
  return (
    <>
      <button
        onClick={showModalColor}
        style={{
          background: color,
        }}
        className="btn-choose-color-picker"
      ></button>

      <Modal
        width={"513px"}
        zIndex={999999999}
        title=""
        className="modal-color-picker"
        visible={isModalOpenColor}
        footer={[]}
      >
        <PhotoshopPicker
          color={color}
          onChange={handleChange}
          onAccept={handleChangeComplete}
          onCancel={handleCancelColor}
        />
      </Modal>
    </>
  );
};

export default ColorPicker;
