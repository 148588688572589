export const SHOPIFY_API = "a6c41424d7f53a4c5aa9ba7eb86e8835";
export const SHOPBASE_API = "71168728c79d70b595b18386315b8f7a";
export const CLOUDFRONT_URL = "https://m106v206c3.execute-api.ap-southeast-2.amazonaws.com/";
export const APP_URL = "https://custom.oooach.com";
export const API_URL = "https://api-custom.oooach.com";
export const WS_URL = "wss://api-custom.oooach.com";
export const DEFAULT_FONT = "fCXIJub0fn-regular";
export const ASSET_URL = 'https://d111oohu250q1p.cloudfront.net/'
export const AWS_API = 'https://opyyfjmmjk.execute-api.ap-southeast-2.amazonaws.com' // API Gateway campaing
export const URL_API_FILL_IMAGES_SHAPE = "https://iem82zr07j.execute-api.ap-southeast-1.amazonaws.com/fill_images_shape"
export const URL_API_IMAGES_CARTOONIZE = "https://4gn3lucfn9.execute-api.ap-southeast-1.amazonaws.com/cartoonize";
export const URL_API_IMAGES_CUT_FACE = "https://4gn3lucfn9.execute-api.ap-southeast-1.amazonaws.com/cut-face";
export const URL_API_SEARCH_LYRIC = 'https://2m5bgmm013.execute-api.us-east-1.amazonaws.com/prod/search'
export const URL_API_RENDER_SPIRAL_LYRICS = "https://o453b05o2k.execute-api.ap-southeast-1.amazonaws.com/render-spiral-lyrics"
export const URL_API_UPLOAD_FILE = "https://m3uokktl3bctp5ms5rbpggifua0chhtj.lambda-url.ap-southeast-1.on.aws/upload-custom-font"
export const URL_API_RENDER_SPIRAL_CUSTOM_LYRICS = "https://o453b05o2k.execute-api.ap-southeast-1.amazonaws.com/render-spiral-lyrics-url";
export const URL_GET_SIZE_ARTWORK = "https://d3nppxlsi6wa20.cloudfront.net/assets-app-custom/default-auto-size.json";
export const URL_API_MIDJOURNEY = "https://raxghjnvfbitsyg7f7zf4eo7ue0hbwda.lambda-url.ap-southeast-1.on.aws"
export const URL_API_IMAGE_GENERATION_STREAM = "https://bizf3d2aoipvq4dxgd43d4msee0htjyp.lambda-url.ap-southeast-1.on.aws"
export const URL_API_KLING_CREATE_IMAGE = "https://po4zgkgoiaqxzvfaa57k6etbae0szcwt.lambda-url.ap-southeast-1.on.aws/create_image";
export const URL_API_KLING_CREATE_VIDEO = "https://po4zgkgoiaqxzvfaa57k6etbae0szcwt.lambda-url.ap-southeast-1.on.aws/create_video";
export const URL_API_KLING_CREATE_DESCRIBE = "https://7k4jmgh6umcroz3a2mf6p2u7xu0ezcwl.lambda-url.ap-southeast-1.on.aws/describe";
export const API_KEY_OPENAI = "sk-None-ln8EqcUu8kP40Ow9UNWgT3BlbkFJmxREviowzbmZoWiBLcUZ";
export const API_CHAT_CREATE_CONTENT_PRODUCT = "https://7k4jmgh6umcroz3a2mf6p2u7xu0ezcwl.lambda-url.ap-southeast-1.on.aws/ai-product";
export const AWS_API_UPLOAD_IMAGE = "https://otvrqmv113.execute-api.ap-southeast-1.amazonaws.com";
export const ASSET_URL_VIEW_IMAGE = "https://d1se9zaettx1vk.cloudfront.net/";
export const URL_API_CROSSWORD_PUZZLE = "https://jixzwrfeglddduhogfyjp2l5k40liwno.lambda-url.ap-southeast-2.on.aws/";
export const USER_ID_BLOCKED = "yFTsgxKBw9";
export const ASSET_API_AUTO_BANNER = "yFTsgxKBw9";
export const URL_API_AUTO_BANNER = "yFTsgxKBw9";
export const PASSWORD_UNLOCK_USER = '123123123';
