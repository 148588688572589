import { Button, Form, InputNumber, notification } from "antd";
import React, { useState } from "react";
import { cloneDeep } from "lodash";
import styled from "styled-components";
const Container = styled.div`
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: -10px;
  }
`;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const getBulkModelTitle = (mode) => {
  switch (mode) {
    case "Regular price":
      return "retail_cost";
    case "Sale price":
      return "sale_cost";
    case "Base code":
      return "base_cost";
    case "All price":
      return "All price";
    default:
      return "All price";
  }
};
const ChangePriceVariant = (props) => {
  const {
    changePrice,
    dataSource,
    selectedRowKeys,
    setSelectedRowKeys,
    handleCancel,
    setDataSource,
  } = props;
  const [regular, setRegular] = useState();
  const [sale, setSale] = useState();
  const [baseCost, setBaseCost] = useState();
  const [form] = Form.useForm();
  const handleChangeVariant = (price) => {
    let newBaseVariants = cloneDeep(dataSource);
    newBaseVariants = newBaseVariants.map((v) => {
      if (selectedRowKeys.includes(v.sku)) {
        return {
          ...v,
          [getBulkModelTitle(changePrice)]: price,
        };
      }
      return v;
    });
    setDataSource(newBaseVariants);
    handleCancel();
  };
  return (
    <div>
      {(() => {
        switch (changePrice) {
          case "Base code":
          case "Regular price":
          case "Sale price":
            return (
              <Form
                form={form}
                {...layout}
                onFinish={(values) => {
                  const price = values.price;
                  if (price) {
                    handleChangeVariant(price);
                  }
                  form.resetFields();
                  setSelectedRowKeys([]);
                  handleCancel();
                }}
              >
                <Form.Item name={"price"} label={changePrice}>
                  <InputNumber
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      form.setFieldsValue({
                        [getBulkModelTitle(changePrice)]: value,
                      })
                    }
                    min={1}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
                <Form.Item
                  style={{ textAlign: "right", marginBottom: 0 }}
                  {...tailLayout}
                >
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    htmlType="submit"
                  >
                    OK
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </Form.Item>
              </Form>
            );
          default:
            return (
              <Container>
                <Form
                  form={form}
                  {...layout}
                  onFinish={(values) => {
                    if (
                      values?.retail_cost >= values?.sale_cost ||
                      values?.sale_cost >= values?.base_cost
                    ) {
                      let newBaseVariants = cloneDeep(dataSource);
                      newBaseVariants = newBaseVariants.map((v) => {
                        if (selectedRowKeys.includes(v.sku)) {
                          return {
                            ...v,
                            ...values,
                          };
                        }
                        return v;
                      });
                      setDataSource(newBaseVariants);
                    } else {
                      if (
                        values?.retail_cost < values?.sale_cost ||
                        values?.sale_cost < values?.base_cost
                      ) {
                        notification.error({ message: "Error" });
                      }
                    }
                    form.resetFields();
                    setSelectedRowKeys([]);
                    handleCancel();
                  }}
                >
                  <Form.Item
                    name="retail_cost"
                    label="Regular Price"
                    rules={[
                      { required: true, message: "Please input regular price" },
                    ]}
                    shouldUpdate={(prevValues, currentValues) => {
                      setRegular(prevValues?.retail_cost);
                      setSale(prevValues?.sale_cost);
                      setBaseCost(prevValues?.base_cost);
                    }}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        form.setFieldsValue({
                          retail_cost: value,
                          sale_cost: value,
                        });
                      }}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="sale_cost"
                    label="Sale Price"
                    validateStatus={regular && sale > regular ? "error" : ""}
                    help={
                      !!regular && sale > regular
                        ? "Sale price is smaller regular"
                        : ""
                    }
                  >
                    <InputNumber
                      value={regular}
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        form.setFieldsValue({
                          sale_cost: value,
                        })
                      }
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                  <Form.Item
                    className="abc"
                    style={{ marginBottom: 0 }}
                    name="base_cost"
                    label="Base Cost"
                    rules={[
                      { required: true, message: "Please input base cost" },
                    ]}
                    validateStatus={
                      (sale && baseCost > sale) ||
                      ((sale === 0 || !sale) && baseCost > regular)
                        ? "error"
                        : ""
                    }
                    help={
                      sale && baseCost > sale
                        ? "Base cost is smaller"
                        : (sale === 0 || !sale) && baseCost > regular
                        ? " Regular price must be greater or equals to base cost"
                        : ""
                    }
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        form.setFieldsValue({
                          base_cost: value,
                        })
                      }
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: 0 }}
                    {...tailLayout}
                  >
                    {/* {productBaseImport ||
                  productBase?.fulfillmentType === "Builtin" ? (
                    <Button
                      type="primary"
                      style={{ marginRight: 10 }}
                      htmlType="submit"
                      disabled={
                        regular > sale || regular === sale || regular !== null
                          ? false
                          : true
                      }
                    >
                      OK
                    </Button>
                  ) : ( */}
                    <Button
                      type="primary"
                      style={{ marginRight: 10 }}
                      htmlType="submit"
                      // disabled={
                      //   sale
                      //     ? regular < sale || sale < baseCost
                      //     : regular > baseCost
                      // }
                      disabled={
                        sale === 0 || !sale
                          ? regular < baseCost
                          : regular < sale || sale < baseCost
                      }
                    >
                      OK
                    </Button>
                    {/* )} */}
                    <Button onClick={handleCancel}>Cancel</Button>
                  </Form.Item>
                </Form>
              </Container>
            );
        }
      })()}
    </div>
  );
};

export default ChangePriceVariant;
