import React, { useEffect, useState } from "react";
import gql from "../../../../api/gql";
import AddCategory from "./components/AddCategory";
import DeleteCategory from "./components/DeleteCategory";
import UpdateCategory from "./components/UpdateCategory";
import { message, Table, Input, Space, Button } from "antd";
import _ from "lodash";
const CategorysAutoBanner = ({onChangeId}) => {
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [categorys, setCategorys] = useState([]);
  const getCaterotyAutoBanner = () => {
    setLoading(true);
    const query = `query bannerAllCategoryFilter($search: String, $page: Int, $pageSize: Int){
			bannerAllCategory(
                search: $search, 
                page: $page,
                pageSize: $pageSize
            ){
            numberOfBanners
            hits{
              id
              title
            }
			}
		}`;
    const filter = {
      pageSize,
      page,
    };
    if (search) filter.search = search;

    gql
      .request(query, filter)
      .then((res) => {
        setTotal(res.bannerAllCategory.numberOfBanners || 0);
        setCategorys(res.bannerAllCategory.hits || []);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Something went wrong, please try again!');
        setLoading(false);
      });
  };
  const onSearch = (v) => {
    setSearch(v);
  };
  // useEffect(() => {
  //     getCaterotyAutoBanner();
  // }, []);

  useEffect(() => {
    getCaterotyAutoBanner();
  }, [page, pageSize, search]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: 'right',
      render: (_, record) => {
        return (
          <Space>
              <Button onClick={() => onChangeId(record.id)}>View</Button>
              <UpdateCategory data={record} onChange={getCaterotyAutoBanner} />
              <DeleteCategory id={record.id} onChange={getCaterotyAutoBanner} />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginBottom: 7,
        }}
      >
        <Search
          placeholder="Search categories"
          onSearch={onSearch}
          enterButton
        />
        <AddCategory onChange={getCaterotyAutoBanner} />
      </div>
      <Table
        loading={loading}
        pagination={{
          total: total,
          defaultPageSize: pageSize,
          current: page,
          showSizeChanger: true,
        }}
        onChange={(e) => {
          setPageSize(e.pageSize);
          setPage(e.current);
        }}
        rowKey={({ id }) => id}
        dataSource={categorys}
        columns={columns}
      />
    </>
  );
};

export default CategorysAutoBanner;
