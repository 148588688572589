import React, { useState, useEffect } from "react";
import { Spin, Modal, Alert, Slider } from "antd";
import { MdFileDownload } from "react-icons/md";
import HistoryGeneration from "./components/HistoryGeneration";
export default function ViewImageGeneration({
  data,
  onSelect,
  loading,
  onChangeData,
  timeNext,
}) {
  const [dataViews, setDataViews] = useState(null);
  const [listDone, setListDone] = useState([]);
  const [openZoomCustom, setOpenZoomCustom] = useState(false);
  const [zoom, setZoom] = useState(1.5);

  useEffect(() => {
    if (data && data != null) {
      setDataViews(data);
      if (data.status == "done" && data?.progress == "100%") {
        data.uuid = uniqueID();
        setListDone((prev) => [...prev, data]);
      }
    }
  }, [data]);

  useEffect(() => {
    const listHistory = localStorage.getItem("listDone");
    if (listHistory && JSON.parse(listHistory).length > 0) {
      const dataHistory = JSON.parse(listHistory);
      const dataCurrent = dataHistory[dataHistory.length - 1];
      dataCurrent.status = "donedone";
      setDataViews(dataCurrent);
      onChangeData(dataCurrent);
      setListDone(JSON.parse(listHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("listDone", JSON.stringify(listDone));
  }, [listDone]);

  const uniqueID = (length = 15) => {
    var result = "";
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  const imageURLToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleDownload = async () => {
    const url = await imageURLToBase64(dataViews?.uri);
    const link = document.createElement("a");
    link.href = url;
    link.download = uniqueID(15);
    link.click();
  };

  const convertToHtmlLink = (text) => {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/g;
    const htmlText = text.replace(
      pattern,
      '<a  target="_blank" href="$2">$1</a>'
    );
    return htmlText;
  };

  const handleSelectData = (v) => {
    setDataViews(v);
    onChangeData(v);
  };

  const handleDeleteAll = () => {
    setListDone([]);
  };

  const handleDeletHistoryItem = (index) => {
    const newList = listDone.filter((e, i) => i != index);
    setListDone(newList);
  };

  const removeZoomFromString = (inputString) => {
    return inputString.replace(/--zoom\s+\S*/, "");
  };

  const getTextBetweenDoubleAsterisks = (inputString) => {
    const regex = /\*\*(.*?)\*\*/;
    const match = inputString.match(regex);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return null;
    }
  };

  const handleRednderLabel = (text) => {
    switch (text) {
      case "U1":
        return "Up Sample 1";
        break;
      case "U2":
        return "Up Sample 2";
        break;
      case "U3":
        return "Up Sample 3";
        break;
      case "U4":
        return "Up Sample 4";
        break;
      case "V1":
        return "Variation 1";
        break;
      case "V2":
        return "Variation 2";
        break;
      case "V3":
        return "Variation 3";
        break;
      case "V4":
        return "Variation 4";
        break;
      default:
        return text;
        break;
    }
  };

  return (
    <div
      style={{
        padding: 20,
        minHeight: 500,
        position: "relative",
        width: 550,
        maxWidth: "100%",
      }}
    >
      {dataViews?.progress &&
        Number(dataViews?.progress.replace("%", "")) > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            {(listDone.length && !loading) > 0 && (
              <HistoryGeneration
                onDeleteAll={handleDeleteAll}
                history={listDone}
                onSelect={handleSelectData}
                onDeleteItem={handleDeletHistoryItem}
              />
            )}
            <div className="box-progress-history">
              {dataViews && dataViews?.uri && (
                <div className="progress-box">
                  <div
                    className="progress-loading"
                    style={{
                      width: dataViews?.progress ? dataViews?.progress : "0%",
                    }}
                  ></div>
                  <div className="progress-view-text">
                    {dataViews?.progress ? dataViews?.progress : "0%"}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

      {dataViews && dataViews?.uri ? (
        <div>
          {dataViews?.descriptions && (
            <ul className="list-descriptions-ai-image">
              {dataViews?.descriptions.map((e, index) => (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: convertToHtmlLink(e),
                  }}
                ></li>
              ))}
            </ul>
          )}
          {!dataViews?.descriptions && (
            <img
              style={{
                width: "100%",
                borderRadius: 7,
              }}
              src={dataViews.uri}
            />
          )}

          {dataViews?.options && (
            <Spin spinning={loading || timeNext > 0}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  // width: "400px",
                  margin: "auto",
                  maxWidth: "100%",
                }}
              >
                {dataViews?.options?.map((option, index) => {
                  if (
                    // (option?.custom).indexOf("Custom") >= 0 ||
                    (option?.custom).indexOf("PicReader::all") >= 0 ||
                    (option?.custom).indexOf("BOOKMARK") >= 0
                  ) {
                    return;
                  }

                  if ((option?.custom).indexOf("Custom") >= 0) {
                    return (
                      <div key={index}>
                        <div
                          onClick={() => {
                            setOpenZoomCustom(true);
                          }}
                          key={index}
                          className="option-item-midjourney"
                        >
                          {option.label}
                        </div>
                        <Modal
                          title="Zoom Out"
                          visible={openZoomCustom}
                          onOk={() => {
                            const dataZoom = {
                              customId: option.custom,
                              useFor: "client",
                              serverId: dataViews?.serverId,
                              msgId: dataViews?.id,
                              flags: dataViews?.flags,
                            };
                            if (dataViews?.hash) {
                              dataZoom.hash = dataViews?.hash;
                            }
                            if (dataViews?.prompt) {
                              dataZoom.content =
                                getTextBetweenDoubleAsterisks(
                                  dataViews?.content
                                ) +
                                " --zoom " +
                                zoom;
                            }
                            onSelect(dataZoom);
                            setOpenZoomCustom(false);
                          }}
                          onCancel={() => {
                            setOpenZoomCustom(false);
                          }}
                        >
                          <Alert
                            message="This form will be submitted to Midjourney Bot. Do not share passwords or other sensitive information."
                            type="info"
                          />
                          <div
                            style={{
                              marginTop: 5,
                            }}
                          >
                            <label>Zoom: {zoom}</label>
                            <Slider
                              min={1.1}
                              max={2}
                              step={0.1}
                              onChange={(v) => {
                                setZoom(v);
                              }}
                              defaultValue={zoom}
                            />
                          </div>
                        </Modal>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={index}
                      className="option-item-midjourney"
                      onClick={() => {
                        let dataCustom = {
                          customId: option.custom,
                          useFor: "client",
                          serverId: dataViews?.serverId,
                          msgId: dataViews?.id,
                          flags: dataViews?.flags,
                        };
                        if (dataViews?.hash) {
                          dataCustom.hash = dataViews?.hash;
                        }
                        if (dataViews?.prompt) {
                          dataCustom.content = dataViews?.prompt;
                        }
                        if (
                          dataViews?.descriptions &&
                          dataViews?.descriptions[index]
                        ) {
                          dataCustom.prompt = dataViews?.descriptions[index];
                          dataCustom.descriptions = true;
                        }
                        if (
                          dataViews?.descriptions &&
                          option.custom.indexOf("Retry") >= 0
                        ) {
                          dataCustom.picreadReset = true;
                          dataCustom.form = dataViews?.form;
                        }
                        onSelect(dataCustom);
                      }}
                    >
                      {handleRednderLabel(option.label)}
                    </div>
                  );
                })}
                {!dataViews?.options?.find((e) => e.label == "U1") &&
                  !dataViews?.descriptions && (
                    <div
                      onClick={handleDownload}
                      className="option-item-midjourney"
                    >
                      <MdFileDownload
                        style={{
                          marginRight: 5,
                        }}
                      />
                      Download
                    </div>
                  )}
              </div>
            </Spin>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
