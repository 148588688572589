import { useMutation, useQuery, useSubscription } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { CAMPAIGNSTORES } from "../../graphql/queries/product";
import {
  Button,
  Divider,
  notification,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import { USER_ID_BLOCKED } from "../../config";
import ButtonUnlockUser from "../../components/ButtonUnlockUser";
import moment from "moment";
import {
  RETRY_PRODUCT,
  DELETE_cAMPAIGN_STORE,
} from "../../graphql/mutation/product/retryProduct";
import _ from "lodash";
import { DeleteOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons";
import { CAMPAIGN_STORE_SUBCRIPTION } from "../../graphql/subscription/campaignStore";
import { connect } from "react-redux";
import { history } from "../../history";
const _targetBlank = {
  target: "_blank",
  rel: "noopener noreferrer",
};

const ProductPush = (props) => {
  const { currentUser, currentSite } = props;
  const seller = currentUser.roles.map((role) => role.name).includes("Seller");
  const [filter, setFilter] = useState({ limit: 20, offset: 0 });
  const [retryPushCampaignStore] = useMutation(RETRY_PRODUCT);
  const [deleteCampaignStore] = useMutation(DELETE_cAMPAIGN_STORE);
  const { data, loading, refetch } = useQuery(CAMPAIGNSTORES, {
    fetchPolicy: "network-only",
    variables: {
      filter: seller
        ? {
            ...filter,
            siteId: currentSite ? currentSite.id : null,
          }
        : {
            ...filter,
          },
    },
  });
  // useEffect(() => {
  //   if (currentUser.roles.map((role) => role.name).includes("Seller")) {
  // state.filter.site_ids = currentSite ? [currentSite.id] : [];
  // setFilter({ ...filter, siteId: currentSite ? currentSite.id : null });
  //   }
  // }, [currentSite, currentUser.roles, filter]);
  const { data: subCampaign } = useSubscription(CAMPAIGN_STORE_SUBCRIPTION, {
    onSubscriptionData: ({
      subscriptionData: {
        data: { campaignStoreChanged },
      },
    }) => {
      if (campaignStoreChanged.status === "Pushed") {
        refetch();
      }
    },
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
      render: (title, { product }) => (
        <div style={{ marginLeft: 10 }}>
          {product.title} <br />
          {product.sku && <em style={{ color: "#8D8D8D" }}>{product.sku}</em>}
        </div>
      ),
    },
    {
      title: "Site",
      dataIndex: "site",
      width: 400,
      render: (site, record) => (
        <div>
          <a href={site?.domain} {..._targetBlank}>
            {site?.title}
            {" - "}
            {site?.domain}
          </a>
        </div>
      ),
    },
    {
      title: "date",
      width: 200,
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("MMM DD, YYYY"),
    },
    {
      title: "status",
      dataIndex: "status",
      width: 100,
      render: (status, { log }) => (
        <>
          {status === "Error" ? (
            <Tooltip title={`${log}`}>
              <span style={{ color: status === "Error" ? "red" : "orange" }}>
                {status}
              </span>
            </Tooltip>
          ) : (
            <span style={{ color: status === "Error" ? "red" : "orange" }}>
              {status}
            </span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      width: 180,
      dataIndex: "id",
      align: "right",
      render: (id, record) => (
        <div>
          <Tooltip title="Edit product">
            <Button
              size="small"
              onClick={(e) => {
                history.push(`/products/edit/${record.productId}`);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Divider type="vertical" />
          {record.status !== "Pushed" ? (
            <Tooltip title="Retry">
              <Popconfirm
                title={`Are you sure to push this product again?`}
                onConfirm={() =>
                  retryPushCampaignStore({ variables: { id: id } })
                    // .then(
                    //   notification.success({ message: "Retry product Success" })
                    // )
                    .catch((err) => {
                      notification.error({
                        message: _.get(err, "[0].message"),
                      });
                    })
                }
              >
                <Button
                  size="small"
                  loading={
                    record.status !== "Pushed" && record.status !== "Error"
                  }
                  icon={<RedoOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          ) : null}
          {record.status === "Error" ? (
            <>
              <Divider type="vertical" />
              <Tooltip title="Delete product">
               {(currentUser.id == USER_ID_BLOCKED) ? 
              <ButtonUnlockUser
              propsButton={{
                size:"small",
                loading: record.status !== "Pushed" && record.status !== "Error",
                icon: <DeleteOutlined style={{ color: "red" }} />
              }}
              onConfirm={() =>
                deleteCampaignStore({ variables: { id: id } })
                  .then((res) => {
                    notification.success({ message: "Delete Success" });
                    refetch();
                  })
                  .catch((err) => {
                    notification.error({
                      message: _.get(err, "[0].message"),
                    });
                  })
              }
              />
              : 
                <Popconfirm
                  title={`Are you sure to push this product again?`}
                  onConfirm={() =>
                    deleteCampaignStore({ variables: { id: id } })
                      .then((res) => {
                        notification.success({ message: "Delete Success" });
                        refetch();
                      })
                      .catch((err) => {
                        notification.error({
                          message: _.get(err, "[0].message"),
                        });
                      })
                  }
                >
                  <Button
                    size="small"
                    loading={
                      record.status !== "Pushed" && record.status !== "Error"
                    }
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                  />
                </Popconfirm>
                }
              </Tooltip>
            </>
          ) : null}
        </div>
      ),
    },
  ];
  const pagination = {
    pageSize: filter.limit,
    total: data ? data.campaignStores.count : 0,
    showTotal: (total, range) => `${range} of ${total}`,
    onChange: (page, pageSize) => {
      setFilter({ ...filter, offset: (page - 1) * pageSize });
    },
  };
  const widthColumn = _.sum(columns.map((c) => c.width));
  // if (loading) return <Skeleton />;
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data?.campaignStores.hits}
        rowKey="id"
        pagination={pagination}
        loading={loading}
        scroll={{ x: widthColumn }}
      />
    </div>
  );
};
export default connect((state) => ({
  currentSite: state.app.currentSite,
  currentUser: state.app.currentUser,
}))(ProductPush);
