import React, {useState, useEffect} from "react";
import axios from "axios";
import { Select, Form } from "antd";
import { API_KEY_OPENAI } from "../../../../config";
export default function SelectModel({
    onSelect,
    value
}){
    const [models, setModels] = useState([]);
    useEffect(() => {
        axios.get('https://api.openai.com/v1/models', {
            headers: {
                'Authorization': `Bearer ${API_KEY_OPENAI}`
            }
        }).then(res => {
            return res.data.data;
        }).then(res => {
            let data = res.map(e => {
                return {
                    value: e.id,
                    label: e.id.replace(/-/g, ' ')
                }
            });
            data = data.filter(e => e.value.indexOf('gpt') >= 0);
            setModels(data);
        })
    }, []);
    return (
    <Form.Item name="model" label="Model">
        <Select
        defaultValue={value}
        value={value}
        style={{
          width: 200,
        }}
        options={models}
        onChange={(v) => {
          onSelect(v);
        }} />
    </Form.Item>);
}