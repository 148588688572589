import { get } from "lodash";
import React from "react";
import { Collapse, Space } from "antd";
import { connect } from "react-redux";
import TextForm from "./TextForm";
import LabelForm from "./LabelForm";
import AdvanceSettings from "./AdvanceSettings";
import ImageForm from "./ImageForm";
import OptionForm from "./OptionForm";

const LayerSettings = ({ workspace }) => {
    const { artwork, selectedTemplate, selectedLayers } = workspace;
    const layers =
        selectedTemplate === -1
            ? get(artwork, "sharedLayers", [])
            : get(artwork, `templates[${selectedTemplate}].layers`) || [];
    const layer =
        selectedLayers.length === 1 ? layers.search(selectedLayers[0]) : null;
    if (layer === null) {
        return null;
    }
    switch (layer.type) {
        case "Text":
            return (
                <Space direction="vertical" style={{ width: "100%", marginTop: 30 }}>
                    <Collapse
                        key={`text-${layer.id}`}
                        expandIconPosition="right"
                        defaultActiveKey="layer-settings"
                    >
                        <Collapse.Panel
                            header={<strong>Layer settings</strong>}
                            key="layer-settings"
                        >
                            <TextForm layer={layer} key={layer.id} />
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={<strong>Advance settings</strong>}
                            key="advance-settings"
                        >
                            <AdvanceSettings layer={layer} key={layer.id} />
                        </Collapse.Panel>
                    </Collapse>
                </Space>
            );
        case "Label":
            return (
                <Space direction="vertical" style={{ width: "100%", marginTop: 30 }}>
                    <Collapse
                        key={`text-${layer.id}`}
                        expandIconPosition="right"
                        defaultActiveKey="layer-settings"
                    >
                        <Collapse.Panel
                            header={<strong>Layer settings</strong>}
                            key="layer-settings"
                        >
                            <LabelForm layer={layer} key={layer.id} />
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={<strong>Advance settings</strong>}
                            key="advance-settings"
                        >
                            <AdvanceSettings layer={layer} key={layer.id} />
                        </Collapse.Panel>
                    </Collapse>
                </Space>
            )
        case "Image":
            return (
                <Space direction="vertical" style={{ width: "100%", marginTop: 30 }}>
                    <Collapse
                        key={`image-${layer.id}`}
                        expandIconPosition="right"
                        defaultActiveKey="layer-settings"
                        accordion={true}
                    >
                        <Collapse.Panel
                            key="layer-settings"
                            header={<strong>Layer settings</strong>}
                        >
                            <ImageForm layer={layer} key={layer.id} />
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={<strong>Advance settings</strong>}
                            key="advance-settings"
                        >
                            <AdvanceSettings layer={layer} key={layer.id} />
                        </Collapse.Panel>
                    </Collapse>
                </Space>
            );
        case "Option":
            return (
                <Space direction="vertical" style={{ width: "100%", marginTop: 30 }}>
                    <Collapse
                        key={`option-${layer.id}`}
                        expandIconPosition="right"
                        defaultActiveKey="layer-settings"
                    >
                        <Collapse.Panel
                            key="layer-settings"
                            header={<strong>Layer settings</strong>}
                        >
                            <OptionForm layer={layer} key={layer.id} />
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={<strong>Advance settings</strong>}
                            key="advance-settings"
                        >
                            <AdvanceSettings layer={layer} key={layer.id} />
                        </Collapse.Panel>
                    </Collapse>
                </Space>
            );
        case "Group":
            if (selectedTemplate === -1) return null;
            return (
                <Space direction="vertical" style={{ width: "100%", marginTop: 30 }}>
                    <Collapse
                        key={`group-${layer.id}`}
                        expandIconPosition="right"
                        accordion={true}
                        defaultActiveKey={"advance-settings"}
                    >
                        <Collapse.Panel
                            header={<strong>Advance settings</strong>}
                            key="advance-settings"
                        >
                            <AdvanceSettings layer={layer} key={layer.id} />
                        </Collapse.Panel>
                    </Collapse>
                </Space>
            );
        default:
            return null;
    }
};
export default connect((state) => ({
    workspace: state.artwork.workspace,
}))(LayerSettings);
