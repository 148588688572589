import { Upload as AntUpload, message } from "antd";
import { useMutation } from "@apollo/client";
import React from "react";
import createSignedUrl from "../../graphql/mutation/createSignedUrl";
import { omit } from "lodash";
import axios from "axios";
import { ApolloClient, InMemoryCache } from "@apollo/client";

const Upload = (props) => {
  const {
    onChange = () => {},
    onUpload = () => {},
    getWidthHeight = () => {},
    dragger = true,
    multiple = false,
    limit,
    setLoading,
    accept = "*",
    showUploadList = true,
    // fileList = [],
    file_key,
  } = props;
  const [CreateSignedUrl] = useMutation(createSignedUrl);
  const handleUpload = (file) => {
    const key = 'uploading_photos';
    message.loading({ content: 'Uploading photos. Please wait...', key, duration: 0 });
    if (setLoading) {
      setLoading(true);
    }
    if (!limit || file.size / (1024 * 1024) <= limit) {
      return new Promise(async (resolve, reject) => {
        CreateSignedUrl({
          variables: {
            filename: file.name,
            fileMime: file.type,
            fileKey: file_key,
          },
        })
          .then((res) => {
            file.key = res.data.createSignedUrl.key;
            resolve(res.data.createSignedUrl.url);
          })
          .catch((error) => {
            message.error(error.message);
            reject(error);
          });
      });
    }
  };

  const handleChange = ({ fileList }) => {
    if (multiple) {
      onChange(fileList);
    } else {
      onChange(fileList[fileList.length - 1]);
    }

    if (fileList.every((f) => f.status === "done")) {
      if (multiple) {
        onUpload(fileList);
      } else {
        onUpload(fileList[fileList.length - 1]);
      }
    }
  };

  const handleCustomRequest = ({
    action,
    file,
    onError,
    onProgress,
    onSuccess,
  }) => {
    axios
      .put(action, file, {
        headers: {
          "Content-type": file.type,
        },
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 }, file);
        },
      })
      .then((res) => onSuccess(res.data, file))
      .catch((err) => {
        onError(err, file);
      });
  };
  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        // set image width and height to your state here
        getWidthHeight(width, height);
      });
    });
  };
  return dragger ? (
    <AntUpload.Dragger
      {...omit(props, ["value"])}
      action={handleUpload}
      onChange={handleChange}
      customRequest={handleCustomRequest}
      multiple={multiple}
      accept={accept}
      showUploadList={showUploadList}
      beforeUpload={beforeUpload}
    >
      {props.children}
    </AntUpload.Dragger>
  ) : (
    <AntUpload.Dragger
      accept={accept}
      {...omit(props, ["value"])}
      multiple={multiple}
      action={handleUpload}
      onChange={handleChange}
      customRequest={handleCustomRequest}
      showUploadList={showUploadList}
      beforeUpload={beforeUpload}
      // fileList={fileList}
    >
      {props.children}
    </AntUpload.Dragger>
  );
};
export const uploadFromBase64 = async (
  path,
  filename,
  data,
  onProgress = () => {}
) => {
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    uri: "http://localhost:4000/",

    // Provide some optional constructor fields
    name: "react-web-client",
    version: "1.3",
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
  const signedUrl = await client
    .mutate({
      mutation: createSignedUrl,
      variables: {
        path: path,
        filename: filename,
      },
    })
    .then(async (res) => {
      return res.data.createSignedUrl;
    })
    .catch((err) => err);
  return new Promise(async (resolve, reject) => {
    var file = await fetch(data)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
    axios
      .put(signedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 }, file);
        },
      })
      .then(() => resolve(signedUrl.key))
      .catch((err) => {
        reject(err);
      });
  });
};
export default Upload;
