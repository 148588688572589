import { Carousel } from "antd";
import React from "react";
import { CLOUDFRONT_URL } from "../config";

const ModalPreviewimage = ({ keys }) => {
  return (
    <div style={{ background: "darkgray" }} className="anc">
      <Carousel autoplay>
        {keys.map((key, index) => (
          <img alt={key} key={index} src={`${CLOUDFRONT_URL}500/${key}`} />
        ))}
      </Carousel>
    </div>
  );
};

export default ModalPreviewimage;
