import { useMutation, useQuery } from "@apollo/client";
import {
    Button,
    Dropdown,
    Image,
    Menu,
    notification,
    Skeleton,
    Tag,
    Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineUpload } from "react-icons/ai";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import { ORDER } from "../../graphql/queries/orders/order";
import CreateFile from "../upload/CreateFile";
import _, { cloneDeep } from "lodash";

import {
    REGENDER_PRINTFILE,
    REGENDER_SUBSCRIPTION,
    UPDATE_ORDER_STATUS,
    UPLOAD_DESIGN,
} from "../../graphql/mutation/orders/orderAction";
import { HiOutlineExternalLink } from "react-icons/hi";
import {
    CloseCircleOutlined,
    EllipsisOutlined,
    SyncOutlined,
} from "@ant-design/icons";
const Container = styled.div`
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag {
    border: none;
    background: none;
  }
  li.ant-menu-item.ant-menu-item-only-child {
    margin-bottom: 0;
    // padding: 0;
  }
`;
const orderStatus = [
    { key: "Completed", color: "success" },
    { key: "Processing", color: "processing" },
    { key: "Pending", color: "volcano" },
    { key: "Error", color: "error" },
];

const AcceptDesign = (props) => {
    const { id, refetchOrders } = props;
    const {
        data,
        loading: loadingQuery,
        refetch: refetchOrder,
        subscribeToMore,
    } = useQuery(ORDER, {
        fetchPolicy: "network-only",
        variables: { id: id },
    });
    const [uploadDesign, { loading }] = useMutation(UPLOAD_DESIGN);
    const [Rengender] = useMutation(REGENDER_PRINTFILE);
    const [updateOrderStatus, { loading: loadingStatus }] =
        useMutation(UPDATE_ORDER_STATUS);
    const order = cloneDeep({ ...data?.order });
    const [designs, setDesigns] = useState();
    const [status, setStatus] = useState();
    useEffect(() => {
        setDesigns(data ? data.order.designs : []);
        setStatus(data ? data.order.status : "");
    }, [data]);

    useEffect(() => {
        if (subscribeToMore) {
            subscribeToMore({
                document: REGENDER_SUBSCRIPTION,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData?.data?.generateOrderPrintFile) return prev;
                    // console.log("sub", subscriptionData?.data?.generateOrderPrintFile);
                    return {
                        ...order,
                        ...subscriptionData.data.reGeneratePrintFile,
                    };
                },
            });
        }
    }, [id]);
    const handleRegender = (designID) => {
        if (id) {
            Rengender({
                variables: {
                    designID: designID,
                    orderId: id,
                },
            }).catch((err) => notification.error({ message: err.message }));
        } else {
            Rengender({
                variables: {
                    orderId: id,
                },
            }).catch((err) => notification.error({ message: err.message }));
        }
    };

    if (loadingQuery) return <Skeleton />;
    return (
        <Container>
            <div
                style={{
                    display: "grid",
                    gap: 10,
                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                    marginBottom: 20,
                }}
            >
                {designs?.map((design, index) => {
                    const v=Math.floor((Math.random() * 1000) + 1)
                    const imageFile = `${CLOUDFRONT_URL}500xauto/${design.file?.key}?v=${v}`;
                    const fileOriginal = `${CLOUDFRONT_URL}auto/${design.file?.key}`;
                    return (
                        <div key={design.id}>
                            <div className="flex space-between item-center">
                                <h3>{design.base_print_area_name}</h3>
                                <div>
                                    {design.status === "Error" ? (
                                        <Tooltip title={design.log} placement="topRight">
                                            <Tag
                                                color="error"
                                                icon={<CloseCircleOutlined />}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {design.status}
                                            </Tag>
                                        </Tooltip>
                                    ) : (
                                        <Tag
                                            color={
                                                orderStatus.find((item) => item.key === design.status)
                                                    ?.color
                                            }
                                            icon={
                                                design.status === "Processing" ? (
                                                    <SyncOutlined spin />
                                                ) : null
                                            }
                                        >
                                            {design.status}
                                        </Tag>
                                    )}

                                    <Dropdown
                                        overlay={
                                            <Container>
                                                <Menu>
                                                    <Menu.Item key="0">
                                                        <Button
                                                            onClick={() => handleRegender(design.id)}
                                                            type="link"
                                                            disabled={
                                                                ["Processing", "Pending"].includes(
                                                                    design.status
                                                                ) ||
                                                                ["exported", "cancelled"].includes(order.status)
                                                            }
                                                            loading={design.status === "Processing"}
                                                            icon={<SyncOutlined />}
                                                        >
                                                            Re Generate
                                                        </Button>
                                                    </Menu.Item>
                                                    <Menu.Item key="1">
                                                        <CreateFile
                                                            file_key={designs[index].file_key}
                                                            loadingButon
                                                            accept=".jpg,.jpeg,.png"
                                                            onChange={(file) => {
                                                                const newDesigns = [...designs];
                                                                newDesigns[index] = {
                                                                    ...newDesigns[index],
                                                                    file: file,
                                                                };
                                                                const designInput = newDesigns.map((ds) => {
                                                                    return {
                                                                        id: ds.id,
                                                                        file_id: ds.file ? ds.file.id : null,
                                                                    };
                                                                });
                                                                uploadDesign({
                                                                    variables: {
                                                                        orderId: id,
                                                                        input: designInput.filter(
                                                                            (item) => item.file_id
                                                                        ),
                                                                    },
                                                                })
                                                                    .then(() => {
                                                                        notification.success({
                                                                            message: "Upload success",
                                                                        });
                                                                        refetchOrder();
                                                                        // setDesigns(newDesigns);
                                                                    })
                                                                    .catch((err) =>
                                                                        notification.error({ message: err.message })
                                                                    );
                                                            }}
                                                        >
                                                            <Button
                                                                disabled={
                                                                    ["exported", "cancelled", "error"].includes(
                                                                        status
                                                                    ) || design.status === "Processing"
                                                                }
                                                                type="link"
                                                                icon={
                                                                    <AiOutlineUpload className="anticon custom-icon" />
                                                                }
                                                            >
                                <span style={{ marginLeft: 0 }}>
                                  Upload custom File
                                </span>
                                                            </Button>
                                                        </CreateFile>
                                                    </Menu.Item>
                                                    {design?.file?.key ? (
                                                        <Menu.Item key="3">
                                                            <a
                                                                onClick={(e) => e.stopPropagation()}
                                                                target="_blank"
                                                                href={fileOriginal}
                                                                className="ant-btn ant-btn-link"
                                                                style={{ display: "initial", paddingLeft: 15 }}
                                                            >
                                                                <HiOutlineExternalLink
                                                                    style={{
                                                                        // color: "white",
                                                                        size: 30,
                                                                    }}
                                                                    className="anticon custom-icon"
                                                                />
                                                                view
                                                            </a>
                                                        </Menu.Item>
                                                    ) : null}
                                                </Menu>
                                            </Container>
                                        }
                                        trigger={["click"]}
                                        placement="bottomRight"
                                    >
                                        {/* <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Action <DownOutlined />
                  </a> */}
                                        <Button
                                            style={{ marginBottom: 10, fontSize: 12 }}
                                            size="small"
                                        >
                                            {/* Action <DownOutlined /> */}
                                            <EllipsisOutlined />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                            <div
                                className="flex content-center"
                                style={{
                                    // minHeight: 300,
                                    display: "contents",
                                    background: "lightgray",
                                    boxShadow:
                                        "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
                                }}
                            >
                                <div className="upload-design" style={{ width: "100%" }}>
                                    {/* <UploadFile
                    accept=".jpg,.jpeg,.png"
                    onChange={(file) => {
                      const newDesigns = [...designs];
                      newDesigns[index] = {
                        ...newDesigns[index],
                        file: file,
                      };
                      setDesigns(newDesigns);
                      const designInput = newDesigns.map((ds) => {
                        return {
                          id: ds.order_id,
                          file_id: ds.file ? ds.file.id : null,
                        };
                      });
                      uploadDesign({
                        variables: {
                          orderId: id,
                          input: designInput.filter((item) => item.file_id),
                        },
                      })
                        .then(() => {
                          notification.success({ message: "Upload success" });
                          //   setCustomModal({
                          //     visible: false,
                          //     type: null,
                          //     apllyTo: null,
                          //   });
                          //   refetch();
                        })
                        .catch((err) =>
                          notification.error({ message: err.message })
                        );
                    }}
                  >
                    {design.file?.key ? (
                      <img src={imageFile} alt={""}></img>
                    ) : (
                      <div>Upload Design</div>
                    )} */}

                                    <Image
                                        style={{
                                            backgroundColor: "darkgray",
                                            objectFit: "contain",
                                        }}
                                        width="100%"
                                        height="250px"
                                        // preview={false}
                                        preview={{
                                            mask: (
                                                <div>
                                                    <div className="flex item-center">
                                                        <AiOutlineEye /> Preview
                                                    </div>
                                                    <Tooltip title="View original">
                                                        <a
                                                            onClick={(e) => e.stopPropagation()}
                                                            target="_blank"
                                                            href={fileOriginal}
                                                            style={{
                                                                zIndex: 200000,
                                                                position: "absolute",
                                                                right: 0,
                                                                top: 0,
                                                            }}
                                                        >
                                                            <HiOutlineExternalLink
                                                                style={{
                                                                    color: "white",
                                                                    size: 30,
                                                                }}
                                                                className="anticon custom-icon"
                                                            />
                                                        </a>
                                                    </Tooltip>
                                                </div>
                                            ),
                                            src: imageFile,
                                        }}
                                        src={imageFile}
                                        fallback={fileOriginal}
                                        // fallback={`/default-img.png`}
                                    />
                                    {/* </UploadFile> */}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", gap: 6 }}>
                <button
                    type="button"
                    className="ant-btn"
                    onClick={() => props.onCancel()}
                >
                    <span>Cancel</span>
                </button>
                <button
                    loading={loadingStatus}
                    type="button"
                    className="ant-btn ant-btn-dangerous"
                    disabled={
                        designs.find((de) => !de.file?.key) ||
                        !["accepted", "pending_review"].includes(status) ||
                        designs.find((item) => item.status !== "Completed")
                    }
                    onClick={() =>
                        updateOrderStatus({
                            variables: {
                                order_id: id,
                                status: "rejected",
                            },
                        })
                            .then((res) => {
                                notification.success({
                                    message: "Reject design order success",
                                });
                                refetchOrder();
                                refetchOrders();
                            })
                            .catch((err) => {
                                notification.error({
                                    message: _.get(err, "[0].message"),
                                });
                            })
                    }
                >
                    <span>Reject</span>
                </button>
                <button
                    loading={loadingStatus}
                    type="button"
                    className="ant-btn ant-btn-primary"
                    disabled={
                        designs.find((de) => !de.file?.key) ||
                        !["pending_review", "rejected"].includes(status) ||
                        designs.find((item) => item.status !== "Completed")
                    }
                    onClick={() =>
                        updateOrderStatus({
                            variables: {
                                order_id: id,
                                status: "accepted",
                            },
                        })
                            .then((res) => {
                                notification.success({
                                    message: "Accept design order success",
                                });
                                refetchOrder();
                                refetchOrders();
                            })
                            .catch((err) => {
                                notification.error({
                                    message: _.get(err, "[0].message"),
                                });
                            })
                    }
                >
                    <span>Accept</span>
                </button>
            </div>
        </Container>
    );
};

export default AcceptDesign;
