import gql from "graphql-tag";

export const CREATE_TAG = gql`
  mutation createTag($input: NewTag!) {
    createTag(input: $input) {
      id
      title
      description
    }
  }
`;
