import React, { Component } from "react";
import gql from "../../api/gql";
import {
  Table,
  Button,
  Select,
  Col,
  Row,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";
import { setCurrentUser } from "../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { DisconnectOutlined, PullRequestOutlined } from "@ant-design/icons";
const { Option } = Select;

class EggSitesList extends Component {
  state = {
    sites: [],
    users: [],
    total: 0,
    loading: false,
    selectedSite: null,
    createSite: false,
    filter: {
      user_id: null,
      offset: 0,
      limit: 20,
      verified: true,
      ...this.props.filter,
    },
    page: 1,
  };
  handleChange = (value) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          verified: value.key === "0" ? false : true,
        },
      },
      () => this.loadSites()
    );
  };
  componentDidMount() {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          verified: true,
        },
      },
      () => this.loadSites()
    );
    this.getUserID();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state;
    if (prevProps.filter !== this.props.filter) {
      this.setState(
        {
          page: 1,
          filter: { ...filter, offset: 0, ...this.props.filter },
        },
        () => this.loadSites()
      );
    }
  }

  loadSites() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const seller = currentUser.roles.find((role) => role.name === "Seller");
    const query = `query SitesList($filter: SiteFilter){
      sites(filter: $filter){
        count
        hits{
          id
          title
          domain
          platform
          status
          verified
          type
          user{
            id
            first_name
            last_name
          }
          webhooks{
            id
            topic
            address
            delivery_url
          }
        }
      }
    }`;
    this.setState({
      loading: true,
    });
    gql
      .request(query, {
        filter: seller
          ? {
              ...this.state.filter,
              user_id: this.props.currentUser.id,
            }
          : { ...this.state.filter },
      })
      .then((res) => {
        this.setState({
          sites: res.sites.hits || [],
          total: res.sites.count,
          loading: false,
        });
      });
  }

  getUserID() {
    const query = `query userByRole($role: String!, $status: Boolean!, $search: String){
      usersByRole(role:$role, status:$status, search: $search){
          id
          first_name
          last_name
      }
    }`;

    this.setState({
      loading: true,
    });
    gql
      .request(query, {
        role: "Seller",
        status: true,
        search: "",
      })
      .then((res) => {
        this.setState({
          users: res.usersByRole || [],
          loading: false,
        });
      });
  }

  handleSelect = (id) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          user_id: id,
        },
      },
      () => this.loadSites()
    );
  };

  fetchProductVariants = (id) => {
    const query = `mutation FetchProductVariants{fetchProductVariants(site_id: ${id})}`;
    gql
      .request(query)
      .then((res) => {
        notification["success"]({
          message: "Success",
        });
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  };

  render() {
    const { sites, filter, total, loading, page, users } = this.state;
    let isSupporter = this.props.currentUser.roles.find((el) =>
      ["Supporter"].includes(el.name)
    );
    const isSeller = this.props.currentUser.roles.find(
      (role) => role.name === "Seller"
    );
    const columns = [
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
      },
      {
        title: "Site Name",
        key: "title",
        dataIndex: "title",
        render: (title, { webhooks }) => (
          <div>
            <div>{title}</div>
            {/* {webhooks && webhooks.map(wh => <span key={wh.id} style={{fontSize:12,color:'#ddd'}}>{wh.topic} => {wh.delivery_url}</span>)} */}
          </div>
        ),
      },
      {
        title: "Site Url",
        key: "domain",
        dataIndex: "domain",
        render: (domain, row) =>
          row.platform !== "import" ? (
            <a href={domain} target="_blank" rel="noopener noreferrer">
              {domain}
            </a>
          ) : (
            "-"
          ),
      },
      {
        title: "Platform",
        key: "platform",
        dataIndex: "platform",
        render: (row) => {
          switch (row) {
            case "shopbase":
              return <img alt="" src="/shopbase-logo.svg" width="80" />;
            case "shopify":
              return <img alt="" src="/shopify-logo.png" width="80" />;
            case "woocommerce":
              return <img alt="" src="/logo-woocommerce.png" width="100" />;
            default:
              return <img alt="" src="/import-site.png" width="100" />;
          }
        },
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (id, row) => `${row.user.first_name} ${row.user.last_name}`,
      },
      {
        title: "Status",
        dataIndex: "verified",
        key: "verified",
        align: "center",
        render: (verified, site) =>
          verified ? (
            <FontAwesomeIcon
              icon={faPlug}
              style={{
                color:
                  site.platform === "import" || site.type === "offline"
                    ? "red"
                    : "green",
                fontSize: 18,
              }}
            />
          ) : (
            <DisconnectOutlined
              style={{ color: "red", fontSize: 18 }}
              onClick={() => this.reConnect(site)}
            />
          ),
      },
      isSupporter
        ? {}
        : {
            title: "Actions",
            key: "actions",
            width: 250,
            dataIndex: "id",
            align: "right",
            render: (id, record) => {
              return (
                <div className="actions">
                  <Tooltip title="Fetch Product Variants">
                    <Popconfirm
                      title="Are you sure to fetch product variant for this site?"
                      onConfirm={() => this.fetchProductVariants(id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button size="small">
                        <PullRequestOutlined style={{ color: "orange" }} />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              );
            },
          },
    ];
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadSites()
        );
      },
    };
    return (
      <div>
        <Row style={{ display: "flex" }}>
          <Col md={12}>
            <Select
              labelInValue
              defaultValue={{ key: "1" }}
              style={{ width: 180, marginRight: 10 }}
              onChange={this.handleChange}
            >
              <Option value="1">Connected</Option>
              <Option value="0">Pending</Option>
            </Select>
            {!isSeller && (
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Seller"
                optionFilterProp="children"
                onChange={this.handleSelect}
              >
                <Option value={null}>All Seller</Option>
                {users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}{" "}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
        <Table
          pagination={pagination}
          columns={columns}
          dataSource={sites}
          loading={loading}
          rowKey={(text, index) => index}
        />
      </div>
    );
  }
}
export default connect(
  (state) => {
    return {
      currentUser: state.app.currentUser,
    };
  },
  { setCurrentUser }
)(EggSitesList);
