import React, { useState } from "react";
import { Popover, Button, Checkbox } from "antd";
import { SketchPicker, TwitterPicker } from "react-color";
import { BiFontColor, BiColorFill } from "react-icons/bi";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
.twitter-picker {
  box-shadow: none !important;
}
`;
const ColorBackgroundField = ({
        value,
        onChange,
        type = "font",
        view = "twitterPicker",
        size,
    }) => {
    const [transparent, setTransparent] = useState(value == "" || value == null ? true : false);
    return (
        <Popover
            placement={view === "sketchPicker" ? "bottomLeft" : "bottom"}
            content={
                <>
                    <Checkbox style={{
                        display: "flex",
                        justifyContent: "center"
                    }} checked={transparent}  onChange={(value)=> {
                        if(value.target.checked){
                            onChange("");
                            setTransparent(true);
                        }else {
                            onChange("#8ed1fc");
                            setTransparent(false);
                        }
                    }}>Transparent</Checkbox>
                    {!transparent ?
                        <TwitterPicker
                            triangle="hide"
                            color={value ? value : ""}
                            onChange={({ hex }) => {
                                if (hex !== value) onChange(hex);
                            }}
                        />
                        : null
                    }

                </>
            }
        >
            {type === "sharp" ? (
                <Button
                    style={{
                        backgroundColor: value ? value : "unset",
                        width: size ? size : 32,
                        height: size ? size : 32,
                    }}
                >
                    {" "}
                </Button>
            ) : (
                <Button
                    type="link"
                    icon={
                        <BiColorFill
                            className="anticon"
                            style={{ color: value ? value : "", fontSize: 20 }}
                        />
                    }
                />
            )}
            <GlobalStyle />
        </Popover>
    );
};

export default ColorBackgroundField;
