import gql from "graphql-tag";
export default gql`
  mutation(
    $id: String!
    $title: String!
    $preview: String
    $productTypeID: String!
    $productID: String
    $layers: [Map]
    $settings: MockupSettingsInput
    $ordering: Int
    $width: Int64
    $height: Int64
    $variantIds: [String!]
  ) {
    updateMockup(
      id: $id
      title: $title
      preview: $preview
      productTypeID: $productTypeID
      productID: $productID
      layers: $layers
      settings: $settings
      ordering: $ordering
      width: $width
      height: $height
      variantIds: $variantIds
    ) {
      id
      title
      preview
      productType {
        id
        printAreas {
          width
          height
          name
        }
      }
      productID
      ordering
      width
      height
      layers
      settings {
        defaultBgColor
        changeBgColorByVariantColor
        applyToVariants
      }
    }
  }
`;
