import React, { useRef, useState, useEffect } from "react";
import { RiUploadCloudFill } from "react-icons/ri";
import { AWS_API, ASSET_URL } from "../../../config";
import { message } from "antd";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const UploadMultiple = (props) => {
  const { accept, onChange, onUpload, disabled, children } = props;
  const inputFile = useRef();
  const [filesUpload, setFilesUpload] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const handleChooseFile = () => {
    inputFile.current.click();
  };
  const getSignedUrl = async (mime) => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    var signedUrl = null;
    await fetch(`${AWS_API}/signedUrl?type=${mime}`, requestOptions)
      .then((response) => response.json())
      .then((res) => (signedUrl = res))
      .catch((err) => console.error(err));
    return signedUrl;
  };

  const upload = async (base64, uid) => {
    var file = await fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
    var signedUrl = await getSignedUrl(file.type);
    const upFile = await axios
      .put(signedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: function (progressEvent) {
          if (progressEvent.lengthComputable) {
            const percentComplete =
              (progressEvent.loaded / progressEvent.total) * 100;
            setFilesUpload((prev) =>
              prev.map((i) => {
                if (i.uid == uid) {
                  i.percent = percentComplete.toFixed(2);
                }
                return i;
              })
            );
          }
        },
      })
      .catch((err) => console.error(err));

    if (upFile.status == 200) {
      return {
        status: true,
        key: signedUrl.key,
      };
    } else {
      return {
        status: false,
      };
    }
  };

  const handleUploadItem = async (file, uid) => {
    const base64 = await toBase64(file);
    const upFile = await upload(base64, uid);
    if (upFile.status) {
      setFilesUpload((prev) =>
        prev.map((i) => {
          if (i.uid == uid) {
            i.percent = 100;
            i.status = "done";
            i.key = upFile.key;
            i.url = ASSET_URL + upFile.key;
          }
          return i;
        })
      );
    } else {
      setFilesUpload((prev) => prev.filter((e) => e.uid != uid));
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  const handleChangeFile = (e) => {
    // setFilesUpload([]);
    const files = e.target.files;
    handlePushFiles(files);
    e.target.value = null;
  };

  function convertExtensionsToArray(extensionString) {
    const extensions = extensionString.split(",").map((ext) => ext.trim());
    const cleanExtensions = extensions.map((ext) =>
      ext.startsWith(".") ? ext.substring(1) : ext
    );
    return cleanExtensions;
  }

  const handlePushFiles = (files) => {
    var filesCurrent = [];
    if (accept) {
      const arrayAccept = convertExtensionsToArray(accept);
      for (var c = 0; c < files.length; c++) {
        const typeFile = files[c].type.split("/")[1];
        if (arrayAccept.includes(typeFile)) {
          filesCurrent = [...filesCurrent, files[c]];
        }
      }
    } else {
      filesCurrent = files;
    }
    if (filesCurrent.length <= 0) return;
    const key = "uploading_photos";
    message.loading({
      content: "Uploading photos. Please wait...",
      key,
      duration: 0,
    });
    for (var a = 0; a < filesCurrent.length; a++) {
      const uid = uuidv4();
      const item = {
        uid,
        file: filesCurrent[a],
        status: "running",
        percent: 0,
        name: filesCurrent[a].name,
      };
      setFilesUpload((prev) => [...prev, item]);
      handleUploadItem(item.file, item.uid);
    }
  };

  useEffect(() => {
    if (filesUpload.length > 0) {
      onChange(filesUpload);
    }

    const findNotDone = filesUpload.find((i) => i.status != "done");
    if (!findNotDone && filesUpload.length > 0) {
      onUpload(filesUpload);
    }
  }, [filesUpload]);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      handlePushFiles(droppedFiles);
      event.dataTransfer.clearData();
    }
  };
  return (
    <>
      <input
        onChange={handleChangeFile}
        ref={inputFile}
        style={{
          display: "none",
        }}
        accept={accept || "*"}
        type="file"
        multiple
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: disabled ? "0.3" : 1,
          pointerEvents: disabled ? "none" : "all",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleChooseFile}
      >
        {children || (
          <>
            <div style={{ padding: "10px 5px" }}>
              <RiUploadCloudFill size={30} />
              <p>Click or drag and drop .png or .jpg file here to upload</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UploadMultiple;
