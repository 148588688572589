import { useMutation, useQuery } from "@apollo/client";
import { Button, Tooltip, Image } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { HiOutlineExternalLink } from "react-icons/hi";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import { ORDER } from "../../graphql/queries/orders/order";
import Grid from "../Grid";
const Container = styled.div``;

const DesignOrder = (props) => {
  const { id, refetch } = props;
  const { data, loading: loadingQuery } = useQuery(ORDER, {
    fetchPolicy: "network-only",
    variables: { id: id },
  });
  const [designs, setDesigns] = useState();
  useEffect(() => {
    setDesigns(data ? data.order.designs : []);
  }, [data]);
  return (
    <Container>
      <Grid width={400} gap={20}>
        {designs?.map((design, index) => {
          const imageFile = `${CLOUDFRONT_URL}/400/${design.file?.key}`;
          const fileOriginal = `${CLOUDFRONT_URL}/auto/${design.file?.key}`;
          return (
            <div key={design.id}>
              <div className="flex space-between item-center">
                <span>{design.name}</span>
              </div>
              <div
                className="flex content-center"
                style={{
                  minHeight: 300,
                  background: "lightgray",
                  boxShadow:
                    "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
                }}
              >
                <div className="upload-design" style={{ width: "100%" }}>
                  <Image
                    style={{ backgroundColor: "#f5f5f5", objectFit: "contain" }}
                    width="auto"
                    height="auto"
                    preview={{
                      mask: (
                        <div>
                          <div className="flex item-center">
                            <AiOutlineEye /> Preview
                          </div>
                          <Tooltip title="View original">
                            <a
                              onClick={(e) => e.stopPropagation()}
                              target="_blank"
                              href={fileOriginal}
                              style={{
                                zIndex: 200000,
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                            >
                              <HiOutlineExternalLink
                                style={{
                                  color: "white",
                                  size: 30,
                                }}
                                className="anticon custom-icon"
                              />
                            </a>
                          </Tooltip>
                        </div>
                      ),
                      src: fileOriginal,
                    }}
                    src={imageFile}
                    fallback={`/default-img.png`}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </Grid>
    </Container>
  );
};

export default DesignOrder;
