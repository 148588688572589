import React, { useMemo, useState } from "react";
import _, { cloneDeep, differenceWith, forEach, isEqual, omit } from "lodash";
import { Divider, Switch, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MOCKUP } from "../../types";
import styled from "styled-components";

const Container = styled.div``;
const clearAttributeChecked = (attris) => {
  return attris.map((item) => omit(item, "checked"));
};

const SettingVariant = ({ productType }) => {
  const [warning, setWarning] = useState(null);
  const mockupWorkspace = useSelector((state) => state.mockup.mockupWorkspace);
  const { variant_ids } = mockupWorkspace;
  const dispatch = useDispatch();
  const [baseVariants, setBaseVariants] = useState(
    productType.product_type_variants.map((item) => {
      if (_.includes(variant_ids, item.id)) {
        return { ...item, is_active: true };
      } else {
        return { ...item, is_active: false };
      }
    })
  );
  const newVariantAttributes = useMemo(() => {
    if (baseVariants?.length) {
      let allAttributesFromVariant = _.concat(
        ...baseVariants.map((item) => item.attributes)
      );
      // unique attributes
      allAttributesFromVariant = _.uniqWith(
        allAttributesFromVariant,
        _.isEqual
      );
      const variantAttributes = allAttributesFromVariant.reduce((init, el) => {
        const key = el?.slug;
        if (!init[key]) {
          init[key] = [];
        }
        // get variants with the same attribute
        const variantContainAttributes = baseVariants.filter(
          (varr) =>
            _.differenceWith([el], varr.attributes, _.isEqual)?.length === 0
        );
        // checked tag if one or multiple variants variantContainAttributes contain status is true
        const attributeChecked = variantContainAttributes.find(
          (v) => v.is_active === true
        );
        init[key].push({
          ...el,
          checked: !!attributeChecked,
        });
        return init;
      }, {});
      return variantAttributes;
    }
    return {};
  }, [baseVariants]);
  const allAttributes = useMemo(() => {
    const total = [];
    forEach(newVariantAttributes, (attr) => {
      attr.forEach((item) => total.push(item));
    });
    return total;
  }, [newVariantAttributes]);
  const attributeBulkAction = (key, enable) => {
    const restAttributes = _.find(
      newVariantAttributes,
      (_, attriKey) => attriKey !== key
    );
    const restAttributesAvailable = restAttributes?.filter(
      (item) => item.checked
    );
    // const firstAttribute = allAttributes.find(
    //   (item) => item.slug === key && item.checked
    // );
    const allAttributeSelected = allAttributes.filter(
      (item) => item.slug === key
    );
    let newBaseVariants = cloneDeep(baseVariants);
    if (enable) {
      if (!restAttributes) {
        newBaseVariants = newBaseVariants.map((variant) => {
          return { ...variant, is_active: true };
        });
      } else {
        for (let index = 0; index < allAttributeSelected.length; index++) {
          const element = allAttributeSelected[index];
          const firstAttribute1 = allAttributes.find(
            (item) =>
              item.slug === key &&
              item.checked &&
              element.option === item.option
          );
          console.log(firstAttribute1);
          if (firstAttribute1) {
            newBaseVariants = newBaseVariants.map((variant) => {
              if (
                !differenceWith(
                  variant.attributes,
                  clearAttributeChecked([
                    ...restAttributesAvailable,
                    ...allAttributeSelected,
                  ]),
                  isEqual
                ).length
              ) {
                return { ...variant, is_active: true };
              }
              return {
                ...variant,
              };
            });
          } else {
            let first_variant = newBaseVariants.find((variant) => {
              if (
                variant.attributes.find(
                  (item) => item.option === element.option
                )
              ) {
                return variant;
              }
            });
            newBaseVariants = newBaseVariants.map((variant) => {
              if (first_variant.id === variant.id) {
                return { ...variant, is_active: true };
              } else {
                return variant;
              }
            });
          }
        }
      }
    } else {
      newBaseVariants = newBaseVariants.map((variant) => {
        return { ...variant, is_active: false };
      });
    }
    setBaseVariants(newBaseVariants);
    dispatch({
      type: MOCKUP.SET_VARIANT_IDS,
      payload: newBaseVariants
        .filter((item) => item.is_active)
        .map((item) => item.id),
    });
  };
  const changeVariant = (check, attributeKey) => {
    let count = newVariantAttributes[attributeKey.slug]?.filter(
      (el) => el.checked
    ).length;
    if (count === 0 && check) {
      let first_variant = baseVariants.find((variant) => {
        if (
          variant.attributes.find(
            (item) => item.option === attributeKey.option
          ) &&
          variant.is_active !== check
        ) {
          return variant;
        }
      });

      let newBaseVariants = baseVariants.map((variant) => {
        if (first_variant.id === variant.id) {
          return { ...variant, is_active: check };
        }
        return variant;
      });
      const variant_ids = newBaseVariants.filter(
        (item) => item.is_active === true
      );
      dispatch({
        type: MOCKUP.SET_VARIANT_IDS,
        payload: variant_ids.map((item) => item.id),
      });
      setBaseVariants(newBaseVariants);
    } else {
      // const restAttributes = _.find(
      //   newVariantAttributes,
      //   (_, key) => key !== attributeKey.slug
      // );

      const restAttributes = _.reduce(
        newVariantAttributes,
        (init, item, key) => {
          if (key !== attributeKey.slug) {
            return init.concat(item);
          }
          return init;
        },
        []
      );
      const restAttributesAvailable = restAttributes?.filter(
        (item) => item.checked
      );
      let newBaseVariants = baseVariants.map((variant) => {
        if (restAttributesAvailable) {
          if (
            !differenceWith(
              variant.attributes,
              clearAttributeChecked([...restAttributesAvailable, attributeKey]),
              isEqual
            ).length
          ) {
            return { ...variant, is_active: check };
          }
          return variant;
        } else {
          if (
            !differenceWith(
              variant.attributes,
              [omit(attributeKey, "checked")],
              isEqual
            ).length
          ) {
            return { ...variant, is_active: check };
          }
          return variant;
        }
      });
      setBaseVariants(newBaseVariants);
      let variantnewids = newBaseVariants
        .filter((item) => item.is_active)
        .map((item) => item.id);
      if (variant_ids.length !== variantnewids.length) {
        dispatch({
          type: MOCKUP.SET_VARIANT_IDS,
          payload: newBaseVariants
            .filter((item) => item.is_active)
            .map((item) => item.id),
        });
      } else {
        setWarning(true);
        setTimeout(() => {
          setWarning(false);
        }, 1000);
      }
    }
  };
  const joinAttr = [].concat.apply(
    [],
    _.map(newVariantAttributes, (item, key) => item)
  );
  const availableVariants = baseVariants.filter((item) => {
    if (item.attributes.length > 1) {
      const matchAttribute = joinAttr.filter(
        (att) =>
          _.differenceWith(item.attributes, [omit(att, "checked")], _.isEqual)
            .length === 1
      );
      const attributeDisabled = matchAttribute?.some(
        (attr) => attr.checked === false
      );
      return !attributeDisabled;
    } else {
      if (item.is_active) {
        return item.is_active;
      }
    }
  });
  const handleSetChangeValue = (variant, key, v) => {
    let newBaseVariants = cloneDeep(baseVariants);
    newBaseVariants = newBaseVariants.map((s) =>
      s.id === variant.id
        ? {
            ...s,
            [key]: v,
          }
        : s
    );

    setBaseVariants(newBaseVariants);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "attributes",
      align: "center",
      key: "attributes",
      render: (attributes) => (
        <div>
          {attributes?.map((att, id) => {
            if (id === 0) {
              return <span key={id}>{att.option}</span>;
            } else {
              return <span key={id}> / {att.option}</span>;
            }
          })}
        </div>
      ),
    },
    {
      title: "active",
      align: "center",
      render: (record) => (
        <div>
          <Switch
            size="small"
            checked={_.includes(variant_ids, record.id)}
            onChange={(e) => {
              if (e) {
                dispatch({
                  type: MOCKUP.SET_VARIANT_IDS,
                  payload: [...variant_ids, record.id],
                });
              } else {
                dispatch({
                  type: MOCKUP.SET_VARIANT_IDS,
                  payload: _.difference(variant_ids, [record.id]),
                });
              }
              handleSetChangeValue(record, "is_active", e);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Container>
      <div>
        <h3>Apply to variants</h3>
        {_.map(newVariantAttributes, (item, key) => (
          <div key={key} style={{ marginTop: 5, alignItems: "center" }}>
            <div
              style={{ textTransform: "capitalize" }}
              className="flex space-between"
            >
              {key}:
              <div>
                <a
                  href
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    attributeBulkAction(key, true);
                  }}
                >
                  All
                </a>
                <Divider type="vertical" />
                <a
                  href
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    attributeBulkAction(key, false);
                  }}
                >
                  None
                </a>
              </div>
            </div>
            {item.map((el) => (
              <Tag.CheckableTag
                key={el.value}
                checked={el.checked}
                onChange={(checked) => {
                  changeVariant(checked, el);
                }}
                style={{
                  cursor: "pointer",
                  border: "1px solid #5c6ac4",
                  padding: "2px 10px",
                  margin: "5px 6px 5px 0",
                  alignItems: "center",
                  display: "inline-flex",
                }}
              >
                {el.option}
              </Tag.CheckableTag>
            ))}
            {/* <div style={{ color: "var(--error-color)" }}>
              {item.filter((e) => e.value === warning).length === 1
                ? "At least one variant is selected"
                : null}
            </div> */}
          </div>
        ))}
      </div>
      {warning ? <div style={{ color: "red" }}> Not available</div> : null}
      <h3 style={{ marginTop: 10 }}>
        <b>Variants</b>
      </h3>
      <Table
        columns={columns}
        dataSource={availableVariants}
        rowKey="id"
        pagination={false}
      />
    </Container>
  );
};

export default SettingVariant;
