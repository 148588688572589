import gql from "graphql-tag";

export const CREATE_ARTWORK = gql`
  mutation createArtwork($input: NewArtwork!) {
    createArtwork(input: $input) {
      id
      title
      lock
      categories {
        id
        title
      }
      templates {
        title
        preview
        thumbnail
      }
      templateDisplayMode
      templateDisplayLabel
      sharedLayers
      width
      height
      author {
        id
        first_name
        last_name
        email
        phone
        avatar {
          id
          key
          url
        }
      }
      createdAt
      # usedIn
    }
  }
`;

export const UPDATE_ARTWORK = gql`
  mutation updateArtwork($id: String!, $input: UpdateArtwork) {
    updateArtwork(input: $input, id: $id) {
      id
      title
      lock
      categories {
        id
        title
      }
      templates {
        title
        preview
        thumbnail
      }
      templateDisplayMode
      templateDisplayLabel
      sharedLayers
      width
      height
      author {
        id
        first_name
        last_name
        email
        phone
        avatar {
          id
          key
          url
        }
      }
      createdAt
      # usedIn
    }
  }
`;

export const DELETE_ARTWORK = gql`
  mutation deleteArtwork($id: String!) {
    deleteArtwork(id: $id)
  }
`;

export const DUPLICATE_ARTWORK = gql`
  mutation duplicateArtwork($id: String!) {
    duplicateArtwork(id: $id) {
      id
      title
      lock
      categories {
        id
        title
      }
      templates {
        title
        preview
        thumbnail
      }
      templateDisplayMode
      templateDisplayLabel
      sharedLayers
      width
      height
      author {
        id
        first_name
        last_name
        email
        phone
        avatar {
          id
          key
          url
        }
      }
      createdAt
      # usedIn
    }
  }
`;

export const CHANGE_LOCK_ARTWORK = gql`
  mutation changeLockStatus($id: String!, $status: Boolean!) {
    changeLockStatus(id: $id, status: $status)
  }
`;

export const IMPORT_PSD_ARTWORK = gql`
  mutation importArtworkFromPSD($fileID: String!, $categoryIDs: [String!]!,$title: String) {
    importArtworkFromPSD(fileID: $fileID, categoryIDs: $categoryIDs, title:$title)
  }
`;
