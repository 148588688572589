import React, {useState, useEffect} from "react";
import { Col, Row } from "antd";
import BackgroundAutoBanner from "./background";
import CategorysAutoBanner from "./category";
const ManagementBackground = (props) => {
  const {currentUser} = props;
  const [categoryID, setCategory] = useState(null);
  return (
    <>
      <Row>
        <Col 
          span={8} 
          style={{
            padding: 10,
            background: "rgb(249 249 249)",
            border: "1px solid #efefef",
            borderRadius: 10
          }}
        >
            <h3>Categorys:</h3>
            <CategorysAutoBanner onChangeId={(v) => setCategory(v)} currentUser={currentUser}/>
        </Col>
        <Col span={16} style={{
          padding: "0px 10px"
        }}>
            <BackgroundAutoBanner category_id={categoryID} currentUser={currentUser}/>
        </Col>
      </Row>
    </>
  );
};

export default ManagementBackground;
