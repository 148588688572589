import gql from "graphql-tag";

export const ORDERS = gql`
  query order($id: String!) {
    orderById(id: $id) {
      id
      product {
        title
        sku
        images
      }
      variant {
        image
      }
      customer {
        id
        first_name
        last_name
        email
        phone
      }
      site {
        id
        title
        domain
      }
      billing {
        address1
        city
        country_code
        zip
      }
      shipping {
        address1
        city
        country_code
        zip
      }
      price
      quantity
      status
      created_at
    }
  }
`;

export const EXPORT_ORDER = gql`
  query p($site_id: Int!, $origin_id: String!) {
    productCheckOriginID(site_id: $site_id, origin_id: $origin_id) {
      variants {
        product_type {
          id
          title
          sku
          images
          categories {
            id
            title
          }
          attributes {
            name
            slug
            options
          }
          product_type_variants {
            id
            product_type_id
            sku
            base_cost
            sale_cost
            retail_cost
            attributes {
              name
              slug
              option
            }
          }
        }
      }
    }
  }
`;
export const GET_ORDER_HISTORY = `
  query GetOrderHistory($order_id: String!) {
    getOrderHistory(order_id: $order_id) {
      id
      message
      user {
        id
        first_name
        last_name
      }
      order {
        id
      }
      type
      updated_at
    }
  }
`;
