import React, { useState, useEffect } from "react";
import UploadImage from "../components/UploadImage";
import { Form, Space, Button } from "antd";
import { URL_API_IMAGE_GENERATION_STREAM } from "../../../../config";
import { SSE } from "../../../../sse";
export default function DescribeGeneration({
  onChange,
  onChangeLoading,
  spinning,
  emitdata,
  serverID,
  timeNext,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    setLoading(spinning);
  }, [spinning]);

  useEffect(() => {
    onChangeLoading(loading);
  }, [loading]);

  const onFinish = (v) => {
    setLoading(true);
    setData({ ...emitdata, progress: "0%" });
    v.image = image;
    v.action = "describe";
    v.serverId = serverID;
    var source = new SSE(URL_API_IMAGE_GENERATION_STREAM, {
      method: "POST",
      payload: JSON.stringify(v),
    });
    window.sourceMidjourney = source;
    source.addEventListener("error", function (e) {
      setLoading(false);
    });
    source.addEventListener("message", function (e) {
      if (!e.data) {
        setLoading(false);
        return;
      }
      const data = JSON.parse(e.data);
      if (data.status == "done") {
        data.progress = "100%";
        data.form = v;
        setLoading(false);
      }
      setData(data);
    });
    source.stream();
  };

  const onFinishFailed = (v) => {
    // console.log(v);
  };

  const handleChangeFile = (v) => {
    setImage(v);
  };

  const handleCancel = () => {
    if (window.sourceMidjourney) {
      window.sourceMidjourney.close();
    }
    setLoading(false);
    setData({ ...data, progress: "100%" });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Image">
        <UploadImage onChange={handleChangeFile} />
      </Form.Item>
      <Form.Item>
        <Space>
          {/* {loading && (
            <Button onClick={handleCancel} type="dashed" htmlType="button">
              Cancel
            </Button>
          )} */}
          <Button
            disabled={!image}
            loading={loading || timeNext > 0}
            type="primary"
            htmlType="submit"
          >
            Generation {timeNext > 0 && `${timeNext}s`}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
