import { Input, Select, Popover, Avatar, Button } from "antd";
import { cloneDeep, get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { CLOUDFRONT_URL } from "../../../config";
import Cliparts from "./Cliparts";
const findLayer = (id, layers) => {
  for (let index in layers) {
    if (layers[index].id === id) {
      return layers[index];
    }
  }
  var _layers = [];
  layers.forEach((l) => {
    if (Array.isArray(l.layers)) {
      _layers.push(...cloneDeep(l.layers));
    }
  });
  if (_layers.length > 0) {
    return findLayer(id, _layers);
  }
  return null;
};

const LayerOptionField = ({
  layerId,
  value,
  onChange = () => {},
  workspace,
}) => {
  const { artwork, selectedTemplate } = workspace;
  const layers =
    get(artwork, `templates[${selectedTemplate}].layers`, []) || [];
  const layer = findLayer(layerId, layers);
  if (layer === null) {
    return "Please select option";
  }
  switch (layer.type) {
    case "Option":
      return (
        <Select size="small" value={value} onChange={onChange}>
          {(layer.options || []).map((op) => (
            <Select.Option key={op.value} value={op.value}>
              {op.label}
            </Select.Option>
          ))}
        </Select>
      );
    case "Text":
      return <Input value={value} onChange={onChange} size="small" />;
    case "Image":
      switch (layer.personalized?.type) {
        case "images":
          return (
            <Select size="small" value={value} onChange={onChange}>
              {layer.values.map((v) => (
              <Select.Option key={v.file.id}>
                <Avatar
                    size={24}
                    // src={`${process.env.CDN_URL}100x100/${v.file.key}`}
                    src={`${CLOUDFRONT_URL}/${v.file.key}`}
                ></Avatar>{" "}
                {v.file.fileName}
              </Select.Option>
              ))}
            </Select>
          );
        case "clipartCategory":
          return (
              <Popover
                  content={
                    <div style={{ width: 325 }}>
                      <Cliparts
                          categoryID={layer.personalized.clipartCategory}
                          value={value ? { file: value } : null}
                          onChange={(clipart) => {
                            onChange({ id: clipart.file.id, key: clipart.file.key });
                          }}
                          auto={false}
                      />
                    </div>
                  }
              >
                {value?.key ? (
                    <div
                        style={{
                          border: "1px solid #d9d9d9",
                          borderRadius: 2,
                          cursor: "pointer",
                        }}
                    >
                      <img src={`${CLOUDFRONT_URL}/100/${value?.key}`} />
                    </div>
                ) : (
                    <Button size="small">Select</Button>
                )}
              </Popover>
          );

        default:
          return null;
      }
    default:
      return null;
  }
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(LayerOptionField);
