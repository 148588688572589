import { useMutation } from "@apollo/client";
import { Button, Form, Input, notification, Skeleton } from "antd";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { UPDATE_ORDER_INFO } from "../../graphql/mutation/orders/orderAction";

const ShippingAddress = ({ edit, setEdit, orderItem, detail, refetch }) => {
  const [form] = Form.useForm();
  const [updateShipping, { loading: mutationLoading }] = useMutation(
    UPDATE_ORDER_INFO
  );
  const [shippings, setShippings] = useState();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    if (orderItem) {
      setShippings(orderItem?.shipping);
      setCustomer(orderItem?.customer);
    }
  }, [orderItem]);
  const customerInfo = [
    {
      value: "first_name",
      name: "First name",
      required: true,
    },
    {
      value: "last_name",
      name: "Last name",
      required: true,
    },
    {
      value: "phone",
      name: "Phone",
      required: true,
    },
    {
      value: "email",
      name: "Email",
    },
  ];

  const shippingInfo = [
    {
      value: "address1",
      name: "Address 1",
      required: true,
    },
    {
      value: "address2",
      name: "Address 2",
    },
    {
      value: "company",
      name: "Company",
    },
    {
      value: "city",
      name: "City",
      required: true,
    },
    {
      value: "state",
      name: "State",
      required: true,
    },
    {
      value: "state_code",
      name: "State code",
      required: true,
    },
    {
      value: "zip",
      name: "Zip code",
      required: true,
    },
    {
      value: "country",
      name: "Country",
      required: true,
    },
    {
      value: "country_code",
      name: "Country code",
      required: true,
      max: 5,
    },
  ];

  const handleSaveUpdate = (values) => {
    const shipping = omit(values, ["phone", "email"]);
    updateShipping({
      variables: {
        order_id: orderItem.id,
        customer_info: {
          shipping: { ...shipping },
          customer: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
          },
        },
      },
    })
      .then(() => {
        refetch();
        notification.success({
          message: "The shipping address updated successfully!",
        });
        setShippings({ ...shippings, ...values });
        setEdit(false);
        form.resetFields();
      })
      .catch((err) => notification.error({ message: err.message }));
  };

  return (
    <div>
      <div className="flex space-between">
        <h3>SHIPPING ADDRESS</h3>
        <Button
          onClick={() => {
            setEdit(!edit);
            form.resetFields();
          }}
          type="link"
          icon={
            edit ? (
              <GrClose className="custom-icon anticon" />
            ) : (
              <BiEditAlt className="custom-icon anticon" />
            )
          }
        />
      </div>
      {edit ? (
        <Form form={form} onFinish={handleSaveUpdate}>
          {customerInfo.map((item) => (
            <Form.Item
              key={item.value}
              initialValue={customer ? customer[item.value] : ""}
              name={item.value}
              label={item.name}
              rules={[
                {
                  required: item.required,
                  message: `${item.name} can't be blank.`,
                },
                {
                  max: item.max,
                  message: `${item.name} max ${item.max} character`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
          {shippingInfo.map((item) => (
            <Form.Item
              key={item.value}
              initialValue={shippings ? shippings[item.value] : ""}
              name={item.value}
              label={item.name}
              rules={[
                {
                  required: item.required,
                  message: `${item.name} can't be blank.`,
                },
                {
                  max: item.max,
                  message: `${item.name} max ${item.max} character`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
          <div className="align-right mt-15">
            <Button
              disabled={mutationLoading}
              onClick={() => {
                setEdit(false);
                form.resetFields();
              }}
              className="mr-15"
            >
              Cancel
            </Button>
            <Button loading={mutationLoading} htmlType="submit" type="primary">
              Save Changes
            </Button>
          </div>
        </Form>
      ) : (
        <>
          {customerInfo.map((item) => (
            <div key={item.value}>
              <p>
                <strong> {item.name}: </strong>
                &nbsp;
                {customer ? customer[item.value] : null}
              </p>
            </div>
          ))}
          {shippingInfo.map((item) => (
            <div key={item.value}>
              <p>
                <strong> {item.name}: </strong>
                &nbsp;
                {shippings ? shippings[item.value] : null}
              </p>
            </div>
          ))}
        </>
      )}
      <div hidden={!detail} style={{ marginTop: 20 }}>
        <h3>BILLING ADDRESS</h3>
        <p>Same as shipping address</p>
      </div>
    </div>
  );
};

export default ShippingAddress;
