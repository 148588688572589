import React, { Component } from "react";
import { Form, notification, Input, Button, InputNumber } from "antd";
import gql from "../../api/gql";
import _ from "lodash";

class AddUserLevel extends Component {
  state = {
    loading: false,
  };
  onClose = () => {
    this.props.onClose();
  };
  onhandleSubmit = (values) => {
    const mutation = `mutation addUser($input: NewUserLevel!){
          createUserLevel(input: $input){
            id
            name
            order_total
            discount
            creater{
              id
              first_name
              last_name
              email
            }
          }
        }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        input: values,
      })
      .then((res) => {
        this.setState({
          loading: true,
        });
        this.props.onLoad();
        this.onClose();
        this.props.form.resetFields();
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };
  render() {
    const { loading } = this.state;
    return (
      <Form onFinish={this.onhandleSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter name of level!",
            },
          ]}
        >
          <Input placeholder={"Level name"} />
        </Form.Item>
        <Form.Item
          label="Total Order"
          name="order_total"
          rules={[
            {
              required: true,
              message: "Please enter total order!",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            max={100000}
            step={1}
          />
        </Form.Item>
        <Form.Item
          label="Discount"
          name="discount"
          rules={[
            {
              required: true,
              message: "Please enter discount!",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            max={100}
            step={0.1}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon="save"
          >
            Save
          </Button>
          <Button
            icon="undo"
            style={{ marginLeft: 10 }}
            onClick={() => {
              this.onClose();
            }}
            loading={loading}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export default AddUserLevel;
