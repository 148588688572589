import {
  Alert,
  Button,
  Form,
  Input,
  notification,
  Progress,
  TreeSelect,
  message
} from "antd";
import React, { useEffect, useState } from "react";
import Upload from "../upload/Upload";
import { CREATE_FILE } from "../../graphql/mutation/createFile";
import { useMutation, useQuery } from "@apollo/client";
import { IMPORT_PSD_ARTWORK } from "../../graphql/mutation/artworkMutation";
import { Subscription } from "@apollo/client/react/components";
import { SUBCRIPTION_IMPORT } from "../../graphql/queries/artworks";
import { history } from "../../history";
import Modal from "antd/lib/modal/Modal";
import { ARTWORK_CATEGORY } from "../../graphql/queries/artwork/artworkCategories";
import ItemAddCategories from "./ItemAddCategories";
import { RiUploadCloudFill } from "react-icons/ri";
import { connect } from "react-redux";

const ImportFromPSD = ({ categoryID, currentUser }) => {
  const isSeller = currentUser?.roles.find((role) => role.name === "Seller");
  const [createFile] = useMutation(CREATE_FILE);
  const [importPSD, { data }] = useMutation(IMPORT_PSD_ARTWORK);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [token, setToken] = useState();
  const [visible, setVisible] = useState();
  const [form] = Form.useForm();
  const { data: dataCategories, refetch } = useQuery(ARTWORK_CATEGORY, {
    variables: {
      userID: isSeller ? currentUser.id : null,
    },
    // fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data) {
      setToken(data.importArtworkFromPSD);
    }
  }, [data]);

  const getTreeData = (treeData) => {
    if (!treeData) {
      return null;
    }
    return treeData.map((cat) => {
      return {
        title: cat.title,
        value: cat.id,
        key: cat.id,
        children: getTreeData(cat.children),
      };
    });
  };
  const ImportForm = (values) => {
    setLoading(true);
    // setVisible(false);
    new Promise((resolve, reject) => {
      createFile({
        variables: {
          input: {
            key: values.fileID.key,
            file_name: values.fileID.name,
            file_mime: values.fileID.type,
            file_size: values.fileID.size,
            source: "aws",
          },
        },
      })
        .then((res) => {
          // console.log("data", res);
          // resolve(res.data.createFile);
          importPSD({
            variables: {
              fileID: res.data.createFile.id,
              title: values.title,
              categoryIDs: values.categoryIDs,
            },
          })
            .then(() => {
              // notification.success({ message: "The clipart saved success!" });
              // refetch();
            })
            .catch((err) => {
              setVisible(false);
              setLoading(false);
            });
          // if (res.data.createFile) {
          // }
        })
        .catch((err) => {
          setLoading(false);
          setVisible(false);
          reject(err);
        });
    });
  };
  return (
    <div className="p-import-psd">
      {data && token && (
        <Subscription
          subscription={SUBCRIPTION_IMPORT}
          variables={{ token }}
          onSubscriptionData={({ subscriptionData }) => {
            notification.success({ message: "Artwork imported" });
            setLoading(false);
            setVisible(false);
            history.push(
              `/artworks/${subscriptionData?.data?.artwork?.id}/design`
            );
          }}
        />
      )}
      <Button
        // disabled={categoryID ? false : true}
        loading={loading}
        onClick={() => {
          setVisible(true);
        }}
      >
        Import From PSD
      </Button>
      <Modal
        forceRender
        visible={visible}
        onCancel={() => {
          if (!loading) {
            setVisible(false);
          }
        }}
        title={"Import Form PSD"}
        footer={false}
      >
        <Form layout={"vertical"} form={form} onFinish={ImportForm}>
          <Form.Item name="title" label="Title">
            <Input readOnly={loading} />
          </Form.Item>
          <Form.Item
            name="categoryIDs"
            label="Categories"
            rules={[
              { required: true, message: "Please input your Categories!" },
            ]}
          >
            <TreeSelect
              disabled={loading}
              multiple
              showSearch
              style={{ width: "100%" }}
              // dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={getTreeData(dataCategories?.artworkCategories)}
              placeholder="Select a parent category"
              treeDefaultExpandAll
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div>
                    <ItemAddCategories refetch={refetch} />
                  </div>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item
            name="fileID"
            label="File PSD"
            rules={[{ required: true, message: "Please input your file PSD!" }]}
          >
            <Upload
              disabled={loadingFile || loading}
              onChange={(file) => {
                setLoadingFile(file);
                const key = 'uploading_photos';
                setTimeout(() => {
                  message.success({ content: 'Uploading image successfully!', key, duration: 2 });
                }, 1000);
              }}
              onUpload={() => {
                setTimeout(() => {
                  setLoadingFile(false);
                }, 1000);
              }}
              showUploadList={false}
              accept=".psd"
            >
              {loadingFile ? (
                <Progress
                  percent={Math.round(loadingFile.percent)}
                  type="circle"
                  size="small"
                  width={50}
                />
              ) : form.getFieldValue("fileID") ? (
                form.getFieldValue("fileID").name
              ) : (
                <div style={{ padding: "10px 0" }}>
                  <RiUploadCloudFill size={30} />
                  <p>Click or drag and drop PSD file here to upload</p>
                </div>
              )}
            </Upload>
          </Form.Item>
          {loading && (
            <Alert
              banner
              message={"Your artwork is importing. Please wait a moment!"}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 20,
            }}
          >
            <Button onClick={() => setVisible(false)} disabled={loading}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loadingFile}
              loading={loading}
            >
              Import
            </Button>
          </div>
        </Form>
      </Modal>
      {/* {loading && (
        <div className="screen-loading" style={{ zIndex: 10000 }}>
          <div className="bounceball"></div>
          <p
            style={{
              fontSize: 20,
              marginBottom: 0,
              lineHeight: 37,
              marginLeft: 10,
            }}
          >
            Your artwork is importing from PSD. Please wait for completion!
          </p>
        </div>
      )} */}
    </div>
  );
};

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(ImportFromPSD);
