import { DeleteOutlined } from "@ant-design/icons"; //DownOutlined
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Divider,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import { USER_ID_BLOCKED } from "../../config";
import ButtonUnlockUser from "../ButtonUnlockUser";
import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { ARTWORK_CATEGORY } from "../../graphql/queries/artwork/artworkCategories";
import { cloneDeep } from "lodash";
import CategoryArtworkModal from "./CategoryArtworkModal";
import deleteArtworkCategory from "../../graphql/mutation/artwork/deleteArtworkCategory";
import { history } from "../../history";
import { connect } from "react-redux";

const CategoriesList = (props) => {
  const { currentUser } = props;
  const isSeller = currentUser?.roles?.find((role) => role.name === "Seller");
  const [search, setSearch] = useState("");
  const [showAddNew, setShowAddNew] = useState(false);
  const [showAddNewTo, setShowAddNewTo] = useState(null);
  const [showRename, setShowRename] = useState(null);
  const [deleteArtworkCategoryMutation] = useMutation(deleteArtworkCategory);
  const { data, subscribeToMore, refetch } = useQuery(ARTWORK_CATEGORY, {
    fetchPolicy: "network-only",
    variables: {
      userID: isSeller ? currentUser.id : null,
    },
  });
  const [dataSource, setDataSource] = useState(
    data ? data.artworkCategories : []
  );
  // console.log(data);
  useEffect(() => {
    setDataSource(data ? data.artworkCategories : []);
  }, [data]);
  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
  };
  useEffect(() => {
    if (!search) {
      setDataSource(data ? data.artworkCategories : []);
    } else {
      keywordFilter(cloneDeep(data.artworkCategories), search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const keywordFilter = (nodes, search) => {
    var newNodes = [];
    for (var n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, search);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (
          n.title.toString().toLowerCase().includes(search.toLowerCase())
        ) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.title.toString().toLowerCase().includes(search.toLowerCase())
        ) {
          newNodes.push(n);
          // letExpandedKey.push(n.id);
        }
      } else {
        if (n.title.toString().toLowerCase().includes(search.toLowerCase())) {
          newNodes.push(n);
          // letExpandedKey.push(n);
        }
      }
    }
    // setExpanded([...expanded, ...letExpandedKey]);
    setDataSource(newNodes);
    return newNodes;
  };

  const columns = [
    { title: "Title", key: "title", dataIndex: "title" },
    {
      title: "Actions",
      width: 150,
      align: "right",
      render: (record) => (
        <>
          <Tooltip title="Edit category">
            <Button
              onClick={() => {
                setShowRename(record);
              }}
              icon={<BiEditAlt className="custom-icon anticon" />}
              size="small"
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Delete category">
          {(currentUser.id == USER_ID_BLOCKED) ? 
          <ButtonUnlockUser
          onConfirm={() => {
            deleteArtworkCategoryMutation({
              variables: { id: record.id },
            })
              .then(() => {
                notification.success({ message: "Delete Success" });
                refetch();
              })
              .catch((err) => notification.error({ message: err.message }));
          }}
          key={'32'} propsButton={{
            icon: (<DeleteOutlined style={{ color: "red" }} />),
            size: "small"
          }} />
          :
          <Popconfirm
              placement="top"
              okButtonProps={{
                danger: true,
              }}
              title="Are you sure to delete this category?"
              onConfirm={() => {
                deleteArtworkCategoryMutation({
                  variables: { id: record.id },
                })
                  .then(() => {
                    notification.success({ message: "Delete Success" });
                    refetch();
                  })
                  .catch((err) => notification.error({ message: err.message }));
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined style={{ color: "red" }} />}
                size="small"
              />
            </Popconfirm>
            }
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <div className="p-15-24 p-artworks">
      <div>
        <PageHeader
          title={"Artwork Categories"}
          style={{ padding: 0, marginBottom: 7 }}
          onBack={() => {
            history.goBack();
          }}
          extra={
            <div className="flex">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowAddNew(true);
                  setShowAddNewTo(true);
                }}
                type="primary"
                style={{ marginLeft: 5 }}
              >
                Add New
              </Button>
            </div>
          }
        />
      </div>
      <div className="header-filter">
        <Input.Search
          onChange={(event) => handleChangeSearch(event)}
          placeholder="Search..."
        />
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={"id"}
        pagination={false}
      />
      <CategoryArtworkModal
        title={`${showRename !== null ? "Edit category" : "Add category"}`}
        visible={showRename !== null ? showRename : showAddNew}
        showAddNewTo={showAddNewTo}
        setShowAddNew={setShowAddNew}
        showAddNew={showAddNew}
        showRename={showRename}
        setShowRename={setShowRename}
        categories={data?.artworkCategories}
        refetch={refetch}
      />
    </div>
  );
};

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(CategoriesList);
