import React from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { history } from "../../history";
import { PlusCircleOutlined } from "@ant-design/icons";

class ProductToolbar extends React.Component {
  render() {
    const add = this.props.currentUser.roles.find((el) =>
      ["Seller"].includes(el.name)
    );
    const showAdd = add && this.props.currentSite;
    // console.log(showAdd);
    return (
      <>
        {add && this.props.currentSite && (
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => history.push("/products/add/step1")}
          >
            Add New
          </Button>
        )}
      </>
    );
  }
}

export default connect((state) => ({
  currentUser: state.app.currentUser,
  currentSite: state.app.currentSite,
}))(ProductToolbar);
