import React, { useState, useEffect } from "react";
import { Tabs, Spin, Button } from "antd";
import KlingCreateImg from "./tabs/KlingCreateImg";
import { CloseOutlined } from "@ant-design/icons";
import KlingCreateVideo from "./tabs/KlingCreateVideo";
import KlingDescribe from "./tabs/KlingDescribe";
export default function AiKling() {
  const [cancel, setCancel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const changeLoading = (value) => {
    setLoading(value);
  };
  const handleCancel = () => {
    setLoading(false);
    if (cancel) {
      cancel.cancel("Request canceled by timeout.");
    }
  };

  useEffect(() => {
    if (progress >= 90) {
      if (window.ProgressIntervalKling) {
        clearInterval(window.ProgressIntervalKling);
      }
    }
  }, [progress]);

  const handeProgressInterval = () => {
    var i = setInterval(() => {
      setProgress((prev) => prev + 2);
    }, 1000);
    window.ProgressIntervalKling = i;
  };

  const handleComplete = () => {
    setProgress(100);
    if (window.ProgressIntervalKling) {
      clearInterval(window.ProgressIntervalKling);
    }
    setTimeout(() => {
      setLoading(false);
      setProgress(0);
    }, 1000);
  };

  const handleChangeCancel = (c) => {
    setCancel(c);
    setProgress(0);
    handeProgressInterval();
  };

  return (
    <div>
      {(loading || progress > 0) && (
        <div
          style={{
            width: 565,
            aspectRatio: "1/1",
            padding: 50,
          }}
          className="box-loading-generation"
        >
          <Spin>
            <div
              style={{
                height: 40,
                marginBottom: 10,
              }}
            ></div>
          </Spin>
          <div className="progress-grooup-loading">
            <div className="progress-box-kling">
              <div
                style={{
                  width: `${progress}%`,
                }}
                className="progress-box-de-kling"
              ></div>
            </div>
            <div className="progress-text-kling">{progress}%</div>
          </div>

          <p
            style={{
              marginBottom: 20,
              textAlign: "center",
              color: "#894e00",
              fontSize: 15,
              fontWeight: 500,
            }}
          >
            Your creation is being finalized! <br />
            Enjoy a coffee break while we complete the work.
            <br /> The task continues to work its magic, even when you leave the
            page.
          </p>

          <Button
            className="btn-cancel"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
      <div className={`${loading ? "loadingGeneration" : ""}`}>
        <Tabs defaultActiveKey="item-1">
          <Tabs.TabPane tab="Imagine" key="item-1">
            <KlingCreateImg
              onChangeCancel={handleChangeCancel}
              onChangeLoading={changeLoading}
              onComplete={handleComplete}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Video" key="item-2">
            <KlingCreateVideo
              onChangeCancel={handleChangeCancel}
              onChangeLoading={changeLoading}
              onComplete={handleComplete}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Describe" key="item-3">
            <KlingDescribe
              onChangeCancel={handleChangeCancel}
              onChangeLoading={changeLoading}
              onComplete={handleComplete}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}
