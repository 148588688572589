import React from "react";
import { Modal, notification, Menu } from "antd";
import {
  AiFillFolderAdd,
  AiFillSetting,
  AiTwotoneDelete,
} from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { HiDuplicate } from "react-icons/hi";
import { useMutation } from "@apollo/client";
// import CategoryDelete from "./CategoryDelete";
import duplicateArtworkCategory from "../graphql/mutation/artwork/duplicateArtworkCategory";
import deleteArtworkCategory from "../graphql/mutation/artwork/deleteArtworkCategory";
import { history } from "../history";

const RightClickMenu = (props) => {
  const {
    tmpStyle,
    setClickRight,
    setShowAddNew,
    showChildren,
    setShowAddNewTo,
    setShowRename,
    clickRight,
    setSettings,
  } = props;
  const [DuplicateArtworkCategory] = useMutation(duplicateArtworkCategory);
  const [deleteArtworkCategoryMutation] = useMutation(deleteArtworkCategory);
  const dulicate = () => {
    Modal.confirm({
      content: "Are you sure to duplicate category?",
      onOk() {
        DuplicateArtworkCategory({ variables: { id: showChildren.id } })
          .then((res) => {
            notification.success({ message: "success" });
            history.push(
              `/artworks?categoryID=${res.data.duplicateArtworkCategory.id}`,
              "artworks"
            );
            // router.push("/artworks", "artworks");
          })
          .catch((err) => {
            notification.error({ message: err.message });
          });
      },
    });
  };
  const deleteCate = () => {
    Modal.confirm({
      title: "Are you sure to delete this category?",
      content:
        "It can contain the sub-folders or cliparts, so if you remove it means you remove the nested folders or cliparts too.",
      onOk() {
        deleteArtworkCategoryMutation({ variables: { id: showChildren.id } })
          .then((res) => {
            notification.success({ message: "success" });
            // router.push("/artworks", "artworks");
          })
          .catch((err) => {
            if (
              err.message ===
              "GraphQL error: Please delete all subcategories first"
            ) {
              notification.error({
                message: "Please delete all subcategories first",
              });
            } else {
              notification.error({ message: err.message });
            }
          });
      },
      okButtonProps: { danger: true },
    });
  };

  return (
    <div>
      <Menu
        className="p-menu"
        style={!clickRight.visible ? { display: "none" } : { ...tmpStyle }}
      >
        <Menu.Item
          disabled={
            showChildren.number && showChildren.__typename === "ClipartCategory"
          }
        >
          <a
            href="/#"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAddNewTo(showChildren);
              setShowAddNew(true);
              setClickRight({ visible: false, pageX: null, pageY: null });
            }}
          >
            <AiFillFolderAdd className="anticon" /> New category
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            href="/#"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAddNewTo(null);
              setShowRename(showChildren);
              setClickRight({ visible: false, pageX: null, pageY: null });
            }}
          >
            <BiEditAlt className=" anticon" /> Rename
          </a>
        </Menu.Item>
        {showChildren.__typename === "ClipartCategory" && (
          <Menu.Item
            disabled={!showChildren.parentID && !showChildren.isFolder}
          >
            <a
              href="/#"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSettings(showChildren);
                setClickRight({ visible: false, pageX: null, pageY: null });
              }}
            >
              <AiFillSetting className=" anticon" /> Setting
            </a>
          </Menu.Item>
        )}
        <Menu.Item>
          {/* <CategoryDuplicate
            setClickRight={setClickRight}
            id={showChildren.id}
          /> */}
          <a
            href="/#"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dulicate();
              setClickRight({ visible: false, clickX: null, clickY: null });
            }}
          >
            <HiDuplicate className="anticon" /> Duplicate
          </a>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item>
          <a
            href="/#"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              deleteCate();
              setClickRight({ visible: false, clickX: null, clickY: null });
            }}
            style={{ color: "#ff4d4f" }}
          >
            <AiTwotoneDelete style={{ color: "#ff4d4f" }} className="anticon" />
            Delete
          </a>
          {/* <CategoryDelete setClickRight={setClickRight} id={showChildren.id} /> */}
        </Menu.Item>
      </Menu>
      <div
        style={
          !clickRight.visible
            ? { display: "none" }
            : {
                width: "100%",
                height: "100vh",
                backgroundColor: "transparent",
                position: "fixed",
                left: 0,
                top: 0,
                zIndex: 99,
              }
        }
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setClickRight({ visible: false, pageX: null, pageY: null });
        }}
      />
    </div>
  );
};

export default RightClickMenu;
