import React, { Component } from "react";
import { notification } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import { history } from "../../history";
import UserForm from "./UserForm";

class UserAdd extends Component {
  state = {
    loading: false,
    isChecked: [],
    roles: [],
    form: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address: "",
      password: "",
      roles: [],
    },
  };

  compareToFirstPassword = (rule, values, callback) => {
    const { form } = this.props;
    if (values && values !== form.getFieldValue("pass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  componentDidMount() {
    const q = `query roles{roles{id name}}`;
    gql.request(q).then((res) => {
      this.setState({
        roles: res.roles
          ? res.roles.filter((r) => r.name !== "Authenticated")
          : [],
      });
    });
  }
  onhandleSubmit = (value) => {
    delete value.confirm;
    if (value.pass === "") {
      delete value.pass;
    }
    //value.birthday = moment(value.birthday).format("DD-MM-YYYY")
    if (value.birthday) {
      value.birthday = value.birthday.toDate();
    }
    const mutation = `mutation createUser($input: NewUser!){
          createUser(input: $input){
            id
            first_name
            last_name
            email
            address
            phone
            birthday
            roles{
              id
              name
            }
            is_subscription
          }
        }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        input: value,
      })
      .then((res) => {
        notification["success"]({ message: "User successfully created " });
        this.setState({
          loading: false,
        });
        history.push("/admin/users-manager");
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
    return;
  };
  render() {
    const { loading } = this.state;
    return (
      <UserForm
        roles={this.state.roles}
        onSubmitAdd={(value) => this.onhandleSubmit(value)}
        loading={loading}
      />
    );
  }
}

export default UserAdd;
