import { useMutation } from "@apollo/client";
import { Mutation, Query } from "@apollo/client/react/components";
import {
  Alert,
  Card,
  Image,
  message,
  Popconfirm,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import styled from "styled-components";
import { CREATEMOCKUP } from "../../graphql/mutation/mokups/create";
import Grid from "../Grid";
import MOCKUPS from "../../graphql/queries/mockupsByProductType";
import UploadFileMockup from "../mockup/UploadFileMockup";
import MockupDesign from "../mockup/MockupDesign";
import DELETEMOCKUP from "../../graphql/mutation/mokups/delete";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CLOUDFRONT_URL } from "../../config";
const Container = styled.div`
  .ant-avatar > img {
    object-fit: contain;
    background: lightgray;
  }
  .mockup-toolbar {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
  }
  img.fit-content {
    object-fit: contain;
  }
  .ant-card-cover {
    margin: 0;
  }
  .disable-upload {
    background: gainsboro;
    cursor: not-allowed;
    pointer-events: unset;
  }
  .delete-icon {
    &:hover {
      color: red;
    }
  }
  .edit-icon {
    &:hover {
      color: #ff9100;
    }
  }
`;

const MokupsProductType = (props) => {
  const { productType } = props;
  const [createMockup, { loading: createLoading }] = useMutation(CREATEMOCKUP, {
    refetchQueries: [
      {
        query: MOCKUPS,
        variables: {
          productTypeID: productType?.id,
        },
      },
    ],
  });
  const [mockup, setMockup] = useState(null);
  const [showEditMockup, setShowEditMockup] = useState(false);

  return (
    <Container>
      <Grid width={120} gap={24}>
        {productType && (
          <Query query={MOCKUPS} variables={{ productTypeID: productType.id }}>
            {({ data, loading, error }) => {
              if (loading) {
                return <Skeleton />;
              }
              if (error) {
                return <Alert message={error.message} type="error" />;
              }
              if (data) {
                return data.mockups.map((mk) => (
                  <Card
                    key={mk.id}
                    size="small"
                    style={{ height: 120 }}
                    cover={
                      <div>
                        <Image
                          src={`${CLOUDFRONT_URL}200x200/${mk.preview}`}
                          preview={{
                            src: `${CLOUDFRONT_URL}autoxauto/${mk.preview}`,
                          }}
                          className="fit-content"
                          fallback="/no-preview.jpg"
                          height={120}
                          width="100%"
                        />
                        <Space className="mockup-toolbar">
                          <EditOutlined
                            className="edit-icon"
                            onClick={() => {
                              setMockup(mk);
                              setShowEditMockup(true);
                            }}
                          />
                          <Mutation
                            mutation={DELETEMOCKUP}
                            refetchQueries={[
                              {
                                query: MOCKUPS,
                                variables: { productTypeID: productType.id },
                              },
                            ]}
                          >
                            {(deteleMockup, { loading, error }) => (
                              <Popconfirm
                                title="Delete this mockup?"
                                okButtonProps={{
                                  danger: true,
                                }}
                                onConfirm={() => {
                                  deteleMockup({
                                    variables: {
                                      id: mk.id,
                                    },
                                  })
                                    .then(() => {
                                      message.success("Mockup deleted");
                                    })
                                    .catch((err) => {
                                      message.error(err.message);
                                    });
                                }}
                              >
                                {loading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <DeleteOutlined className="delete-icon" />
                                )}
                              </Popconfirm>
                            )}
                          </Mutation>
                        </Space>
                      </div>
                    }
                    bodyStyle={{ display: "none" }}
                  />
                ));
              }
            }}
          </Query>
        )}
        <UploadFileMockup
          accept=".jpg,.jpeg,.png,.psd"
          onChange={(file) => {
            createMockup({
              variables: {
                fileID: file.id,
                productTypeID: productType.id,
                isProductBaseMockup: false,
              },
            })
              .then((res) => {
                setMockup(res.data.createMockup);
                setShowEditMockup(true);
              })
              .catch((err) => {
                message.error(err.message);
              });
          }}
        >
          {createLoading ? (
            <LoadingOutlined style={{ fontSize: 40, color: "#52c41a" }} spin />
          ) : (
            <Tooltip
              title={
                productType
                  ? "Add new11"
                  : "Please save your product base before add mockup"
              }
            >
              <div style={{ padding: "25px 0" }}>
                <PlusOutlined />
              </div>
            </Tooltip>
          )}
        </UploadFileMockup>
      </Grid>
      <Modal
        visible={showEditMockup}
        title="Edit Mockup"
        width={"100vw"}
        footer={null}
        maskClosable={false}
        bodyStyle={{ padding: "15px 0 0", maxHeight: "100vh" }}
        onCancel={() => setShowEditMockup(false)}
        className="modal-fixed"
        key={mockup?.id}
      >
        {mockup && (
          <MockupDesign
            productType={productType}
            id={mockup.id}
            onFinish={() => {
              setShowEditMockup(false);
              setMockup(null);
            }}
            onCancel={() => {
              setShowEditMockup(false);
              setMockup(null);
            }}
          />
        )}
      </Modal>
    </Container>
  );
};

export default MokupsProductType;
