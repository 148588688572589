import React from "react";
import styled from "styled-components";
import {
  PlusOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import UploadNew from "../upload/Upload";
import { Progress, message } from "antd";
import { CLOUDFRONT_URL } from "../../config";

const Container = styled.div`
  .delete-action {
    position: absolute;
    right: 10px;
    top: 5px;
    &:hover {
      color: red;
    }
  }
`;
export default class Images extends React.Component {
  state = {
    value: this.props.value || [],
    loading: false,
    images: [],
  };

  handleUpload = (e) => {
    const newabc = e.map((item) => item.key);
    this.props.onChange([...this.state.value, ...newabc]);
  };

  handlePreview = (e) => {
    this.setState({ previewVisible: true });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleDeleteImage = (img) => {
    this.setState(
      {
        value: this.state.value.filter((url) => url !== img),
      },
      () => this.props.onChange(this.state.value)
    );
  };

  render() {
    const { value, images } = this.state;
    return (
      <Container>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, min(100%, 100px))",
            gap: 5,
          }}
        >
          {value.map((img, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                position: "relative",
                background: "#ddd",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                size="small"
                onClick={() => this.handleDeleteImage(img)}
                className="delete-action"
              >
                <CloseCircleOutlined size="small" title="Delete file" />
              </div>

              <div>
                <img
                  src={`${CLOUDFRONT_URL}/100/${img}`}
                  style={{ height: 100, width: "100%" }}
                  alt=""
                />
              </div>
            </div>
          ))}
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 100,
                position: "relative",
                background: "#ddd",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {(image.id || image.percent === 100) &&
              image.status === "done" ? (
                <div>
                  <div
                    size="small"
                    onClick={() => {
                      const newImage = images.filter(
                        (item) => item.key !== image.key
                      );
                      const newImageKey = newImage.map((item) => item.key);
                      this.setState(
                        {
                          images: newImage,
                        },
                        () =>
                          this.props.onChange([
                            ...this.state.value,
                            ...newImageKey,
                          ])
                      );
                    }}
                    className="delete-action"
                  >
                    <CloseCircleOutlined size="small" title="Delete file" />
                  </div>
                  <img
                    src={`${CLOUDFRONT_URL}/100/${image.originFileObj.key}`}
                    style={{ height: 100, width: "100%" }}
                    alt=""
                  />
                </div>
              ) : (
                <Progress percent={image.percent} type="circle" width={30} />
              )}
            </div>
          ))}
          <UploadNew
            accept=".jpg, .png, .jpeg"
            multiple={true}
            fileList={images}
            showUploadList={false}
            onChange={(files) => {
              this.setState({ images: files });
              const key = 'uploading_photos';
              setTimeout(() => {
                message.success({ content: 'Uploading image successfully!', key, duration: 2 });
              }, 1000);
            }}
            onUpload={this.handleUpload}
          >
            <PlusOutlined />
          </UploadNew>
        </div>
      </Container>
    );
  }
}
