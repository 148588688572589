import React, { useState, useRef } from "react";
import { Tag, Row, message } from "antd";
const TagsForm = ({ value, onChange }) => {
  const input = useRef();
  const [inputValue, setInputValue] = useState("");

  const isValidTag = (tag) => {
    return /^[a-zA-Z]+$/.test(tag);
  };

  const hasCommonCharacter = (arr) => {
    const charSets = arr.map(item => new Set(item.toLowerCase()));
    for (let i = 0; i < charSets.length - 1; i++) {
        for (let j = i + 1; j < charSets.length; j++) {
          for (let char of charSets[i]) {
              if (charSets[j].has(char)) {
                return true;
              }
          }
        }
    }
    return false;
  }

  const handleChange = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      const trimmedTag = inputValue.trim();
      if (!trimmedTag) return;
      const newTag = [...value, trimmedTag];
      if (!isValidTag(trimmedTag)) {
        message.error("Only use alphabet characters (a-z, A-Z), DO NOT use numbers or special characters.");
        return;
      }
      if(!hasCommonCharacter(newTag) && newTag.length == 2){
        message.error(`No letters in the word ${trimmedTag} exist in any other word.`);
        return;
      }
      if (value.includes(trimmedTag)) {
        message.error("For the same name, vary the capitalization i.e Ashley/ashLey.");
        return;
      }
      onChange([...value, trimmedTag]);
      input.current.focus();
      setInputValue("");
    }
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      !inputValue &&
      value.length > 0
    ) {
      handleClose(value.length - 1);
    }
  };

  const handleClose = (index) => {
    const newValue = value.filter((i, inx) => inx !== index);
    onChange(newValue);
  };
  return (
    <>
      <div>
        <Row className="row-add-tag">
          {value.map((i, index) => (
            <Tag
              color="#2db7f5"
              className="tag-item"
              key={i}
              closable
              onClose={() => handleClose(index)}
            >
              {i}
            </Tag>
          ))}
          <input
            value={inputValue}
            onChange={handleChange}
            ref={input}
            onKeyDown={handleKeyDown}
            className="input-add-tag"
            placeholder={value.length <= 0 ? "Inster words" : ""}
          />
        </Row>
      </div>
    </>
  );
};

export default TagsForm;
