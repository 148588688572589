import React from "react";
import styled from "styled-components";
import { Row, Col, Progress, message } from "antd";
import Upload from "./Upload";
import _, { get } from "lodash";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { CLOUDFRONT_URL } from "../config";
const Container = styled.div`
  .ant-col > div {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    .image-tools {
      position: absolute;
      top: 0;
      width: 100%;
      border: ${(props) =>
        props.selected ? "2px dashed blue" : "1px solid #f5f5f5"};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      z-index: 1;
      &.image-tools {
        z-index: 2;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.25);
        color: #fff;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  img {
    max-width: 100%;
  }
  .ant-row-flex > div {
    overflow: hidden;
  }
`;

export default class UploadImages extends React.Component {
  state = {
    loading: false,
    percent: 0,
    value: this.props.value || [],
    seletedImage: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onProcess = (e) => {
    this.setState({
      loading: true,
      percent: Math.round((e.loaded / e.total) * 100),
    });
  };

  onUpload = (image) => {
    const { value } = this.state;
    value.push({ image: image, type: "create" });
    this.setState({ value, loading: false }, this.handleOnChange);
  };

  handleOnChange = () => {
    const { onChange } = this.props;
    if (typeof onChange === "function") {
      onChange(this.state.value);
    }
  };

  render() {
    const { loading, percent, value, seletedImage } = this.state;
    const { selectMode } = this.props || false;
    return (
      <Container>
        <Row gutter={5} type="flex">
          {value.map((image, index) => (
            <Col span={12} sm={8} md={6} key={index}>
              <div
                onClick={(e) => {
                  if (!selectMode) {
                    return true;
                  }
                  e.preventDefault();
                  this.setState({ seletedImage: image }, () => {
                    const { onSelect } = this.props;
                    if (typeof onSelect === "function") {
                      onSelect(this.state.seletedImage);
                    }
                  });
                }}
                style={{
                  border:
                    seletedImage === image
                      ? "2px dashed blue"
                      : "1px solid #f5f5f5",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${CLOUDFRONT_URL}/100xauto/${get(image.image, 'key') ? get(image.image, 'key') : image.image}`}
                    // src={image.image.url}
                    alt=""
                  />
                </div>

                {!selectMode && (
                  <div className="image-tools">
                    <DeleteOutlined
                      style={{ fontSize: 16, color: "red" }}
                      onClick={(e) => {
                        e.preventDefault();
                        const { value } = this.state;
                        value.splice(index, 1);
                        this.setState({ value }, this.handleOnChange);
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          ))}
          <Col span={12} sm={8} md={6}>
            <Upload
              onUpload={this.onUpload}
              onProcess={this.onProcess}
              onChange={() => {
                const key = 'uploading_photos';
                setTimeout(() => {
                  message.success({ content: 'Uploading image successfully!', key, duration: 2 });
                }, 1000);
              }}
              showUploadList={false}
              accept=".jpg, .png, .jpeg"
              {..._.pickBy(
                this.props,
                (value, key) =>
                  ["multiple", "accept", "fileList"].indexOf(key) !== -1
              )}
            >
              {loading ? (
                <Progress type="circle" percent={percent} width={40} />
              ) : (
                <PlusOutlined
                  style={{
                    cursor: "pointer",
                    width: "100%",
                  }}
                />
              )}
              <div className="drag-photo"></div>
            </Upload>
          </Col>
        </Row>
      </Container>
    );
  }
}
