import Konva from "konva";
import { get, omit } from "lodash";
import React, { useEffect, useRef } from "react";
import { Image as KonvaImage } from "react-konva";
import useImage from "use-image";
import { CLOUDFRONT_URL, API_URL } from "../../config";

const Image = (props) => {
  const imageRef = useRef();
  const { file, _filters, absolutePosition, ratio, zoom } = props;
  const filters = _filters || [];

  const [img] = useImage(
    CLOUDFRONT_URL + `${ratio === 1 ? "auto" : ratio * 10 + "10"}/` + file?.key,
    API_URL
  );

  useEffect(() => {
    if (img) {
      const node = imageRef.current;
      if (!node.getAttr("width")) {
        node.setAttr("width", img.width);
      }
      if (!node.getAttr("height")) {
        node.setAttr("height", img.height);
      }
      imageRef.current.cache();
      imageRef.current.getLayer().batchDraw();
    }
  }, [img, imageRef]);

  useEffect(() => {
    if (imageRef && imageRef.current && absolutePosition) {
      imageRef.current.absolutePosition({
        x: absolutePosition.x * zoom,
        y: absolutePosition.y * zoom,
      });
    }
  }, [imageRef, absolutePosition, zoom]);

  useEffect(() => {
    if (img && imageRef && imageRef.current) {
      imageRef.current.getLayer().dispatchEvent(new Event("update"));
    }
  }, [imageRef, img, props.width, props.height]);

  useEffect(() => {
    if (img) {
      const node = imageRef.current;
      if (!node.getAttr("width")) {
        node.setAttr("width", img.width);
      }
      if (!node.getAttr("height")) {
        node.setAttr("height", img.height);
      }
      imageRef.current.cache();
      imageRef.current.getLayer().batchDraw();
    }
  }, [img]);

  const onTransform = () => {
    const node = imageRef.current;
    const { scaleX, scaleY } = node.getAttrs();
    node.width(node.width() * scaleX);
    node.height(node.height() * scaleY);
    node.scale({ x: 1, y: 1 });
    imageRef.current.cache();
    imageRef.current.getLayer().batchDraw();
  };

  return (
    <KonvaImage
      ref={imageRef}
      {...omit(props, [
        "absolutePosition",
        "crop",
        "cropX",
        "cropY",
        "cropWidth",
        "cropHeight",
      ])}
      image={img}
      filters={filters.map((filter) => get(Konva.Filters, filter))}
      onTransform={onTransform}
    />
  );
};
export default Image;
