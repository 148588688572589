import React, { useState, useEffect } from "react";
import gql from "../../../../api/gql";
import { Select, message, Card } from "antd";
import ListBackgroundByID from "./components/ListBackgroundByID";
const DesignsCategorys = ({ selected, onChange, onChangeBackground, selectedImage }) => {
  const [loading, setLoading] = useState(false);
  const [categorys, setCategorys] = useState([]);
  const [idActive, setIdActive] = useState(null);
  const getCaterotyAutoBanner = () => {
    setLoading(true);
    const query = `query{
        bannerAllCategory{
            numberOfBanners
                hits{
                    id
                    title
                }
            }
    }`;

    gql
      .request(query)
      .then((res) => {
        const hits = res.bannerAllCategory.hits || [];
        const cates = hits.map((e) => {
          return { value: e.id, label: e.title };
        })
        setCategorys(cates);
        setIdActive(cates[0] ? cates[0].value : null);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again!");
        setLoading(false);
      });
  };

  const handleChangeSelect = (v) => {
    setIdActive(v);
    onChange([]);
  }

  useEffect(() => {
    getCaterotyAutoBanner();
  }, []);

  return (
    <>
      <Card title="Background" style={{ width: "100%" }}>
        <Select
          value={idActive}
          showSearch
          style={{
            width: "100%",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={handleChangeSelect}
          options={categorys}
        />
        <div style={{
          marginTop: 10
        }}>
          <ListBackgroundByID
            selected={selected}
            onChange={onChange}
            onChangeBackground={onChangeBackground}
            category_id={idActive}
            selectedImage={selectedImage}
          />
        </div>
      </Card>
    </>
  );
};

export default DesignsCategorys;
