import { useMutation } from "@apollo/client";
import { Col, Form, Input, InputNumber, notification, Row, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { CREATE_ARTWORK } from "../../graphql/mutation/artworkMutation";
import ArtworkCategoriesField from "./ArtworkCategoriesField";
import { history } from "../../history";
import { URL_GET_SIZE_ARTWORK } from "../../config";
import axios from "axios";
const ArtWorkNew = ({ categoryID, showAddArtwork }) => {
  const [form] = Form.useForm();
  const [sizes, getSizes] = useState([]);
  const [createArtworkMutation, { loading }] = useMutation(CREATE_ARTWORK);
  useEffect(() => {
    query_sizes();
  }, []);
  const query_sizes = async () => {
    const typesArtWork = await axios.get(URL_GET_SIZE_ARTWORK);
    if(typesArtWork.status == 200){
      let newData = typesArtWork.data
      newData.forEach((element, index) => {
        element.id = index;
      });
      getSizes(newData)
    }
  }
  const handleChangeSelectSize = (e) => {
    const sise = sizes.find(item => item.id == e.target.value);
    if(sise){
      let valueFields = form.getFieldsValue();
      valueFields.width = sise.width;
      valueFields.height = sise.height;
      form.setFieldsValue(valueFields)
    }
  }
  const onFinish = (values) => {
    createArtworkMutation({
      variables: {
        input: values,
      },
    })
      .then((res) =>
        history.push(`/artworks/${res?.data?.createArtwork?.id}/design`)
      )
      .catch((err) => {
        notification.error({ message: err.message });
      });
  };
  useEffect(() => {
    if (categoryID === null) {
      form.setFieldsValue({
        categoryIds: [],
      });
    } else {
      form.setFieldsValue({
        categoryIds: [categoryID],
      });
    }
  }, [categoryID, form]);
  return [
    <div>
      <Form
        layout="vertical"
        id="formAddArtwork"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item name="title" label="Title" rules={[
          {
            required: true,
            message: 'Please enter title!',
          },
        ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="categoryIds"
          label="Categories"
          initialValue={categoryID ? categoryID : null}
          rules={[
            {
              required: true,
              message: 'Please select product category!',
            },
          ]}
        >
          <ArtworkCategoriesField />
        </Form.Item>
        <Form.Item label="Choose an available size">
          <Radio.Group onChange={handleChangeSelectSize} buttonStyle="solid" className="add_new_artwork_select_size">
              {sizes.map(e => (
                <Radio.Button key={e.id} value={e.id} className="add_new_artwork_select_size_item">
                  <img width="100%" src={e.images} alt={e.name} />
                  <div className="add_new_artwork_select_size_item_label">{e.name}</div>
                </Radio.Button>
              ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item style={{ padding: 0, marginBottom: 0 }} label="Size">
          <Row gutter={[30]}>
            <Col span={12}>
              <Form.Item name="width" style={{ marginBottom: 5 }} 
              rules={[
                {
                  required: true,
                  message: 'Please enter width!',
                },
              ]}
              >
                <InputNumber
                  min={1}
                  style={{ width: "100%" }}
                  placeholder="Width"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="height" style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: 'Please enter height!',
                },
              ]}
              >
                <InputNumber
                  min={1}
                  style={{ width: "100%" }}
                  placeholder="Height"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>,
    { form, loading },
  ];
};

export default ArtWorkNew;
