import React, { useState } from "react";
import Animate from "rc-animate";
import { Button, Card, Divider, notification } from "antd";
import UploadDesigns from "./UploadDesigns2";
import { useMutation, useSubscription } from "@apollo/client";
import {
  GENERATE_PRODUCT_MOCKUP,
  SUBCRIPTION_GENERATE_PRODUCT_MOCKUP,
} from "../../graphql/mutation/product/createproduct";
import _ from "lodash";
import BaseMockup from "./BaseMockup";
import CustomerMockup from "./CustomerMockup";
const ProductPrintFile = ({
  printAreas,
  setPrintAreas,
  newId,
  images,
  subImages,
  variations,
  setvariations,
  selectedVariations,
  setSelectedVariations,
  loadingRenderMockup,
  setLoadingRenderMockup,
  excludeMockups,
  changeExcludeMockups,
  closeId,
}) => {
  const [genrate] = useMutation(GENERATE_PRODUCT_MOCKUP);
  // const [loading, setLoading] = useState(false);
  const [productTypeMockup, setProductTypeMockup] = useState(printAreas);
  const [numberCustomer, setNumberCustomer] = useState(0);

  const generateProductMockups = () => {
    subImages(images.filter((item) => item.type === "create"));
    setLoadingRenderMockup(true);
    let dataRender = [];
    printAreas.forEach((element) => {
      element.printAreas.forEach((item, key) => {
        dataRender = [
          ...dataRender,
          {
            productId: newId,
            productTypePrintAreaId: item.id,
            productTypeId: element.product_type_id,
            artworkId: item.artwork_id,
            sorting: key,
          },
        ];
      });
    });

    genrate({
      variables: { input: dataRender, excludeMockups: excludeMockups },
    })
      .then((res) => {})
      .catch((err) => {
        notification.error({ message: err.message });
        // setLoadingRenderMockup(false);
      });
  };
  const { data } = useSubscription(SUBCRIPTION_GENERATE_PRODUCT_MOCKUP, {
    onSubscriptionData: ({
      subscriptionData: {
        data: { generateMockupProduct },
      },
    }) => {
      if (generateMockupProduct.productId === newId) {
        setLoadingRenderMockup(!generateMockupProduct.allDone);
        // setLoading(!generateMockupProduct.allDone);
      }
      setSelectedVariations(
        selectedVariations.map((item) => {
          if (
            _.includes(generateMockupProduct.productTypeVariantIds, item.id)
          ) {
            return {
              ...item,
              image: {
                mockup_id: generateMockupProduct.id,
                image: generateMockupProduct.image,
                type: generateMockupProduct.mockupId,
              },
            };
          } else {
            return item;
          }
        })
      );
      setvariations(
        variations.map((item) => {
          if (
            _.includes(generateMockupProduct.productTypeVariantIds, item.id)
          ) {
            return {
              ...item,
              image: {
                mockup_id: generateMockupProduct.id,
                image: generateMockupProduct.image,
                type: generateMockupProduct.mockupId,
              },
            };
          } else {
            return item;
          }
        })
      );

      if (newId === generateMockupProduct.productId)
        if (
          images.find((item) => item.type === generateMockupProduct.mockupId)
        ) {
          subImages(
            images.map((item) => {
              if (item.type === generateMockupProduct.mockupId) {
                return {
                  ...item,
                  mockup_id: generateMockupProduct.id,
                  image: generateMockupProduct.image,
                };
              } else {
                return item;
              }
            })
          );
        } else {
          subImages([
            ...images,
            {
              mockup_id: generateMockupProduct.id,
              image: generateMockupProduct.image,
              type: generateMockupProduct.mockupId,
            },
          ]);
        }
    },
  });
  return (
    <div>
      <Animate transitionAppear transitionName="fade">
        <div>
          <Card title="Base Mockups" style={{ marginTop: 10 }}>
            {printAreas.map((item, index) => (
              <div key={index}>
                <h3>{item.title}</h3>
                <BaseMockup
                  setProductTypeMockup={(value, baseId) => {
                    setProductTypeMockup(
                      productTypeMockup.map((item) => {
                        if (item.product_type_id === baseId) {
                          return {
                            ...item,
                            numberMockup: value.mockups.length,
                          };
                        } else {
                          return item;
                        }
                      })
                    );
                  }}
                  idBase={item.product_type_id}
                  changeExcludeMockups={changeExcludeMockups}
                  excludeMockups={excludeMockups}
                />
              </div>
            ))}
          </Card>
          <Card title="Custom Mockups" style={{ marginTop: 10 }}>
            <CustomerMockup
              closeId={closeId}
              setNumberCustomer={(value) => {
                setNumberCustomer(value);
              }}
              newId={newId}
              productType={printAreas.map((item) => {
                return {
                  product_type_id: item.product_type_id,
                  title: item.title,
                };
              })}
            />
          </Card>
          <Card
            title="Print Files"
            style={{ margin: "20px 0" }}
            extra={
              <div>
                <Button
                  onClick={() => generateProductMockups()}
                  loading={loadingRenderMockup}
                  disabled={
                    printAreas.find((pfile) => {
                      return pfile.printAreas.find((i) => !i.artwork_id);
                    }) ||
                    (productTypeMockup
                      .map((item) => item.numberMockup)
                      .reduce((a, b) => a + b) === excludeMockups.length &&
                      numberCustomer === 0)
                      ? true
                      : false
                  }
                >
                  Render Mockups
                </Button>
              </div>
            }
          >
            {printAreas.map((item, index) => (
              <div key={index}>
                <h3>{item.title}</h3>
                <Divider style={{ marginTop: 0 }} />
                <UploadDesigns
                  printFiles={item.printAreas}
                  onChange={(files) => {
                    printAreas.find(
                      (pfile) => pfile.product_type_id === item.product_type_id
                    ).printAreas = files;
                    setPrintAreas(printAreas);
                  }}
                />
              </div>
            ))}
            {/* <UploadDesigns
              printFiles={productType.printAreas}
              onChange={(files) => {
                // const { printAreas } = this.state;
                printAreas.find(
                  (pfile) => pfile.product_type_id === productType.id
                ).printAreas = files;
                setPrintAreas(printAreas);
                // this.setState({ printAreas });
              }}
            /> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => generateProductMockups()}
                loading={loadingRenderMockup}
                disabled={
                  printAreas.find((pfile) => {
                    return pfile.printAreas.find((i) => !i.artwork_id);
                  }) ||
                  (productTypeMockup
                    .map((item) => item.numberMockup)
                    .reduce((a, b) => a + b) === excludeMockups.length &&
                    numberCustomer === 0)
                    ? true
                    : false
                }
              >
                Render Mockups
              </Button>
            </div>
          </Card>
        </div>
      </Animate>
    </div>
  );
};

export default ProductPrintFile;
