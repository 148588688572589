import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Menu, notification } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import { setOrderId } from "../../actions";
import { ExportOutlined } from "@ant-design/icons";

class OrdersToolbar extends React.Component {
  state = {
    loading: false,
    templates: [],
  };
  componentDidMount() {
    const { currentUser } = this.props;
    let isSeller = currentUser.roles.find((role) =>
      ["Seller"].includes(role.name)
    );
    if (isSeller) {
    } else {
      this.loadExportTemplates();
    }
  }
  loadExportTemplates = () => {
    const { filter } = this.state;
    let query = `query exportTemplates($filter: TemplateFilter){
      exportTemplates(filter: $filter){
        count
        hits {
          id
          name
          author_id
          data{
            name
            type
            value
          }
        }
      }
    }`;
    gql
      .request(query, {
        filter,
      })
      .then((res) => {
        this.setState({
          templates: res.exportTemplates.hits,
        });
      });
  };
  exportOrders = (value) => {
    // e.preventDefault();
    this.setState({
      loading: true,
    });
    const { filter, currentUser } = this.props;
    let isSeller = currentUser.roles.find((role) =>
      ["Seller"].includes(role.name)
    );
    let query = `mutation supplierExportOrder($filter: OrderFilter){
      supplierExportOrder(filter: $filter)
    }`;
    let seller = `mutation exportOrders($filter: OrderFilter){
      exportOrders(filter: $filter)
    }`;
    gql
      .request(isSeller ? seller : query, {
        template_id: value,
        filter: { ...filter, order_id: this.props.order_id },
      })
      .then((res) => {
        window.location = `${
          isSeller ? res.exportOrders : res.supplierExportOrder
        }`;
        if (isSeller) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState(
            {
              loading: false,
            },
            () => this.props.onChange()
          );
        }

        this.props.setOrderId(null);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  };

  render() {
    const { loading } = this.state;
    const { filter, currentUser } = this.props;
    let isSeller = currentUser.roles.find((role) =>
      ["Seller"].includes(role.name)
    );
    const status = _.get(filter, "status", []);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
          marginLeft: 5,
        }}
      >
        {isSeller ? (
          <Button
            onClick={this.exportOrders}
            // disabled={!status || (status && !status.includes("accepted"))}
            // disabled={!status || (status && status.length === 0)}
            loading={loading}
            style={{ marginLeft: 5 }}
            type="primary"
            icon={<ExportOutlined />}
          >
            Export Orders
          </Button>
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => {
                    this.exportOrders();
                  }}
                >
                  Default template
                </Menu.Item>
                {this.state.templates.map((item) => (
                  <Menu.Item
                    key={2}
                    value={item.id}
                    onClick={() => {
                      this.exportOrders(item.id);
                    }}
                  >
                    {item.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomRight"
            arrow
            trigger={["click"]}
            loading={loading}
            style={{ marginLeft: 5 }}
            disabled={!status || (status && !status.includes("accepted"))}
          >
            <Button type="primary" icon={<ExportOutlined />}>
              Export Orders
            </Button>
          </Dropdown>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentUser: state.app.currentUser,
    filter: state.orders.filter,
    order_id: state.orders.order_id,
  }),
  { setOrderId }
)(OrdersToolbar);
