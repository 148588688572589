import React, { useState, useEffect } from "react";
import { Input, Form, Button, Row, Col } from "antd";
import UploadImage from "../../../midjourney/components/UploadImage";
import ChatPreview from "../ChatPreview";
export default function ChatProductTitle({   
  loading,
  onChangeLoading 
}) {
  
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [url, setUrl] = useState("");
  const [prompt, setPrompt] = useState("");
  const [dataRequest, setDataRequest] = useState({});
  const [promptReply, setpromptReply] = useState("");
  const onFinishChatProduct = (v) => {
    let dataRequest = {};
    dataRequest.prompt = v.prompt ? v.prompt : "";
    dataRequest.url = url ? url : "";
    dataRequest.type = "product";
    form.resetFields();
    setDataRequest(dataRequest);
  };
  useEffect(() => {
    if (promptReply) {
      form.setFieldsValue({
        prompt: promptReply,
      });
    }
  }, [promptReply]);

  useEffect(() => {
    setPrompt("");
  }, [dataRequest]);

  const handleReplyEditUser = (text) => {
    setpromptReply(text);
  };
  const onFinishFailed = () => {};

  return (
    <Row>
      <Col
        span={12}
        style={{
          position: "relative",
        }}
      >
        <Form
          disabled={loading}
          form={form}
          layout="vertical"
          onFinish={onFinishChatProduct}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="prompt"
            label="Prompt"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Image">
            <UploadImage onChange={(v) => setUrl(v)} />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={!url}
              type="primary"
              htmlType="submit"
            >
              Generation
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col
          span={12}
          style={{
            position: "relative",
          }}
        >
          <ChatPreview
            typeChat="producttt"
            loading={loading}
            dataRequest={dataRequest}
            onChangeLoading={onChangeLoading}
            onReplyEdit={handleReplyEditUser}
          />
        </Col>
    </Row>
  );
}
