import React from "react";
import gql from "../../api/gql";
import {
  Table,
  Button,
  Select,
  message,
  Popconfirm,
  Input,
  Divider,
  Popover,
  Tooltip,
  Image,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import S3Image from "../../components/S3Image";
import { connect } from "react-redux";
import ProductTypeToolbar from "../product_type/ProductTypeToolbar";
import _, { debounce } from "lodash";
import styled from "styled-components";
import {
  FilterOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ButtonUnlockUser from "../ButtonUnlockUser";
import { USER_ID_BLOCKED } from "../../config";
import { CLOUDFRONT_URL } from "../../config";
import ModalPreviewimage from "../ModalPreviewimage";
import { history } from "../../history";
import qs from "query-string";
const { Option } = Select;

const Container = styled.div`
  .product-types {
    display: flex;
    justify-content: space-between;
  }
  .product-types-filter {
    display: flex;
    width: -webkit-fill-available;
  }
  .product-type-toolbar {
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 540px) {
    .product-types-filter {
      margin-bottom: 10px;
    }
    .product-types {
      display: flex;
      flex-direction: column-reverse;
    }
    .product-type-toolbar {
      margin-bottom: 10px;
    }
  }
`;

class SupplierProductTypes extends React.Component {
  _isMounted = false;
  state = {
    productTypes: [],
    loading: true,
    filter: {
      search: null,
      categories: [],
      status: "true",
    },
    categories: [],
    suppliers: [],
    pagination: {
      results: 100,
      pageSize: 30,
      total: 0,
    },
    page: 1,
    previewImage: false,
  };

  resetFilter = () => {
    this.setState({
      filter: {
        search: null,
        categories: [],
        status: "true",
      },
    });
    history.push({
      pathname: "/admin/product-types",
      search:
        "?" +
        new URLSearchParams(
          this.clean({
            search: null,
            categories: [],
            status: "true",
          })
        ).toString(),
    });
  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        filter: {
          search: query.search,
          status: query.status ? query.status : "true",
          categories: query.categories ? [query.categories] : [],
        },
      },
      () => this.loadProductType()
    );

    this.loadCategories();
    // this.loadProductType();
    this.loadSupplier();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadProductType() {
    const { pagination, filter, page } = this.state;
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        count,
        hits{
          id
          sku
          title
          images
          state
          categories{
            id, title
          }
          attributes{
            name
          }
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        filter: {
          ...filter,
          limit: pagination.pageSize,
          offset: (page - 1) * pagination.pageSize,
          status: JSON.parse(filter.status),
        },
      })
      .then((res) => {
        this.setState({
          productTypes: res.productTypes.hits || [],
          loading: false,
          pagination: {
            ...pagination,
            total: res.productTypes.count,
            results: res.productTypes.count,
          },
        });
      });
  }
  loadCategories() {
    const query = `query categories {
      categories(filter:{status:true, limit: 9999, offset: 0}){
        hits{id title}
      }
    }
    `;
    gql
      .request(query)
      .then((res) =>
        this.setState({ categories: res.categories.hits || [], loading: false })
      )
      .catch((err) => {
        console.log("1");
        message["error"]("Something wrong!");
      });
  }

  loadSupplier() {
    const { currentUser } = this.props;
    if (currentUser.roles && currentUser.roles[0].name !== "Supplier") {
      const query = `query user{
				users(filter:{role:"Supplier"}){
					hits{
						id first_name last_name
					}
				}
			}`;
      gql
        .request(query)
        .then((res) => this.setState({ suppliers: res.users.hits || [] }))
        .catch((err) => {
          // message["error"]("Something wrong!");
          console.log("");
        });
    }
  }
  onChangeTable = async (e) => {
    await this.setState({ page: e.current });
    this.loadProductType();
  };
  handleChangeCategory = async (value) => {
    if (!_.isArray(value) && value !== null) {
      await this.setState({
        filter: {
          ...this.state.filter,
          categories: [value],
        },
      });
    } else {
      delete this.state.filter.categories;
      await this.setState({
        filter: this.state.filter,
      });
    }
    history.push({
      pathname: "/admin/product-types",
      search:
        "?" + new URLSearchParams(this.clean(this.state.filter)).toString(),
    });
    this.loadProductType();
  };

  handleChangeStatus = async (value) => {
    await this.setState({
      filter: {
        ...this.state.filter,
        status: value,
      },
    });
    history.push({
      pathname: "/admin/product-types",
      search:
        "?" + new URLSearchParams(this.clean(this.state.filter)).toString(),
    });
    this.loadProductType();
  };
  clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  handleChangeSearch = async (e) => {
    await this.setState({
      filter: { ...this.state.filter, search: e.target?.value },
    });
    this.loadProductType();
    history.push({
      pathname: "/admin/product-types",
      search:
        "?" + new URLSearchParams(this.clean(this.state.filter)).toString(),
    });
  };

  resetButton = async () => {
    await this.resetFilter();
    this.loadProductType();
  };
  deleteProductType(record) {
    const mutation = `mutation deleteProductType($id: String!){
      deleteProductType(id: $id)
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { id: record })
      .then((res) => {
        this.setState({ loading: false });
        message["success"]("Delele success");
        this.loadProductType();
      })
      .catch((err) => {
        message["error"]("Something wrong!");
        console.log("3");
      });
  }
  pauseOrResume = (id, state) => {
    const mutation = `mutation updateProductTypeState($id: Int!, $state: String!){
      updateProductTypeState(id: $id, state: $state)
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { id: id, state: state })
      .then((res) => {
        let type = "resumed";
        if (state === "active") {
          type = "paused";
        }
        message["success"](`Product Type has been ${type}`);
        this.loadProductType();
        this.setState({ loading: false });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };
  render() {
    const { currentUser } = this.props;
    const { productTypes, loading, categories, filter, previewImage } =
      this.state;
    const isAdmin = this.props.currentUser.roles.find((role) =>
      ["Administrator", "Listing"].includes(role.name)
    );
    let columns = [
      {
        title: "Title",
        width: 200,
        dataIndex: "title",
        render: (title, { images }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {images && (
              <S3Image
                size="100xauto"
                src={images[0]}
                style={{ maxWidth: 60, maxHeight: 60 }}
              />
            )}{" "} */}
            <div className="cp-image" style={{ width: 120 }}>
              <Image
                style={{
                  backgroundColor: "#f5f5f5",
                  // objectFit: `${images ? "cover" : "contain"}`,
                  objectFit: "contain",
                  height: 120,
                  width: 100,
                }}
                preview={false}
                // preview={{
                //   src: `${CLOUDFRONT_URL}/autoxauto/${mockup.image}`,
                // }}
                src={`${CLOUDFRONT_URL}/100/${images ? images[0] : null}`}
                fallback={`/no-preview.jpg`}
              />
              {images ? (
                <div
                  style={{ height: 120 }}
                  className="cp-prewview-image"
                  onClick={() => {
                    this.setState({ previewImage: [images[0]] });
                  }}
                >
                  <EyeOutlined style={{ marginRight: 5 }} />
                  Preview
                </div>
              ) : null}
            </div>
            <span>{title}</span>
          </div>
        ),
      },
      {
        title: "SKU",
        width: 100,
        key: "sku",
        dataIndex: "sku",
      },
      {
        title: "Categories",
        width: 140,
        dataIndex: "categories",
        render: (categories) => categories.map((cat) => cat.title).join(", "),
      },
      isAdmin
        ? {
            title: "Actions",
            dataIndex: "id",
            width: 250,
            align: "right",
            render: (id, record) => (
              <div>
                <Link to={`/admin/product-types/${id}/variants`}>Variants</Link>
                <Divider type="vertical" />
                <Link to={`/admin/product-types/edit/${id}`}>
                  <Tooltip title="Edit">
                    <Button size="small">
                      <EditOutlined theme="twoTone" />
                    </Button>
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                <Tooltip title="Delete">
                  {(currentUser.id == USER_ID_BLOCKED) ? 
                    <ButtonUnlockUser
                      propsButton={{
                        size: "small"
                      }}
                      onConfirm={() => this.deleteProductType(record.id)}
                      buttonChild={<DeleteOutlined style={{ color: "red" }} />}
                    />
                  : 
                  <Popconfirm
                    title="Confirm to delete this product type?"
                    onConfirm={() => this.deleteProductType(record.id)}
                    okText="Yes"
                    placement="top"
                    cancelText="No"
                  >
                    <Button size="small">
                      <DeleteOutlined style={{ color: "red" }} />
                    </Button>
                  </Popconfirm>
                  }
                </Tooltip>
                {/* <Divider type="vertical" />
                <Popconfirm
                  title={`Are you sure to ${
                    record.state === "active" ? "pause" : "resume"
                  } this product type?`}
                  onConfirm={() =>
                    this.pauseOrResume(
                      id,
                      record.state === "active" ? "paused" : "active"
                    )
                  }
                  okText="Yes"
                  placement="top"
                  cancelText="No"
                >
                  <Button
                    style={{ color: "#ffc000" }}
                    size="small"
                    icon={
                      record.state === <PlayCircleOutlined /> ? (
                        <PauseCircleOutlined />
                      ) : (
                        <StepForwardOutlined />
                      )
                    }
                  ></Button>
                </Popconfirm> */}
              </div>
            ),
          }
        : {},
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    const content = (
      <div style={{ width: 250 }}>
        <Select
          value={this.state.filter.status}
          placeholder="Filter by status"
          style={{ width: "100%", marginBottom: 15 }}
          onChange={this.handleChangeStatus}
        >
          <Option value="true">Active</Option>
          <Option value="false">Inactive</Option>
        </Select>
        <Select
          value={this.state.filter.categories}
          placeholder="Filter by category"
          style={{ width: "100%", marginBottom: 15 }}
          showSearch
          optionFilterProp="children"
          onChange={this.handleChangeCategory}
        >
          <Option value={null}>All categories</Option>
          {categories.map((category) => (
            <Option key={category.id} value={category.id}>
              {category.title}
            </Option>
          ))}
        </Select>
        <Button onClick={this.resetButton}>Reset Filter</Button>
      </div>
    );
    return (
      <Container>
        <div className="product-types">
          <div className="product-types-filter">
            <Input.Search
              value={filter.search}
              style={{
                minWidth: 250,
                height: "fit-content",
              }}
              placeholder="Search..."
              onChange={debounce(this.handleChangeSearch, 1000)}
              // onSearch={this.handleChangeSearch}
              addonBefore={
                <Popover
                  trigger="click"
                  placement="bottomLeft"
                  content={content}
                >
                  Filter <FilterOutlined />
                </Popover>
              }
            />
            {/* <Popover placement="bottomLeft" content={content} trigger="click">
                <Button>
                  Filter <Icon type="filter"></Icon>
                </Button>
              </Popover>
              <Input.Search 
                value={filter.search}
                style={{
                  minWidth: 250, 
                  width: '90%', 
                  height: 'fit-content' 
                }} 
                placeholder="Search..." 
                enterButton
                onChange={this.handleChangeSearch}
                onSearch={this.applyButton}
              /> */}
          </div>
          <div className="product-type-toolbar">
            <ProductTypeToolbar />
          </div>
        </div>
        <Table
          pagination={this.state.pagination}
          onChange={this.onChangeTable}
          dataSource={productTypes}
          loading={loading}
          columns={columns}
          rowKey={(row) => row.id}
          scroll={{ x: tableWidth }}
        />
        {previewImage && (
          <Modal
            width={800}
            title="Preview Image"
            visible={previewImage ? true : false}
            onCancel={() => this.setState({ previewImage: false })}
            footer={false}
          >
            <ModalPreviewimage keys={previewImage} />
          </Modal>
        )}
      </Container>
    );
  }
}

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(SupplierProductTypes);
