import gql from "graphql-tag";

export default gql`
  query($productTypeIDs: [String!], $productID: String!) {
    mockups: campaignMockups(
      productTypeIDs: $productTypeIDs
      productID: $productID
    ) {
      id
      title
      preview
      productID
      productType {
        id
        attributes {
          name
          slug
          options
        }
        printAreas {
          id
          width
          height
          name
        }
        product_type_variants {
          id
          product_type_id
          sku
          base_cost
          sale_cost
          retail_cost
          attributes {
            name
            slug
            option
          }
          active: is_active
        }
      }
      status
      ordering
      width
      height
      layers
      settings {
        defaultBgColor
        changeBgColorByVariantColor
        applyToVariants
      }
    }
  }
`;
