import React from "react";
import { Button, Form, Popover, Radio, Tooltip } from "antd";
import { debounce } from "lodash";
import { useEffect } from "react";
import { MdTextFields } from "react-icons/md";

const TextTransform = ({ layer, onChange }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ textTransform: layer.textTransform });
  }, [layer.textTransform]);

  const onValuesChange = (_, values) => {
    onChange({
      ...layer,
      ...values,
    });
  };

  return (
    <Popover
      forceRender={true}
      placement="bottom"
      content={
        <div style={{ width: 200 }}>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={debounce(onValuesChange, 100)}
          >
            <Form.Item label="Text transform" name={["textTransform"]}>
              <Radio.Group>
                <Radio value="" style={{ display: "block" }}>
                  Default
                </Radio>
                <Radio value="uppercase" style={{ display: "block" }}>
                  Uppercase
                </Radio>
                <Radio value="lowercase" style={{ display: "block" }}>
                  Lowercase
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      }
    >
      <Tooltip title="Text Transform">
        <Button
          type="link"
          icon={<MdTextFields className="anticon" />}
        ></Button>
      </Tooltip>
    </Popover>
  );
};

export default TextTransform;
