import React from "react";
import { connect } from "react-redux";
import { Select, message, Button, Input, Popover, Form } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import styled from "styled-components";
import { FilterOutlined } from "@ant-design/icons";
import { history } from "../../history";
import qs from "query-string";

const Container = styled.div`
  width: 100%;
`;
class ProductsFilter extends React.Component {
  constructor(props) {
    super(props);
    var state = {
      fetching: false,
      productTypes: [],
      filter: {
        search: "",
        site_ids: null,
        product_type_ids: null,
      },
      productTypeFilter: {
        status: true,
        limit: 1000,
      },
    };
    const { currentUser, currentSite } = props;
    if (currentUser.roles.map((role) => role.name).includes("Seller")) {
      state.filter.site_ids = currentSite ? [currentSite.id] : [];
    }
    this.state = state;
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState({
      filter: {
        ...this.state.filter,
        ...query,
        product_type_ids: query.product_type_ids
          ? [query.product_type_ids]
          : null,
        site_ids: query.site_ids ? [query.site_ids] : null,
      },
    });
    this.loadProductType();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentSite !== prevProps.currentSite) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            site_ids: [this.props.currentSite?.id],
          },
        },
        () => this.applyFilter()
      );
    }
  }
  // product type in filter
  loadProductType() {
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        hits{
          id 
          title
        }
      }
    }`;
    gql
      .request(query, { filter: this.state.productTypeFilter })
      .then((res) => {
        this.setState({ productTypes: res.productTypes.hits || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  applyFilter = () => {
    const { onChange } = this.props;
    if (typeof onChange === "function") {
      onChange(this.state.filter);
    }
  };
  // reset  filter
  clean(obj) {
    const { currentUser } = this.props;
    if (currentUser.roles.map((role) => role.name).includes("Seller")) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName].length === 0 ||
          propName === "site_ids"
        ) {
          delete obj[propName];
        }
      }
    } else {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName].length === 0
        ) {
          delete obj[propName];
        }
      }
    }

    return obj;
  }
  handleChange(key, value) {
    if (_.isArray(value)) {
      value = value.filter((item) => item !== null);
    }
    const { filter } = this.state;
    filter[key] = value;
    this.setState({ filter: { ...filter } });
    history.push({
      pathname: "/products",
      search: "?" + new URLSearchParams(this.clean(filter)).toString(),
    });
    this.applyFilter();
  }
  resetFilter = () => {
    const { onChange, currentUser, currentSite } = this.props;
    onChange({
      search: "",
      // site_ids: null,
      site_ids: currentUser.roles.map((role) => role.name).includes("Seller")
        ? [currentSite.id]
        : null,
      product_type_ids: null,
    });
    this.setState({
      filter: {
        search: "",
        site_ids: currentUser.roles.map((role) => role.name).includes("Seller")
          ? [currentSite.id]
          : null,
        product_type_ids: null,
      },
    });
    history.push({
      pathname: "/products",
      search:
        "?" +
        new URLSearchParams(
          this.clean({
            search: "",
            site_ids: null,
            product_type_ids: null,
          })
        ).toString(),
    });
  };
  render() {
    const { productTypes, filter } = this.state;
    const { currentUser, sites } = this.props;
    if (!currentUser) return null;
    return (
      <Container>
        <Input.Search
          style={{ marginBottom: 10 }}
          placeholder="Search..."
          value={filter.search}
          onChange={(e) => this.handleChange("search", e.target.value)}
          addonBefore={
            <Popover
              trigger="click"
              placement="bottomLeft"
              content={
                <Form>
                  {/** admin show all sitse, all product types */}
                  {!currentUser.roles
                    .map((role) => role.name)
                    .includes("Seller") && (
                    <Form.Item>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Site"
                        value={filter.site_ids ? filter.site_ids : null}
                        onChange={(v) => {
                          this.handleChange("site_ids", [v]);
                          this.applyFilter();
                        }}
                        style={{
                          width: 250,
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Select.Option value={null}>All Sites</Select.Option>
                        {sites.map((s) => (
                          <Select.Option key={s.id} value={s.id}>
                            {s.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={
                        filter.product_type_ids ? filter.product_type_ids : null
                      }
                      placeholder="Product types"
                      style={{ width: 250 }}
                      onChange={(v) =>
                        this.handleChange("product_type_ids", [v])
                      }
                    >
                      <Select.Option value={null}>
                        All Product Types
                      </Select.Option>
                      {productTypes.map((type) => (
                        <Select.Option key={type.id} value={type.id}>
                          {type.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ marginTop: 20 }}>
                    <Button
                      onClick={(v) => {
                        v.preventDefault();
                        this.resetFilter();
                      }}
                    >
                      Reset Filter
                    </Button>
                  </Form.Item>
                </Form>
              }
            >
              Filter <FilterOutlined />
            </Popover>
          }
        />
      </Container>
    );
  }
}

export default connect((state) => ({
  currentSite: state.app.currentSite,
  currentUser: state.app.currentUser,
}))(ProductsFilter);
