import gql from "graphql-tag";

export const ARTWORKS = gql`
  query artworks(
    $categoryID: String
    $search: String
    $page: Int
    $pageSize: Int
    $sortBy: String
    $order: String
    $userID: String
  ) {
    artworks(
      categoryID: $categoryID
      search: $search
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      order: $order
      userID: $userID
    ) {
      count
      hits {
        id
        title
        lock
        templates {
          title
          preview
          thumbnail
          isDefault
          layers
        }
        categories {
          id
          parentID
          title
          children {
            id
            title
          }
        }
        templateDisplayMode
        templateDisplayLabel
        sharedLayers
        width
        height
        author {
          id
          first_name
          last_name
          avatar {
            id
            key
            url
          }
        }
        createdAt
        # usedIn
      }
    }
  }
`;

export const SUBCRIPTION_IMPORT = gql`
  subscription ($token: String!) {
    artwork: artworkImportPSD(token: $token) {
      id
    }
  }
`;


export const ARTWORK_QUERY_BY_ID = gql`
  query($id: String!){
    artwork(id: $id) {
      id
      title
      categories {
        id
        title
      }
      width
      height
      templates {
        title
        isDefault
        layers
        preview
        thumbnail
      }
      lock
      sharedLayers
      templateDisplayMode
      templateDisplayLabel
    }
  }
`;
