import Avatar from "antd/lib/avatar/avatar";
import { pick } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CLOUDFRONT_URL } from "../../config";
import CreateFile from "./CreateFile";
// import MediaSelector from "./MediaSelector";
const FileField = ({
  value,
  onChange = () => {},
  onClick = () => {},
  size = 32,
  style = { width: size ? size : 32 },
  editable = true,
  accept = "*",
  loading = false,
}) => {
  const [file, setFile] = useState(value);
  const [showMediaSelector, setShowMediaSelector] = useState(false);
  useEffect(() => {
    setFile(value);
  }, [value]);
  return (
    <div style={style} onClick={onClick}>
      {editable ? (
        <CreateFile
          accept={accept}
          onChange={(file) => {
            onChange(pick(file, ["id", "key"]));
          }}
          loading={loading}
        >
          <Avatar
            onClick={() => {
              if (editable) {
                setShowMediaSelector(true);
              }
            }}
            src={file ? `${CLOUDFRONT_URL}/100/${file.key}` : null}
            size={size}
            shape={"square"}
            style={{ cursor: "pointer", backgroundColor: "#ddd" }}
            className="object-fix-contain"
          />
        </CreateFile>
      ) : (
        <Avatar
          onClick={() => {
            if (editable) {
              setShowMediaSelector(true);
            }
          }}
          src={file ? `${CLOUDFRONT_URL}/100/${file.key}` : null}
          size={size}
          shape={"square"}
          style={{ cursor: "pointer", backgroundColor: "#ddd" }}
          className="object-fix-contain"
        />
      )}

      {/* <MediaSelector
        visible={showMediaSelector}
        onChange={(files) => {
          onChange(pick(files[0], ["id", "key"]));
          setShowMediaSelector(false);
        }}
        onCancel={() => setShowMediaSelector(false)}
      /> */}
    </div>
  );
};

export default FileField;
