import React, { useState, useEffect } from "react";
import { URL_API_KLING_CREATE_DESCRIBE } from "../../../../config";
import axios from "axios";
import UploadImage from "../../midjourney/components/UploadImage";
import { Row, Col, Form, Button, message } from "antd";
import KlingPreview from "../components/KlingPreview";
export default function KlingDescribe({
  onChangeLoading,
  onChangeCancel,
  onComplete,
}) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [url, setURL] = useState("");

  const uniqueID = (length = 15) => {
    var result = "";
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  useEffect(() => {
    const queryLocal = localStorage.getItem("localStorageKlingDescribe");
    if (queryLocal) {
      setData(JSON.parse(queryLocal));
    }
  }, []);

  const handleChangeData = (v) => {
    setData(v);
    localStorage.setItem("localStorageKlingDescribe", JSON.stringify(v));
  };

  const onFinishFailed = () => {};

  const handleChangeFile = (v) => {
    setURL(v);
  };

  const onFinish = async (v) => {
    onChangeLoading(true);
    let dataRequest = {};
    dataRequest.text = url;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    onChangeCancel(source);
    try {
      const create = await axios
        .post(URL_API_KLING_CREATE_DESCRIBE, dataRequest, {
          cancelToken: source.token,
          onDownloadProgress: function (progressEvent) {
            onComplete();
          },
        })
        .then((res) => res.data)
        .catch((thrown) => {
          message.error(thrown.message);
          onChangeLoading(false);
          onComplete();
        });
      if (!create.message) {
        message.error("Please try again, the system is busy or overloaded..!");
        onChangeLoading(false);
        return;
      }
      const body = create.message;
      const newData = [
        ...data,
        {
          id: uniqueID(),
          data: [body],
        },
      ];
      setData(newData);
      localStorage.setItem(
        "localStorageKlingDescribe",
        JSON.stringify(newData)
      );
      onChangeLoading(false);
    } catch (error) {
      onChangeLoading(false);
    }
  };
  return (
    <Row>
      <Col
        span={12}
        style={{
          position: "relative",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Image">
            <UploadImage onChange={handleChangeFile} />
          </Form.Item>

          <Form.Item>
            <Button disabled={!url} type="primary" htmlType="submit">
              Generation
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col
        span={12}
        style={{
          position: "relative",
        }}
      >
        {data.length > 0 && (
          <KlingPreview
            onChangeData={handleChangeData}
            type="describe"
            data={data}
          />
        )}
      </Col>
    </Row>
  );
}
