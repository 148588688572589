import React, { Fragment } from "react";
import { Form, Button, Alert, DatePicker } from "antd";
import moment from "moment";
import gql from "../../api/gql";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
class FetchOrders extends React.Component {
  state = {
    comments: [],
    submitting: false,
    successOrders: [],
    faildOrders: [],
  };

  componentDidMount() {}
  formRef = React.createRef();
  handleSubmit = (values) => {
    const { site_id } = this.props;
    // if (values.orderIds !== "") {
    const orderIds = values.orderIds ? values.orderIds.split(",") : [];
    // if (orderIds.length > 0) {
    // let failOrders = [];
    // orderIds.forEach((id) => {
    //   if (id) {
    const mutation = `mutation fetchMultipleOrders($site_id: ID!, $order_ids: [String!],$range_time: DateTimeFetchOrder){
      fetchMultipleOrders(site_id: $site_id, order_ids: $order_ids,range_time:$range_time)
                }`;
    this.setState({ submitting: true });
    gql
      .request(mutation, {
        site_id: site_id,
        order_ids: orderIds,
        range_time: values.range_time
          ? {
              from: moment(values.range_time[0]).utcOffset(0).set({
                hour: 0,
                minute: 0,
                second: 0,
              }),
              to: moment(values.range_time[1]).utcOffset(0).set({
                hour: 23,
                minute: 59,
                second: 59,
              }),
            }
          : null,
      })
      .then((res) => {
        this.setState({ submitting: false });
        // this.props.form.resetFields();
        this.formRef.current.resetFields();
        this.setState({});
      })
      .catch((err) => {
        this.setState({ submitting: false });
        // const mes = `${id}: ${_.get(err, "[0].message")}`;
        // failOrders.push(mes);
        // this.setState({ faildOrders: failOrders });
        //console.log("this.state.faildOrders", this.state.faildOrders)
        // notification['error']({
        //   message: _.get(err, '[0].message')
        // })
      });
    //   }
    // });
    // }
    // }
  };
  render() {
    const { submitting } = this.state;
    return (
      <Fragment>
        <Form onFinish={this.handleSubmit} ref={this.formRef}>
          <Form.Item
            help="Enter list of order ids separated by comma. For example: 123456,654321,456789,987654"
            name="orderIds"
            // rules={[
            //   {
            //     initialValue: "",
            //     required: true,
            //     message: "Please input order ids",
            //   },
            // ]}
          >
            <TextArea rows={3} />
          </Form.Item>
          <p>Range Time:</p>
          <Form.Item name="range_time">
            <DatePicker.RangePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={submitting} type="primary">
              Fetch Orders
            </Button>
          </Form.Item>
        </Form>

        {this.state.faildOrders &&
          this.state.faildOrders.map((mess, i) => (
            <Alert
              message={mess}
              type="error"
              key={i}
              style={{ marginTop: 10, marginBottom: 10 }}
            ></Alert>
          ))}

        <span>
          <i>
            *After fetching orders, close the form to refresh the order list.
          </i>
        </span>
      </Fragment>
    );
  }
}

export default FetchOrders;
