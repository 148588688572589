import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { setCurrentSite } from "../../actions";

class SiteSelector extends React.Component {
  state = {
    sites: [],
  };

  componentDidMount() {
    let s = this.loadsite();
    const { setCurrentSite } = this.props;
    let currentSiteID = localStorage.getItem("currentSiteID");
    if (s) {
      // setSellerSites(s);
      if (
        currentSiteID &&
        s.find((site) => site.id.toString() === currentSiteID.toString())
      ) {
        setCurrentSite(
          s.find(
            (site) =>
              site.status && site.id.toString() === currentSiteID.toString()
          )
        );
      } else if (s.length) {
        setCurrentSite(s[0]);
      }
    }
  }
  loadsite = () => {
    const { currentUser, sites } = this.props;
    if (sites) {
      var sitesofSeller = sites.filter((s) => s.user.id === currentUser.id);
      return sitesofSeller;
    } else {
      return null;
    }
  };

  onSiteChange = (site_id) => {
    const { setCurrentSite, sites } = this.props;
    setCurrentSite(sites.find((site) => site.id === site_id));
  };

  render() {
    const { currentUser, currentSite } = this.props;
    if (!currentUser) return null;
    if (!currentUser.roles.find((role) => role.name === "Seller")) return null;
    const s = this.loadsite();
    return (
      <Select
        className="oppprint-site-select"
        style={{ minWidth: 250 }}
        onChange={this.onSiteChange}
        value={currentSite ? currentSite.id : null}
      >
        {s.map((site) => (
          <Select.Option key={site.id} value={site.id}>
            {site.title}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default connect(
  (state) => {
    return {
      currentUser: state.app.currentUser,
      currentSite: state.app.currentSite,
      sites: state.app.sellerSites || [],
    };
  },
  { setCurrentSite }
)(SiteSelector);
