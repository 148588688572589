import gql from "graphql-tag";

export const CANCEL_ORDER = gql`
  mutation($id: String!) {
    cancelOrder(id: $id)
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation($order_id: String!, $status: String!) {
    updateOrderStatus(order_id: $order_id, status: $status) {
      id
      origin_id
      site_id
    }
  }
`;

export const UPDATE_ORDER_INFO = gql`
  mutation($order_id: String!, $customer_info: CustomerInfo!) {
    updateCustomerInfo(order_id: $order_id, customer_info: $customer_info) {
      id
      origin_id
      site_id
      product {
        id
        title
      }
      variant {
        id
        product_id
        product_type_id
        sku
        image
        product {
          id
          title
        }
      }
    }
  }
`;

export const FETCH_ORDER = gql`
  mutation($storeId: String!, $orderId: String!) {
    fetchOrder(storeId: $storeId, orderId: $orderId)
  }
`;

export const ADD_TRACKING = gql`
  mutation($orderId: ID!, $data: [TrackingInput!]) {
    addTracking(orderId: $orderId, data: $data)
  }
`;

export const UPLOAD_DESIGN = gql`
  mutation($orderId: String!, $input: [OrderDesignInput!]!) {
    uploadDesigns(orderId: $orderId, input: $input)
  }
`;

export const ACCEPT_DESIGN = gql`
  mutation($orderId: String!, $accept: Boolean!) {
    acceptDesign(orderId: $orderId, accept: $accept)
  }
`;
export const UPDATE_TRACKING_STATUS = gql`
  mutation($id: ID!, $status: String!) {
    updateTrackingStatus(id: $id, status: $status)
  }
`;
export const SAVE_SCREEN_OPTION = gql`
  mutation($options: [String]) {
    saveScreenOption(options: $options)
  }
`;
export const EXPORT_ORDERS = gql`
  mutation exportOrders($input: ExportOrderInput!) {
    exportOrders(input: $input)
  }
`;
export const RE_TRACKING = gql`
  mutation reTracking($trackingCode: String!) {
    reTracking(trackingCode: $trackingCode)
  }
`;
export const RE_SUBMIT = gql`
  mutation reSubmitOrder($originID: String!) {
    reSubmitOrder(originID: $originID)
  }
`;
export const REGENDER_PRINTFILE = gql`
  mutation reGeneratePrintFile($orderId: String!, $designID: String) {
    reGeneratePrintFile(orderId: $orderId, designID: $designID)
  }
`;

export const REGENDER_SUBSCRIPTION = gql`
  subscription generateOrderPrintFile {
    generateOrderPrintFile {
      id
      origin_id
      price
      base_cost
      quantity
      created_at
      status
      design_status
      name
      customer {
        first_name
        last_name
        email
        phone
      }
      site {
        title
        user {
          first_name
          last_name
        }
      }
      billing {
        id
        address1
        address2
        city
        state
        state_code
        zip
        country
      }
      shipping {
        id
        address1
        address2
        city
        state
        state_code
        zip
        country
        country_code
      }
      designs {
        order_id
        base_print_area_id
        artwork_id
        base_print_area_name
        file_id
        file {
          id
          source
          key
          file_name
          file_mime
          file_size
          url
          sku
        }
        status
      }
      product {
        id
        title
        images
        is_custom_design
        mockups {
          id
          variantIds
          image
        }
      }
      variant {
        id
        product_type_id
        design_status
        mockup {
          image
        }
        product_type {
          state
          print_files {
            key
            mockup
            title
            width
            height
            note
            variant_ids
          }
        }
        product_type_variant {
          id
          attributes {
            slug
            option
          }
        }
      }
    }
  }
`;
