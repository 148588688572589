import gql from "graphql-tag";
import { useEffect, useState } from "react";
import {
  cloneDeep,
  each,
  groupBy,
  keyBy,
  omit,
  sortBy,
  sumBy,
  unionBy,
} from "lodash";
import { CLIPART_CATEGORIES_QUERY } from "../graphql/queries/clipart/clipartCategories";
import { useQuery } from "@apollo/client";
const SUBSCRIPTION = gql`
  subscription {
    clipartCategory {
      action
      category {
        id
        key: id
        parentID
        title
        numberOfCliparts
        hasChild
      }
    }
  }
`;

const updateNumberOfCliparts = (node, nodes) => {
  if (node._) {
    return;
  }
  // node.hasClipart = node.numberOfCliparts > 0;
  var children = nodes.filter((n) => n.parentID === node.id);
  if (children.length) {
    children.forEach((n) => updateNumberOfCliparts(n, nodes));
    node.numberOfCliparts += sumBy(children, (c) => c.numberOfCliparts);
    node._ = true;
  }
};

export const makeTree = (data) => {
  var groupedByParents = groupBy(data, "parentID");
  var catsById = keyBy(data, "id");
  each(omit(groupedByParents, "null"), (children, parentID) => {
    if (catsById[parentID]) {
      catsById[parentID].children = children;
    }
  });
  return groupedByParents["null"] || [];
};
export const getParentIDs = (node, nodes) => {
  var parents = [];
  if (node.parentID !== null) {
    parents.push(node.parentID);
    var parent = nodes.find((n) => n.id === node.parentID);
    parents.push(...getParentIDs(parent, nodes));
  }
  return parents;
};
export const getChildrenIDs = (node, nodes) => {
  var child = [];
  if (Array.isArray(node.children)) {
    node.children.forEach((n) => {
      child.push(n.id, ...getChildrenIDs(n, nodes));
    });
  }
  return child;
};
export const deleteSameId = (data) => {
  const unique = [
    ...new Map(
      data?.map((item) =>
        item.parentID === null
          ? [item.category["id"], item]
          : [item.category["parentID"], item]
      )
    ).values(),
  ];
  return unique;
  // var repeats = [],
  //   item,
  //   i = 0;

  // while (i < newCate?.length) {
  //   repeats.indexOf((item = newCate[i++].name)) > -1
  //     ? newCate.pop(i--)
  //     : repeats.push(item);
  // }
  // return newCate;
};

const useClipartCategory = (props) => {
  const { search = "", currentUser } = props;
  const isSeller = currentUser?.roles.find((role) => role.name === "Seller");
  const [numberOfCliparts, setNumberOfCliparts] = useState(0);
  const [categories, setCategories] = useState([]);
  const [tree, setTree] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    CLIPART_CATEGORIES_QUERY,
    { variables: { userID: isSeller ? currentUser.id : null } }
  );
  // console.log(data);
  useEffect(() => {
    if (data) {
      var cats = cloneDeep(unionBy(data.categories.hits, "id"));
      cats.forEach((cat) => updateNumberOfCliparts(cat, cats));
      setNumberOfCliparts(data.categories.numberOfCliparts);
      setCategories(cats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // console.log(categories);
  useEffect(() => {
    if (subscribeToMore) {
      try {
        subscribeToMore({
          document: SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            const { action, category } = subscriptionData.data.clipartCategory;
            if (action === "create") {
              setExpandedKeys(category.id);
              return Object.assign({}, prev, {
                categories: {
                  ...prev.categories,
                  hits: sortBy(
                    [category, ...prev.categories.hits, ...[]],
                    "title"
                  ),
                },
              });
            }
            if (action === "delete") {
              return Object.assign({}, prev, {
                categories: {
                  ...prev.categories,
                  hits: prev.categories.hits.filter(
                    (cat) => cat.id !== category.id
                  ),
                },
              });
            }
            return prev;
          },
        });
      } catch (err) {
        //keep slient
      }
    }
  }, [subscribeToMore]);
  useEffect(() => {
    if (search) {
      var visibleIds = [];
      categories.forEach((cat) => {
        if (cat.title.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          visibleIds.push(cat.id, ...getParentIDs(cat, categories));
        }
      });
      var cats = categories.filter((cat) => visibleIds.includes(cat.id));
      setExpandedKeys(cats.filter((cat) => cat.parentID).map((cat) => cat.id));
    }
    setTree(makeTree(categories));
  }, [categories, search]);
  return {
    tree,
    categories,
    numberOfCliparts,
    expandedKeys,
    loading,
    error,
    refetch,
  };
};

export default useClipartCategory;
