import React, { Component } from "react";
import { connect } from "react-redux";
import Products from "../../components/Products";
import ProductsFilter from "../../components/products/ProductsFilter";
import { Row, Col, message } from "antd";
import ProductToolbar from "../../components/products/ProductToolbar";
import styled from "styled-components";
import gql from "../../api/gql";
import _ from "lodash";
import ProductsList from "../../components/ProductsList";
import qs from "query-string";

const Container = styled.div`
  @media only screen and (min-width: 768px) {
    .btn-add {
      text-align: right;
    }
  }
  @media only screen and (max-width: 768px) {
    .p-filter {
      display: flex;
      flex-direction: column-reverse;
      .btn-add {
        text-align: right;
      }
    }
  }
`;
class ProductsPage extends Component {
  constructor(props) {
    super(props);
    var state = {
      fetching: false,
      sites: [],
      productTypes: [],
      filter: {
        search: "",
        site_ids: null,
        product_type_ids: null,
        limit: 20,
        offset: 0,
      },
    };
    const { currentUser, currentSite } = props;
    if (currentUser.roles.map((role) => role.name).includes("Seller")) {
      state.filter.site_ids = currentSite ? [currentSite.id] : [];
    }
    this.state = state;
  }
  componentDidMount() {
    const { currentUser } = this.props;
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState({
      filter: {
        ...this.state.filter,
        ...query,
        product_type_ids: query.product_type_ids
          ? [query.product_type_ids]
          : null,
        site_ids: currentUser.roles.map((role) => role.name).includes("Seller")
          ? this.state.filter.site_ids
          : query.site_ids
          ? [query.site_ids]
          : null,
      },
    });

    this.fetchSites();
  }
  fetchSites() {
    const query = `query sites{sites{hits{id,title,user{id},domain}}}`;
    gql
      .request(query)
      .then((res) => {
        const { currentUser } = this.props;
        const seller = currentUser.roles.find((role) => role.name === "Seller");
        if (seller) {
          var siteslist = res.sites.hits;
          var sitesSeller = siteslist.filter((ele) => {
            return ele.user.id === this.props.currentUser.id;
          });
          if (sitesSeller) {
            this.setState({
              sites: sitesSeller || [],
            });
          }
        } else {
          this.setState({ sites: res.sites.hits || [] });
        }
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }
  render() {
    const { currentSite, currentUser } = this.props;
    const allowProductAction =
      currentUser.roles.map((role) => role.name).includes("Administrator") ||
      (currentSite && currentSite.type !== "offline");
    return (
      <Container>
        {currentUser.roles
          .map((role) => role.name)
          .includes("Administrator") ? (
          <Row type="flex" style={{ width: "calc(100%)" }}>
            <ProductsFilter
              onChange={(filter) => this.setState({ filter })}
              {...this.props}
              sites={this.state.sites}
            />
          </Row>
        ) : (
          <Row style={{ width: "calc(100%)" }} className="p-filter">
            <Col md={20} style={{ display: "flex", alignItems: "center" }}>
              <ProductsFilter
                onChange={(filter) => this.setState({ filter })}
                {...this.props}
                sites={this.state.sites}
              />
            </Col>
            <Col md={4} style={{ textAlign: "left", marginBottom: 10 }}>
              <div className="btn-add">
                {allowProductAction && <ProductToolbar />}
              </div>
            </Col>
          </Row>
        )}
        {/* <Products
          filter={this.state.filter}
          {...this.props}
          sites={this.state.sites}
        /> */}
        <ProductsList
          filter={this.state.filter}
          {...this.props}
          onChange={(filter) => this.setState({ filter })}
          sites={this.state.sites}
        />
      </Container>
    );
  }
}

export default connect((state) => ({
  currentSite: state.app.currentSite,
  // sites: state.app.sellerSites || [],
}))(ProductsPage);
