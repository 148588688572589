import gql from "graphql-tag";

export const CREATE_CLIPART_CATEGORY = gql`
  mutation($parentID: String, $title: String!) {
    category: createClipartCategory(parentID: $parentID, title: $title) {
      id
      key: id
      parentID
      title
      numberOfCliparts
    }
  }
`;
export const UPDATE_CLIPART_CATEGORY = gql`
  mutation($parentID: String, $title: String!, $id: String!) {
    category: updateClipartCategory(
      id: $id
      parentID: $parentID
      title: $title
    ) {
      id
      key: id
      parentID
      title
      numberOfCliparts
    }
  }
`;
export const DELETE_CLIPART_CATEGORY = gql`
  mutation deleteClipartCategory($id: String!) {
    deleteClipartCategory(id: $id)
  }
`;
