import React, { useState, useEffect, useRef } from "react";
import { Button, message, Popconfirm, Space } from "antd";
import axios from "axios";
import {
  API_KEY_OPENAI,
  API_CHAT_CREATE_CONTENT_PRODUCT,
} from "../../../../config";
import { SSE } from "../../../../sse";
import { get } from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import MarkdownPreviewer from "./MarkdownPreviewer";
import UserMessage from "./MessageItem/UserMessage";
import AssistantMessage from "./MessageItem/AssistantMessage";
export default function ChatPreview({
  onChangeLoading,
  dataRequest,
  onReplyEdit,
  model,
  loading,
  typeChat
}) {
  const list = useRef();
  const [messages, setMessages] = useState([]);
  const [textRender, setTextRender] = useState("");

  const getMessage = (v) => {
    let newArray = [...v];
    newArray = newArray.reverse();
    return newArray;
  };

  useEffect(() => {
    const getLocalMessages = localStorage.getItem(typeChat == "chatqa" ? "localStorageMessagesGpt" : "localStorageMessagesProducttt");
    if (getLocalMessages) {
      setMessages(JSON.parse(getLocalMessages));
    }
    setTimeout(() => {
      toBottom();
    }, 1000);
  }, []);

  const handleChatGPT = (payloadMessages) => {
    toBottom();
    onChangeLoading(true);
    const payload = {
      top_p: 1,
      temperature: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stream: true,
      model,
    };
    payload.messages = getMessage(payloadMessages);

    var source = new SSE(`https://api.openai.com/v1/chat/completions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + API_KEY_OPENAI,
      },
      method: "POST",
      payload: JSON.stringify(payload),
    });

    window.sourceChatGPT = source;

    source.addEventListener("error", function (e) {
      const dataError = JSON.parse(e.data);
      message.error(dataError.error.message);
      onChangeLoading(false);
    });

    var text = "";
    source.addEventListener("message", function (e) {
      if (e.data == "[DONE]") {
        payloadMessages = [
          {
            role: "assistant",
            content: text,
          },
          ...payloadMessages,
        ];
        setMessages(payloadMessages);
        localStorage.setItem(
          typeChat == "chatqa" ? "localStorageMessagesGpt" : "localStorageMessagesProducttt",
          JSON.stringify(payloadMessages)
        );
        setTextRender("");
        toBottom();
        onChangeLoading(false);
      } else {
        const payloads = handleSplitPayloads(e.data);
        payloads.forEach((payload) => {
          const key = get(payload.choices[0], "delta.content");
          if (key) {
            text += key;
            setTextRender(text);
            toBottom();
          }
        });
      }
    });
    source.stream();
  };

  const handleChatProduct = (payloadMessages, newMessages) => {
    const { prompt, url, type } = payloadMessages;
    toBottom();
    onChangeLoading(true);
    axios
      .post(API_CHAT_CREATE_CONTENT_PRODUCT, {
        text: prompt,
        url,
      })
      .then((res) => {
        const text = res.data.message;
        newMessages = [
          {
            role: "assistant",
            content: text,
            type,
          },
          ...newMessages,
        ];
        setMessages(newMessages);
        localStorage.setItem(
          typeChat == "chatqa" ? "localStorageMessagesGpt" : "localStorageMessagesProducttt",
          JSON.stringify(newMessages)
        );
        setTextRender("");
        toBottom();
        onChangeLoading(false);
      })
      .catch(() => {
        onChangeLoading(false);
      });
  };

  useEffect(() => {
    setTextRender("");
    const { prompt, type, url } = dataRequest;

    switch (type) {
      case "chatgpt":
        if (model && prompt) {
          var newMessages = [
            {
              role: "user",
              content: prompt,
            },
            ...messages,
          ];
          setMessages(newMessages);
          handleChatGPT(newMessages);
        }       
        break;
      case "product":
        if (url && prompt) {
          var newMessages = [
            {
              role: "user",
              content: prompt,
            },
            ...messages,
          ];
          setMessages(newMessages);
          handleChatProduct(dataRequest, newMessages);
        }
        break;
      default:
        break;
    }

    if (model && prompt) {
      
      
    }
  }, [dataRequest]);

  const handleSplitPayloads = (string) => {
    const arraySplit = string.split('{"id"');
    return arraySplit
      .filter((e) => e)
      .map((item) => JSON.parse(`{"id"${item}`));
  };

  const toBottom = () => {
    const container = list.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const handleStop = () => {
    const sourceChatGPT = window.sourceChatGPT;
    if (sourceChatGPT) {
      sourceChatGPT.close();
      onChangeLoading(false);
      var newMessages = [
        {
          role: "assistant",
          content: textRender,
        },
        ...messages,
      ];
      setMessages(newMessages);
      localStorage.setItem(
        typeChat == "chatqa" ? "localStorageMessagesGpt" : "localStorageMessagesProducttt",
        JSON.stringify(newMessages)
      );
      setTextRender("");
    }
  };

  const handleDeleteConversations = () => {
    setMessages([]);
    localStorage.setItem(typeChat == "chatqa" ? "localStorageMessagesGpt" : "localStorageMessagesProducttt", JSON.stringify([]));
  };

  const handleRepeat = (index) => {
    const newMessage = messages.filter((e, i) => i > index);
    setMessages(newMessage);
    handleChatGPT(newMessage);
  };

  return (
    <>
      {messages.length > 0 && (
        <div className="group-preview-chatgpt">
          <div className="group-preview-chatgpt__control">
            <Space>
              <Popconfirm
                title="Are you sure delete?"
                onConfirm={handleDeleteConversations}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="dashed"
                  className="btn-delete"
                  icon={<DeleteOutlined />}
                >
                  Delete all conversations
                </Button>
              </Popconfirm>
              {(loading && typeChat == 'chatqa') && (
                <Button onClick={handleStop}>
                  <i
                    style={{
                      marginRight: 5,
                    }}
                    className="fa-solid fa-stop"
                  ></i>{" "}
                  Stop
                </Button>
              )}
            </Space>
          </div>
          <ul className="message-group" ref={list}>
            {textRender && (
              <li className="message-item-assistant">
                <div className="message-item-content">
                  <MarkdownPreviewer>{textRender}</MarkdownPreviewer>
                </div>
              </li>
            )}
            {messages.map((messageItem, index) => (
              <li className={`message-item-${messageItem.role}`} key={index}>
                {messageItem.role == "user" && (
                  <UserMessage onReplyEdit={onReplyEdit}>
                    {messageItem.content}
                  </UserMessage>
                )}
                {messageItem.role == "assistant" && (
                  <AssistantMessage
                    dataMessage={messageItem}
                    onRepeat={() => handleRepeat(index)}
                  >
                    {messageItem.content}
                  </AssistantMessage>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
