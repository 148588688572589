import { PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, TreeSelect } from "antd";
import React, { useState } from "react";
import { ARTWORK_CATEGORIES } from "../../graphql/queries/artworkCategory";
import ArtworkCategoryForm from "./ArtworkCategoryForm";
import { connect } from "react-redux";
const ArtworkCategoriesField = (props) => {
  const {
    value,
    multiple = true,
    onChange = () => {},
    addNewable = true,
    currentUser,
  } = props;
  const [showAddNew, setShowAddNew] = useState(false);
  const isSeller = currentUser?.roles?.find((role) => role.name === "Seller");
  const { data, refetch } = useQuery(ARTWORK_CATEGORIES, {
    variables: {
      userID: isSeller ? currentUser.id : null,
    },
  });
  // console.log(data);
  const renderTree = (cats) => {
    return cats.map((cat) => (
      <TreeSelect.TreeNode key={cat.id} value={cat.id} title={cat.title}>
        {cat.children && renderTree(cat.children)}
      </TreeSelect.TreeNode>
    ));
  };
  return (
    <div>
      <TreeSelect
        value={value}
        showSearch={true}
        onChange={onChange}
        style={{ width: "100%" }}
        multiple={multiple}
        treeNodeFilterProp="title"
        treeDefaultExpandAll={true}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {addNewable && (
              <div style={{ textAlign: "right", margin: 10 }}>
                <Button
                  className="ant-btn ant-btn-primary"
                  icon={<PlusOutlined />}
                  onClick={() => setShowAddNew(true)}
                >
                  New Category
                </Button>
              </div>
            )}
          </div>
        )}
      >
        {renderTree(data?.categories ? data.categories : [])}
      </TreeSelect>
      {addNewable && (
        <div style={{ textAlign: "right" }}>
          {/* <Button
            type="link"
            icon={<PlusOutlined />}
            style={{ paddingRight: 0 }}
            onClick={() => setShowAddNew(true)}
          >
            New Category
          </Button> */}
          <ArtworkCategoryForm
            currentUser={currentUser}
            visible={showAddNew}
            onCancel={() => setShowAddNew(false)}
            onFinish={(cat) => {
              if (multiple) {
                if (Array.isArray(value)) {
                  onChange([...value, cat.id]);
                } else {
                  onChange([cat.id]);
                }
              } else {
                onChange(cat.id);
              }
              setShowAddNew(false);
              refetch();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(ArtworkCategoriesField);
