import gql from "graphql-tag";

export const CLIPARTS = gql`
  query cliparts(
    $categoryID: [String]
    $search: String
    $page: Int
    $pageSize: Int
    $userID: String
  ) {
    cliparts(
      categoryID: $categoryID
      search: $search
      page: $page
      pageSize: $pageSize
      userID: $userID
    ) {
      count
      hits {
        id
        title
        author {
          id
          first_name
          last_name
          is_subscription
          email
          roles {
            id
            name
          }
        }
        file {
          id
          key
          sku
          url
        }
        thumbnail {
          id
          source
          key
          file_name
          file_mime
          file_size
          url
          sku
        }
        category {
          id
          title
          parentID
          hasChild
          children {
            id
            title
          }
        }
        createdAt
      }
    }
  }
`;
export const CLIPARTS_BY_CATEGORY = gql`
  query clipartsByCategory($categoryID: String!) {
    clipartsByCategory(categoryID: $categoryID) {
      id
      title
      author {
        id
        first_name
        last_name
        is_subscription
        email
        roles {
          id
          name
        }
      }
      file {
        id
        key
        sku
        url
      }
      category {
        id
        title
        parentID
        hasChild
        children {
          id
          title
        }
      }
      createdAt
    }
  }
`;
