import React, {useState, useEffect} from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import ImageFilter from "./Utilities/ImageFilter";
import LayerAdvance from "./Utilities/LayerAdvance";
import LayerPosition from "./Utilities/LayerPosition";
import ColorField from "./Utilities/ColorField";
import FontSize from "./Utilities/FontSize";
import FontSelector from "../Font/FontSelector";
import {ARTWORK} from "../../types";
import {useDispatch} from "react-redux";
import {API_URL} from "../../config";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;
const ImageToolbar = ({ layer }) => {
    const dispatch = useDispatch();
const handleFontChange = (fontFamily) => {
    const token = localStorage.getItem("_token");
    const fontIdd = (fontFamily || "").split("-");
    var id = fontIdd[0]
    var query = `query($id: String!) {
    font(id: $id) {
      id
      family
      variants {
        variant
        file {
          url
        }
      }
    }
  }`;

   fetch(`${API_URL}/query`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            query,
            variables: { id },
        })
    })
        .then(r => r.json())
        .then(data =>{
            onChange({...layer, fontFamily, fontFamilyUrl: data.data.font.variants[0]['file']['url']})
        });
};
    const onChange = (update) => {
        dispatch({
            type: ARTWORK.SET_LAYER,
            payload: { ...layer, ...update },
        });
    };


  return (
    <Container>
      <ImageFilter layer={layer} />
      <LayerPosition layer={layer} />
      <LayerAdvance layer={layer} />
      {(layer.imageLyric) && !layer.imageSpiralLyric ? <FontSelector fontFamily={layer.fontFamily} onSelect={handleFontChange} /> : null }
      {(layer.imageLyric) && !layer.imageSpiralLyric ? <ColorField layer={layer} value={layer.colorTextLyrics} onChange={(color) => onChange({ colorTextLyrics: color })} /> : null }

    </Container>
  );
};

export default ImageToolbar;
