import React from "react";
import styled from "styled-components";
import { Tabs, message } from "antd";
import AccountSettingsForm from "./AccountSettingsForm";
import { history } from "../../history";
import queryString from "query-string";
import gql from "../../api/gql";
import * as _ from "lodash";
import MailSettingsForm from "./MailSettingsForm";

const Container = styled.div`
  .ant-tabs {
    .ant-tabs-left-bar {
      width: 150px;
    }
  }
  .btn-add {
    position: absolute;
    z-index: 1;
    right: 0;
  }
`;
class Setting extends React.Component {
  state = {
    loading: false,
  };
  onChangeTab = (key) => {
    history.push(`/admin/settings?key=${key}`);
  };
  onSubmit = (values) => {
    const input = Object.keys(values).map((el) => {
      let item = {
        name: el,
        value: values[el] || "",
      };
      return item;
    });
    const mutation = `mutation saveAppSettings($input: [AppSetting!]!){
      saveAppSettings(input: $input){
        name
        value
      }
    }`;
    gql
      .request(mutation, { input: input })
      .then((res) => {
        message["success"]("Your settings saved");
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };
  render() {
    const { loading } = this.state;
    const key =
      Object.keys(queryString.parse(history.location.search)).length === 0
        ? "1"
        : queryString.parse(history.location.search).key;
    return (
      <Container>
        <Tabs onChange={this.onChangeTab} defaultActiveKey={key}>
          <Tabs.TabPane tab="Account Settings" key="account">
            <AccountSettingsForm
              loading={loading}
              onSubmit={(values) => this.onSubmit(values)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Mail Templates" key="emails">
            <MailSettingsForm onSubmit={(values) => this.onSubmit(values)} />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Notification" key="notification">
            <NotificationSettings
              onSubmit={(values) => this.onSubmit(values)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Payment" key="peyment">
            <PaymentSettingsForm onSubmit={(values) => this.onSubmit(values)} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Message" key="message">
            <MessageSettingsForm onSubmit={(values) => this.onSubmit(values)} />
          </Tabs.TabPane> */}
        </Tabs>
      </Container>
    );
  }
}

export default Setting;
