import gql from "graphql-tag";
export const UPDATEORDERCUSTOMIZE = gql`
  mutation updateOrderCustomize($id: String!, $customize: Map) {
    updateOrderCustomize(id: $id, customize: $customize) {
      id
      origin_id
      site_id
      product {
        title
        sku
        images
        mockups {
          id
          mockupId
          productId
          variantIds
          image
          sorting
        }
      }
      variant {
        mockup {
          image
        }
        product_type_variant {
          attributes {
            name
            slug
            option
          }
        }
      }
    }
  }
`;
