import React from "react";
import { Button, Popconfirm, message } from "antd";
import gql from "../../../../../api/gql";
import _ from "lodash";
const DeleteCategory = ({id, onChange}) => {
    const deleteCategory = () => {
        const mutation = `mutation{
            deleteBannerCategory(id: "${id}")
        }`;
        gql.request(mutation)
        .then(res => {
            onChange();
        })
        .catch((error) => {
            message.error('Something went wrong, please try again!');
        });
    }
    return (
        <>
        <Popconfirm placement="topRight" title="Are you sure to delete?" onConfirm={deleteCategory} okText="Yes" cancelText="No">
            <Button style={{color:"red"}}  type="dashed">
                Delete
            </Button>
        </Popconfirm>
        </>
    )
}
export default DeleteCategory;