import React, { Component } from "react";
import { Tabs, Form, Checkbox, Button, message, Card, Skeleton } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
// import BrandingSettings from "../../components/sites/BrandingSettings";

// const settings = [
//   {
//     key: "order_received",
//     title: "Order received"
//   },
//   {
//     key: "order_start_producing",
//     title: "Order start producing"
//   },
//   {
//     key: "order_shipped",
//     title: "Order shipped"
//   }
// ]

class SiteSettings extends Component {
  state = {
    loading: false,
    loadingget: false,
    siteId: 0,
    menukey: "1",
    settings: {
      branding_packing_slip_front: "0",
      branding_packing_slip_back: "0",
      branding_insert_card_outside: "0",
      branding_insert_card_inside: "0",
      branding_sticker_front: "0",
      order_received_enable: false,
      order_received_after: 1,
      order_received_message: "",
      order_producing_enable: false,
      order_producing_message: "",
      order_shipping_enable: false,
      order_shipping_message: "",
      fulfill_role: false,
      auto_accept_design: false,
    },
  };

  componentDidMount() {
    this.setState({ loadingget: true });
    this.loadSiteSettings();
  }

  loadSiteSettings() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ site_id: id });
    let query = `query getSiteSettings($site_id: String!){
      getSiteSettings(site_id: $site_id){
        auto_accept_design
      }
    }`;

    gql
      .request(query, { site_id: id })
      .then((res) => {
        this.setState({
          settings: res.getSiteSettings,
          loadingget: false,
        });
      })
      .catch((err) => {
        this.setState({ loadingget: false });
      });
  }

  handleClickMenu = (e) => {
    this.setState({ menukey: e.key });
  };

  onChangeChecked = (key, checked) => {
    const { settings } = this.state;
    settings[key] = checked.target.checked;
    this.setState({ settings: { ...settings } });
  };

  onSubmit = (values) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { settings } = this.state;
    const mutation = `mutation updateSiteSettings($site_id: String!, $settings: SiteSettingsInput!){
          updateSiteSettings(site_id: $site_id, settings: $settings){
            auto_accept_design
          }
        }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        site_id: id,
        settings: {
          ...values,
          // branding_packing_slip_front: settings.branding_packing_slip_front,
          // branding_packing_slip_back: settings.branding_packing_slip_back,
          // branding_insert_card_outside: settings.branding_insert_card_outside,
          // branding_insert_card_inside: settings.branding_insert_card_inside,
          // branding_sticker_front: settings.branding_sticker_front,
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        message["success"]("Site settings has been updated");
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  };

  render() {
    const { settings, site_id, loadingget } = this.state;
    if (loadingget) {
      return <Skeleton />;
    }
    return (
      <Form onFinish={this.onSubmit}>
        <Tabs defaultActiveKey="fulfill">
          {/* <Tabs.TabPane tab="Email notification" key="email">
            <Tabs tabPosition="left">
              <Tabs.TabPane tab="Order received" key="order_received">
                <Card title="Order received settings">
                  <Form.Item
                    name="order_received_enable"
                    rules={[
                      {
                        valuePropName: "checked",
                        initialValue: settings.order_received_enable,
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(ck) =>
                        this.onChangeChecked("order_received_enable", ck)
                      }
                    >
                      Enable
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label="After"
                    name="order_received_after"
                    rules={[
                      {
                        initialValue: settings.order_received_after,
                      },
                    ]}
                  >
                    <Select disabled={!settings.order_received_enable}>
                      <Select.Option value={1}>1 day</Select.Option>
                      <Select.Option value={2}>2 day</Select.Option>
                      <Select.Option value={3}>3 day</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Message"
                    name="order_received_message"
                    rules={[
                      {
                        initialValue: settings.order_received_message,
                      },
                    ]}
                  >
                    <TextArea disabled={!settings.order_received_enable} />
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Order start producing"
                key="order_start_producing"
              >
                <Card title="Order start producing settings">
                  <Form.Item
                    name="order_producing_enable"
                    rules={[
                      {
                        valuePropName: "checked",
                        initialValue: settings.order_producing_enable,
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(ck) =>
                        this.onChangeChecked("order_producing_enable", ck)
                      }
                    >
                      Enable
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label="Message"
                    name="order_producing_message"
                    rules={[
                      {
                        initialValue: settings.order_producing_message,
                      },
                    ]}
                  >
                    <TextArea disabled={!settings.order_producing_enable} />
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Order shipped" key="order_shipped">
                <Card title="Order shipped settings">
                  <Form.Item
                    name="order_shipping_enable"
                    rules={[
                      {
                        valuePropName: "checked",
                        initialValue: settings.order_shipping_enable,
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(ck) =>
                        this.onChangeChecked("order_shipping_enable", ck)
                      }
                    >
                      Enable
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label="Message"
                    name="order_shipping_message"
                    rules={[
                      {
                        initialValue: settings.order_shipping_message,
                      },
                    ]}
                  >
                    <TextArea disabled={!settings.order_shipping_enable} />
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
            </Tabs>
            <Form.Item style={{ textAlign: "right", marginTop: 20 }}>
              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="Fulfill role" key="fulfill">
            <Card title="Fulfill role settings">
              <Form.Item
                name="fulfill_role"
                initialValue={settings.fulfill_role}
                valuePropName="checked"
              >
                <Checkbox>Remove hold time</Checkbox>
              </Form.Item>
              <div>
                By default, your orders are held for 24 hours after successful
                payment in order to accomodate changes. By checking this box,
                your orders will ship one day sooner, but this will forfeit the
                ability to change orders.
              </div>
            </Card>
            <Form.Item>
              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                style={{ marginTop: 10 }}
              >
                Save
              </Button>
            </Form.Item>
          </Tabs.TabPane> */}
          <Tabs.TabPane tab="Auto Accept Design" key="autoAccept">
            <Card>
              <Form.Item
                name="auto_accept_design"
                initialValue={settings.auto_accept_design}
                valuePropName="checked"
              >
                <Checkbox>Auto Accept Design</Checkbox>
              </Form.Item>
              <div>
                By default, the design files will need to be reviewed and
                accepted by seller. If this option selected, they will be
                accepted automatically.
              </div>
            </Card>
            <Form.Item>
              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                style={{ marginTop: 10 }}
              >
                Save
              </Button>
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    );
  }
}

export default SiteSettings;
