import React from "react";
import { InputNumber } from 'antd';

const FontSizeChooseLyric = ({value, onChange}) => {
    const onChangeFont = (value) => {
        onChange(value);
    }
    return (
        <div>
            <InputNumber min={1} defaultValue={value} onChange={onChangeFont} />
        </div>
    )
}

export default FontSizeChooseLyric;