import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Image, message, Progress } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import { UPLOAD_PRODUCT_MOCKUP } from "../../graphql/mutation/product/uploadProductMockup";
import Grid from "../Grid";
import arrayMove from "array-move";
// import UploadFileMockup from "../mockup/UploadFileMockup";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import ModalPreviewimage from "../ModalPreviewimage";
import UploadImage from "./UploadImage";
import { UPLOADmOCKUPOEDERING } from "../../graphql/mutation/mokups/updoadMockupOrdering";
import { get } from "lodash";
const Container = styled.div`
  .ant-image {
    width: 100%;
  }
`;
const SortableItem = SortableElement(({ image, setPreviewImage }) => (
  <div className="cp-image">
    <Image
      style={{
        backgroundColor: "#f5f5f5",
        // objectFit: `${mockups ? "cover" : "contain"}`,
        objectFit: "contain",
        height: 200,
        // width: "100%",
      }}
      preview={false}
      // preview={{
      //   src: `${CLOUDFRONT_URL}/autoxauto/${mockup.image}`,
      // }}
      src={`${CLOUDFRONT_URL}/200/${get(image.image, 'key') ? get(image.image, 'key') : image.image}`}
      fallback={`/no-preview.jpg`}
    />
    {image.image ? (
      <div
        style={{ height: 200, width: "100%" }}
        className="cp-prewview-image"
        onClick={() => {
          setPreviewImage([image.image.key || image.image ]);
        }}
      >
        <EyeOutlined style={{ marginRight: 5 }} />
        Preview
      </div>
    ) : null}
  </div>
));

const SortableList = SortableContainer(
  ({
    value,
    onChange,
    UploadproductMockup,
    id,
    fileUpload,
    setPreviewImage,
    setFileUploads,
  }) => {
    return (
      <Grid gap={20} width={200}>
        {value?.map((image, index) => (
          <SortableItem
            key={index}
            image={image}
            index={index}
            setPreviewImage={setPreviewImage}
          />
        ))}
        {fileUpload
          ? fileUpload.map((fileUpload) => (
              <div
                key={fileUpload.uid}
                style={{
                  width: "100%",
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Progress
                  percent={Math.round(fileUpload.percent)}
                  type="circle"
                  size="small"
                  width={50}
                />
              </div>
            ))
          : null}
        {/* <UploadFileMockup
          onChange={(file) => {
            UploadproductMockup({
              variables: {
                productId: id,
                fileId: file.id,
              },
            })
              .then((res) => {
                if (value) {
                  onChange([
                    ...value,
                    {
                      mockup_id: res.data.uploadProductMockups.id,
                      image: res.data.uploadProductMockups.image,
                      type: "create",
                    },
                  ]);
                } else {
                  onChange([
                    {
                      mockup_id: res.data.uploadProductMockups.id,
                      image: res.data.uploadProductMockups.image,
                      type: "create",
                    },
                  ]);
                }
              })
              .catch((err) => {
                // notification.error({ message: err.message });
              });
          }}
        >
          <div style={{ padding: 50 }}>
            <PlusOutlined />
          </div>
        </UploadFileMockup> */}
        <UploadImage
          fileUpload={fileUpload}
          setFileUploads={setFileUploads}
          onChange={(files) => {
            Promise.all(
              files.map(
                (file) =>
                  new Promise((resolve, reject) => {
                    UploadproductMockup({
                      variables: {
                        productId: id,
                        fileId: file.id,
                      },
                    })
                      .then((res) => {
                        resolve({
                          mockup_id: res.data.uploadProductMockups.id,
                          image: res.data.uploadProductMockups.image,
                          type: "create",
                        });
                      })
                      .catch((err) => reject(err));
                  })
              )
            )
              .then((files) => {
                if (value) {
                  onChange([...value, ...files]);
                } else {
                  onChange(files);
                }
                setFileUploads();
              })
              .catch((err) => {
                message.error(err.message);
              });
          }}
        >
          <div style={{ padding: 50 }}>
            <PlusOutlined />
          </div>
        </UploadImage>
      </Grid>
    );
  }
);

const ImagesProduct = ({ value, onChange, id }) => {
  const [previewImage, setPreviewImage] = useState(false);
  const [UploadproductMockup] = useMutation(UPLOAD_PRODUCT_MOCKUP);
  const [fileUpload, setFileUploads] = useState();
  const [uploadMockupOrdering] = useMutation(UPLOADmOCKUPOEDERING);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(value, oldIndex, newIndex));
    let orderingNew = arrayMove(value, oldIndex, newIndex).map(
      (item, index) => {
        return { ordering: index, mockup_id: item.mockup_id };
      }
    );

    uploadMockupOrdering({ variables: { mockups: orderingNew } });
  };
  return (
    <Container>
      <SortableList
        setPreviewImage={setPreviewImage}
        fileUpload={fileUpload}
        setFileUploads={setFileUploads}
        value={value}
        axis="xy"
        distance={1}
        lockToContainerEdges={true}
        onSortEnd={onSortEnd}
        helperClass="row-dragging"
        onChange={onChange}
        id={id}
        UploadproductMockup={UploadproductMockup}
      />

      {previewImage && (
        <Modal
          width={800}
          title="Preview Image"
          visible={previewImage ? true : false}
          onCancel={() => setPreviewImage(false)}
          footer={false}
        >
          <ModalPreviewimage keys={previewImage} />
        </Modal>
      )}
    </Container>
  );
};

export default ImagesProduct;
