import { Button, InputNumber, Popover, Slider, Radio, Form } from "antd";
import React from "react";
import { SketchPicker, TwitterPicker } from "react-color";
import { GlobalStyle } from "./ColorField";

const TextStroke = ({ layer, onChange, view = "twitterPicker" }) => {
  return (
      <Popover
          placement={view === "sketchPicker" ? "bottomLeft" : "bottom"}
          content={
            <div>
              {view === "sketchPicker" ? (
                  <SketchPicker
                      width={300}
                      triangle="hide"
                      color={layer.stroke ? layer.stroke : ""}
                      onChange={({ hex }) => onChange({ ...layer, stroke: hex })}
                  />
              ) : (
                  <TwitterPicker
                      triangle="hide"
                      color={layer.stroke ? layer.stroke : ""}
                      onChange={({ hex }) => {
                        if (hex !== layer.stroke) onChange({ ...layer, stroke: hex });
                      }}
                  />
              )}
              <div style={{ display: "flex", gap: 10 }}>
                <Slider
                    step={1}
                    style={{ width: 200 }}
                    min={0}
                    max={10}
                    onChange={(value) => onChange({ ...layer, strokeWidth: value })}
                    value={layer.strokeWidth ? layer.strokeWidth : null}
                />
                <InputNumber
                    style={{ width: 65 }}
                    min={0}
                    max={10}
                    value={layer.strokeWidth ? layer.strokeWidth : 0}
                    onChange={(value) => onChange({ ...layer, strokeWidth: value })}
                />
              </div>
              <Radio.Group
                  value={layer.fillAfterStrokeEnabled}
                  onChange={(e) =>
                      onChange({ ...layer, fillAfterStrokeEnabled: e.target.value })
                  }
              >
                <Radio value={false}>Inside</Radio>
                <Radio value={true}>Outside</Radio>
              </Radio.Group>
            </div>
          }
      >
        <Button type="link">Stroke</Button>

        <GlobalStyle />
      </Popover>
  );
};

export default TextStroke;
