import { Query } from "@apollo/client/react/components";
import { useMutation } from "@apollo/client";
import { Form, Input, notification, Skeleton, TreeSelect } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { CREATE_ARTWORK_CATEGORY } from "../../graphql/mutation/artworkCategoryMutation";
import { ARTWORK_CATEGORIES } from "../../graphql/queries/artworkCategory";
const ArtworkCategoryForm = ({
  visible = true,
  category = null,
  showParent = true,
  onFinish = () => {},
  onCancel = () => {},
  refetch = true,
  currentUser,
}) => {
  const [form] = Form.useForm();
  const isSeller = currentUser?.roles?.find((role) => role.name === "Seller");

  const [createCategory] = useMutation(CREATE_ARTWORK_CATEGORY, {
    // refetchQueries: refetch
    //   ? [
    //       {
    //         query: ARTWORK_CATEGORIES,
    //       },
    //     ]
    //   : [],
  });

  const onSubmit = (values) => {
    createCategory({ variables: values })
      .then((res) => {
        notification.success({ message: "Category created" });
        onFinish(res.data.category);
        form.resetFields();
      })
      .catch((err) => notification.error({ message: err.message }));
  };

  const renderTree = (cats) => {
    return cats.map((cat) => (
      <TreeSelect.TreeNode
        key={cat.id ? cat.id : "-"}
        value={cat.id}
        title={cat.title}
      >
        {cat.children && renderTree(cat.children)}
      </TreeSelect.TreeNode>
    ));
  };

  return (
    <Modal
      forceRender
      visible={visible}
      title={category ? "Update category" : "Add new category"}
      onOk={() => form.submit()}
      onCancel={onCancel}
      okText="Save"
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="title"
          label="Category Name"
          rules={[{ required: true, message: "Category name is required" }]}
        >
          <Input />
        </Form.Item>
        {showParent && (
          <Query
            query={ARTWORK_CATEGORIES}
            variables={{ userID: isSeller ? currentUser.id : null }}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Skeleton loading={true} />;
              }
              if (data) {
                return (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="parentID"
                    label="Parent Category"
                    rules={[{ initialValue: null }]}
                  >
                    <TreeSelect
                      showSearch={true}
                      multiple={false}
                      treeNodeFilterProp="title"
                      treeDefaultExpandAll={true}
                      allowClear
                      placeholder="--Root---"
                    >
                      {renderTree(data?.categories)}
                    </TreeSelect>
                  </Form.Item>
                );
              }
              return null;
            }}
          </Query>
        )}
      </Form>
    </Modal>
  );
};

export default ArtworkCategoryForm;
