import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Form } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
const Container = styled.div`
  .col-attr {
    padding: 10px;
  }
  table {
    tr {
      border-bottom: 1px solid #9999993d;
      th {
        padding: 20px 14px;
        background-color: #e4e2e252;
      }
      td {
        padding: 14px;
      }
    }
  }
`;

const PrintAreas = (props) => {
  const { value, onChange = () => {}, setPrintAreasForm } = props;
  const [printAreas, setPrintAreas] = useState(value ? value : []);
  const [count, setCount] = useState(0);
  const handleAdd = (e) => {
    setPrintAreas((prevState) => {
      return [
        ...prevState,
        { name: "Default", width: null, height: null, dpi: 300 },
      ];
    });
    setCount(count + 1);
  };
  useEffect(() => {
    if (onChange) {
      onChange(printAreas);
    }
  }, [onChange, printAreas]);
  useEffect(() => {
    setPrintAreasForm(printAreas);
  }, [printAreas]);
  return (
    <Container>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr
            style={{
              margin: "20px 0",
              color: "#ff9100",
              background: "#FAFAFA",
            }}
          >
            <th style={{ width: "31%", background: "#FAFAFA" }}>Name</th>
            <th style={{ width: "20", background: "#FAFAFA" }}>DPI</th>
            <th style={{ width: "21%", background: "#FAFAFA" }}>Width</th>
            <th style={{ width: "21%", background: "#FAFAFA" }}>Height</th>
            <th
              style={{ width: "7%", textAlign: "right", background: "#FAFAFA" }}
            ></th>
          </tr>
          {printAreas.map((pri, index) => (
            <tr className="tr-content-printfile" key={index}>
              <td style={{ width: "%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                  }}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <Form.Item
                      validateStatus={pri.name === "" ? "error" : ""}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Name"
                        value={pri.name}
                        onChange={(e) => {
                          setPrintAreas(
                            printAreas.map((item, key) =>
                              key === index
                                ? { ...item, name: e.target.value }
                                : item
                            )
                          );
                        }}
                      />
                    </Form.Item>
                    <div>
                      {pri.name === "" ? (
                        <small
                          style={{
                            position: "absolute",
                            bottom: -14,
                            color: "red",
                          }}
                        >
                          Please input name
                        </small>
                      ) : null}
                    </div>
                  </div>
                </div>
              </td>
              <td style={{ width: "21%", position: "relative" }}>
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  // placeholder="px"
                  value={pri.dpi}
                  onChange={(e) => {
                    setPrintAreas(
                      printAreas.map((item, key) =>
                        key === index ? { ...item, dpi: e } : item
                      )
                    );
                  }}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </td>
              <td style={{ width: "21%", position: "relative" }}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true }]}
                  validateStatus={
                    (pri.name !== "" && pri.width === null) ||
                    (pri.height !== null && pri.width === null)
                      ? "error"
                      : ""
                  }
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="px"
                    value={pri.width}
                    onChange={(e) => {
                      setPrintAreas(
                        printAreas.map((item, key) =>
                          key === index ? { ...item, width: e } : item
                        )
                      );
                    }}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
                {(pri.name !== "" && pri.width === null) ||
                (pri.height !== null && pri.width === null) ? (
                  <small
                    style={{
                      position: "absolute",
                      color: "red",
                      bottom: -4,
                    }}
                  >
                    Please input width
                  </small>
                ) : null}
              </td>
              <td style={{ width: "21%", position: "relative" }}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  validateStatus={
                    (pri.name !== "" && pri.height === null) ||
                    (pri.width !== null && pri.height === null)
                      ? "error"
                      : ""
                  }
                >
                  <InputNumber
                    min={0}
                    style={{ textAlign: "center", width: "100%" }}
                    placeholder="px"
                    value={pri.height}
                    onChange={(e) => {
                      setPrintAreas(
                        printAreas.map((item, key) =>
                          key === index ? { ...item, height: e } : item
                        )
                      );
                    }}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
                {(pri.name !== "" && pri.height === null) ||
                (pri.width !== null && pri.height === null) ? (
                  <small
                    style={{
                      position: "absolute",
                      color: "red",
                      bottom: -4,
                    }}
                  >
                    Please input height
                  </small>
                ) : null}
              </td>
              <td
                style={{
                  width: "7%",
                  textAlign: "right",
                  padding: "10px 0px",
                }}
              >
                <Button
                  type="link"
                  onClick={() => {
                    setPrintAreas((prevState) => {
                      return prevState.filter((el, i) => i !== index);
                    });
                  }}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="flex space-between"
        style={{ padding: 14, background: "#FAFAFA" }}
      >
        <Button
          onClick={() => handleAdd()}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          Add
        </Button>
      </div>
    </Container>
  );
};

export default PrintAreas;
