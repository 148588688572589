import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CategoriesClipart from "../../components/cliparts/CategoriesClipart";
import qs from "query-string";
import { get } from "lodash";
import ClipartList from "../../components/cliparts/ClipartList";
import { connect } from "react-redux";

const Container = styled.div`
  display: grid;
  grid-template-columns: 315px calc(100% - 315px);
  position: relative;
`;
const Cliparts = (props) => {
  const { currentUser } = props;
  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const [categoryID, setCategoryID] = useState(get(query, "categoryID", null));

  useEffect(() => {
    setCategoryID(get(query, "categoryID", null));
  }, [query]);

  return (
    <Container style={{ height: "100%" }}>
      <CategoriesClipart
        categoryID={categoryID}
        setCategoryID={setCategoryID}
        currentUser={currentUser}
      />
      <ClipartList categoryID={categoryID} setCategoryID={setCategoryID} />
    </Container>
  );
};

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(Cliparts);
