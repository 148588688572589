import React, {useState, useEffect} from "react";
import { Button, Modal, Input, message, Form } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { PASSWORD_UNLOCK_USER } from "../config";
const ButtonUnlockUser = ({onConfirm, propsButton, buttonChild, title, content}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [statusInput, setStatusInput] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
        
    };
    const handleOk = () => {
        if(PASSWORD_UNLOCK_USER == password){
            setStatusInput('');
            onConfirm('ok');
            setIsModalOpen(false);
        }else{
            setPassword('');
            setStatusInput('error');
            message.error('The password you provided is incorrect.');
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        
    }, [])
    return (<>
        
            <Button
                onClick={showModal}
                type={propsButton.type ? propsButton.type : "default"}
                size={propsButton.size ? propsButton.size : 'small'} 
                icon={propsButton.icon ? propsButton.icon : false}
                style={propsButton.style ? propsButton.style : {}}
                loading={propsButton.loading ? propsButton.loading : false}
                className={propsButton.className ? propsButton.className : ''}
            >{buttonChild}</Button>
        
        <Modal title="Enter the password to unlock?" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {title && <h4 style={{fontWeight: 'bold'}}>{title}</h4>}
            {content && <p>{content}</p>}
            <Form>
            <Input.Password
                name="pass"
                value={password}
                onChange={(v) => setPassword(v.target.value)}
                placeholder="Password"
                status={statusInput}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
            </Form>
            
        </Modal>
    </>);
}

export default ButtonUnlockUser;