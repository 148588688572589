import { combineReducers } from "redux";
import app from "./app";
import orders from "./orders";
import product from "./product";
import mockup from "./mokup";
import artwork from "./artwork";

export default combineReducers({
  app,
  orders,
  product,
  mockup,
  artwork,
});
