import { EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Tabs,
  Table,
  Image,
  Tooltip,
  Modal,
  Button,
  notification,
  Input,
} from "antd";
import { cloneDeep, concat, forEach, get, omit } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { CLOUDFRONT_URL } from "../../config";
import { UPDATEORDERCUSTOMIZE } from "../../graphql/mutation/mokups/UpdateOrderCustomize";
import { ORDER } from "../../graphql/queries/orders/order";
import ChangeImageMockup from "./ChangeImageMockup";
import _ from "lodash";
import styled from "styled-components";
const Container = styled.div`
  .edit {
    display: none;
  }
  &:hover {
    .edit {
      display: inline-block;
    }
  }
`;
const treeToArray = (arr) => {
  var res = [];
  if (Array.isArray(arr)) {
    arr.forEach((el) => {
      res.push({ ...omit(el, ["layers"]) });
      if (Array.isArray(el.layers)) {
        res.push(...treeToArray(el.layers));
      }
    });
  }
  return res;
};

const CustomizationNew = (props) => {
  const { id, onCancel } = props;
  const { data, loading: loadingQuery, refetch } = useQuery(ORDER, {
    fetchPolicy: "network-only",
    variables: { id: id },
  });
  const [orderCustomize] = useMutation(UPDATEORDERCUSTOMIZE);
  const [artworkId, setArtworkId] = useState();
  const [personalizedData, setPersonalizedData] = useState();
  const [visible, setVisible] = useState();
  const [editText, setEditText] = useState(false);
  useEffect(() => {
    setPersonalizedData(data ? data.order.personalizedData : []);
    if (data) {
      setArtworkId("0");
    }
  }, [data]);
  const status = data ? data.order.status : null;
  const dataSource = ({ personalized, artwork }) => {
    var flatLayers = treeToArray(
      concat(...artwork.templates.map((tpl) => tpl.layers))
    );
    var rows = [];
    const template = get(personalized, "template", 0);
    if (artwork.templates.length > 1) {
      rows.push({
        id: "template",
        title: "Template",
        data: artwork.templates[template].title,
      });
    }
    forEach(personalized, (data, id) => {
      if (id !== "template") {
        var layer = flatLayers.find((l) => l.id === id);
        if (!layer || !get(layer, "personalized.enable", false)) return;
        rows.push({
          id: id,
          title: get(layer, "personalized.label", layer.title),
          data: data,
        });
      }
    });
    return rows;
  };
  return (
    <div>
      <Tabs activeKey={artworkId} onChange={(key) => setArtworkId(key)}>
        {personalizedData?.map((data, index) => (
          <Tabs.TabPane key={index} tab={data.printArea}>
            <Table
              style={{ marginLeft: 1 }}
              dataSource={dataSource(data)}
              columns={[
                {
                  title: "Field",
                  dataIndex: "title",
                },
                {
                  title: "Value",
                  dataIndex: "data",
                  render: (data, record) => {
                    if (typeof data === "object") {
                      if (data.key) {
                        return (
                          <Image
                            src={`${CLOUDFRONT_URL}100/${data.key}`}
                            style={{ backgroundColor: "hsl(0, 0%, 90%)" }}
                            // preview={false}
                            preview={{
                              mask: (
                                <div>
                                  <div className="flex item-center">
                                    <AiOutlineEye /> Preview
                                  </div>
                                  {["exported", "cancelled"].includes(
                                    status
                                  ) ? null : (
                                    <Tooltip title="Edit Image">
                                      <EditOutlined
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setVisible(record);
                                        }}
                                        style={{
                                          zIndex: 200000,
                                          position: "absolute",
                                          right: 5,
                                          top: 5,
                                          color: "white",
                                          size: 30,
                                        }}
                                        className="anticon custom-icon"
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              ),
                            }}
                          />
                        );
                      }
                      return <pre>{JSON.stringify(data)}</pre>;
                    }
                    return (
                      <Container>
                        <EditOutlined
                          className="edit"
                          style={{ cursor: "pointer", marginRight: 10 }}
                          onClick={(e) => {
                            setEditText(record);
                          }}
                        />
                        {editText?.id === record.id ? (
                          <Input
                            style={{ width: 100 }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            autoFocus={true}
                            onPressEnter={() => {
                              setEditText(false);
                            }}
                            onChange={(e) => {
                              var dataClone = cloneDeep(personalizedData);
                              dataClone[artworkId].personalized[editText.id] =
                                e.target.value;
                              setPersonalizedData(dataClone);
                            }}
                            value={data}
                            onBlur={() => {
                              setEditText(false);
                            }}
                          />
                        ) : (
                          data
                        )}
                      </Container>
                    );
                  },
                  align: "right",
                },
              ]}
              rowKey={(row) => row.id}
              pagination={false}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <Button
          type="primary"
          onClick={() => {
            var abc = {};
            for (let index = 0; index < personalizedData.length; index++) {
              abc[personalizedData[index].artwork.id] =
                personalizedData[index].personalized;
            }

            orderCustomize({
              variables: {
                id: id,
                // customize: personalizedData.map((item) => {
                //   let id = item.artwork.id;
                //
                //   return (abc[id] = item.personalized);
                // }),
                customize: abc,
              },
            })
              .then((res) => {
                onCancel();
              })
              .catch((err) => {
                notification["error"]({
                  message: _.get(err, "[0].message"),
                });
              });
          }}
        >
          Save
        </Button>
      </div>
      <Modal
        title="Change image mockup"
        visible={visible ? true : false}
        onCancel={() => {
          setVisible(false);
        }}
        footer={false}
      >
        <ChangeImageMockup
          record={visible?.data}
          onSave={(value) => {
            var dataClone = cloneDeep(personalizedData);

            forEach(dataClone[artworkId].personalized, (data, id) => {
              if (id === visible.id) {
                dataClone[artworkId].personalized[id] = value;
              }
            });
            setPersonalizedData(dataClone);
            setVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default CustomizationNew;
