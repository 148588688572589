import { get, indexOf } from "lodash";
import { ARTWORK } from "../types";

export const initState = {
  workspace: {
    artworks: [],
    artwork: {
      title: "",
      width: 0,
      height: 0,
      templates: [
        {
          title: "",
          isDefault: true,
          data: { layers: [] },
        },
      ],
    },
    clipboard: null,
    selectedTemplate: 0,
    selectedLayers: [],
    ctrl: false,
  },
};

export const isClient = () => {
  return typeof window === "object";
};

export const updateLayer = (layers, update) => {
  for (let i = 0; i < layers.length; i++) {
    if (layers[i].id === update.id) {
      layers[i] = { ...layers[i], ...update };
      break;
    }
    if (layers[i].layers) {
      updateLayer(layers[i].layers, update);
    }
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case ARTWORK.SET:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          artwork: action.payload,
        },
      };
    case ARTWORK.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedTemplate: action.payload,
        },
      };
    case ARTWORK.SET_LAYERS:
      var { selectedTemplate, artwork } = state.workspace;
      var { templates } = artwork;
      return {
        ...state,
        workspace: {
          ...state.workspace,
          artwork: {
            ...artwork,
            templates: templates?.map((tpl, index) =>
              index === selectedTemplate
                ? {
                    ...tpl,
                    layers: action.payload,
                  }
                : tpl
            ),
          },
        },
      };
    case ARTWORK.SET_SELECTED_LAYERS:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedLayers: action.payload,
        },
      };
    case ARTWORK.SET_LAYER:
      var { selectedTemplate, artwork } = state.workspace;
      var { templates } = artwork;
      var layers = [];
      layers = [...templates[selectedTemplate].layers];
      updateLayer(layers, action.payload);
      return {
        ...state,
        workspace: {
          ...state.workspace,
          artwork: {
            ...artwork,
            templates: templates.map((template, index) =>
              index === selectedTemplate
                ? { ...template, layers: layers }
                : template
            ),
          },
        },
      };
    case ARTWORK.SET_CLIPBOARD:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          clipboard: action.payload,
        },
      };
    case ARTWORK.SET_THUMBNAIL:
      var { selectedTemplate, artwork } = state.workspace;
      return {
        ...state,
        workspace: {
          ...state.workspace,
          artwork: {
            ...artwork,
            templates: artwork.templates.map((template, index) =>
              index === selectedTemplate
                ? { ...template, thumbnail: action.payload }
                : template
            ),
          },
        },
      };
    case ARTWORK.UNDO:
      return {
        ...state,
      };
    case ARTWORK.SET_CTRL:
      return {
        ...state,
        workspace: {
          ...state.workspace,
          ctrl: action.payload,
        },
      };
    case ARTWORK.ADD_LAYER:
      var { workspace } = state;
      var { selectedTemplate, artwork, selectedLayers } = workspace;
      var newLayers = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      newLayers.forEach((newLayer) => {
        var firstSelectedLayer = null,
          layers = [];
        layers = get(artwork, `templates[${selectedTemplate}].layers`);
        if (selectedLayers.length > 0) {
          firstSelectedLayer = layers.search(selectedLayers[0]);
        }
        if (firstSelectedLayer && firstSelectedLayer.parent) {
          newLayer.parent = firstSelectedLayer.parent;
          //Insert new layer to group
          var parent = layers.search(firstSelectedLayer.parent);
          var firstSelectedLayerIndex = indexOf(
            parent.layers,
            firstSelectedLayer
          );
          parent.layers.splice(firstSelectedLayerIndex + 1, 0, newLayer);
        } else {
          if (firstSelectedLayer) {
            var firstSelectedLayerIndex = indexOf(layers, firstSelectedLayer);
            layers.splice(firstSelectedLayerIndex + 1, 0, newLayer);
          } else {
            layers.push(newLayer);
          }
        }
      });
      return {
        ...state,
        workspace: {
          ...state.workspace,
          selectedLayers: newLayers.map((l) => l.id),
        },
      };
    default:
      return state;
  }
};
