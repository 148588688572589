import React, { Component } from "react";
import { Form, InputNumber } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
const Container = styled.div`
  position: relative;
  .message {
    font-size: 10px;
    color: red;
    position: absolute;
  }
`;

class ProductVariantCostField extends Component {
  render() {
    return (
      <Container>
        <Form.Item
          label={this.props.label}
          validateStatus={this.props.checkValue ? "error" : ""}
        >
          <InputNumber
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            onChange={this.props.onChange}
            value={this.props.value > 0 ? this.props.value : 0}
          />
        </Form.Item>
        {this.props.checkValue ? (
          <small className="message">{this.props.message}</small>
        ) : null}
      </Container>
    );
  }
}

ProductVariantCostField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
};

export default ProductVariantCostField;
