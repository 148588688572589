import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, notification, Select } from "antd";
import { debounce } from "lodash";
import React, { useState } from "react";
import { CREATE_TAG } from "../../graphql/mutation/createTag";
import { TAGS } from "../../graphql/queries/tags";
import _ from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";
const Container = styled.div`
  .hover-backgroud:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const TagsSelect = ({ value, onChange = () => {}, currentUser }) => {
  const isSeller = currentUser?.roles.find((role) => role.name === "Seller");
  const [search, setSearch] = useState("");
  const { data, refetch } = useQuery(TAGS, {
    variables: {
      filter: {
        limit: -1,
        search: search,
        userID: isSeller ? currentUser.id : null,
      },
    },
  });
  let tags = data ? data.tags.hits : [];
  const [createTag] = useMutation(CREATE_TAG);
    const onFinish = (valueForm) => {
        createTag({ variables: { input: { title: valueForm } } })
        .then((res) => {
            // form.resetFields();
            setSearch("");
            refetch().then(() => {
                if (value && value.length > 0) {
                    onChange([...value, res.data.createTag.id]);
                } else {
                    onChange([res.data.createTag.id]);
                }
            });
        })
        .catch((err) => {
            notification["error"]({
                message: "Error",
                description: _.get(err, "[0].message"),
            });
        });
    };
  return (
    <>
        <Select
            mode="multiple"
            showSearch
            value={value}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSearch={debounce((value) => {
                setSearch(value);
            }, 300)}
            onChange={(value) => {
                onChange(value);
                setSearch("");
            }}
            // onBlur={() => setSearch("")}
            dropdownRender={(menu) => (
                <Container>
                    {tags.length === 0 && search?.length > 0 ? (
                        <div
                            className="hover-backgroud"
                            onClick={() => {
                                onFinish(search);
                            }}
                            style={{ paddingLeft: 10 }}
                        >
                            <Button type="link">
                                <PlusOutlined />
                                Add item
                            </Button>
                        </div>
                    ) : (
                        menu
                    )}
                </Container>
            )}
        >
            {tags?.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                    {item.title}
                </Select.Option>
            ))}
        </Select>
    </>
  );
};

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(TagsSelect);
