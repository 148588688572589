import React from "react";
import { Form, Button, Input, notification, message, DatePicker } from "antd";
import gql from "../../api/gql";
import * as _ from "lodash";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   if (!isJpgOrPng) {
//     message.error('You can only upload JPG/PNG file!');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!');
//   }
//   return isJpgOrPng && isLt2M;
// }

class UploadMyProfile extends React.Component {
  state = {
    loading: false,
    avatar: [],
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  onSubmit = (values) => {
    let defaultValue = {
      address: values.address,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
    };
    this.setState({ loading: true });
    const mutation = `mutation updateUser($id: ID! $input: UpdateUser!){
          updateUser(id: $id input: $input){
            id
            first_name
            last_name
            email
            phone
            address
            birthday
            }
          }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        input: defaultValue,
        id: this.props.me.id,
      })
      .then((res) => {
        this.setState({ loading: false });
        message["success"]("Your profile updated");
        this.props.onLoad();
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  onChange(date, dateString) {
    console.log(date, dateString);
  }

  render() {
    const { me } = this.props;
    return (
      <Form layout={"vertical"} hideRequiredMark onFinish={this.onSubmit}>
        <Form.Item
          label="First Name"
          name="first_name"
          rules={[
            {
              initialValue: me.first_name,
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          rules={[
            {
              initialValue: me.last_name,
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Birthday"
          name="birthday"
          rules={[
            {
              required: true,
              initialValue: "",
            },
          ]}
        >
          <DatePicker onChange={this.onChange} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              initialValue: me.email,
              required: true,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            {
              initialValue: "",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              initialValue: "",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Upload My Profile
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export default UploadMyProfile;
