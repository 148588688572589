import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { API_URL, WS_URL } from "./config";

var CLIENT = null;
const ApolloClientInit = (initialState) => {
  if (CLIENT !== null) {
    return CLIENT;
  }
  const errorLink = onError(
    ({ networkError, graphQLErrors, forward, operation, response }) => {}
  );

  const httpLink = new HttpLink({
    uri: `${API_URL}/query`,
  });

  const getToken = () => {
    return localStorage.getItem("_token");
  };

  const authLink = setContext((_, { headers }) => {
    let token = getToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const wsLink = new WebSocketLink({
    uri: `${WS_URL}/query`,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        let token = getToken();
        return {
          Authorization: token ? `Bearer ${token}` : "",
        };
      },
    },
  });

  return new ApolloClient({
    credentials: "include",
    link: ApolloLink.from([
      errorLink,
      authLink,
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        httpLink
      ),
    ]),
    cache: new InMemoryCache().restore(initialState || {}),
  });
};

export const apolloClient = ApolloClientInit({});
