import React, { useState, useEffect } from "react";
import { Form, Button, Space } from "antd";
import SelectAspect from "../components/SelectAspect";
import UploadImage from "../components/UploadImage";
import { URL_API_IMAGE_GENERATION_STREAM } from "../../../../config";
import { SSE } from "../../../../sse";
import { AiOutlinePlus } from "react-icons/ai";
export default function BlendGeneration({
  onChange,
  onChangeLoading,
  spinning,
  emitdata,
  serverID,
  timeNext,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [aspect, setAspect] = useState("1:1");
  const [data, setData] = useState(null);

  const [arrayImages, setArrayImages] = useState([
    {
      id: 1,
      lock: true,
      url: "",
      show: true,
    },
    {
      id: 2,
      lock: true,
      url: "",
      show: true,
    },
    {
      id: 3,
      lock: false,
      url: "",
      show: false,
    },
    {
      id: 4,
      lock: false,
      url: "",
      show: false,
    },
    {
      id: 5,
      lock: false,
      url: "",
      show: false,
    },
  ]);

  const updateArrayImages = (id, type, value) => {
    setArrayImages((pre) =>
      pre.map((e, key, index) => {
        if (type == "show" && e.lock) return e;
        if (e.id == id) {
          e[type] = value;
        }
        return e;
      })
    );
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    setLoading(spinning);
  }, [spinning]);

  useEffect(() => {
    onChangeLoading(loading);
  }, [loading]);

  const onFinish = (v) => {
    setLoading(true);
    setData({ ...emitdata, progress: "0%" });
    v.aspect = aspect;
    v.images = arrayImages.filter((e) => e.url && e.show).map((e) => e.url);
    v.serverId = serverID;
    v.action = "blend";
    var source = new SSE(URL_API_IMAGE_GENERATION_STREAM, {
      method: "POST",
      payload: JSON.stringify(v),
    });
    window.sourceMidjourney = source;
    source.addEventListener("error", function (e) {
      setLoading(false);
    });
    source.addEventListener("message", function (e) {
      if (!e.data) {
        setLoading(false);
        return;
      }
      const data = JSON.parse(e.data);
      if (data.status == "done") {
        data.progress = "100%";
        setLoading(false);
      }
      setData(data);
    });
    source.stream();
  };

  const onFinishFailed = () => {};

  const handlePlusImageShow = () => {
    const findHide = arrayImages.find((e) => !e.show);
    if (findHide) {
      updateArrayImages(findHide.id, "show", true);
    }
  };

  const handleCancel = () => {};

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <SelectAspect
        onSelect={(v) => {
          setAspect(v);
        }}
      />
      <Form.Item label="Images">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignTtems: "flex-start",
          }}
        >
          {arrayImages.map((item, index) => {
            if (!item.show) return;
            return (
              <div
                key={item.id}
                style={{
                  position: "relative",
                  marginRight: 3,
                  marginBottom: 3,
                }}
              >
                <UploadImage
                  onHide={() => {
                    updateArrayImages(item.id, "show", false);
                  }}
                  onChange={(v) => {
                    updateArrayImages(item.id, "url", v);
                  }}
                />
              </div>
            );
          })}
          {arrayImages.find((e) => e.show == false) && (
            <div
              onClick={handlePlusImageShow}
              style={{
                width: 95,
                aspectRatio: "1/1",
                background: "rgb(0 0 0 / 70%)",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgb(255 255 255)",
                fontSize: 25,
                overflow: "hidden",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <AiOutlinePlus />
            </div>
          )}
        </div>
      </Form.Item>
      <Form.Item>
        <Space>
          {/* {loading && (
            <Button onClick={handleCancel} type="dashed" htmlType="button">
              Cancel
            </Button>
          )} */}
          <Button
            disabled={
              arrayImages.filter((e) => e.url && e.show).map((e) => e.url)
                .length < 2
            }
            loading={loading || timeNext > 0}
            type="primary"
            htmlType="submit"
          >
            Generation {timeNext > 0 && `${timeNext}s`}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
