import { Tabs } from "antd";
import React from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import Layers from "./Layers";
import SettingVariant from "../product_type/SettingVariant";
const Container = styled.div`
  height: 100%;
  .ant-tabs {
    min-height: 100%;
    &.canvas-left-toolbars {
      .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-nav-list {
        svg {
          font-size: 24px;
        }
      }
      .ant-tabs-content-holder {
        border-left: none;
        margin-left: 0;
        overflow-y: auto;
      }
      .ant-tabs-tabpane {
        padding-left: 0;
      }
      .ant-collapse {
        background-color: transparent;
        border-radius: 0;
        border: none;
        .ant-collapse-item {
          border-color: hsla(0, 0%, 100%, 0.07);
          &:last-child {
            border-radius: 0;
          }
        }
        .ant-collapse-content {
          border-radius: 0;
          .ant-form-item {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

const LeftToolbar = ({ productType }) => {
  return (
    <Container>
      <Tabs className="canvas-left-toolbars" type="card">
        <Tabs.TabPane tab="Printareas" key="printareas">
          <Layers />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Settings" key="settings">
          <SettingVariant productType={productType} />
          {/* <Settings campaignView={campaignView} /> */}
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};
export default connect((state) => ({
  mockupWorkspace: state.mockup.mockupWorkspace,
}))(LeftToolbar);
