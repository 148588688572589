import { useSubscription } from "@apollo/client";
import React from "react";
import { REGENDER_SUBSCRIPTION } from "../../graphql/mutation/orders/orderAction";

const SubOrder = ({ orders, setOrders }) => {
  useSubscription(REGENDER_SUBSCRIPTION, {
    onSubscriptionData: ({
      subscriptionData: {
        data: { generateOrderPrintFile },
      },
    }) => {
      setOrders(
        orders.map((item) => {
          if (item.id === generateOrderPrintFile.id) {
            // return { ...item, designs: generateOrderPrintFile.designs };
            return generateOrderPrintFile;
          } else {
            return item;
          }
        })
      );
    },
  });
  return <div></div>;
};

export default SubOrder;
