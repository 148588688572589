import React, { Component } from "react";
import {
  notification,
  Avatar,
  Button,
  Drawer,
  Tooltip,
  Divider,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import Table from "./Table";
import gql from "../api/gql";
import _ from "lodash";
import UploadDesigns from "./products/UploadDesigns";
import moment from "moment";
import { connect } from "react-redux";
import { history } from "../history";
import { setProduct } from "../actions";
import styled from "styled-components";
import {
  CopyOutlined,
  DeleteOutlined,
  PictureOutlined,
  CheckOutlined,
  UploadOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

const _targetBlank = {
  target: "_blank",
  rel: "noopener noreferrer",
};
const Container = styled.div`
  .ant-drawer-content-wrapper {
    width: 800px !important;
  }
  @media only screen and (min-width: 768px) {
    .ant-drawer-content-wrapper {
      width: 800px !important;
    }
  }
`;
class Products extends Component {
  state = {
    orders: [],
    // sites: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 20,
      ...this.props.filter,
    },
    selectedProducts: [],
    selectedProduct: null,
    showUploadDesign: false,
    loading: false,
    showDeleteConfirm: false,
  };

  componentDidMount() {
    // this.loadSites();
    if (
      this.props.currentUser.roles
        .map((role) => role.name)
        .includes("Seller") &&
      this.props.currentSite
    ) {
      this.loadProducts();
    } else if (
      !this.props.currentUser.roles.map((role) => role.name).includes("Seller")
    ) {
      this.loadProducts();
    }
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state;
    if (prevProps.filter !== this.props.filter) {
      this.setState(
        {
          page: 1,
          filter: { ...filter, offset: 0, ...this.props.filter },
        },
        () => {
          if (
            this.props.currentUser.roles
              .map((role) => role.name)
              .includes("Seller") &&
            this.props.currentSite
          ) {
            this.loadProducts();
          } else if (
            !this.props.currentUser.roles
              .map((role) => role.name)
              .includes("Seller")
          ) {
            this.loadProducts();
          }
        }
      );
    }
  }
  // loadSites() {
  //   const query = `query sites{sites(filter:{limit:9999}){hits{id,title}}}`;
  //   gql.request(query).then((res) => {
  //     this.setState({ sites: res.sites.hits || [] });
  //   });
  // }
  loadProducts() {
    const query = `query products($filter: ProductFilter){
      products(filter: $filter){
        count
        hits{
          id
          sku
          title
          images
          created_at
          product_stores{
            id
            site_id
            origin_id
            permalink
          }
          is_custom_design
            variants{
            sku
            design_status
            product_type_id
            print_files{
              key
              title
              width
              height
              file_id
              variant_ids
            }
          }
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        filter: this.state.filter,
      })
      .then((res) => {
        this.setState({
          products: res.products.hits || [],
          total: res.products.count,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        notification["error"]({ message: _.get(err, "[0].message") });
      });
  }

  updateProductFormSite = (id) => {
    const query = `mutation updateProduct{
      updateProductFromSite(id: "${id}")
    }`;
    gql.request(query).then((res) => {
      notification["success"]({
        message: "Product updated",
      });
    });
  };

  deleteProduct = (id, isDelOnStore) => {
    const query = `mutation deleteProduct($product_id: String!, $isDelOnStore: Boolean!)
    {deleteProduct(product_id: $product_id, isDelOnStore: $isDelOnStore)}`;
    gql
      .request(query, {
        product_id: id,
        isDelOnStore: isDelOnStore,
      })
      .then((res) => {
        notification["success"]({
          message: "Product has been deleted",
        });
        const products = this.state.products.filter((item) => item.id !== id);
        this.setState({ products: products, showDeleteConfirm: false });
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ showDeleteConfirm: false });
      });
  };

  handleYes = () => {
    this.deleteProduct(this.state.selectedProduct.id, true);
  };
  handleNo = () => {
    this.deleteProduct(this.state.selectedProduct.id, false);
  };
  handleCancel = () => {
    this.setState({ showDeleteConfirm: false });
  };

  render() {
    const {
      products,
      selectedProduct,
      showUploadDesign,
      total,
      filter,
      loading,
    } = this.state;
    const { currentSite, currentUser, sites } = this.props;
    const allowProductAction =
      currentUser.roles.map((role) => role.name).includes("Administrator") ||
      (currentSite && currentSite.type !== "offline");
    const allowCloneProduct =
      currentUser.roles.map((role) => role.name).includes("Seller") &&
      currentSite &&
      currentSite.type !== "offline";
    let isAdmin = this.props.currentUser.roles.find((el) =>
      ["Administrator", "Seller", "Listing"].includes(el.name)
    );
    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     this.setState({
    //       selectedProducts: selectedRows
    //     })
    //   }
    // }
    const columns = [
      // {
      //   title: "Origin ID",
      //   width: 150,
      //   render: (_, { product_stores }) => product_stores[0].origin_id,
      // },

      {
        title: "Title",
        dataIndex: "title",
        width: 500,
        render: (title, { id, product_stores, images, sku, variants }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              icon={<PictureOutlined />}
              src={images ? images[0] : null}
              shape="square"
              size={60}
              style={{ marginRight: "10px" }}
            />
            <div>
              {title}{" "}
              {/* {allowProductAction && (
                <a href={product_stores[0].permalink} {..._targetBlank}>
                  <EyeOutlined />
                </a>
              )} */}
              <br />
              {variants && (
                <em style={{ color: "#8D8D8D" }}>{variants[0]?.sku}</em>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Sites",
        dataIndex: "product_stores",
        width: 250,
        render: (product_stores) => {
          if (currentUser.roles.map((role) => role.name).includes("Seller")) {
            return (
              <a
                href={
                  product_stores?.find(
                    (item) => item.site_id === filter.site_ids?.[0]
                  )?.permalink
                }
                {..._targetBlank}
              >
                {
                  sites.find((item) => item.id === product_stores[0]?.site_id)
                    ?.title
                }
                {" - "}
                {
                  sites.find((item) => item.id === product_stores[0]?.site_id)
                    ?.domain
                }
              </a>
            );
          } else {
            return (
              <Dropdown
                overlay={
                  <div>
                    {product_stores.length >= 2 && (
                      <Menu>
                        {product_stores.map((item) => (
                          <Menu.Item>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.permalink}
                            >
                              {sites.find((i) => i.id === item.site_id)?.title}
                              {" - "}
                              {sites.find((i) => i.id === item.site_id)?.domain}
                            </a>
                          </Menu.Item>
                        ))}
                      </Menu>
                    )}
                  </div>
                }
              >
                <a
                  className="ant-dropdown-link"
                  href={product_stores[0]?.permalink}
                >
                  {
                    sites.find((item) => item.id === product_stores[0]?.site_id)
                      ?.title
                  }
                  {" - "}
                  {
                    sites.find((item) => item.id === product_stores[0]?.site_id)
                      ?.domain
                  }
                  {product_stores.length > 1 ? <CaretDownOutlined /> : null}
                </a>
              </Dropdown>
            );
          }
        },
      },
      // {
      //   title: "Designs",
      //   dataIndex: "file",
      //   key: "file",
      //   width: 100,
      //   render: (files, product) => {
      //     const isUploadingDesign = _.find(product.variants, {
      //       design_status: false,
      //     });
      //     return (
      //       <>
      //         {!product.is_custom_design && (
      //           <div
      //             style={{ display: "flex", width: 200, gap: 5 }}
      //             onClick={(e) => {
      //               e.preventDefault();
      //               this.setState({
      //                 selectedProduct: product,
      //                 showUploadDesign: true,
      //               });
      //             }}
      //           >
      //             <Tooltip
      //               title={
      //                 !isUploadingDesign
      //                   ? "View design files"
      //                   : "Upload design files"
      //               }
      //             >
      //               <Button
      //                 type={!isUploadingDesign ? "primary" : "danger"}
      //                 ghost
      //                 icon={
      //                   !isUploadingDesign ? (
      //                     <CheckOutlined />
      //                   ) : (
      //                     <UploadOutlined />
      //                   )
      //                 }
      //                 onClick={(e) => {
      //                   e.preventDefault();
      //                   this.setState({
      //                     selectedProduct: product,
      //                     showUploadDesign: true,
      //                   });
      //                 }}
      //               >
      //                 {!isUploadingDesign ? "View" : "Upload"}
      //               </Button>
      //             </Tooltip>
      //           </div>
      //         )}
      //         {product.is_custom_design && (
      //           <Tooltip title="View/upload designs from order">
      //             <Button type="dashed" ghost disabled>
      //               Custom
      //             </Button>
      //           </Tooltip>
      //         )}
      //       </>
      //     );
      //   },
      // },
      {
        title: "Created Date",
        width: 150,
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("MMM DD, YYYY"),
      },
      isAdmin
        ? {
            title: "Actions",
            width: 180,
            dataIndex: "id",
            key: "actions",
            align: "right",
            render: (id, product) => (
              <>
                {allowCloneProduct && (
                  <>
                    <Tooltip title="Clone product">
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          const product_type_ids = [
                            ...new Set(
                              product.variants.map((el) => el.product_type_id)
                            ),
                          ];
                          history.push(
                            `/products/clone/${id}/${product_type_ids.join(
                              ","
                            )}`
                          );
                        }}
                      >
                        <CopyOutlined />
                      </Button>
                    </Tooltip>
                    <Divider type="vertical" />
                  </>
                )}
                {allowProductAction && (
                  <Tooltip title="Delete product">
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          selectedProduct: product,
                          showDeleteConfirm: true,
                        });
                      }}
                    >
                      <DeleteOutlined style={{ color: "red" }} />
                    </Button>
                  </Tooltip>
                )}
              </>
            ),
          }
        : {},
    ];
    const pagination = {
      pageSize: filter.limit,
      total: total,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadProducts()
        );
      },
    };
    return (
      <Container>
        <Table
          columns={columns}
          dataSource={products}
          // rowSelection={rowSelection}
          pagination={pagination}
          rowKey={({ id }) => id}
          loading={loading}
        />
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={(e) =>
            this.setState({ selectedProduct: null, showUploadDesign: false })
          }
          visible={showUploadDesign}
        >
          {selectedProduct && (
            <UploadDesigns
              product_id={selectedProduct.id}
              reloadProduct={() => this.loadProducts()}
              onClose={(e) =>
                this.setState({
                  selectedProduct: null,
                  showUploadDesign: false,
                })
              }
            />
          )}
        </Drawer>
        {selectedProduct && (
          <Modal
            visible={this.state.showDeleteConfirm}
            title="Delete Product Confirmation"
            onCancel={this.handleCancel}
            footer={[
              <Button key="cancel" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button key="no" loading={loading} onClick={this.handleNo}>
                No
              </Button>,
              <Button
                key="yes"
                type="primary"
                loading={loading}
                onClick={this.handleYes}
              >
                Yes
              </Button>,
            ]}
          >
            <p>Do you want to delete the product on your store also?</p>
            <p style={{ color: "red" }}>Note: This action cannot be undo</p>
          </Modal>
        )}
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    currentUser: state.app.currentUser,
  }),
  { setProduct }
)(Products);
