import React, { useState } from "react";
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
import { MOCKUP } from "../../types";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { cloneDeep, get } from "lodash";
import arrayMove from "array-move";
import { getIDs, uniqueID } from "./helper";
import styled from "styled-components";
import { Button, Collapse, Dropdown, Input, Menu, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { BiText, BiMoveVertical } from "react-icons/bi";
import { AiFillFolderOpen } from "react-icons/ai";
import { FaRegEyeSlash, FaRegEye, FaImage } from "react-icons/fa";
import Upload from "../upload/Upload";
import { CREATE_FILE } from "../../graphql/mutation/createFile";
import { useMutation } from "@apollo/client";
import LayerSettings from "./LayerSettings";

const Container = styled.div`
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag {
    border: none;
  }
`;
const DragHandle = SortableHandle(() => (
  <BiMoveVertical className="anticon" style={{ fontSize: 15 }} />
));
const layerExtra = (layer, dispatch) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="action-icons"
      style={{
        display: "flex",
        gap: 5,
        alignItems: "center",
        marginTop: 4,
        fontSize: 13,
      }}
    >
      <DragHandle />

      <span
        style={{ display: "inline-flex" }}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: MOCKUP.SET_LAYER,
            payload: { ...layer, visible: !layer.visible },
          });
        }}
      >
        {layer.visible === false ? (
          <FaRegEyeSlash className="anticon" />
        ) : (
          <FaRegEye className="anticon" />
        )}
      </span>
    </div>
  );
};
const SortableItem = SortableElement(
  ({ layer, layers, onChange, layerExtra, index }) => {
    const mockupWorkspace = useSelector(
      (state) => state.mockup.mockupWorkspace
    );
    const { selectedLayers } = mockupWorkspace;
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const onLayerSelect = (key) => {
      dispatch({
        type: MOCKUP.SET_SELECTED_LAYERS,
        payload: key ? [key] : layer.parent ? [layer.parent] : [],
      });
    };
    const onSortEnd = ({ oldIndex, newIndex }) => {
      dispatch({
        type: MOCKUP.SET_LAYER,
        payload: {
          ...layer,
          layers: arrayMove(
            layer.layers,
            layer.layers.length - oldIndex - 1,
            layer.layers.length - newIndex - 1
          ),
        },
      });
    };
    return (
      <li style={{ listStyle: "none" }} key={layer.id}>
        <Collapse
          accordion={true}
          activeKey={
            selectedLayers.length === 1 &&
            selectedLayers.find((id) => getIDs(layers, id).includes(layer.id))
              ? layer.id
              : null
          }
          onChange={onLayerSelect}
          expandIconPosition="left"
        >
          <Collapse.Panel
            header={
              <span className="layer-title">
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  {((type) => {
                    if (type === "Text") {
                      return <BiText className="layer-icon" />;
                    }
                    if (type === "Group") {
                      return <AiFillFolderOpen className="layer-icon" />;
                    }
                    if (type === "Image") {
                      return <FaImage className="layer-icon" />;
                    }
                  })(layer.type)}
                  {edit ? (
                    <Input
                      size="small"
                      value={layer.title || `Layer ${layer.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      autoFocus={true}
                      onPressEnter={() => {
                        setEdit(false);
                      }}
                      onChange={(e) => {
                        dispatch({
                          type: MOCKUP.SET_LAYER,
                          payload: { ...layer, title: e.target.value },
                        });
                      }}
                      onBlur={() => setEdit(false)}
                    />
                  ) : (
                    <>
                      <span
                        style={{
                          maxWidth: "216px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        onDoubleClick={(e) => {
                          e.stopPropagation();
                          setEdit(true);
                        }}
                      >
                        {layer.title || `Layer ${layer.id}`}
                      </span>{" "}
                      <EditOutlined
                        className="edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEdit(true);
                        }}
                      />
                    </>
                  )}
                </span>
              </span>
            }
            key={layer.id}
            extra={layerExtra(layer, dispatch, mockupWorkspace)}
            className={layer.type}
          >
            {(() => {
              switch (layer.type) {
                case "Text":
                  return null;
                case "Image":
                  return null;
                case "Group":
                  return (
                    <div>
                      <SortableList
                        layers={layer.layers}
                        selectedLayers={selectedLayers}
                        lockAxis="y"
                        useDragHandle
                        onSortEnd={onSortEnd}
                        onChange={onChange}
                        layerExtra={layerExtra}
                      />
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </Collapse.Panel>
        </Collapse>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({ layers, onLayerSelect, layerExtra, dispatch }) => (
    <ul className="layer-list">
      {layers.map((_, index) => {
        var idx = layers.length - index - 1;
        var layer = layers[idx];
        return (
          <SortableItem
            key={layer.id}
            index={index}
            layer={layer}
            layers={layers}
            onLayerSelect={onLayerSelect}
            layerExtra={layerExtra}
            dispatch={dispatch}
          />
        );
      })}
    </ul>
  )
);

const Layers = ({ mockupWorkspace }) => {
  const dispatch = useDispatch();
  const { mockup, selectedLayers, mockupsManage } = mockupWorkspace;
  const { layers } = mockup;
  const [widthHeight, setWidthHeight] = useState(null);
  const [fileUpload, setFileUploads] = useState();
  const setMockupList = (layers) => {
    if (mockupsManage.length) {
      let newListMockup = cloneDeep(mockupsManage);
      if (newListMockup.length) {
        newListMockup = newListMockup.map((item) => {
          if (item.id === mockup.id) {
            return {
              ...mockup,
              layers,
            };
          }
          return { ...item };
        });
      }
      dispatch({
        type: MOCKUP.SET_MOCKUPS,
        payload: newListMockup,
      });
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newLayers = arrayMove(
      layers,
      layers.length - oldIndex - 1,
      layers.length - newIndex - 1
    );
    dispatch({
      type: MOCKUP.SET,
      payload: {
        ...mockup,
        layers: newLayers,
      },
    });
    setMockupList(newLayers);
  };
  const addPrintare = (printArea) => {
    const { width, height } = mockup;
    var printareaLayer = {
      title: `Printarea: ${get(printArea, "name")}`,
      id: uniqueID(),
      type: "Printarea",
      x: (width - 100) / 2,
      y: (height - 100) / 2,
      printArea: printArea,
    };
    dispatch({
      type: MOCKUP.SET,
      payload: {
        ...mockup,
        layers: [...mockup.layers, printareaLayer],
      },
    });

    setMockupList([...mockup.layers, printareaLayer]);
    dispatch({
      type: MOCKUP.SET_SELECTED_LAYERS,
      payload: [printareaLayer.id],
    });
  };
  const addImage = (file) => {
    var imgLayer = {
      id: uniqueID(),
      type: "Image",
      file: file,
      x: 0,
      y: 0,
    };
    dispatch({
      type: MOCKUP.SET,
      payload: {
        ...mockup,
        layers: [...mockup.layers, imgLayer],
      },
    });
    setMockupList([...mockup.layers, imgLayer]);
  };
  const printAreas = get(mockup, "productType.printAreas", []) || [];
  const [createFile] = useMutation(CREATE_FILE);
  const handleChange = (file) => {
    createFile({
      variables: {
        input: {
          key: file.key,
          file_name: file.name,
          file_mime: file.type,
          file_size: file.size,
          source: "aws",
        },
      },
    })
      .then((res) => {
        addImage(res.data.createFile);
        setFileUploads();
      })
      .catch();
  };
  return (
    <Container id="mockup-design-sidebar">
      <SortableList
        layers={layers}
        selectedLayers={selectedLayers}
        lockAxis="y"
        useDragHandle
        onSortEnd={onSortEnd}
        layerExtra={layerExtra}
        helperClass="draging"
        helperContainer={document.getElementsByClassName("layer-list")[0]}
        dispatch={dispatch}
      />
      <Button.Group
        style={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "repeat(2,auto)",
          borderRadius: 0,
          marginTop: 30,
        }}
      >
        <Upload
          accept=".jpg, .png, .jpeg"
          getWidthHeight={(width, height) => {
            setWidthHeight({ width: width, height: height });
          }}
          onUpload={(file) => {
            handleChange(file);
          }}
          onChange={(file) => {
            setFileUploads(file);
            const key = 'uploading_photos';
            setTimeout(() => {
              message.success({ content: 'Uploading image successfully!', key, duration: 2 });
            }, 1000);
            }
          }
          showUploadList={false}
        >
          <Button style={{ width: "100%" }} loading={fileUpload}>
            {" "}
            Add Image
          </Button>
        </Upload>
        <Dropdown
          placement="bottomCenter"
          arrow
          overlay={
            <Menu
              onClick={({ key }) => {
                addPrintare(printAreas.find((prt) => prt.id === key));
              }}
            >
              {printAreas.map((printArea) => (
                <Menu.Item key={printArea.id}>{printArea.name}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button style={{ borderRadius: 0 }}>Add Printarea</Button>
        </Dropdown>
      </Button.Group>
      <LayerSettings />
    </Container>
  );
};
export default connect((state) => ({
  mockupWorkspace: state.mockup.mockupWorkspace,
}))(Layers);
