import React, { Component } from "react";
import gql from "../../api/gql";
import { message, Form, Input, Button, Card } from "antd";
import * as _ from "lodash";

class ChangePassword extends Component {
  state = {
    loading: false,
    currentPassword: "",
  };

  changePassword(id, current_password, new_password) {
    this.setState({ loading: true });
    const mutation = `mutation changePassword($id:String! $current_password: String, $new_password: String!){
      changePassword(id: $id current_password: $current_password new_password: $new_password )
      }`;
    gql
      .request(mutation, { id, current_password, new_password })
      .then((res) => {
        this.setState({ loading: false });
        message["success"]("Your password updated");
      })
      .catch((err) => {
        this.setState({ loading: false });
        message["error"](_.get(err, "[0].message"));
      });
  }

  handleConfirmBlur = (e) => {
    const { values } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!values });
  };

  compareToFirstPassword = (rule, values, callback) => {
    const { form } = this.props;
    if (values && values !== form.getFieldValue("new_password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  onSubmit = (values) => {
    const { currentUser } = this.props;
    if (currentUser.id) {
      this.changePassword(
        currentUser.id,
        values.current_password,
        values.new_password
      );
    }
  };

  render() {
    return (
      <Form layout={"vertical"} hideRequiredMark onFinish={this.onSubmit}>
        <Card>
          <Form.Item
            label="Current password"
            name="current_password"
            initialValue={""}
            rules={[
              { required: true, message: "Please enter current password" },
            ]}
          >
            <Input.Password placeholder="************" />
          </Form.Item>
          <Form.Item
            label="New password"
            name="new_password"
            initialValue={""}
            rules={[
              { required: true, message: "Please enter confirm password" },
            ]}
          >
            <Input.Password placeholder="************" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["new_password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password placeholder="************" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Card>
      </Form>
    );
  }
}

export default ChangePassword;
