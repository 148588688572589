import { useMutation } from "@apollo/client";
import { message, Progress } from "antd";
import React, { useState } from "react";
import { CREATE_FILE } from "../../graphql/mutation/createFile";
import Upload from "../upload/Upload";

const UploadImage = (props) => {
  const {
    dragger = true,
    onChange = () => {},
    accept = "image/*",
    multiple = true,
    setFileUploads,
    fileUpload,
  } = props;
  const [createFile] = useMutation(CREATE_FILE);

  const handleChange = (files) => {
    Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            createFile({
              variables: {
                input: {
                  key: file.key,
                  file_name: file.name,
                  file_mime: file.type,
                  file_size: file.size,
                  source: "aws",
                },
              },
            })
              .then((res) => {
                resolve(res.data.createFile);
              })
              .catch((err) => reject(err));
          })
      )
    )
      .then((files) => {
        onChange(files);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  return (
    <div>
      <Upload
        fileList={fileUpload}
        multiple={multiple}
        onChange={(file) => {
          const key = 'uploading_photos';
          setTimeout(() => {
            message.success({ content: 'Uploading image successfully!', key, duration: 2 });
          }, 1000);
          setFileUploads(file)
        }}
        onUpload={(file) => {
          handleChange(file);
          // setFileUploads([]);
        }}
        dragger={dragger}
        accept={accept}
        showUploadList={false}
      >
        {props.children}
        {/* {fileUpload
          ? fileUpload.map((fileUpload) => (
              <Progress
                percent={Math.round(fileUpload.percent)}
                type="circle"
                size="small"
                width={50}
              />
            ))
          : props.children} */}
      </Upload>
    </div>
  );
};

export default UploadImage;
