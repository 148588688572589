import React, { useState, useEffect } from "react";
import MarkdownPreviewer from "../MarkdownPreviewer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";
export default function AssistantMessage({
    children,
    onRepeat,
    dataMessage
}) {
  return (
    <div className="message-item-control-group">
      <div className="message-item-content">
        <MarkdownPreviewer>
          {children ? children : ""}
        </MarkdownPreviewer>
      </div>
      <div className="message-item-control-assistant">
        {!dataMessage.type && 
          <button onClick={onRepeat} className="btn-control-chat">
              <i className="fa-solid fa-repeat"></i>
          </button>
        }        
        <CopyToClipboard
            text={children ? children : ""}
            onCopy={() => {
              message.info('Copy successful!');
            }}
        >
            <button className="btn-control-chat">
                <i className="fa-solid fa-copy"></i>
            </button>
        </CopyToClipboard>
      </div>
    </div>
  );
}
