import React from "react";
import { Row, Col } from "antd";
import gql from "../../api/gql";
import S3Image from "../S3Image";
import styled from "styled-components";
import { minBy, maxBy } from "lodash";
import { CheckOutlined } from "@ant-design/icons";
const Container = styled.div`
  position: relative;
  .anticon {
    color: #fff;
    position: absolute;
    font-size: 20px;
    top: -5px;
    right: -5px;
    padding: 4px;
    border-radius: 50%;
    background-color: #52c41a;
  }
`;

export default class ProductTypeSelector extends React.Component {
  state = {
    productTypes: [],
    loading: true,
    categories: [],
    selectedItems: [],
    filter: {
      status: true,
      limit: 1000,
    },
    category: null,
    current: 0,
  };
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  componentDidMount() {
    this.loadProductType();
  }
  loadProductType() {
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        count,
        hits{
          id,
          title
          sku
          images
          categories{
            id, title
          }
          attributes{
            name
            slug
            options
          }
          product_type_variants{
            id
            product_type_id
            sku
            base_cost
            sale_cost
            retail_cost
            attributes{
              name
              slug
              option
            }
          }
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, { filter: this.state.filter })
      .then((res) => {
        this.setState({
          productTypes: res.productTypes.hits || [],
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  toggleImage = (type) => {
    var { selectedItems } = this.state;
    const { isSingleSelect } = this.props;
    if (selectedItems.length) {
      if (type.categories[0].id !== selectedItems[0].categories[0].id) {
        return false;
      }
    }
    //Check if exists
    if (selectedItems.find((item) => item.id === type.id)) {
      //Exists, remove item
      selectedItems = selectedItems.filter((item) => item.id !== type.id);
    } else {
      if (isSingleSelect) {
        selectedItems = [];
      }
      selectedItems.push(type);
    }
    this.setState({ selectedItems }, () => {
      this.props.onSelect(selectedItems);
    });
  };

  render() {
    const { productTypes, selectedItems } = this.state;
    var products = [];
    productTypes.forEach((t) => {
      t.categories.forEach((cat) => {
        t.product_type_variants = t.product_type_variants.filter(
          (v) => v.base_cost
        );
        if (t.product_type_variants.length) {
          products[cat.id] = products[cat.id] || [];
          products[cat.id].push(t);
        }
      });
    });
    products = products.filter((type) => type);
    return (
      <div>
        {products.map((group, index) => {
          return (
            <Row
              key={index}
              style={{ marginBottom: 25 }}
              gutter={20}
              type="flex"
            >
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <h3 style={{ lineHeight: "40px" }}>
                      {group[0].categories[0].title}
                    </h3>
                  </Col>
                </Row>
              </Col>
              {group.map((type) => {
                var minCost = minBy(type.product_type_variants, "base_cost");
                var maxCost = maxBy(type.product_type_variants, "base_cost");
                return (
                  <Col
                    key={type.id}
                    span={6}
                    onClick={() => this.toggleImage(type)}
                  >
                    <Container
                      style={{
                        cursor: "pointer",
                        background: "#f5f5f5",
                        marginBottom: 20,
                        padding: 10,
                        borderRadius: 3,
                        boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.2)",
                        opacity:
                          selectedItems.length === 0 ||
                          selectedItems.find(
                            (item) =>
                              item.categories[0].id === type.categories[0].id
                          )
                            ? "1"
                            : "0.3",
                      }}
                    >
                      {selectedItems.filter((item) => item.id === type.id)
                        .length ? (
                        <CheckOutlined />
                      ) : null}
                      <S3Image src={type.images} style={{ maxWidth: "100%" }} />
                      <h4 style={{ marginTop: 10, marginBottom: 0 }}>
                        {type.title}
                      </h4>
                      <div>
                        Base Cost:{" "}
                        {minCost.base_cost === maxCost.base_cost
                          ? `$${maxCost.base_cost}`
                          : `$${minCost.base_cost} - $${maxCost.base_cost}`}
                      </div>
                    </Container>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}
