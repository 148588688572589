import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Card, Image, message, Modal, Popconfirm, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import { CREATEMOCKUP } from "../../graphql/mutation/mokups/create";
import campaignMockups from "../../graphql/queries/mockup/campaignMockups";
import Grid from "../Grid";
import MockupDesign from "../mockup/MockupDesign";
import UploadFileMockup from "../mockup/UploadFileMockup";
import DELETEMOCKUP from "../../graphql/mutation/mokups/delete";
const Container = styled.div`
  .delete-icon:hover {
    color: red;
  }
`;
const CustomerMockup = (props) => {
  const { productType, newId, closeId, setNumberCustomer } = props;
  // const { data: dataClone } = useQuery(campaignMockups, {
  //   variables: {
  //     productTypeIDs: productType.map((item) => item.product_type_id),
  //     productID: closeId,
  //   },
  //   skip: !closeId,
  // });

  const { data, loading, refetch } = useQuery(campaignMockups, {
    variables: {
      productTypeIDs: productType.map((item) => item.product_type_id),
      productID: newId,
    },
  });
  const [loadingMockups, setLoadingMockups] = useState(false);
  const [createMockup, { loading: createLoading }] = useMutation(CREATEMOCKUP);
  const [deleteCampainMockup] = useMutation(DELETEMOCKUP);
  const [mockup, setMockup] = useState(null);
  const [showEditMockup, setShowEditMockup] = useState(false);
  // useEffect(() => {
  //   console.log(dataClone);
  // }, [dataClone]);

  useEffect(() => {
    if (data) {
      setNumberCustomer(data.mockups.length);
    }
  }, [data]);

  var result = productType.map((item) => {
    return {
      id: item.product_type_id,
      title: item.title,
      mockups: data?.mockups.filter(
        (mk) => mk.productType.id === item.product_type_id
      ),
    };
  });
  if (loading) return <Skeleton />;
  return (
    <Container>
      {result.map((item, index) => (
        <div style={{ marginBottom: 10 }} key={index}>
          <h3>{item.title}</h3>
          <Grid width={120} gap={24} key={index}>
            {item?.mockups.map((mk) => (
              <Card
                key={mk.id}
                size="small"
                style={{ height: 120 }}
                cover={
                  <div>
                    <Image
                      src={`${CLOUDFRONT_URL}100x100/${mk.preview}`}
                      preview={{
                        src: `${CLOUDFRONT_URL}autoxauto/${mk.preview}`,
                      }}
                      style={{ objectFit: "contain", padding: 1 }}
                      fallback="/no-preview.jpg"
                      height={120}
                      width="100%"
                    />
                    <Space style={{ position: "absolute", top: 5, right: 5 }}>
                      <EditOutlined
                        className="edit-icon"
                        onClick={() => {
                          setMockup(mk);
                          setShowEditMockup(true);
                        }}
                      />
                      <Popconfirm
                        title="Delete this mockup customer?"
                        okButtonProps={{
                          danger: true,
                        }}
                        onConfirm={() => {
                          deleteCampainMockup({
                            variables: {
                              id: mk.id,
                            },
                          })
                            .then(() => {
                              message.success("Mockup deleted");
                              refetch();
                            })
                            .catch((err) => {
                              message.error(err.message);
                            });
                        }}
                      >
                        <DeleteOutlined className="delete-icon" />
                      </Popconfirm>
                    </Space>
                  </div>
                }
                bodyStyle={{ display: "none" }}
              />
            ))}
            <UploadFileMockup
              accept=".jpg,.jpeg,.png,.psd"
              onChange={(file) => {
                setLoadingMockups(item.id);
                createMockup({
                  variables: {
                    fileID: file.id,
                    productTypeID: item.id,
                    isProductBaseMockup: false,
                    productID: newId,
                  },
                })
                  .then((res) => {
                    setMockup(res.data.createMockup);
                    refetch();
                    setShowEditMockup(true);
                    setLoadingMockups(false);
                  })
                  .catch((err) => {
                    message.error(err.message);
                  });
              }}
            >
              {createLoading && loadingMockups === item.id ? (
                <LoadingOutlined
                  style={{ fontSize: 40, color: "#52c41a" }}
                  spin
                />
              ) : (
                <div style={{ padding: "25px 0" }}>
                  <PlusOutlined />
                </div>
              )}
            </UploadFileMockup>
          </Grid>
        </div>
      ))}

      <Modal
        visible={showEditMockup}
        title="Edit Mockup"
        width={"100vw"}
        footer={null}
        maskClosable={false}
        bodyStyle={{ padding: "15px 0 0", maxHeight: "100vh" }}
        onCancel={() => setShowEditMockup(false)}
        className="modal-fixed"
        key={mockup?.id}
      >
        {mockup && (
          <MockupDesign
            productType={mockup.productType}
            productID={newId}
            id={mockup.id}
            onFinish={() => {
              refetch();
              setShowEditMockup(false);
              setMockup(null);
            }}
            onCancel={() => {
              setShowEditMockup(false);
              setMockup(null);
            }}
          />
        )}
      </Modal>
    </Container>
  );
};

export default CustomerMockup;
