import React from "react";
import {
  Table,
  Button,
  Popconfirm,
  Select,
  Input,
  AutoComplete,
  Form,
} from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import styled from "styled-components";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
const toSlug = (text) => {
  if (!text) {
    return "";
  }
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

const SortTable = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`;

let dragingIndex = -1;
const optionsName = [
  { value: "Size" },
  { value: "Color" },
  { value: "Style" },
  { value: "Title" },
  { value: "Material" },
];
const validateAttribute = () => {
  window.alert("Attribute name/slug is unique. Please input again!");
};
class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

export default class DragSortingTable extends React.Component {
  state = {
    data: this.props.value,
  };

  handleDelete = (index) => {
    const { data } = this.state;
    this.setState({ data: data.filter((item, i) => i !== index) }, () =>
      this.props.onChange(this.state.data)
    );
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  footer = () => {
    return (
      <Button
        icon={<PlusCircleOutlined />}
        onClick={(e) => {
          e.preventDefault();
          const { data } = this.state;
          this.setState(
            {
              data: [...data, { name: "", slug: "", options: [] }],
            },
            () => {
              this.props.onChange(this.state.data);
            }
          );
        }}
        type="primary"
      >
        Add
      </Button>
    );
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
      () => {
        this.props.onChange(this.state.data);
      }
    );
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (text, record, index) => (
          <div style={{ width: "100%", position: "relative" }}>
            <Form.Item
              validateStatus={text === "" ? "error" : ""}
              style={{ marginBottom: 0 }}
            >
              <AutoComplete
                value={text}
                onBlur={(e) => {
                  e.preventDefault();
                  const { data } = this.state;
                  const attributesName = data.map((item) =>
                    item.name.toLowerCase()
                  );
                  const isDuplicate = attributesName.some(
                    (item, idx) => attributesName.indexOf(item) !== idx
                  );
                  if (isDuplicate) {
                    validateAttribute();
                  }
                }}
                onChange={(e) => {
                  const { data } = this.state;
                  data[index].name = e;
                  data[index].slug = toSlug(e);
                  this.setState({ data }, () =>
                    this.props.onChange(this.state.data)
                  );
                }}
                placeholder="Name"
              >
                {_.xor(
                  optionsName.map((el) => el.value),
                  this.state.data.map((item) => item.name)
                )
                  .filter((i) => i !== "")
                  .map((option) => (
                    <AutoComplete.Option key={option} value={option}>
                      {option}
                    </AutoComplete.Option>
                  ))}
              </AutoComplete>
            </Form.Item>
            <div>
              {text === "" ? (
                <small
                  style={{
                    position: "absolute",
                    bottom: -15,
                    color: "red",
                  }}
                >
                  Please input name
                </small>
              ) : null}
            </div>
            {/* <Input
            value={text}
            onChange={(e) => {
              const { data } = this.state;
              data[index].name = e.target.value;
              data[index].slug=toSlug(e.target.value);
              this.setState({ data }, () =>
                this.props.onChange(this.state.data)
              );
            }}
          /> */}
          </div>
        ),
      },
      {
        title: "Slug",
        dataIndex: "slug",
        key: "slug",
        width: 150,
        render: (text, record, index) => (
          <div style={{ width: "100%", position: "relative" }}>
            <Form.Item
              validateStatus={text === "" ? "error" : ""}
              style={{ marginBottom: 0 }}
            >
              <Input
                value={text}
                onChange={(e) => {
                  const { data } = this.state;
                  data[index].slug = e.target.value;
                  this.setState({ data }, () =>
                    this.props.onChange(this.state.data)
                  );
                }}
              />
            </Form.Item>
            <div>
              {text === "" ? (
                <small
                  style={{
                    position: "absolute",
                    bottom: -15,
                    color: "red",
                  }}
                >
                  Please input slug
                </small>
              ) : null}
            </div>
          </div>
        ),
      },
      {
        title: "Options",
        dataIndex: "options",
        render: (options, row, index) => (
          <div style={{ width: "100%", position: "relative" }}>
            <Form.Item
              validateStatus={options.length === 0 ? "error" : ""}
              style={{ marginBottom: 0 }}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                value={options}
                onChange={(value) => {
                  const { data } = this.state;
                  data[index].options = value;
                  this.setState({ data }, () =>
                    this.props.onChange(this.state.data)
                  );
                }}
                dropdownStyle={{ display: "none" }}
                tokenSeparators={[","]}
              ></Select>
            </Form.Item>
            <div>
              {options.length === 0 ? (
                <small
                  style={{
                    position: "absolute",
                    bottom: -15,
                    color: "red",
                  }}
                >
                  Please input options
                </small>
              ) : null}
            </div>
          </div>
        ),
      },
      {
        title: "",
        width: 50,
        render: (_, row, i) =>
          this.state.data.filter.length >= 1 ? (
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => this.handleDelete(i)}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          ) : null,
      },
    ];
    return (
      <div className="attribute">
        <SortTable>
          <DndProvider backend={HTML5Backend}>
            <Table
              footer={this.footer}
              columns={columns}
              dataSource={this.state.data}
              components={this.components}
              onRow={(row, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              rowKey={(row, index) => index}
              pagination={false}
            />
          </DndProvider>
        </SortTable>
      </div>
    );
  }
}
