import React, { useEffect, useState } from "react";
import SelectModel from "../SelectModel";
import { Input, Form, Button, Row, Col } from "antd";
import ChatPreview from "../ChatPreview";
const { TextArea } = Input;
export default function ChatQA({
  loading,
  onChangeLoading
}) {
  const [form] = Form.useForm();
  const [dataRequest, setDataRequest] = useState({});
  const getModel = localStorage.getItem("localStorageModel");
  const [model, setModel] = useState(getModel ? getModel : "gpt-4o-mini");
  const [prompt, setPrompt] = useState("");
  const [promptReply, setpromptReply] = useState("");
  useEffect(() => {
    if (promptReply) {
      form.setFieldsValue({
        prompt: promptReply,
      });
    }
  }, [promptReply]);

  useEffect(() => {
    localStorage.setItem("localStorageModel", model);
  }, [model]);

  const handleReplyEditUser = (text) => {
    setpromptReply(text);
  };

  useEffect(() => {
    setPrompt("");
  }, [dataRequest]);

  const handleChangeLoading = (v) => {
    onChangeLoading(v);
  };

  const onFinishFailed = () => {};
  const onFinishChatQA = (v) => {
    let dataRequest = v;
    dataRequest.model = model;
    dataRequest.type = "chatgpt";
    setDataRequest(dataRequest);
    form.resetFields();
  };
  return (
    <Row>
      <Col
        span={12}
        style={{
          position: "relative",
        }}
      >
        <Form
          disabled={loading}
          form={form}
          layout="vertical"
          onFinish={onFinishChatQA}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <SelectModel value={model} onSelect={(v) => setModel(v)} />
          <Form.Item
            name="prompt"
            label="Prompt"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Generation
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col
          span={12}
          style={{
            position: "relative",
          }}
        >
          <ChatPreview
            typeChat="chatqa"
            model={model}
            loading={loading}
            dataRequest={dataRequest}
            onChangeLoading={handleChangeLoading}
            onReplyEdit={handleReplyEditUser}
          />
        </Col>
    </Row>
  );
}
