import gql from "graphql-tag";

export const CREATE_ARTWORK_CATEGORY = gql`
  mutation createArtworkCategory($parentID: String, $title: String!) {
    category: createArtworkCategory(parentID: $parentID, title: $title) {
      id
      title
      key: id
      parentID
      numberOfArtworks
    }
  }
`;
export const UPDATE_ARTWORK_CATEGORY = gql`
  mutation updateArtworkCategory(
    $parentID: String
    $title: String!
    $id: String!
  ) {
    category: updateArtworkCategory(
      id: $id
      parentID: $parentID
      title: $title
    ) {
      id
      title
      parentID
      key: id
      numberOfArtworks
    }
  }
`;

export const DELETE_ARTWORK_CATEGORY = gql`
  mutation deleteArtworkCategory($id: String!) {
    deleteArtworkCategory(id: $id)
  }
`;
export const DUPLICATE_ARTWORK_CATEGORY = gql`
  mutation duplicateArtworkCategory($id: String!) {
    duplicateArtworkCategory(id: $id) {
      id
      title
      key: id
      parentID
      numberOfArtworks
    }
  }
`;
