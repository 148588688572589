import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { Form, Input, notification, Button } from "antd";
import _ from "lodash";
import gql from "../../api/gql";

const Container = styled.div`
  padding: 25px;
  min-width: 350px;
  margin: auto;
  text-align: center;
`;
class LoginPage extends Component {
  state = {
    loading: false,
    redirectToLogin: false,
  };

  handleSubmit = (values) => {
    const match = this.props.location.search;
    this.setState({
      loading: true,
    });
    const query = `mutation resetPasswordByToken($token: String!,$pass: String!){
          resetPasswordByToken(token: $token, pass: $pass)
        }`;
    gql
      .request(query, { token: match.slice(1), pass: values.pass })
      .then((user) => {
        this.setState({ redirectToLogin: true });
      })
      .catch((err) => {
        const msg = _.get(err, "[0].message", "Register error");
        if (msg.search(/email_UNIQUE/)) {
          notification["error"]({
            message: _.get(err, "[0].message"),
          });
        } else {
          notification["error"]({
            message: msg,
          });
        }
        this.setState({ loading: false });
      });
  };

  handleConfirmBlur = (e) => {
    const { values } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!values });
  };

  compareToFirstPassword = (rule, values, callback) => {
    const { form } = this.props;
    if (values && values !== form.getFieldValue("pass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to="/login" />;
    }
    return (
      <div layout="register">
        <Container className={"register-container"}>
          <img
            src="/logo.png"
            style={{ width: 100, marginBottom: 40 }}
            alt="OOPPRINT"
          />
          <h1 style={{ color: "#41566B", fontSize: 28, textAlign: "center" }}>
            Forgot Password
          </h1>
          <h5 style={{ color: "#41566B", fontSize: 20, textAlign: "center" }}>
            Enter your new password
          </h5>
          <Form
            onFinish={this.handleSubmit}
            className="register-form"
            style={{ textAlign: "initial" }}
            layout="horizontal"
          >
            <Form.Item
              label={"Password"}
              name="pass"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>
            <Form.Item
              label={"Confirm password"}
              name="confirm"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                  validator: this.compareToFirstPassword,
                },
              ]}
            >
              <Input.Password
                placeholder="Confirm password"
                onBlur={this.handleConfirmBlur}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button btn-primary"
                loading={this.state.loading}
                style={{ height: 40, width: "100%" }}
              >
                Proceed
              </Button>
            </Form.Item>
          </Form>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentUser } = state.app;
  return { currentUser };
}

export default connect(mapStateToProps)(LoginPage);
