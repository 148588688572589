import React, { useState, useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import ChatQA from "./components/FormChat/ChatQA";
import ChatPreview from "./components/ChatPreview";
import ChatProductTitle from "./components/FormChat/ChatProductTitle";

export default function AiChatGPT() {
  
  const [loading, setLoading] = useState(false);

  
  
  

  

  

  const handleChangeLoading = (v) => {
    setLoading(v);
  };

  
  return (
    <>
      <Row>
        <Col
          span={24}
          style={{
            position: "relative",
          }}
        >
          <Tabs defaultActiveKey="item-1">
            <Tabs.TabPane tab="Chat Q&A" key="item-1">
              <ChatQA
                loading={loading}
                onChangeLoading={handleChangeLoading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Generator Product" key="item-2">
              <ChatProductTitle
                loading={loading}
                onChangeLoading={handleChangeLoading}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
        {/* <Col
          span={12}
          style={{
            position: "relative",
          }}
        >
          <ChatPreview
            model={model}
            loading={loading}
            dataRequest={dataRequest}
            onChangeLoading={handleChangeLoading}
            onReplyEdit={handleReplyEditUser}
          />
        </Col> */}
      </Row>
    </>
  );
}
