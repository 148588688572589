import {
  Avatar,
  Button,
  Image,
  Modal,
  PageHeader,
  Skeleton,
  Table,
  notification
} from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import ArtworkAction from "./ArtworkAction";
import moment from "moment";
import useArtWorkNew from "./ArtWorkNew";
import { useQuery } from "@apollo/client";
import { ARTWORKS } from "../../graphql/queries/artworks";
import { ARTWORK_CATEGORY } from "../../graphql/queries/artworkCategory";
import ImportFromPSD from "./ImportFromPSD";
import { sum } from "lodash";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import { CLOUDFRONT_URL } from "../../config";
import FilterSearch from "./FilterSearch";
import { EyeOutlined } from "@ant-design/icons";
import ModalPreviewimage from "../ModalPreviewimage";
import { history } from "../../history";
// import gql from "../../api/gql";
import { connect } from "react-redux";
const Container = styled.div`
  border-left: 0.1rem solid rgb(223, 227, 232);
  .lock-position {
    position: absolute;
    top: 9px;
    left: 8px;
    cursor: pointer;
    font-size: larger;
  }
`;
const ArtworkList = (props) => {
  const { categoryID, setCategoryID, currentUser } = props;
  const seller = currentUser?.roles.find((role) => role.name === "Seller");
  const [filter, setFilter] = useState({
    search: null,
    page: 1,
    pageSize: 20,
    userID: null,
    categoryID: null,
  });
  // console.log(categoryID);
  const { data, loading, refetch } = useQuery(ARTWORKS, {
    variables: {
      ...filter,
      userID: seller ? currentUser.id : null,
      categoryID: categoryID ? categoryID : null,
    },
    fetchPolicy: "no-cache",
  });
  // console.log(data);

  const [showAddArtwork, setShowAddArtwork] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [formAddArtwork, { loading: createLoading }] = useArtWorkNew({
    categoryID,
  });
  const { data: dataCategory } = useQuery(ARTWORK_CATEGORY, {
    variables: categoryID ? { ID: categoryID } : {},
  });

  // useEffect(() => {
  //   if (categoryID) {
  //     setFilter({
  //       ...filter,
  //       page: 1,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categoryID, filter]);
  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 100,
      render: (id) => (
        <div>
          <Button title={'Tap to copy id '+ id} onClick={()=> {
            const coppy = navigator.clipboard.writeText(id);
            if(coppy){
              notification.success({
                message: "Successfully copied ID " + id,
              });
            }
          }} type="text">{id}</Button>
        </div>
      )
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "title",
      width: 200,
      render: (name, record) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "90px calc(100% - 90px)",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div className="cp-image">
            <Image
              style={{ backgroundColor: "#f5f5f5", objectFit: "contain" }}
              width="100px"
              height="100px"
              src={`${CLOUDFRONT_URL}100/${record.templates[0].preview}`}
              preview={false}
              // preview={{
              //   src: `${CLOUDFRONT_URL}auto/${record.templates[0].preview}`,
              // }}
              fallback={`http://haiphongtours.vn/wp-content/uploads/2016/10/no-image-available.jpg`}
            />
            {record.templates[0].preview ? (
              <div
                className="cp-prewview-image"
                onClick={() => {
                  setPreviewImage([record.templates[0].preview]);
                }}
              >
                <EyeOutlined style={{ marginRight: 5 }} />
                Preview
              </div>
            ) : null}
          </div>

          {/* <div className="lock-position">
            <ArtworkAction
              refetch={refetch}
              record={record}
              lockAction={true}
            />
          </div> */}
          <b style={{ marginLeft: 10 }}>
            <Link to={`/artworks/${record.id}/design`}>{name}</Link>
          </b>
        </div>
      ),
    },
    {
      title: "Author",
      key: "author",
      dataIndex: "author",
      render: (author) => (
        <div>
          <Avatar>
            {author?.first_name.charAt(0)}
            {author?.last_name.charAt(0)}
          </Avatar>{" "}
          {author?.first_name} {author?.last_name}
        </div>
      ),
      width: 130,
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "createdAt",
      render: (date) => moment(date).format("MMM DD, YYYY"),
      width: 120,
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      align: "right",
      render: (record) => <ArtworkAction record={record} refetch={refetch} currentUser={currentUser} />,
    },
  ];
  const pagination = {
    current: filter.page,
    pageSize: filter.pageSize,
    total: data?.artworks?.count,
    onChange: (page, pageSize) => {
      setFilter({ ...filter, page, pageSize });
    },
    showTotal: (total, range, a) =>
      `${range[0]} to ${range[1]} of ${total} items`,
  };

  const tableWidth = sum(columns.map((c) => c.width));
  return (
    <Container className="p-15-24 p-artworks">
      <div>
        <PageHeader
          title={
            dataCategory && categoryID
              ? dataCategory?.artworkCategory?.title
              : "All Artworks"
          }
          style={{ padding: 0, marginBottom: 7 }}
          onBack={() => {
            history.goBack();
          }}
          extra={
            <div className="flex">
              <ImportFromPSD categoryID={categoryID} />
              <Button
                onClick={() => setShowAddArtwork(true)}
                type="primary"
                style={{ marginLeft: 5 }}
              >
                Add New
              </Button>
            </div>
          }
        />
      </div>
      <FilterSearch
        filter={filter}
        setFilter={setFilter}
        categoryID={categoryID}
        setCategoryID={setCategoryID}
      />
      {/* <CustomizeSearch filter={filter} setFilter={setFilter} categoryID={categoryID} /> */}
      {loading ? (
        <Skeleton />
      ) : (
        <Scrollbars
          autoHeight
          autoHeightMax={"calc(100vh - 230px)"}
          style={{
            marginTop: 24,
            // width: "100%",
            // height: "calc ( 100% - 300px)",
          }}
        >
          <Table
            scroll={{ x: tableWidth }}
            pagination={pagination}
            columns={columns}
            dataSource={data?.artworks?.hits}
            rowKey={(record) => record.id}
          />
        </Scrollbars>
      )}
      <Modal
        forceRender
        getContainer={false}
        killOnClose={false}
        title="Add new artwork"
        visible={showAddArtwork}
        onCancel={() => setShowAddArtwork(false)}
        footer={
          <div>
            <Button onClick={() => setShowAddArtwork(false)}>Cancel</Button>
            <Button
              loading={createLoading}
              type="primary"
              htmlType="submit"
              form="formAddArtwork"
            >
              Save
            </Button>
          </div>
        }
      >
        {formAddArtwork}
      </Modal>
      {previewImage && (
        <Modal
          width={800}
          title="Preview Image"
          visible={previewImage ? true : false}
          onCancel={() => setPreviewImage(false)}
          footer={false}
        >
          <ModalPreviewimage keys={previewImage} />
        </Modal>
      )}
    </Container>
  );
};

export default connect((state) => {
  return {
    currentUser: state.app.currentUser,
  };
})(ArtworkList);
