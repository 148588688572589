import {
  SET_ORDERS_FILTER,
  RESET_ORDERS_FILTER,
  SET_ORDER_ID,
  RESET_ORDER_ID,
} from "../types";

const initState = {
  filter: {
    site_ids: null,
    product_type_ids: null,
    seller_ids: null,
    from: null,
    to: null,
    search: null,
    field: "all",
  },
  order_id: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_ORDERS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case RESET_ORDERS_FILTER:
      return {
        ...state,
        filter: initState.filter,
      };
    case SET_ORDER_ID:
      return {
        ...state,
        order_id: action.payload,
      };
    case RESET_ORDER_ID:
      return {
        ...state,
        order_id: initState.order_id,
      };
    default:
      return state;
  }
};
