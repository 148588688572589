import React, { useState } from "react";
import gql from "../../../../../api/gql";
import _ from "lodash";
import { Button, Drawer, Form, Input, message } from "antd";
const AddCategory = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    const { title } = values;
    const mutation = `mutation createBannerCategoryAdd($title: String!){
  	createBannerCategory(title: $title){
            id
        }
    }    
    `;
    gql
      .request(mutation, {
        title,
      })
      .then((res) => {
        onChange();
        form.resetFields();
        onClose();
      })
      .catch((err) => {
        message.error('Something went wrong, please try again!');
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <Button onClick={showDrawer} style={{ marginLeft: 5 }} type="primary">
        Add new category
      </Button>
      <Drawer
        title="Add new category"
        placement="left"
        closable={false}
        onClose={onClose}
        visible={open}
      >
        <Form
          layout="vertical"
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input title!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddCategory;
