import Upload from "./Upload";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_FILE } from "../../graphql/mutation/createFile";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import { message } from 'antd';

const CreateFile = (props) => {
  const {
    accept,
    onChange = () => {},
    loading,
    loadingButon,
    file_key,
  } = props;
  const [createFile] = useMutation(CREATE_FILE);
  const [fileUpload, setFileUploads] = useState();
  const handleChange = (file) => {
    createFile({
      variables: {
        input: {
          key: file.key,
          file_name: file.name,
          file_mime: file.type,
          file_size: file.size,
          source: "aws",
        },
      },
    })
      .then((res) => {
        onChange(res.data.createFile);
        setTimeout(() => {
          setFileUploads();
        }, 1000);
      })
      .catch((err) => {
        notification.error({
          message: _.get(err, "[0].message"),
        });
      });
    const key = 'uploading_photos';
    setTimeout(() => {
      message.success({ content: 'Uploading image successfully!', key, duration: 2 });
    }, 1000);
  };
  return (
    <Upload
      file_key={file_key}
      accept={accept}
      onChange={(file) => setFileUploads(file)}
      onUpload={(file) => handleChange(file)}
      showUploadList={false}
    >
      {fileUpload && loadingButon ? <LoadingOutlined /> : ""}
      {fileUpload && loading ? (
        // <Progress
        //   percent={Math.round(fileUpload.percent)}
        //   type="circle"
        //   size="small"
        //   width={10}
        // />
        // <LoadingOutlined />
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "#dfdfdf",
            zIndex: 20,
            position: "absolute",
            opacity: 0.5,
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 22,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <LoadingOutlined />
          </div>
        </div>
      ) : null}
      {props.children}
    </Upload>
  );
};

export default CreateFile;
