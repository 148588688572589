import { Query } from "@apollo/client/react/components";
import React, { useState } from "react";
import MOCKUPQUERY from "../../graphql/queries/mockup/mockup";
import { connect, useDispatch } from "react-redux";
import { cloneDeep, omit } from "lodash";
import { Button, message, Skeleton, Space } from "antd";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import MainDesign from "./MainDesign";
import Zoom from "../Konva/Zoom";
import { MOCKUP } from "../../types";
import UPDATEMOCKUP from "../../graphql/mutation/mokups/update";
import LeftToolbar from "./LeftToolbar";
import { useMutation } from "@apollo/client";
const Container = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  background: #fff;
  margin: 0 15px;
  gap: 0px;
`;

const MokupDesignType = ({
  id,
  onFinish = () => {},
  onCancel = () => {},
  mockupWorkspace,
  campaignView,
  productType,
  productID,
}) => {
  const { mockup, mockupsManage } = mockupWorkspace;
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(1);
  const [updateMockup] = useMutation(UPDATEMOCKUP);
  const saveMockup = async (e) => {
    await Promise.all(
      mockupsManage.map((item, index) => {
        var mockupItem = item;
        if (mockupItem.id === mockup.id.toString()) {
          mockupItem = mockup;
        }
        return new Promise((resolve, reject) => {
          updateMockup({
            variables: {
              id: mockupItem.id,
              title: mockupItem.title,
              preview: mockupItem.preview,
              productTypeID: mockupItem.productType.id,
              layers: mockupItem.layers,
              ordering: index,
              width: mockupItem.width,
              height: mockupItem.height,
              variantIds: mockupWorkspace.variant_ids,
              settings: omit(mockupItem.settings, ["__typename"]),
              productID: productID ? productID : null,
            },
          })
            .then((res) => {
              resolve(res.data.updateMockup);
            })
            .catch((err) => reject(err));
        });
      })
    )
      .then(() => {
        onFinish();
      })
      .catch((err) => {
        onFinish();
        message.error(err.message);
      });
  };
  return (
    <div>
      <Query
        query={MOCKUPQUERY}
        fetchPolicy="network-only"
        variables={{ id }}
        onCompleted={(data) => {
          dispatch({
            type: MOCKUP.SET,
            payload: { ...cloneDeep(data.mockup) },
          });
          if (!campaignView) {
            dispatch({
              type: MOCKUP.SET_MOCKUPS,
              payload: [{ ...cloneDeep(data.mockup) }],
            });
          }
          dispatch({
            type: MOCKUP.SET_VARIANT_IDS,
            payload: data.mockup.variantIds ? data.mockup.variantIds : [],
          });
        }}
      >
        {({ data, loading, error, refetch: refetchMockup }) => {
          if (loading) {
            return <Skeleton />;
          }
          if (data) {
            return (
              <Container>
                <div
                  style={{ borderRight: "1px solid #f5f5f5", paddingRight: 15 }}
                >
                  <Scrollbars autoHeight autoHeightMax={`calc(100vh - 74px)`}>
                    <LeftToolbar productType={productType} />
                  </Scrollbars>
                </div>
                <div
                  className="abc"
                  style={{
                    display: "grid",
                    gridTemplateRows: "auto 66px",
                    height: `calc(100vh -  74px)`,
                  }}
                >
                  {/* <TopToolbar /> */}
                  <div
                    style={{
                      overflow: "auto",
                      backgroundColor: "rgb(237, 240, 242)",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 30,
                      paddingBottom: 30,
                      position: "relative",
                    }}
                  >
                    <MainDesign zoom={zoom} key={mockup?.id} />
                    <Zoom
                      value={zoom}
                      onChange={setZoom}
                      style={{ left: 380, bottom: 80 }}
                    />
                  </div>
                  <div className="flex item-center space-between">
                    <div></div>
                    <Space className="align-right">
                      <Button
                        onClick={() => {
                          onCancel();
                          refetchMockup();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" onClick={saveMockup}>
                        Save
                      </Button>
                    </Space>
                  </div>
                </div>
              </Container>
            );
          }
        }}
      </Query>
    </div>
  );
};

export default connect((state) => ({
  mockupWorkspace: state.mockup.mockupWorkspace,
}))(MokupDesignType);
