import React from "react";
import gql from "../../api/gql";
import {
  notification,
  Row,
  Col,
  Form,
  Button,
  Input,
  Card,
  Skeleton,
  Tabs,
  Table,
  Avatar,
  InputNumber,
  Switch,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import {
  DownOutlined,
  DollarOutlined,
  PictureOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import _, { orderBy } from "lodash";
import Wysiwyg from "../../components/wysiwyg";
import ProductImages from "../../components/products/EditProductImages";
import { SaveOutlined } from "@ant-design/icons";
import TagsSelect from "../../components/products/TagsSelect";
import ColectionSelect from "../../components/products/ColectionSelect";
import styled from "styled-components";
import DraggableTabs from "../../components/products/DraggableTabs";
import { CLOUDFRONT_URL } from "../../config";
import CurrencyFormat from "react-currency-format";
import { history } from "../../history";
import ProductPrintFile from "../../components/products/ProductPrintFile";
import ImagesProduct from "../../components/products/ImagesProduct";
import { CgToggleOff, CgToggleOn } from "react-icons/cg";
import UploadImages from "../../components/UploadImages";
import update from "immutability-helper";
import { DraggableBodyRow } from "../../components/products/DraggableRow";
const Container = styled.div`
  #seconddiv.coolclass {
    max-height: 100%;
    background: purple;
  }
`;
const { TabPane } = Tabs;

class EditProduct extends React.Component {
  state = {
    loading: false,
    product: null,
    productTypes: [],
    selectedVariations: [],
    variations: [],
    selectedVariants: [],
    editVariantsImage: false,
    excludeMockups: [],
    loadingRenderMockup: false,
    images: [],
    selectedImage: null,
    bulkPrices: {
      retail_cost: null,
      sale_cost: null,
    },
    editVariantsPrice: false,
  };
  components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { selectedVariations } = this.state;

    const dragRow = selectedVariations[dragIndex];

    this.setState(
      update(this.state, {
        selectedVariations: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      })
    );
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const query = `query productById($id: String!){productByID(id: $id){
      id
      title
      description
      images
      variants{
        id
        image
        design_status
        sku
        regular_price
        price
        image
        active
        product_type_id
        product_type{
          id
          title
          attributes{
            name
            slug
            options
          }
        }
        product_type_variant{
          id
          sku
          attributes {
            name
            slug
            option
          }
          retail_cost
          base_cost
          sale_cost
          is_active
          print_files{
            key
            width
            height
          }
        }
      }
      product_stores{
        id
        site_id
        origin_id
        permalink
      }
      tags {
        id
        tag{
          id
          title
          description
        }
        sorting
      }
      collections{
        id
      collection{
        id
        title
        description
        }
      sorting
      }
      description
      
      print_areas{
        id
        product_type_id
        name
        product_type_print_area_id
        artwork_id
        artwork{
          id
          templates
          {
            title
            preview
          }
        }
      }
      mockups{
        id
        mockupId
        productId
        variantIds
        productTypeVariantIds
        image
        sorting
        allDone
      }
      excludeMockups

    }}`;
    gql
      .request(query, { id })
      .then((res) => {
        let productVariant = res.productByID.variants.map(
          (variant) => variant.product_type
        );
        let productTypes = [];
        productVariant.map((item) => {
          if (
            _.includes(
              productTypes.map((item) => item.id),
              item.id
            )
          ) {
          } else {
            productTypes.push(item);
          }
        });
        let images = [];
        res.productByID.mockups.map((mockup) => {
          if (mockup.mockupId.length <= 0) {
            images.push({
              mockup_id: mockup.id,
              image: mockup.image,
              type: "create",
            });
          } else {
            if (
              _.includes(
                images.map((item) => item.type),
                mockup.mockupId
              )
            ) {
              images = images.map((image) => {
                if (image.type === mockup.mockupId) {
                  return {
                    mockup_id: mockup.id,
                    image: mockup.image,
                    type: mockup.mockupId,
                    productTypeVariantIds: mockup.productTypeVariantIds,
                  };
                } else {
                  return image;
                }
              });
            } else {
              images.push({
                mockup_id: mockup.id,
                image: mockup.image,
                type: mockup.mockupId,
                productTypeVariantIds: mockup.productTypeVariantIds,
              });
            }
          }
          return mockup;
        });
        let variantsMew = res.productByID.variants.map((variant) => {
          let image = images.find((image) =>
            _.includes(
              image.productTypeVariantIds,
              variant.product_type_variant.id
            )
          );
          return {
            ...variant,
            image: image ? image : "",
          };
        });
        this.setState({
          product: res.productByID || {},
          productTypes: productTypes,
          variations: variantsMew,
          selectedVariants: variantsMew.filter(
            (item) => item.product_type_id === productTypes[0].id
          ),
          selectedVariations: variantsMew.filter(
            (item) => item.product_type_id === productTypes[0].id
          ),
          excludeMockups: res.productByID.excludeMockups,
          printAreas: productTypes.map((type) => ({
            product_type_id: type.id,
            title: type.title,
            printAreas: res.productByID.print_areas
              .filter((item) => item.product_type_id === type.id)
              .map((item) => ({
                ...item,
                id: item.product_type_print_area_id,
                preview: item.artwork.templates[0].preview,
              })),
          })),
          images: images.map((item) => ({
            mockup_id: item.mockup_id,
            image: item.image,
            type: item.type,
          })),
        });
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  }

  updateProduct = (values) => {
    const {
      product,
      variations,
      printAreas,
      excludeMockups,
      productTypes,
      selectedImage,
    } = this.state;
    this.setState({ loading: true });
    let print_areas = [];
    printAreas.forEach((element) => {
      element.printAreas.forEach((item) => {
        print_areas = [
          ...print_areas,
          {
            product_type_print_area_id: item.id,
            name: item.name,
            product_type_id: element.product_type_id,
            artwork_id: item.artwork_id,
          },
        ];
      });
    });
    const query = `mutation updateProduct($input: EditProduct!){
      updateProduct(input: $input){
        id
      }
    }`;
    gql
      .request(query, {
        input: {
          id: product.id,
          title: product.title,
          collection_ids: values.collection_ids?.map((collection, index) => {
            return { entity_id: collection, sorting: index };
          }),
          variants: variations.map((item, index) => {
            return {
              id: item.id,
              product_type_id: item.product_type_id,
              product_type_variant_id: item.id,
              regular_price: item.regular_price,
              price: item.price,
              active: item.active,
              sorting: index,
              mockup_id: item.image?.mockup_id,
            };
          }),
          print_areas: print_areas,
          product_type_ids: productTypes.map((item) => item.id),
          // site_id: currentSite.id,

          tag_ids: values.tag_ids?.map((tag, index) => {
            return { entity_id: tag, sorting: index };
          }),
          excludeMockups: excludeMockups,
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        notification["success"]({
          message: `Product: ${product.title} has been updated`,
        });
        this.props.history.push("/products/pushing");
      })
      .catch((err) => {
        this.setState({ loading: false });
        notification["error"]({
          message: _.get(err, "errors[0].message"),
        });
      });
  };

  onChangeImage = (images) => {
    this.setState({ images });
  };

  render() {
    const {
      product,
      loading,
      productTypes,
      selectedVariations,
      variations,
      selectedVariants,
      excludeMockups,
      loadingRenderMockup,
      editVariantsImage,
      selectedImage,
      images,
      bulkPrices,
      editVariantsPrice,
    } = this.state;
    const { id } = this.props.match.params;
    const columns = [
      {
        title: "Image",
        dataIndex: "id",
        key: "image",
        render: (id, variant) => (
          <Avatar
            shape="square"
            icon={<PictureOutlined />}
            size={50}
            style={{ cursor: "pointer" }}
            // src={variant.image ? variant.image.url : null}
            src={
              variant.image
                ? `${CLOUDFRONT_URL}/100/${variant.image.image}`
                : null
            }
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                selectedVariants: [id],
                editVariantsImage: true,
              });
            }}
          />
        ),
      },
      {
        title: "Variant",
        dataIndex: "sku",
        key: "sku",
        render: (sku, { product_type_variant }) => {
          return (
            <div>
              {product_type_variant.attributes
                ?.map((attr) => `${attr.name}: ${attr.option}`)
                .join(", ")}{" "}
              <br />
              <span style={{ color: "#999" }}>SKU: {sku}</span>
            </div>
          );
        },
      },
      {
        title: "Base Cost",
        dataIndex: "base_cost",
        key: "base_cost",
        render: (base_cost, { product_type_variant }) =>
          `$${product_type_variant.base_cost}`,
        align: "center",
      },
      {
        title: "Regular Price",
        dataIndex: "regular_price",
        key: "retail_cost",
        render: (retail_cost, { id, product_type_variant, retail }) => (
          <Form.Item
          // validateStatus={retail ? "error" : null}
          // help={retail ? "Default regular price" : null}
          >
            <InputNumber
              formatter={(value) => `$${value}`}
              min={product_type_variant.base_cost}
              value={retail_cost}
              onChange={(v) => {
                const { variations } = this.state;
                variations.find((v) => v.id === id).regular_price = v;
                this.setState({
                  variations: variations,
                });
              }}
            />
          </Form.Item>
        ),
      },
      {
        title: "Sale Price",
        dataIndex: "price",
        key: "price",
        render: (sale_cost, { product_type_variant, id, sale }) => (
          <Form.Item
          // validateStatus={sale ? "error" : null}
          // help={sale ? "Default sale price" : null}
          >
            <InputNumber
              formatter={(value) => `$${value}`}
              min={0}
              value={sale_cost ? sale_cost : 0}
              onChange={(v) => {
                const { variations } = this.state;
                variations.find((v) => v.id === id).price = v;
                this.setState({
                  variations: variations,
                });
              }}
            />
          </Form.Item>
        ),
      },
      {
        title: "Profit",
        dataIndex: "id",
        key: "profit",
        render: (id, { price, regular_price, product_type_variant }) => (
          <CurrencyFormat
            value={
              (price && price > 0 ? price : regular_price) -
              product_type_variant.base_cost
            }
            displayType="text"
            prefix={"$"}
            decimalScale={2}
          />
        ),
      },
      {
        title: "status",
        dataIndex: "active",
        key: "active",
        render: (active, { id }) => (
          <div>
            <Switch
              checked={active}
              onChange={(e) => {
                const { variations, selectedVariations } = this.state;
                variations.find((v) => v.id === id).active = e;
                selectedVariations.find((v) => v.id === id).active = e;
                this.setState({
                  variations: variations,
                  selectedVariations: selectedVariations,
                });
              }}
            ></Switch>
          </div>
        ),
      },
    ];
    const changeStatus = (status) => {
      let NewVariantions = variations.map((item) => {
        if (_.includes(selectedVariants, item.id)) {
          return { ...item, active: status };
        } else {
          return item;
        }
      });
      let newSelectedVariations = selectedVariations.map((item) => {
        if (_.includes(selectedVariants, item.id)) {
          return { ...item, active: status };
        } else {
          return item;
        }
      });
      this.setState({
        variations: NewVariantions,
        selectedVariants: [],
        selectedVariations: newSelectedVariations,
      });
    };
    if (loading) {
      return <Skeleton />;
    }
    return (
      <Container>
        {product ? (
          <Form onFinish={this.updateProduct} layout="vertical">
            <Row gutter={20}>
              <Col span={24} xl={16}>
                <Card title="Detail">
                  <Form.Item
                    name="title"
                    label="Title"
                    initialValue={product ? product.title : null}
                    rules={[
                      {
                        required: true,
                        message: "Please input product title!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  {/* <Form.Item label="Permalink" name="">
                    <Input />
                  </Form.Item> */}
                  <Form.Item
                    name="collection_ids"
                    label="Colections"
                    initialValue={
                      product
                        ? product.collections.map((item) => item.collection.id)
                        : []
                    }
                  >
                    <ColectionSelect />
                  </Form.Item>
                  <Form.Item
                    name="tag_ids"
                    label="Tags"
                    initialValue={
                      product ? product.tags.map((item) => item.tag.id) : null
                    }
                  >
                    <TagsSelect />
                  </Form.Item>
                  <Form.Item
                    label="Decription"
                    name="description"
                    initialValue={product ? product.description : null}
                  >
                    <Wysiwyg />
                  </Form.Item>
                  {/* <Form.Item>
                <Card title="Images">
                  <ProductImages images={product.images} />
                </Card>
              </Form.Item> */}
                </Card>
                <DraggableTabs
                  style={{ marginTop: 20 }}
                  defaultActiveKey={`${productTypes[0].id}`}
                  onChange={(e) => {
                    this.setState({
                      selectedVariants: [],
                      productTypeIndex: e,
                      selectedVariations: variations.filter(
                        (v) => v.product_type_id === e
                      ),
                    });
                  }}
                  // onChangeTabs={([dragKey, hoverKey]) => {
                  //   this.setState({
                  //     productTypes: productTypes.map((type) =>
                  //       type.id === dragKey
                  //         ? productTypes.find((pt) => pt.id === hoverKey)
                  //         : type.id === hoverKey
                  //         ? productTypes.find((pt) => pt.id === dragKey)
                  //         : type
                  //     ),
                  //   });
                  // }}
                  tabBarStyle={{
                    display: productTypes.length === 1 ? "none" : "block",
                  }}
                >
                  {productTypes.map((productType) => (
                    <TabPane tab={productType.title} key={productType.id}>
                      <Card
                        title="Variants"
                        extra={
                          <Dropdown
                            placement="bottomRight"
                            disabled={selectedVariants.length === 0}
                            overlay={
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    const {
                                      selectedVariants,
                                      selectedVariations,
                                    } = this.state;
                                    if (selectedVariants.length === 1) {
                                      let v = selectedVariations.find(
                                        (v) => v.id === selectedVariants[0]
                                      );
                                      this.setState(
                                        {
                                          bulkPrices: {
                                            retail_cost: v.retail_cost,
                                            sale_cost: v.sale_cost,
                                          },
                                        },
                                        () =>
                                          this.setState({
                                            editVariantsPrice: true,
                                          })
                                      );
                                    } else {
                                      this.setState(
                                        {
                                          bulkPrices: {
                                            retail_cost: null,
                                            sale_cost: null,
                                          },
                                        },
                                        () =>
                                          this.setState({
                                            editVariantsPrice: true,
                                          })
                                      );
                                    }
                                  }}
                                >
                                  <DollarOutlined /> Price
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() =>
                                    this.setState({ editVariantsImage: true })
                                  }
                                >
                                  <PictureOutlined /> Image
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    changeStatus(true);
                                  }}
                                >
                                  <CgToggleOn /> Enable
                                </Menu.Item>
                                <Menu.Item onClick={() => changeStatus(false)}>
                                  <CgToggleOff /> Disable
                                </Menu.Item>
                                {/* <Menu.Item
                                onClick={() => {
                                  const {
                                    selectedVariants,
                                    selectedVariations,
                                  } = this.state;
                                  this.setState({
                                    selectedVariations: selectedVariations.filter(
                                      (v) => !selectedVariants.includes(v.id)
                                    ),
                                    variations: variations.filter(
                                      (v) => !selectedVariants.includes(v.id)
                                    ),
                                    selectedVariants: [],
                                  });
                                }}
                              >
                                <DeleteOutlined style={{ color: "red" }} />{" "}
                                Remove
                              </Menu.Item> */}
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              href="/#"
                              onClick={(e) => e.preventDefault()}
                            >
                              <EditOutlined theme="twoTone" /> Edit{" "}
                              <DownOutlined />
                            </a>
                          </Dropdown>
                        }
                      >
                        <DndProvider backend={HTML5Backend}>
                          <Table
                            size="middle"
                            rowSelection={{
                              selectedRowKeys: selectedVariants,
                              onChange: (selectedVariants) =>
                                this.setState({ selectedVariants }),
                              selections:
                                selectedVariations &&
                                _.concat(
                                  ...selectedVariations.map(
                                    (item) =>
                                      item.product_type_variant.attributes
                                  )
                                ).reduce((b, c) => {
                                  if (
                                    b.filter(
                                      (item) =>
                                        item.option === c.option &&
                                        item.name === c.name
                                    ).length === 0
                                  ) {
                                    b.push({
                                      key: `${c.name} : ${c.option}`,
                                      option: c.option,
                                      name: c.name,
                                      text: `${c.name} : ${c.option}`,
                                      onSelect: (changableRowKeys) => {
                                        let newSelectedRowKeys = [];
                                        newSelectedRowKeys = selectedVariations.filter(
                                          (key) =>
                                            key.product_type_variant.attributes.filter(
                                              (item) =>
                                                item.option === c.option &&
                                                item.name === c.name
                                            ).length > 0
                                        );
                                        this.setState({
                                          selectedVariants: newSelectedRowKeys.map(
                                            (el) => el.id
                                          ),
                                        });
                                      },
                                    });
                                  }
                                  return orderBy(
                                    b,
                                    ["name", "option"],
                                    ["asc", "asc"]
                                  );
                                }, []),
                            }}
                            onRow={(record, index) => ({
                              index,
                              moveRow: this.moveRow,
                            })}
                            components={this.components}
                            dataSource={
                              selectedVariations && selectedVariations.length
                                ? selectedVariations
                                : variations.filter(
                                    (v) => v.product_type_id === productType.id
                                  )
                            }
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                          />
                        </DndProvider>
                      </Card>
                    </TabPane>
                  ))}
                </DraggableTabs>
              </Col>
              <Col span={24} lg={8}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{}}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    disabled={
                      this.state.printAreas.find((item) => {
                        return item.printAreas.find((i) => !i.artwork_id);
                      }) ||
                      images.length === 0 ||
                      loadingRenderMockup
                    }
                  >
                    Submit
                  </Button>
                </div>
                <ProductPrintFile
                  excludeMockups={excludeMockups}
                  changeExcludeMockups={(value) => {
                    this.setState({ excludeMockups: value });
                  }}
                  images={images}
                  selectedVariations={selectedVariations}
                  setSelectedVariations={(value) => {
                    this.setState({ selectedVariations: value });
                  }}
                  variations={variations}
                  setvariations={(variations) => {
                    this.setState({ variations });
                  }}
                  subImages={(files) => {
                    this.onChangeImage(files);
                  }}
                  printAreas={this.state.printAreas}
                  setPrintAreas={(printAreas) => {
                    this.setState({ printAreas });
                  }}
                  newId={id}
                  loadingRenderMockup={loadingRenderMockup}
                  setLoadingRenderMockup={(value) =>
                    this.setState({ loadingRenderMockup: value })
                  }
                />
                <Card title="Images" style={{ marginTop: 20 }}>
                  <Form.Item>
                    <ImagesProduct
                      id={id}
                      value={images}
                      onChange={(files) => {
                        this.onChangeImage(files);
                      }}
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <Button
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    disabled={
                      this.state.printAreas.find((item) => {
                        return item.printAreas.find((i) => !i.artwork_id);
                      }) ||
                      images.length === 0 ||
                      loadingRenderMockup
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        ) : null}
        <Modal
          title="Select variant image"
          visible={editVariantsImage}
          onCancel={() => this.setState({ editVariantsImage: false })}
          okButtonProps={{ disabled: selectedImage === null }}
          onOk={() => {
            const {
              selectedImage,
              selectedVariants,
              variations,
              selectedVariations,
            } = this.state;
            variations.forEach((variant) => {
              if (selectedVariants.includes(variant.id)) {
                variant.image = selectedImage;
              }
            });
            selectedVariations.forEach((variant) => {
              if (selectedVariants.includes(variant.id)) {
                variant.image = selectedImage;
              }
            });

            this.setState({
              variations,
              selectedImage: null,
              editVariantsImage: false,
              selectedVariations,
              selectedVariants: [],
            });
          }}
        >
          <UploadImages
            selectMode={true}
            value={images}
            onChange={this.onChangeImage}
            onSelect={(image) => this.setState({ selectedImage: image })}
            showButtonUpload={this.state.showButtonUpload}
            accept=".jpg, .png, .jpeg"
          />
        </Modal>
        <Modal
          title="Update variants price"
          visible={editVariantsPrice}
          onCancel={() => this.setState({ editVariantsPrice: false })}
          onOk={(e) => {
            e.preventDefault();
            const { variations, selectedVariants } = this.state;
            if (bulkPrices.retail_cost) {
              variations.forEach((v) => {
                if (selectedVariants.includes(v.id)) {
                  v.retail = false;
                  v.retail_cost = bulkPrices.retail_cost;
                }
              });
            }
            if (bulkPrices.sale_cost) {
              variations.forEach((v) => {
                if (selectedVariants.includes(v.id)) {
                  v.sale = false;
                  v.sale_cost = bulkPrices.sale_cost;
                }
              });
            }
            this.setState({
              variations,
              editVariantsPrice: false,
              selectedVariants: [],
            });
          }}
        >
          <Form.Item label="Regular Price">
            <InputNumber
              formatter={(value) => `$${value}`}
              value={bulkPrices.retail_cost}
              onChange={(v) =>
                this.setState({
                  bulkPrices: { ...bulkPrices, retail_cost: v },
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Sale Price"
            style={{ display: "grid", gridTemplateColumns: "100px 1px" }}
          >
            <InputNumber
              formatter={(value) => `$${value}`}
              value={bulkPrices.sale_cost}
              onChange={(v) =>
                this.setState({
                  bulkPrices: { ...bulkPrices, sale_cost: v },
                })
              }
            />
          </Form.Item>
        </Modal>
      </Container>
    );
  }
}

export default EditProduct;
