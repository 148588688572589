import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setOrdersFilter, setOrderId } from "../../actions";
import {
  Button,
  Popconfirm,
  Drawer,
  Tabs,
  Badge,
  message,
  Dropdown,
  Menu,
  notification,
  Timeline,
  Image,
  Modal,
  Tag,
} from "antd";
import Table from "../Table";
import moment from "moment";
import gql from "../../api/gql";
import ButtonGroup from "antd/lib/button/button-group";
import Comments from "../orders/Comments";
import _ from "lodash";
import OrdersFilter from "./OrdersFilter";
import OrdersToolbar from "../orders/OrdersToolbar";
import styled from "styled-components";
import qs from "query-string";

import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  RedoOutlined,
  ContactsOutlined,
  DownOutlined,
  EyeOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { CLOUDFRONT_URL } from "../../config";
import { BsCardChecklist, BsFileCheck } from "react-icons/bs";
import CustomerAddress from "../orders/CustomerAddress";
import AcceptDesign from "../orders/AcceptDesign";
import CustomizationNew from "../orders/CustomizationNew";
import DesignOrder from "../orders/DesignOrder";
import ModalPreviewimage from "../ModalPreviewimage";
import SubOrder from "./SubOrder";

const Container = styled.div`
  .order-action {
    display: flex;
    justify-content: space-between;
  }
  .od-filter {
    width: -webkit-fill-available;
  }
  @media only screen and (max-width: 700px) {
    .order-action {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

const PER_PAGE = 20;
export const ORDER_FIELD = `
  id,
  origin_id,
  price,
  base_cost,
  quantity,
  created_at
  status
  design_status
  name
  customer{
    first_name
    last_name
    email
    phone
  }
  site{
    title
    user{
      first_name
      last_name
    }
  }
  billing{
    id
    address1
    address2
    city
    state
    state_code
    zip
    country
  }
  shipping{
    id
    address1
    address2
    city
    state
    state_code
    zip
    country
    country_code
  }
  designs{
    order_id
    base_print_area_id
    artwork_id
    base_print_area_name
    file_id
    file{
      id
      source
      key
      file_name
      file_mime
      file_size
      url
      sku
    }
    status
  }
  product{
    id
    title
    images
    is_custom_design
    mockups{
      id
      variantIds
      image
    }
  }
  variant{
    id
    product_type_id
    design_status
    mockup{
      image
    }
    product_type{
      state
      print_files{
        key
        mockup
        title
        width
        height
        note
        variant_ids
      }
    }
    product_type_variant{
      id
      attributes{
        slug
        option
      }
    }
  }
`;
const checkStatusDesign = (design) => {
  const status = design?.map((item) => item.status);
  if (_.includes(status, "Error")) {
    return {
      color: "error",
      status: "Error",
    };
  }
  if (_.includes(status, "Processing")) {
    return {
      color: "processing",
      status: "Processing",
    };
  }
  if (_.includes(status, "Pending")) {
    return {
      status: "Pending",
      color: "warning",
    };
  }
  return {
    color: "success",
    status: "View",
  };
};
class Orders extends Component {
  state = {
    orders: [],
    aggs: [],
    selectedOrder: null,
    total: 0,
    filter: {
      ...this.props.filter,
      offset: 0,
      limit: PER_PAGE,
      aggs_by: "status",
    },
    page: 1,
    loading: false,
    showUpdateCustomer: false,
    showOrderComments: false,
    showUploadDesign: false,
    orderSelect: [],
    selectCarrier: null,
    tracking_code: "",
    carrier_id: null,
    supplier_id: null,
    showAddTracking: false,
    addingTracking: false,
    carriers: [],
    suppliers: [],
    loadingCarrier: false,
    loadingSupplier: false,
    orderHistory: [],
    showHistory: false,
    showAcceptDesign: false,
    showCustomization: false,
    previewImage: false,
  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        filter: {
          ...this.state.filter,
          ...query,
          product_type_ids: query.product_type_ids
            ? [query.product_type_ids]
            : null,
        },
      },
      () => this.loadOrders()
    );
    // this.loadOrders();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState(
        {
          page: 1,
          filter: {
            ...this.props.filter,
            offset: 0,
            limit: PER_PAGE,
            aggs_by: "status",
          },
        },
        () => this.loadOrders()
      );
    }
  }

  loadOrders() {
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        filter: this.state.filter,
      })
      .then((res) => {
        this.setState({
          orders: res.orders.hits || [],
          aggs: res.orders.aggs || [],
          total: res.orders.count,
          loading: false,
        });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  }

  loadOrderHistory() {
    const query = `query GetOrderHistory($order_id: String!){
      getOrderHistory(order_id: $order_id){
        id
        message
        user{
          id
          first_name
          last_name
        }
        order{
          id
        }
        type
        updated_at
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        order_id: this.state.selectedOrder.id,
      })
      .then((res) => {
        this.setState({
          orderHistory: res.getOrderHistory || [],
          loading: false,
        });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  }

  statusFilter = (status) => {
    const { setOrdersFilter } = this.props;
    setOrdersFilter({
      ...this.state.filter,
      status: status === "all" ? null : [status],
    });
  };
  cancelOrder = (id) => {
    const mutation = `mutation cancelOrder($order_id: String!){
      cancelOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](`Order has been cancel`);
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.cancelOrder;
          } else return el;
        });
        this.setState({ orders, loading: false });
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  };
  enterPayment = (id) => {
    const mutation = `mutation enterPayment($order_id: Int!){
      enterPayment(order_id: $order_id)
    }`;
    gql
      .request(mutation, { order_id: +id })
      .then((res) => {
        message["success"](`Order has been enter payment`);
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  changeSupplier = (orders) => {
    const ordersId = orders.map((el) => el.id);
    const order = this.state.orders.map((el) => {
      if (ordersId.includes(el.id)) {
        return {
          ...el,
          supplier: orders[0].supplier,
          carrier: orders[0].carrier,
        };
      } else return el;
    });
    this.setState({ orders: order, orderSelect: [] });
  };

  deleteOrder = (id) => {
    let query = `mutation deleteOrder{deleteOrder(order_id:${id})}`;
    gql
      .request(query)
      .then(() => {
        notification.success({ message: `Order #${id} has been deleted` });
        this.loadOrders();
      })
      .catch((err) => {
        notification.error({ message: _.get(err, "[0].message") });
      });
  };

  refreshOrder = (id) => {
    const mutation = `mutation RefreshOrder($order_id: String!){
      refreshOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](`Order has been refreshed`);
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.refreshOrder;
          } else return el;
        });
        this.setState({ orders, loading: false });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  render() {
    const {
      orders,
      aggs,
      total,
      filter,
      loading,
      page,
      selectedOrder,
      showUpdateCustomer,
      showOrderComments,
      orderHistory,
      showHistory,
      previewImage,
    } = this.state;
    const { currentUser } = this.props;
    const isAdmin = currentUser.roles.find((role) =>
      ["Administrator"].includes(role.name)
    );
    const columns = [
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
        width: 250,
        render: (id, order) => (
          <div>
            <p>
              <b>App ID: </b>
              {id}
            </p>
            <p>
              {" "}
              <b>Origin Id: </b>
              {`${order.origin_id} `}
            </p>
            <p>
              <b>Origin Number: </b>
              {order.name ? `${order.name}` : null}
            </p>
          </div>
        ),
      },

      {
        title: "Product / Variant",
        dataIndex: "product",
        key: "product",
        width: 300,
        render: (id, order) => {
          // let image = order.product.mockups?.find((item) =>
          //   _.includes(item.variantIds, order.variant.id)
          // );
          let image = order.variant.mockup?.image ? order.variant.mockup : null;
          if (!image) {
            image = order.product.mockups
              ? { image: order.product.mockups[0].image }
              : null;
          }
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Image.PreviewGroup>
                {image ? (
                  <Image
                    style={{
                      backgroundColor: "#f5f5f5",
                      objectFit: "cover",
                      width: 120,
                      height:"auto"
                    }}
                    preview={{
                      src: `${CLOUDFRONT_URL}/autoxauto/${image.image}`,
                    }}
                    src={`${CLOUDFRONT_URL}/100/${image.image}`}
                    fallback={`/no-preview.jpg`}
                  />
                ) : (
                  <Image
                    style={{
                      backgroundColor: "#f5f5f5",
                      objectFit: "contain",
                      height: "auto",
                      width: 120,
                    }}
                    preview={{
                      src: `${CLOUDFRONT_URL}/autoxauto/`,
                    }}
                    src={`${CLOUDFRONT_URL}/100/`}
                    fallback={`/no-preview.jpg`}
                  />
                )}
              </Image.PreviewGroup> */}
              <div className="cp-image" style={{ width: 120 }}>
                <Image
                  style={{
                    backgroundColor: "#f5f5f5",
                    // objectFit: `${image?.image ? "cover" : "contain"}`,
                    objectFit: "contain",
                    height: 120,
                    width: 100,
                  }}
                  preview={false}
                  // preview={{
                  //   src: `${CLOUDFRONT_URL}/autoxauto/${mockup.image}`,
                  // }}
                  src={`${CLOUDFRONT_URL}/100/${image?.image}`}
                  fallback={`/no-preview.jpg`}
                />
                {image ? (
                  <div
                    style={{ height: 120 }}
                    className="cp-prewview-image"
                    onClick={() => {
                      this.setState({ previewImage: [image.image] });
                    }}
                  >
                    <EyeOutlined style={{ marginRight: 5 }} />
                    Preview
                  </div>
                ) : null}
              </div>
              <div style={{ paddingLeft: 10 }}>
                <div>{`${order.product.title}`}</div>
                <div>
                  {order.variant.product_type_variant.attributes &&
                    order.variant.product_type_variant.attributes.map(
                      (a, index) => {
                        return (
                          <Fragment key={index}>
                            <div
                              style={{ marginBottom: 0, fontStyle: "italic" }}
                            >
                              <b style={{ textTransform: "capitalize" }}>
                                {a.slug}
                              </b>
                              : {a.option}{" "}
                            </div>
                          </Fragment>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Designs",
        dataIndex: "order",
        key: "design",
        width: 150,
        render: (_, order) => {
          const designStatus = checkStatusDesign(order.designs);
          return (
            <Tag
              color={designStatus.color}
              icon={
                designStatus.status === "View" ? (
                  <CheckOutlined />
                ) : designStatus.status === "Processing" ? (
                  <SyncOutlined spin />
                ) : designStatus.status === "Error" ? (
                  <CloseCircleOutlined />
                ) : null
              }
              // type="primary"
              // ghost
              style={{
                cursor: "pointer",
                minWidth: 100,
                padding: 5,
                textAlign: "center",
                fontSize: 14,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showAcceptDesign: order.id,
                  selectedOrder: order,
                });
              }}
            >
              {designStatus.status}
            </Tag>
          );
        },
      },

      {
        title: "QTY / COST",
        dataIndex: "base_cost",
        key: "base_cost",
        width: 200,
        render: (id, order) => (
          <div>
            <span>{`${order.quantity} x $${order.base_cost}`}</span>
            <br />
            <span style={{ marginBottom: 0, fontStyle: "italic" }}>
              {moment(order.created_at).format("MMM DD, YYYY")}
            </span>
          </div>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 250,
        render: (customer, order) => (
          <div>
            <span>{`${order.customer.first_name} ${order.customer.last_name}`}</span>
            <br />
            <span style={{ marginBottom: 0, fontStyle: "italic" }}>
              {order.customer.email}
            </span>
          </div>
        ),
      },
      {
        title: "Site",
        dataIndex: "site",
        key: "site",
        width: 160,
        render: (site, order) => (
          <div>
            <p style={{ marginBottom: 0, fontStyle: "italic" }}>
              {order.site.title}
            </p>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status) => {
          const item = orderStatus.find((el) => [status].includes(el.key));
          return (
            item && <span style={{ color: `${item.color}` }}>{item.title}</span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        align: "right",
        fixed: "right",
        width: 120,
        render: (id, order) => (
          <div>
            <ButtonGroup>
              {/* {(isAdmin ||
                ["pending_review", "accepted"].includes(order.status)) && (
                <Fragment>
                  <Popconfirm
                    title={`Are you sure to cancel this order?`}
                    onConfirm={() => this.cancelOrder(id)}
                  >
                    <Button
                      style={{ color: "red", borderRadius: 4, padding: 0 }}
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Popconfirm>
                  <Divider type="vertical" style={{ marginTop: "0.45em" }} />
                </Fragment>
              )} */}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 0,
                        }}
                        icon={<ContactsOutlined />}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showUpdateCustomer: true,
                            selectedOrder: order,
                          });
                        }}
                      >
                        Customer Info
                      </Button>
                    </Menu.Item>
                    {/* <Menu.Item key="2">
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 0,
                        }}
                        icon={<InfoOutlined />}
                        onClick={() =>
                          history.push(`/orders/orders-manager/${id}`)
                        }
                      >
                        Detail
                      </Button>
                    </Menu.Item> */}
                    <Menu.Item key="4">
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 0,
                        }}
                        onClick={() => this.setState({ showAcceptDesign: id })}
                        icon={<BsFileCheck className="anticon" />}
                      >
                        {order.status === "pending_review"
                          ? "Accepted Design"
                          : "View Desings"}
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 0,
                        }}
                        onClick={() => this.setState({ showCustomization: id })}
                        icon={<BsCardChecklist className="anticon" />}
                      >
                        Customization
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="7">
                      <Popconfirm
                        title={`Are you sure to cancel this order?`}
                        onConfirm={() => this.cancelOrder(id)}
                      >
                        <Button
                          type="link"
                          style={{
                            paddingLeft: 0,
                          }}
                          size="small"
                          icon={<CloseOutlined />}
                        >
                          Cancel Order
                        </Button>
                      </Popconfirm>
                    </Menu.Item>

                    {isAdmin && (
                      <Menu.Item key="3">
                        <Button
                          type="link"
                          style={{
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: 0,
                          }}
                          icon={<HistoryOutlined />}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState(
                              {
                                showHistory: true,
                                selectedOrder: order,
                              },
                              () => this.loadOrderHistory()
                            );
                          }}
                        >
                          History
                        </Button>
                      </Menu.Item>
                    )}
                    {isAdmin && (
                      <Menu.Item key="5">
                        <Button
                          type="link"
                          style={{
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: 0,
                          }}
                          icon={<RedoOutlined />}
                          onClick={(e) => {
                            e.preventDefault();
                            this.refreshOrder(order.id);
                          }}
                        >
                          Refresh
                        </Button>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                // style={{ borderRadius: 4, padding: 0 }}
                // size="small"
                // icon={<DashOutlined />}
                >
                  Action <DownOutlined />
                </Button>
              </Dropdown>
            </ButtonGroup>
          </div>
        ),
      },
    ];

    const orderStatus = [
      {
        key: "pending_review",
        title: "Pending Review",
        color: "orange",
      },
      {
        key: "accepted",
        title: "Accepted Design",
        color: "blue",
      },
      {
        key: "exported",
        title: "Exported",
        color: "green",
      },
      {
        key: "rejected",
        title: "Rejected design",
        color: "#f78783",
      },
      {
        key: "cancelled",
        title: "Cancelled",
        color: "#fa5b55",
      },
      {
        key: "error",
        title: "Error",
        color: "red",
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadOrders()
        );
      },
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.setOrderId(selectedRowKeys);
        this.setState({ orderSelect: selectedRows });
      },
    };
    return (
      <Container>
        <div className="order-action">
          <div className="od-filter">
            <OrdersFilter
              {...this.props}
              orders={this.state.orderSelect}
              onCompleted={(data) => this.changeSupplier(data)}
            />
          </div>
          <div>
            <OrdersToolbar onChange={() => this.loadOrders()} />
          </div>
        </div>
        <Tabs
          onChange={this.statusFilter}
          style={{ marginBottom: 0 }}
          activeKey={filter.status ? filter.status[0] : "all"}
        >
          {isAdmin && (
            <Tabs.TabPane
              tab={
                <Badge
                  overflowCount={9999999}
                  count={_.sum(aggs.map((agg) => agg.doc_count))}
                  style={{
                    background: "#fff",
                    color: "#000",
                    border: "1px solid #f5f5f5",
                    right: -20,
                  }}
                >
                  All
                </Badge>
              }
              key="all"
            ></Tabs.TabPane>
          )}
          {orderStatus.map((s) => {
            if (!s.hide) {
              return (
                <Tabs.TabPane
                  key={s.key}
                  tab={
                    <Badge
                      overflowCount={9999999}
                      count={_.sum(
                        aggs
                          .filter((agg) => agg.key === s.key)
                          .map((a) => a.doc_count)
                      )}
                      style={{ backgroundColor: s.color }}
                    >
                      {s.title}
                    </Badge>
                  }
                />
              );
            } else {
              return null;
            }
          })}
        </Tabs>
        <SubOrder
          orders={orders}
          setOrders={(values) => this.setState({ orders: values })}
        />
        <Table
          scroll={{ x: tableWidth }}
          // rowSelection={rowSelection}
          rowSelection={
            filter.status?.includes("accepted") ? rowSelection : false
          }
          columns={columns}
          dataSource={orders}
          pagination={pagination}
          rowKey={(record) => record.id}
          loading={loading}
        />
        {this.state.showAcceptDesign && (
          <Modal
            title={
              <>
                Order Designs <Tag>{this.state.showAcceptDesign}</Tag>
              </>
            }
            width={800}
            visible={this.state.showAcceptDesign}
            onCancel={() => this.setState({ showAcceptDesign: false })}
            footer={false}
          >
            <AcceptDesign
              id={this.state.showAcceptDesign}
              onCancel={() => this.setState({ showAcceptDesign: false })}
              refetchOrders={() => this.loadOrders()}
            />
          </Modal>
        )}
        {this.state.showCustomization && (
          <Modal
            title="Customization"
            visible={this.state.showCustomization}
            onCancel={() => this.setState({ showCustomization: false })}
            footer={false}
          >
            <CustomizationNew
              id={this.state.showCustomization}
              refetch={() => this.loadOrders()}
              onCancel={() => this.setState({ showCustomization: false })}
            />
          </Modal>
        )}
        {this.state.showUploadDesign && (
          <Modal
            title="Upload Design"
            visible={this.state.showUploadDesign}
            onCancel={() => this.setState({ showUploadDesign: false })}
            footer={false}
            width={1000}
          >
            <DesignOrder
              id={this.state.showUploadDesign}
              refetch={() => this.loadOrders()}
            />
          </Modal>
        )}

        <Drawer
          title={`Update customer info #${
            selectedOrder ? selectedOrder.origin_id : ""
          }`}
          onClose={() =>
            this.setState({
              showUpdateCustomer: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showUpdateCustomer}
        >
          {selectedOrder && (
            <CustomerAddress
              onCancel={() => this.setState({ showUpdateCustomer: false })}
              order={selectedOrder}
              refetch={() => {
                this.loadOrders();
              }}
            />
          )}
        </Drawer>
        <Drawer
          title={`Comments #${selectedOrder ? selectedOrder.origin_id : ""}`}
          onClose={() =>
            this.setState({
              showOrderComments: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showOrderComments}
        >
          {selectedOrder && <Comments order_id={selectedOrder.id} />}
        </Drawer>
        {/* <Drawer
          width="800px"
          title="Upload Designs"
          onClose={(e) =>
            this.setState({ selectedOrder: null, showUploadDesign: false })
          }
          visible={showUploadDesign}
        >
          {selectedOrder && selectedOrder.product.is_custom_design !== true && (
            <UploadDesigns
              product_id={selectedOrder.product.id}
              order={selectedOrder}
              variant_type_id={selectedOrder.variant.product_type_variant.id}
              onClose={(e) =>
                this.setState(
                  { selectedOrder: null, showUploadDesign: false },
                  () => this.loadOrders()
                )
              }
            />
          )}
          {selectedOrder && selectedOrder.product.is_custom_design === true && (
            <UploadCustomDesigns
              product_id={selectedOrder.product.id}
              variant_type_id={selectedOrder.variant.product_type_variant.id}
              order={selectedOrder}
              onClose={(e) =>
                this.setState(
                  { selectedOrder: null, showUploadDesign: false },
                  () => this.loadOrders()
                )
              }
            />
          )}
        </Drawer> */}
        <Drawer
          width="800px"
          title={`Order History: ${selectedOrder && selectedOrder.name}`}
          onClose={(e) =>
            this.setState({ selectedOrder: null, showHistory: false })
          }
          visible={showHistory}
        >
          <Timeline>
            {orderHistory.map((his, index) => (
              <Timeline.Item key={index} color="orange">
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "1 1" }}>{his.message}</div>
                  <div className="mr-7">
                    {moment(his.updated_at).format("MMM DD, YYYY h:mm:ss a")}
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        </Drawer>
        {previewImage && (
          <Modal
            width={800}
            title="Preview Image"
            visible={previewImage ? true : false}
            onCancel={() => this.setState({ previewImage: false })}
            footer={false}
          >
            <ModalPreviewimage keys={previewImage} />
          </Modal>
        )}
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    filter: state.orders.filter,
  }),
  { setOrdersFilter, setOrderId }
)(Orders);
