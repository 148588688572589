import React, { useState, useEffect, useRef } from "react";
import { Input, Form, Button, Space } from "antd";
import SelectType from "../components/SelectType";
import SelectAspect from "../components/SelectAspect";
import UploadImage from "../components/UploadImage";
import { URL_API_IMAGE_GENERATION_STREAM } from "../../../../config";

import { SSE } from "../../../../sse";
const { TextArea } = Input;

export default function ImagineGeneration({
  onChange,
  onChangeLoading,
  spinning,
  emitdata,
  serverID,
  timeNext,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(null);
  const [image, setImage] = useState(null);
  const [aspect, setAspect] = useState("1:1");
  const [data, setData] = useState(null);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    setLoading(spinning);
  }, [spinning]);

  useEffect(() => {
    onChangeLoading(loading);
  }, [loading]);

  const handleChangeType = (v) => {
    setType(v);
  };

  const onFinish = (v) => {
    setLoading(true);
    let dataRequest = v;
    setData({ ...emitdata, progress: "0%" });
    dataRequest.action = "imagine";
    dataRequest.serverId = serverID;
    dataRequest.fullPrompt = true;
    dataRequest.aspect = aspect;
    dataRequest.prompt = dataRequest.prompt.replace(/[\[\]\{\}:~`/]/g, "");
    if (image) {
      dataRequest.image = image;
    }
    if (type) {
      dataRequest.type = type;
    }
    var source = new SSE(URL_API_IMAGE_GENERATION_STREAM, {
      method: "POST",
      payload: JSON.stringify(dataRequest),
    });
    window.sourceMidjourney = source;
    source.addEventListener("error", function (e) {
      setLoading(false);
    });

    source.addEventListener("message", function (e) {
      if (!e.data) {
        setLoading(false);
        return;
      }
      const data = JSON.parse(e.data);
      if (data.status == "done") {
        data.progress = "100%";
        data.content = form.getFieldValue("prompt");
        setLoading(false);
      }
      setData(data);
    });
    source.stream();
  };

  const onFinishFailed = () => {};

  const handleChangeFile = (v) => {
    setImage(v);
  };

  const handleCancel = () => {
    if (window.sourceMidjourney) {
      window.sourceMidjourney.close();
    }
    setLoading(false);
    setData({ ...data, progress: "100%" });
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <SelectAspect
          onSelect={(v) => {
            setAspect(v);
          }}
        />

        <Form.Item
          name="prompt"
          label="Prompt"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={5} />
        </Form.Item>

        <Form.Item label="Image">
          <UploadImage onChange={handleChangeFile} />
        </Form.Item>

        <SelectType onSelect={handleChangeType} />

        <Form.Item>
          <Space>
            {/* {loading &&
                    <Button onClick={handleCancel} type="dashed" htmlType="button">
                        Cancel
                    </Button>
                }                 */}
            <Button
              loading={loading || timeNext > 0}
              type="primary"
              htmlType="submit"
            >
              Generation {timeNext > 0 && `${timeNext}s`}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
