import React from "react";
import { Popover, Button, Select } from "antd";
import { MdLineWeight } from "react-icons/md";
const { Option } = Select;

const FontWeightField = ({
    value,
    onChange
}) => {
    const handleChangeSelect = (value) => {
        onChange(value)
    }
    const WeightData = [
        {
            title: "100",
            value: "100"
        },
        {
            title: "200",
            value: "200"
        },
        {
            title: "300",
            value: "300"
        },
        {
            title: "400",
            value: "400"
        },
        {
            title: "500",
            value: "500"
        },
        {
            title: "600",
            value: "600"
        },
        {
            title: "700",
            value: "700"
        },
        {
            title: "800",
            value: "800"
        },
        {
            title: "900",
            value: "900"
        },
        {
            title: "Normal",
            value: "normal"
        },
        {
            title: "Bold",
            value: "bold"
        },
        {
            title: "Bolder",
            value: "bolder"
        },
        {
            title: "lighter",
            value: "lighter"
        },

    ]
    return (
        <Popover
            placement="bottom"
            content={(
                <>
                    <Select
                        defaultValue={value}
                        style={{
                            width: 220,
                        }}
                        onChange={handleChangeSelect}>
                        {WeightData.map((item, index)=> (
                            <Option key={index} value={item.value}>{item.title}</Option>
                        ) )}
                    </Select>
                </>
            )}
        >
            <Button
                type="link"
                icon={
                    <MdLineWeight
                        className="anticon"
                        style={{ fontSize: 20 }}
                    />
                }
            />
        </Popover>
    )
}


export default FontWeightField;