import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import ArtworkCategories from "../../components/artworks/ArtworkCategories";
import ArtworkList from "../../components/artworks/ArtworkList";
import qs from "query-string";
import { get } from "lodash";

const Container = styled.div`
  display: grid;
  // grid-template-columns: 315px calc(100% - 315px);
  position: relative;
  .ant-tree {
    background: transparent !important;
  }
  .ant-tree-show-line .ant-tree-switcher {
    background: transparent !important;
  }
`;
const Artworks = (props) => {
  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const [categoryID, setCategoryID] = useState(get(query, "categoryID", null));

  useEffect(() => {
    setCategoryID(get(query, "categoryID", null));
  }, [query]);

  return (
    <Container style={{ height: "100%" }}>
      {/* <ArtworkCategories
        categoryID={categoryID}
        setCategoryID={setCategoryID}
      /> */}
      <ArtworkList categoryID={categoryID} setCategoryID={setCategoryID} />
    </Container>
  );
};

export default Artworks;
