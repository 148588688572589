import React, { Component } from "react";
import { notification, Skeleton } from "antd";
import gql from "../../api/gql";
import { history } from "../../history";
import _ from "lodash";
import moment from "moment";
import UserForm from "./UserForm";

class UserDetail extends Component {
  state = {
    users: null,
    loading: false,
    roles: [],
  };

  componentDidMount() {
    this.getIdUser();
  }
  getIdUser() {
    this.setState({ loading: false });
    const { id } = this.props;
    if (id) {
      const query = `query userById($id:String!){
        userById(id:$id){
          id 
          last_name
          first_name
          email
          pass
          phone
          birthday
          address
          roles{
            id
            name
          }
          avatar{
            id
            url
          }
          is_subscription
          is_supper_admin
        }
      }`;
      this.setState({ loading: true });
      gql
        .request(query, {
          id: id,
        })
        .then((res) => {
          this.setState({ users: res.userById || {} });
        });
      const query_role = `query roles{roles{id name}}`;

      gql.request(query_role).then((res) => {
        this.setState({
          // roles: res.roles
          roles: res.roles
            ? res.roles.filter((r) => r.name !== "Authenticated")
            : [],
        });
      });
    }
  }
  onSubmit = (value) => {
    value.birthday = value.birthday && moment(value.birthday).format();
    const mutation = `mutation updateUser($id: ID! $input: UpdateUser!){
            updateUser(input: $input, id: $id){
              id
              first_name
              last_name
              email
            }
          }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        input: value,
        id: this.props.id,
      })
      .then((res) => {
        notification["success"]({
          message: "User has been updated",
        });
        this.setState({ loading: false });
        history.push("/admin/users-manager");
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };
  render() {
    const { id } = this.props;
    const { users, loading } = this.state;
    if (id && !users) return <Skeleton />;
    return (
      <UserForm
        users={users}
        loading={loading}
        roles={this.state.roles}
        onSubmitUpdate={(value) => this.onSubmit(value)}
      />
    );
  }
}

export default UserDetail;
