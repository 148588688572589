import React from "react";
import { Row, Col, Button, Tooltip, Input } from "antd";
import gql from "../../api/gql";
import S3Image from "../S3Image";
import styled from "styled-components";
import { history } from "../../history";
import { minBy, maxBy, forEach } from "lodash";
import { CheckCircleTwoTone, DownloadOutlined } from "@ant-design/icons";
import { CLOUDFRONT_URL } from "../../config";
import {get} from "lodash"
const Container = styled.div`
  position: relative;
  .anticon.pod-check {
    position: absolute;
    font-size: 32px;
    top: 0px;
    right: 0px;
    padding: 4px;
    border-radius: 50%;
  }
  .pod-mockup-download {
    position: absolute;
    font-size: 20px;
    bottom: 5px;
    right: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    i {
      color: #fff;
    }
  }
`;

export default class PushStepOne extends React.Component {
  state = {
    productTypes: [],
    loading: true,
    categories: [],
    selectedItems: [],
    filter: {
      status: true,
      limit: 1000,
    },
    category: null,
    current: 0,
  };
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  componentDidMount() {
    this.loadProductType();
  }
  loadProductType() {
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        count,
        hits{
          id,
          title
          sku
          images
          mockup
          categories{
            id, title
          }
          attributes{
            name
          }
          product_type_variants{
            base_cost
          }
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, { filter: this.state.filter })
      .then((res) => {
        this.setState({
          productTypes: res.productTypes.hits || [],
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  toggleImage = (type) => {
    var { selectedItems } = this.state;
    if (selectedItems.length) {
      if(selectedItems.find(e => e.id != type.id)){
        return false;
      }
      if (type.categories[0].id !== selectedItems[0].categories[0].id) {
        return false;
      }
    }
    //Check if exists
    if (selectedItems.find((item) => item.id === type.id)) {
      //Exists, remove item
      selectedItems = selectedItems.filter((item) => item.id !== type.id);
    } else {
      selectedItems = [type]
    }
    this.setState({ selectedItems });
  };
  handleChangeSearch = async (e) => {
    await this.setState({
      filter: { ...this.state.filter, search: e },
    });
    this.loadProductType();
  };
  render() {
    const { productTypes, selectedItems } = this.state;
    var products = {};
    productTypes &&
      productTypes.forEach((t) => {
        t.categories.forEach((cat) => {
          t.product_type_variants = t.product_type_variants?.filter(
            (v) => v.base_cost
          );
          if (t.product_type_variants.length) {
            products[cat.id] = products[cat.id] || [];
            products[cat.id].push(t);
          }
        });
        return products;
      });
    var productByCategory = [];
    forEach(products, (value, key) => {
      productByCategory = [...productByCategory, { key: key, value: value }];
    });
    return (
      <div>
        <Input.Search
            style={{ marginBottom: 10 }}
            placeholder={"search..."}
            onChange={(value) => {
              this.handleChangeSearch(value.target.value);
            }}
        />
        {productByCategory?.map((group, index) => {
          return (
            <Row
              key={index}
              // style={{ marginBottom: 25 }}
              gutter={20}
              type="flex"
            >
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <h2 style={{ lineHeight: "40px" }}>
                      {group.value[0].categories[0].title}
                      {selectedItems.length &&
                      selectedItems.find(
                        (item) => item.categories[0].id === group.key
                      ) ? (
                        <Button
                          style={{ marginLeft: 15 }}
                          type="primary"
                          onClick={() => {
                            history.push(
                              `/products/add/step2/${selectedItems
                                .map((item) => item.id)
                                .join(",")}`
                            );
                          }}
                        >
                          Continue
                        </Button>
                      ) : null}
                    </h2>
                  </Col>
                </Row>
              </Col>
              {group.value.map((type) => {
                var minCost = minBy(type.product_type_variants, "base_cost");
                var maxCost = maxBy(type.product_type_variants, "base_cost");
                return (
                  <Col
                    key={type.id}
                    span={12}
                    lg={6}
                    onClick={() => this.toggleImage(type)}
                    style={{ marginBottom: 25 }}
                  >
                    <Container
                      style={{
                        cursor: "pointer",
                        background: "#f5f5f5",
                        marginBottom: 20,
                        padding: 10,
                        borderRadius: 3,
                        height: "100%",
                        paddingBottom: "40px",
                        boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.2)",
                        opacity:
                          selectedItems.length === 0 ||
                          selectedItems.find(
                            (item) =>
                              item.categories[0].id === type.categories[0].id && item.id == type.id
                          )
                            ? "1"
                            : "0.3",
                      }}
                    >
                      {selectedItems.filter((item) => item.id === type.id)
                        .length ? (
                        <CheckCircleTwoTone
                          twoToneColor="#52c41a"
                          className="pod-check"
                        />
                      ) : // <CheckCircleOutlined className="pod-check" />
                      null}
                      <S3Image
                        src={type.images}
                        size="500xauto"
                        style={{ width: "100%" }}
                      />
                      <div style={{ position: "absolute", bottom: 10 }}>
                        <h4 style={{ marginTop: 10, marginBottom: 0 }}>
                          {type.title}
                        </h4>
                        Base Cost:{" "}
                        {minCost.base_cost === maxCost.base_cost
                          ? `$${maxCost.base_cost}`
                          : `$${minCost.base_cost} - $${maxCost.base_cost}`}
                      </div>
                      <div className="pod-mockup-download">
                        <a
                          href={
                            type.mockup
                              ? `${CLOUDFRONT_URL}/auto/${type.mockup}`
                              : type.images
                              ? `${CLOUDFRONT_URL}/auto/${type.images[0]}`
                              : `${CLOUDFRONT_URL}/auto/`
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                          title="Download Mockup"
                        >
                          <Tooltip placement="topRight" title="Download Mockup">
                            <DownloadOutlined />
                          </Tooltip>
                        </a>
                      </div>
                    </Container>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}
