import gql from "graphql-tag";

export const RETRY_PRODUCT = gql`
  mutation retryPushCampaignStore($id:ID!) {
    retryPushCampaignStore(id: $id) 
  }
`;

export const DELETE_cAMPAIGN_STORE = gql`
  mutation deleteCampaignStore($id:ID!) {
    deleteCampaignStore(id: $id) 
  }
`;