import React, { useEffect, useMemo, useState } from "react";
import { Query } from "@apollo/client/react/components";
import ARTWORKQUERY from "../../graphql/queries/artwork/artwork";
import { connect, useDispatch } from "react-redux";
import { ARTWORK } from "../../types";
import { cloneDeep, get, max, omit } from "lodash";
import UseArtworkForm from "../hooks/ArtworkForm";
import UPDATEARTWORK from "../../graphql/mutation/artwork/updateArtwork";
import { Button, Modal, notification, PageHeader, Skeleton, Space } from "antd";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import Zoom from "../Konva/Zoom";
import { EditOutlined } from "@ant-design/icons";
import Konva from "../Konva/Konva";
import { useMutation } from "@apollo/client";
import LeftToolbar from "../Konva/LeftToolbar";
import TemplateName from "../Konva/Form/TemplateName";
import TopToolbar from "../Konva/TopToolbar";
import { history } from "../../history";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  overflow: hidden;
  .main-content {
    background: #ffffff;
    order: 0;
    // display: grid;
    // grid-template-columns: 100%;
    // grid-template-rows: 50px 30px auto;
    .header-content {
      display: flex;
      justify-content: space-between;
      padding: 6px 6px;
      border-bottom: 1px solid #ddd;
    }
  }
  .left-toolbar {
    order: 1;
    height: calc(100vh - 50px);
  }
`;

const Design = (props) => {
  const { id } = props.match.params;
  const { workspace } = props;
  const { artwork, selectedLayers } = workspace;
  const [showSetting, setShowSetting] = useState(false);
  const [showTemplateName, setShowTemplateName] = useState(null);
  const [artworkForm, { form, showAddCategory }] = UseArtworkForm({ artwork });
  const dispatch = useDispatch();
  useEffect(() => {
    if (showAddCategory !== null) {
      setShowSetting(!showAddCategory);
    }
  }, [showAddCategory]);

  const [zoom, setZoom] = useState(1);
  const ratio = useMemo(() => {
    var r = max([artwork.width, artwork.height]);
    var res = 10;
    for (; res > 1; res--) {
      if (r * res <= 10000) {
        break;
      }
    }
    return res / 10;
  }, [artwork]);

  useEffect(() => {
    //Did mount
    // dispatch({
    //   type: ARTWORK.SET,
    //   payload: {},
    // });
    return () => {
      //Unmount
      dispatch({
        type: ARTWORK.SET,
        payload: {},
      });
    };
  }, []);

  const handleQueryCompleted = (data) => {
    dispatch({
      type: ARTWORK.SET,
      payload: cloneDeep(data.artwork),
    });
    dispatch({
      type: ARTWORK.SET_SELECTED_TEMPLATE,
      payload: 0,
    });
    dispatch({
      type: ARTWORK.SET_SELECTED_LAYERS,
      payload: [],
    });
  };
  const [updateArtwork] = useMutation(UPDATEARTWORK);
  const [uploadLoading, setLoading] = useState(false);
  const saveArtwork = async (e) => {
    setLoading(true);
    updateArtwork({
      variables: {
        id: artwork.id,
        input: {
          title: artwork.title,
          categoryIds: artwork.categories.map((cat) => cat.id),
          width: artwork.width,
          height: artwork.height,
          templates: artwork.templates.map((template, index) => ({
            ...omit(template, ["__typename"]),
          })),
          sharedLayers: artwork.sharedLayers,
          templateDisplayMode: artwork.templateDisplayMode,
          templateDisplayLabel: artwork.templateDisplayLabel,
        },
      },
    })
      .then(() => {
        setLoading(false);
        notification.success({ message: "Artwork updated" });
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.message });
      });
  };

  return (
    <div>
      <title>Edit Artwork {artwork.title}</title>
      {id && (
        <Query
          query={ARTWORKQUERY}
          variables={{ id: id }}
          onCompleted={handleQueryCompleted}
        >
          {({ data, loading, error }) => {
            // if (error) {
            //   return <ErrorPage statusCode={404} />;
            // }
            if (loading) {
              return <Skeleton />;
            }
            if (data) {
              return (
                <Container>
                  <div className="left-toolbar">
                    <Scrollbars
                      style={{ width: "100%", height: "calc(100vh - 50px)" }}
                    >
                      <LeftToolbar />
                    </Scrollbars>
                  </div>
                  <div className="main-content">
                    <div className="header-content">
                      <PageHeader
                        className="artwork-page-header"
                        title={
                          <>
                            {artwork.title}{" "}
                            <Button
                              type="link"
                              icon={<EditOutlined />}
                              onClick={() => {
                                setShowSetting(true);
                              }}
                              disabled={data.artwork.lock}
                            />
                          </>
                        }
                        style={{ padding: 0, justifyContent: "center" }}
                        // onBack={() => router.back()}
                      />
                      <Space>
                        <Button
                          type="link"
                          // onClick={() => router.push("/artworks", "/artworks")}
                          onClick={() => history.push(`/artworks`)}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={data.artwork.lock}
                          type="primary"
                          onClick={saveArtwork}
                          loading={loading || uploadLoading}
                        >
                          Save
                        </Button>
                      </Space>
                    </div>
                    {selectedLayers.length > 0 ? (
                      <TopToolbar ratio={ratio} />
                    ) : null}

                    <div style={{ backgroundColor: "#F4F6F8" }}>
                      <Scrollbars
                        style={{ width: "100%", height: "calc(100vh - 147px)" }}
                      >
                        <Konva
                          width={get(artwork, "width", 600) * ratio * zoom}
                          height={get(artwork, "height", 600) * ratio * zoom}
                          ratio={ratio}
                          {...{ scaleX: zoom, scaleY: zoom }}
                          zoom={zoom}
                        />
                        <Zoom value={zoom} onChange={(v) => setZoom(v)} />
                      </Scrollbars>
                    </div>
                  </div>
                  <TemplateName
                    template={showTemplateName}
                    onCancel={() => setShowTemplateName(null)}
                  />
                  <Modal
                    visible={showSetting}
                    onCancel={() => setShowSetting(false)}
                    footer={false}
                  >
                    {artworkForm}
                    <div style={{ textAlign: "right" }}>
                      <Button
                        type="primary"
                        onClick={() =>
                          form.validateFields().then((values) => {
                            dispatch({
                              type: ARTWORK.SET,
                              payload: {
                                ...artwork,
                                ...values,
                                categories: values.categoryIds?.map((id) => ({
                                  id: id,
                                })),
                              },
                            });
                            setShowSetting(false);
                          })
                        }
                      >
                        Update
                      </Button>
                    </div>
                  </Modal>
                </Container>
              );
            }
          }}
        </Query>
      )}
    </div>
  );
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(Design);
