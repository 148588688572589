import React from "react";
import styled from "styled-components";
import FontSelector from "../Font/FontSelector";
import {ARTWORK} from "../../types";
import {useDispatch} from "react-redux";
import FontSize from "./Utilities/FontSize";
import TextAlign from "./Utilities/TextAlign";
import TextSpacing from "./Utilities/TextSpacing";
import TextTransform from "./Utilities/TextTransform";
import ColorField from "./Utilities/ColorField";
import ColorBackgroundField from "./Utilities/ColorBackgroundField";
import FontWeightField from "./Utilities/FontWeightField";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const LabelToolbar = ({ layer }) => {
    const dispatch = useDispatch();
    const onChange = (update) => {
        dispatch({
            type: ARTWORK.SET_LAYER,
            payload: { ...layer, ...update },
        });
    };
    return (
        <Container>
            <FontSize layer={layer} />
            <TextAlign layer={layer} />
            <TextSpacing layer={layer} />
            <FontWeightField
                value={layer.fontWeight}
                onChange={(value) => {
                    onChange({fontWeight: value})
                }}
            />
            <ColorField
                value={layer.fill}
                onChange={(color) => onChange({ fill: color })}
            />
            <ColorBackgroundField
                value={layer.background}
                onChange={(color) => onChange({ background: color })}
            />
            <TextTransform
                layer={layer}
                onChange={(value) => {
                    onChange(value);
                }}
            />
        </Container>
    )
}

export default LabelToolbar;