import React, { useState, useEffect } from "react";
import { Checkbox, message, Pagination, Empty, Alert, Button } from "antd";
import gql from "../../../../../api/gql";
import { CLOUDFRONT_URL, ASSET_URL } from "../../../../../config";
const ListBackgroundByID = ({
  category_id,
  selected,
  onChange,
  onChangeBackground,
  selectedImage,
}) => {
  const [backgrounds, setBackgrounds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [keyImg, setKeyImg] = useState(null);

  const onChangeCheckBox = (data, status) => {
    const { id, imageUrl } = data;
    const findID = selected.find((e) => e.id == id);
    var newSelected = [...selected];
    if (findID) {
      newSelected = newSelected.filter((e) => e.id != id);
    } else {
      newSelected = [
        ...newSelected,
        {
          id,
          imageUrl: ASSET_URL + imageUrl,
        },
      ];
    }
    onChange(newSelected);
  };
  const onDeSelectAll = () => {
    onChange([]);
  }
  const getBackgrounds = () => {
    setLoading(true);
    const query = `query bannersFilter($categoryID: [String], $page: Int, $pageSize: Int){
            banners(
            categoryID: $categoryID,
            page: $page,
            pageSize: $pageSize
        ){
                count
                hits{
                    id
                    title
                    imageUrl
                }
            }
        }`;
    const filter = {
      categoryID: [category_id],
      pageSize,
      page,
    };
    gql
      .request(query, filter)
      .then((res) => {
        setTotal(res.banners.count || 0);
        setBackgrounds(res.banners.hits || []);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again!");
        setLoading(false);
      });
  };
  useEffect(() => {
    getBackgrounds();
  }, [page, category_id]);

  const handleContextMenu = (e, keyimg) => {
    e.preventDefault();
    setKeyImg(keyimg);
    setPosition({ x: e.clientX, y: e.clientY });
    setMenuVisible(true);
  };

  const handleSetBackground = () => {
    if (keyImg) {
      onChangeBackground(ASSET_URL + keyImg);
    }
    setMenuVisible(false);
  };

  const handleHideMenu = () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  const checkIsBackground = (background_id) => {
    const lengtBackground = selected.length;
    const findID = selected.find((e) => e.id == background_id);
    const count = Math.floor(20 / selectedImage.length);
    if (lengtBackground >= count && !findID) {
      return true;
    }
    return false;
  };

  return (
    <>
      {backgrounds.length > 0 ? (
        <>
        
          <Alert
            style={{
              marginBottom: 5,
            }}
            message={`You can only select up to ${Math.floor(
              20 / (selectedImage.length || 1)
            )} photos`}
            type="info"
          />
          
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 5,
            }}
          >
            {backgrounds.map((i) => {
              return (
                <li
                  key={i.id}
                  className={
                    checkIsBackground(i.id)
                      ? "disabled-box li-select-background-autobanner"
                      : "li-select-background-autobanner"
                  }
                >
                  <Checkbox
                    checked={selected.find((e) => e.id == i.id)}
                    onChange={(v) => onChangeCheckBox(i, v)}
                  >
                    <div
                      onContextMenu={(e) => handleContextMenu(e, i.imageUrl)}
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={`${CLOUDFRONT_URL}/100/${i.imageUrl}`}
                      />
                    </div>
                  </Checkbox>
                </li>
              );
            })}
          </ul>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}>
          <Button style={{
            borderRadius: 5,
            marginRight: 5
          }} disabled={selected <= 0} onClick={onDeSelectAll}>
            Deselect All
          </Button>
          <Pagination
            onChange={setPage}
            pageSize={pageSize}
            current={page}
            total={total}
          />
          </div>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {menuVisible && (
        <>
          <div
            onClick={handleHideMenu}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          ></div>
          <ul
            id="customContextMenu"
            style={{
              position: "fixed",
              top: position.y,
              left: position.x,
              backgroundColor: "white",
              border: "1px solid #ccc",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
              listStyleType: "none",
              padding: "0",
              margin: "0",
              zIndex: 1000,
            }}
          >
            <li
              onClick={handleSetBackground}
              style={{ padding: "8px 12px", cursor: "pointer" }}
            >
              set Background
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default ListBackgroundByID;
