import gql from "graphql-tag";

export const UPLOAD_PRODUCT_MOCKUP = gql`
  mutation uploadProductMockups($productId: String!, $fileId: String!) {
    uploadProductMockups(productId: $productId, fileId: $fileId) {
      id
      mockupId
      image
    }
  }
`;
