import UseClipartCategory, {
  makeTree,
  getChildrenIDs,
} from "../../hooks/UseClipartCategory";
import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ButtonUnlockUser from "../ButtonUnlockUser";
import { USER_ID_BLOCKED } from "../../config";
import { useMutation } from "@apollo/client";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Tooltip,
  Tree,
  TreeSelect,
} from "antd";
import confirm from "antd/lib/modal/confirm";
import React, { useEffect, useState } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import Scrollbars from "react-custom-scrollbars";
import {
  CREATE_CLIPART_CATEGORY,
  UPDATE_CLIPART_CATEGORY,
  DELETE_CLIPART_CATEGORY,
} from "../../graphql/mutation/clipartCategoryMutation";
import { connect } from "react-redux";
import { history } from "../../history";
import styled from "styled-components";
const ContainerItem = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 25px;
  .action-clipart{
    display:none
  }
  &:hover{
    .action-clipart{
      display:block
      white-space: nowrap;
    }
  }
`;

const useClipartCategoryForm = ({
  category,
  onFinish = () => {},
  currentUser,
}) => {
  const [form] = Form.useForm();
  const { categories, refetch } = UseClipartCategory({
    search: "",
    currentUser,
  });
  const [createClipcartCategory, { loading: createLoading }] = useMutation(
    CREATE_CLIPART_CATEGORY
  );
  const [updateClipcartCategory, { loading: updateLoading }] = useMutation(
    UPDATE_CLIPART_CATEGORY
  );
  useEffect(() => {
    if (category) {
      form.setFieldsValue(category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);
  const handSubmit = (values) => {
    if (category.id) {
      updateClipcartCategory({
        variables: { id: category.id, ...values },
      })
        .then((res) => {
          onFinish(res.data.category);
          notification.success({ message: "Category updated" });
          refetch();
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
    } else {
      createClipcartCategory({
        variables: values,
      })
        .then((res) => {
          onFinish(res.data.category);
          notification.success({ message: "Category created" });
          refetch();
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
    }
  };
  // console.log(categories);
  const childrenIDs =
    category && category.id ? getChildrenIDs(category, categories || []) : [];
  return [
    <Form
      form={form}
      id="clipart-category-form"
      onFinish={handSubmit}
      layout="vertical"
    >
      <Form.Item label="Parent" name="parentID">
        <TreeSelect
          treeDefaultExpandAll={true}
          treeData={makeTree(
            categories?.map((cat) => {
              if (
                (category && category.id === cat.id) ||
                cat.hasClipart ||
                childrenIDs.includes(cat.id)
              ) {
                return { ...cat, value: cat.id, disabled: true };
              }
              return { ...cat, value: cat.id };
            })
          )}
          allowClear
          placeholder="--Root--"
        />
      </Form.Item>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Category title is required" }]}
      >
        <Input placeholder="Category title" autoFocus />
      </Form.Item>
    </Form>,
    createLoading || updateLoading,
  ];
};

const CategoriesClipart = ({ categoryID, currentUser }) => {
  const [searchValue, setSearchValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([]);
  const {
    tree,
    numberOfCliparts,
    expandedKeys: searchExpandKeys,
  } = UseClipartCategory({
    search: searchValue,
    currentUser,
  });
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [categoryForm, loading] = useClipartCategoryForm({
    category: selectedCategory,
    onFinish: () => {
      setShowCategoryForm(false);
    },
    currentUser,
  });

  const [deleteCategory] = useMutation(DELETE_CLIPART_CATEGORY);
  // const onSearch = (_, values) => {
  //   setSearchValue(values.search);
  // };

  const handleMenuClick = ({ item, key }) => {
    setMenuVisible(false);
    switch (key) {
      case ".$edit":
        setShowCategoryForm(true);
        break;
      case ".$add":
        setSelectedCategory({ parentID: selectedCategory.id, title: "" });
        setShowCategoryForm(true);
        break;
      // case ".$settings":
      //   router.query.settings = 1;
      //   router.query.categoryID = selectedCategory.id;
      //   router.push(router);
      //   break;
      case ".$delete":
        confirm({
          title: `Delete "${selectedCategory.title}" category?`,
          content:
            "It can contain the sub-folders or cliparts, so if you remove it means you remove the nested folders or cliparts too.",
          icon: <QuestionCircleOutlined />,
          type: "warning",
          okButtonProps: { danger: true },
          okText: "Delete",
          onOk: () => {
            deleteCategory({ variables: { id: selectedCategory.id } })
              .then(() => {
                notification.success({
                  message: "The Clipart delete success",
                });
                history.push({
                  pathname: "/cliparts",
                  search: "",
                });
                // if (selectedCategory.id === router.query.categoryID) {
                //   // router.query = omit(router.query, "categoryID");
                //   // router.push(router);
                // }
              })
              .catch((err) => {
                notification.error({ message: err.message });
              });
          },
        });
        break;
      default:
        break;
    }
  };

  const handleSelect = (keys) => {
    if (keys.length === 0) return;
    if (keys[0]) {
      history.push({
        pathname: "/cliparts",
        search: `?categoryID=${keys[0]}`,
      });
    } else {
      history.push({
        pathname: "/cliparts",
        search: "",
      });
    }
  };

  return (
    <div className="p-clipart-category" style={{ padding: 15 }}>
      <div
        className="pb-15 header-category"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>Categories</div>
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelectedCategory({ parentID: null, title: "" });
            setShowCategoryForm(true);
          }}
        >
          New category
        </Button>
      </div>
      {/* <Form onValuesChange={debounce(onSearch, 200)}>
        <Form.Item name="search" className="header-filter">
          <Search placeholder="Search..." />
        </Form.Item>
      </Form> */}
      <Scrollbars style={{ height: `calc(100vh - 140px)` }}>
        <Tree
          className="tree-transparent"
          style={{ paddingRight: 10 }}
          filterTreeNode={(node) => {
            // node.title === "Dog";
          }}
          onSelect={handleSelect}
          treeData={[
            { title: "All categories", key: "", id: null, numberOfCliparts },
            ...tree,
          ]}
          autoExpandParent={searchValue !== ""}
          expandedKeys={[...expandedKeys, ...searchExpandKeys]}
          selectedKeys={categoryID ? [categoryID] : []}
          onExpand={(keys) => setExpandedKeys(keys)}
          showLine={{ showLeafIcon: false }}
          showIcon={null}
          blockNode
          titleRender={(node) => {
            if (node.id === null) {
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{node.title}</span>{" "}
                  {/* <span style={{ color: "#999" }}>
                    {Intl.NumberFormat().format(numberOfCliparts)}
                  </span> */}
                </div>
              );
            }
            var title = <span>{node.title}</span>;
            if (searchValue) {
              const index = node.title
                .toLocaleLowerCase()
                .indexOf(searchValue.toLowerCase());
              const beforeTitle = node.title.substr(0, index);
              const highlight = node.title.substr(index, searchValue.length);
              const afterTitle = node.title.substr(index + searchValue.length);
              title =
                index > -1 ? (
                  <span>
                    {beforeTitle}
                    <span style={{ color: "red" }}>{highlight}</span>
                    {afterTitle}
                  </span>
                ) : (
                  <span>{node.title}</span>
                );
            }
            return (
              <ContextMenuTrigger
                collect={() => {
                  setMenuVisible(true);
                  setSelectedCategory(node);
                }}
                categoryId={node.id}
                id="clipart-category-contextmenu"
              >
                <ContainerItem
                // style={{
                //   display: "flex",
                //   gap: 10,
                //   justifyContent: "space-between",
                // }}
                >
                  <span className="tree-title">{title}</span>
                  <span className="action-clipart">
                    <Tooltip placement="top" title={"Edit"}>
                      <Button
                        type="link"
                        size="small"
                        icon={<EditOutlined />}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedCategory(node);
                          setShowCategoryForm(true);
                        }}
                      />
                    </Tooltip>
                    {node?.hasClipart ? null : (
                      <Tooltip placement="top" title={" Add Subcategory"}>
                        <Button
                          type="link"
                          size="small"
                          icon={<FolderAddOutlined />}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedCategory({
                              parentID: node.id,
                              title: "",
                            });
                            setShowCategoryForm(true);
                          }}
                        />
                      </Tooltip>
                    )}
                    {(currentUser.id == USER_ID_BLOCKED) ? 
                    <ButtonUnlockUser
                      title={`Delete "${node.title}" category?`}
                      content="It can contain the sub-folders or cliparts, so if you remove it means you remove the nested folders or cliparts too."
                      propsButton={{
                        type:"link",
                        size:"small",
                        icon: (<DeleteOutlined />)
                      }}
                      onConfirm={() => {
                        deleteCategory({ variables: { id: node.id } })
                        .then(() => {
                          notification.success({
                            message: "The Clipart delete success",
                          });
                          history.push({
                            pathname: "/cliparts",
                            search: "",
                          });
                          // if (selectedCategory.id === router.query.categoryID) {
                          //   // router.query = omit(router.query, "categoryID");
                          //   // router.push(router);
                          // }
                        })
                        .catch((err) => {
                          notification.error({ message: err.message });
                        });
                      }}
                    />
                    : 
                    <Tooltip placement="top" title={"Delete"}>
                      <Button
                        type="link"
                        size="small"
                        icon={<DeleteOutlined />}
                        danger
                        onClick={(e) => {
                          e.preventDefault();
                          confirm({
                            title: `Delete "${node.title}" category?`,
                            content:
                              "It can contain the sub-folders or cliparts, so if you remove it means you remove the nested folders or cliparts too.",
                            icon: <QuestionCircleOutlined />,
                            type: "warning",
                            okButtonProps: { danger: true },
                            okText: "Delete",
                            onOk: () => {
                              deleteCategory({ variables: { id: node.id } })
                                .then(() => {
                                  notification.success({
                                    message: "The Clipart delete success",
                                  });
                                  history.push({
                                    pathname: "/cliparts",
                                    search: "",
                                  });
                                  // if (selectedCategory.id === router.query.categoryID) {
                                  //   // router.query = omit(router.query, "categoryID");
                                  //   // router.push(router);
                                  // }
                                })
                                .catch((err) => {
                                  notification.error({ message: err.message });
                                });
                            },
                          });
                        }}
                      />
                    </Tooltip>
                    }
                  </span>
                  {/* <span style={{ color: "#999" }}>
                    {Intl.NumberFormat().format(node.numberOfCliparts)}
                  </span> */}
                </ContainerItem>
              </ContextMenuTrigger>
            );
          }}
        />
      </Scrollbars>

      {/* <ContextMenu id="clipart-category-contextmenu" style={{ zIndex: 99 }}>
        {menuVisible && (
          <div className="ant-popover-inner">
            <Menu
              style={{
                borderRight: "none",
                width: 200,
                borderRadius: 2,
              }}
              selectedKeys={null}
              onClick={handleMenuClick}
            >
              <Menu.Item key="edit" icon={<EditOutlined />}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="add"
                icon={<FolderAddOutlined />}
                disabled={selectedCategory?.hasClipart}
              >
                Add Subcategory
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="delete" icon={<DeleteOutlined />} danger>
                Delete
              </Menu.Item>
            </Menu>
          </div>
        )}
      </ContextMenu> */}
      <Modal
        visible={showCategoryForm}
        title={selectedCategory?.id ? "Edit Category" : "New Category"}
        onCancel={() => setShowCategoryForm(false)}
        okButtonProps={{
          htmlType: "submit",
          form: "clipart-category-form",
          loading: loading,
        }}
      >
        {categoryForm}
      </Modal>
    </div>
  );
};
export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(CategoriesClipart);
