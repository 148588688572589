import React from "react";
import { Form, Tabs, Card, Input, Button, notification } from "antd";
import gql from "../../api/gql";
import * as _ from "lodash";
import { SaveOutlined } from "@ant-design/icons";

const settings = [
  {
    key: "welcome_email",
    title: "Welcome",
  },
  {
    key: "password_recovery",
    title: "Password",
  },
];
class AccountSettingsForm extends React.Component {
  state = {
    initSetting: [],
  };
  componentDidMount = () => {
    const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`;
    const input = settings
      .map((el) => el.key)
      .reduce(
        (init, item) => [...init, item + "_subject", item + "_message"],
        []
      );
    this.setState({ loading: true });
    gql
      .request(query, { input: input })
      .then((res) =>
        this.setState({ initSetting: res.getAppSettings || [], loading: false })
      )
      .catch((err) => {
        notification["error"]({
          message: "Error",
          description: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, onSubmit } = this.props;
    const { initSetting } = this.state;
    const setting =
      initSetting &&
      initSetting.reduce(
        (init, item) => ({ ...init, [item.name]: item.value }),
        {}
      );
    return (
      <Form
        layout="vertical"
        loading={loading}
        onFinish={(values) => {
          onSubmit(values);
        }}
      >
        <Tabs tabPosition="left">
          {settings.map((el) => (
            <Tabs.TabPane tab={el.title} key={el.key}>
              <Card title={`${el.title} settings`}>
                <Form.Item
                  label="Subject"
                  name={`${el.key}_subject`}
                  rules={[
                    {
                      required: true,
                      message: "Please enter subject",
                    },
                  ]}
                  initialValue={setting ? setting[`${el.key}_subject`] : ""}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Message"
                  name={`${el.key}_message`}
                  rules={[
                    {
                      required: true,
                      message: "Please enter message",
                    },
                  ]}
                  initialValue={setting ? setting[`${el.key}_message`] : ""}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
              </Card>
            </Tabs.TabPane>
          ))}
        </Tabs>
        <Form.Item style={{ textAlign: "right", marginTop: 20 }}>
          <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default AccountSettingsForm;
