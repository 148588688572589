import { useMutation } from "@apollo/client";
import { Button, Input, notification, Form } from "antd";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { UPDATE_ORDER_INFO } from "../../graphql/mutation/orders/orderAction";
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const CustomerAddress = (props) => {
  const { order, refetch } = props;
  const [form] = Form.useForm();
  const [shippings, setShippings] = useState(order?.shipping);
  const [customer, setCustomer] = useState(order?.customer);
  const [updateShipping, { loading: mutationLoading }] = useMutation(
    UPDATE_ORDER_INFO
  );

  useEffect(() => {
    if (order) {
      setShippings(order?.shipping);
      setCustomer(order?.customer);
    }
  }, [order]);
  const customerInfo = [
    {
      value: "first_name",
      name: "First name",
    },
    {
      value: "last_name",
      name: "Last name",
      required: true,
    },
    {
      value: "phone",
      name: "Phone",
    },
    {
      value: "email",
      name: "Email",
    },
  ];

  const shippingInfo = [
    {
      value: "address1",
      name: "Address 1",
      required: true,
    },
    {
      value: "address2",
      name: "Address 2",
    },
    {
      value: "company",
      name: "Company",
    },
    {
      value: "city",
      name: "City",
      required: true,
    },
    {
      value: "state",
      name: "State",
    },
    {
      value: "state_code",
      name: "State code",
      required: true,
    },
    {
      value: "zip",
      name: "Zip code",
    },
    {
      value: "country",
      name: "Country",
    },
    {
      value: "country_code",
      name: "Country code",
      required: true,
      max: 5,
    },
  ];
  const handleSaveUpdate = (values) => {
    const shipping = omit(values, ["phone", "email"]);
    updateShipping({
      variables: {
        order_id: order.id,
        customer_info: {
          shipping: { ...shipping },
          customer: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
          },
        },
      },
    })
      .then(() => {
        // refetch();
        notification.success({
          message: "The shipping address updated successfully!",
        });
        setShippings({ ...shippings, ...values });
        refetch();
        // form.resetFields();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  return (
    <div>
      <Form form={form} {...layout} onFinish={handleSaveUpdate}>
        {customerInfo.map((item) => (
          <Form.Item
            key={item.value}
            initialValue={customer ? customer[item.value] : ""}
            name={item.value}
            label={item.name}
            rules={[
              {
                required: item.required,
                message: `${item.name} can't be blank.`,
              },
              {
                max: item.max,
                message: `${item.name} max ${item.max} character`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ))}
        {shippingInfo.map((item) => (
          <Form.Item
            key={item.value}
            initialValue={shippings ? shippings[item.value] : ""}
            name={item.value}
            label={item.name}
            rules={[
              {
                required: item.required,
                message: `${item.name} can't be blank.`,
              },
              {
                max: item.max,
                message: `${item.name} max ${item.max} character`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ))}
        <div className="align-right mt-15">
          <Button
            disabled={mutationLoading}
            onClick={() => {
              //form.resetFields();
              props.onCancel();
            }}
            className="mr-15"
          >
            Cancel
          </Button>
          <Button
            loading={mutationLoading}
            htmlType="submit"
            type="primary"
            disabled={["exported", "cancelled"].includes(order.status)}
          >
            Save Changes
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CustomerAddress;
