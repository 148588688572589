import React from "react";
import { Card, Modal, message } from "antd";
import gql from "../../api/gql";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import ChooseArtWorkModal from "./ChooseArtWorkModal";
import Grid from "../Grid";

const Container = styled.div`
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
`;
export default class UploadDesigns extends React.Component {
  state = {
    product: null,
    printFiles: this.props.printFiles,
    loading: true,
    percent: 0,
    submitLoading: false,
    uploadLoading: {},
    visibleArtwork: false,
    artwork: null,
  };

  componentDidMount() {}

  render() {
    const { printFiles, visibleArtwork, artwork } = this.state;
    return (
      <Container>
        <Grid width={200} gap={10}>
          {printFiles &&
            printFiles.map((print_file, index) => (
              <Card
                onClick={() => {
                  this.setState({ visibleArtwork: print_file });
                }}
                key={index}
                hoverable
                cover={
                  <div
                    style={{
                      height: 200,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      background: "#f1f1f1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {print_file.artwork_id ? (
                      <img
                        alt=""
                        src={`${CLOUDFRONT_URL}/200x200/${print_file?.preview}`}
                      />
                    ) : (
                      <div>Upload Artwork</div>
                    )}
                  </div>
                }
              >
                <Card.Meta
                  title={print_file.name}
                  description={`${print_file.width ? print_file.width : ""} ${
                    print_file.width && print_file.height ? "X" : ""
                  } ${print_file.height ? print_file.height : ""}`}
                />
                {/* {print_file.sku && (
                  <Card.Meta
                    description={
                      <div>
                        SKU: {print_file.sku}{" "}
                        <Icon
                          onClick={() => this.setState({ open: true })}
                          type="edit"
                        />
                      </div>
                    }
                  />
                )} */}
              </Card>
            ))}
          {printFiles.length % 2 !== 0 ? <div></div> : null}
        </Grid>

        <Modal
          key={visibleArtwork.id}
          title="Choose Artwork"
          visible={visibleArtwork ? true : false}
          onCancel={() => {
            this.setState({ visibleArtwork: false });
          }}
          onOk={() => {
            let newPrintArea = printFiles.map((item) => {
              if (item.id === visibleArtwork.id) {
                return {
                  ...item,
                  artwork_id: artwork.id,
                  preview: artwork.templates[0].preview,
                };
              } else {
                return item;
              }
            });
            this.setState(
              {
                visibleArtwork: false,
                artwork: null,
                printFiles: newPrintArea,
              },
              () => this.props.onChange(this.state.printFiles)
            );
          }}
          okButtonProps={{ disabled: artwork ? false : true }}
          width="90%"
          className="modal-choose-artwork"
        >
          <ChooseArtWorkModal
            onChange={(value) => {
              this.setState({ artwork: value });
            }}
          />
        </Modal>
      </Container>
    );
  }
}
