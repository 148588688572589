import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Form, Input, Button, notification, message } from "antd";
import _ from "lodash";
import gql from "../../api/gql";

const Container = styled.div`
  margin: auto;
  text-align: center;
  padding: 25px;
`;
class LoginPage extends Component {
  state = {
    loading: false,
  };

  handleSubmit = (values) => {
    const query = `mutation resetPassword($email: String!){
          resetPassword(email: $email)
        }`;
    this.setState({ loading: true });
    gql
      .request(query, { email: values.email })
      .then((res) => {
        this.setState({
          loading: false,
        });
        message["success"](
          "Further instructions have been sent to your email address."
        );
      })
      .catch((err) => {
        const msg = _.get(err, "[0].message", "Register error");
        if (msg.search(/email_UNIQUE/)) {
          notification["error"]({
            message: _.get(err, "[0].message"),
          });
        } else {
          notification["error"]({
            message: msg,
          });
        }
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div layout="register">
        <Container className={"register-container"}>
          <img
            src="/logo.png"
            style={{ width: 100, marginBottom: 40 }}
            alt="OOPPRINT"
          />
          <h1 style={{ color: "#41566B", fontSize: 28, textAlign: "center" }}>
            Forgot Password
          </h1>
          <h5 style={{ color: "#41566B", fontSize: 20, textAlign: "center" }}>
            Enter your email to reset password
          </h5>
          <Form
            onFinish={this.handleSubmit}
            className="register-form"
            style={{ textAlign: "initial", marginTop: 40 }}
            layout="horizontal"
          >
            <Form.Item
              label={"Email"}
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button btn-primary"
                loading={this.state.loading}
                style={{
                  height: 40,
                  width: "100%",
                }}
              >
                Proceed
              </Button>
            </Form.Item>
          </Form>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentUser } = state.app;
  return { currentUser };
}

export default connect(mapStateToProps)(LoginPage);
