import React, { useState, useEffect } from "react";
import MarkdownPreviewer from "../MarkdownPreviewer";
export default function UserMessage({
    onReplyEdit,
    children
}) {
  return (
    <div className="message-item-control-group">
      <div className="message-item-content">
        <MarkdownPreviewer>
          {children ? children : ""}
        </MarkdownPreviewer>
      </div>
      <div className="message-item-control">
        <button onClick={() => onReplyEdit(children ? children : "")} className="btn-control-chat">
            <i className="fa-solid fa-reply"></i>
        </button>
      </div>
    </div>
  );
}
