import { cloneDeep } from "lodash";
import { MOCKUP } from "../types";

export const initState = {
  mockupWorkspace: {
    clipboard: null,
    mockup: {
      layers: [],
    },
    selectedLayers: [],
    mockupsManage: [],
  },
};

export const isClient = () => {
  return typeof window === "object";
};

export const updateLayer = (layers, update) => {
  for (let i = 0; i < layers.length; i++) {
    if (layers[i].id === update.id) {
      layers[i] = { ...layers[i], ...update };
      break;
    }
    if (layers[i].layers) {
      updateLayer(layers[i].layers, update);
    }
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case MOCKUP.SET:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          mockup: action.payload,
        },
      };
    case MOCKUP.SET_SELECTED_LAYERS:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          selectedLayers: action.payload,
        },
      };
    case MOCKUP.SET_LAYERS:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          mockup: {
            ...state.mockupWorkspace.mockup,
            layers: action.payload,
          },
        },
      };
    case MOCKUP.SET_LAYER:
      var { mockupsManage, mockup } = state.mockupWorkspace;
      var layers = [...mockup.layers];
      updateLayer(layers, action.payload);
      let newListMockup = cloneDeep(mockupsManage);
      if (newListMockup.length) {
        newListMockup = newListMockup.map((item) => {
          if (item.id === mockup.id) {
            return {
              ...mockup,
              layers: layers,
            };
          }
          return { ...item };
        });
      }
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          mockup: {
            ...state.mockupWorkspace.mockup,
            layers: layers,
          },
          mockupsManage: newListMockup,
        },
      };
    case MOCKUP.SET_CTRL:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          ctrl: action.payload,
        },
      };
    case MOCKUP.SET_CLIPBOARD:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          clipboard: action.payload,
        },
      };
    case MOCKUP.SET_MOCKUPS:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          mockupsManage: action.payload,
        },
      };
    case MOCKUP.SET_VARIANT_IDS:
      return {
        ...state,
        mockupWorkspace: {
          ...state.mockupWorkspace,
          variant_ids: action.payload,
        },
      };

    default:
      return state;
  }
};
