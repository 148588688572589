import gql from "graphql-tag";

export default gql`
  query($id: String!) {
    mockup(id: $id) {
      id
      title
      productType {
        id
        attributes {
          name
          slug
          options
        }
        printAreas {
          id
          name
          width
          height
        }
      }
      status
      preview
      ordering
      width
      height
      layers
      settings {
        defaultBgColor
        changeBgColorByVariantColor
        applyToVariants
      }
      variantIds
    }
  }
`;
