import React from "react";
import { Select, Form } from "antd";
export default function SelectServerId({ serverID, onSelect }) {
  return (
    <Form.Item name="server">
      <Select
        defaultValue={serverID}
        style={{
          width: 200,
        }}
        onChange={(v) => {
          onSelect(v);
        }}
        options={[
          {
            value: "1",
            label: "Other - Line 1",
          },
          {
            value: "2",
            label: "Floor 3 - Line 2",
          },
          {
            value: "3",
            label: "Floor 4 - Line 3",
          }
        ]}
      />
    </Form.Item>
  );
}
