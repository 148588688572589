import React from "react";
import {
  Skeleton,
  Card,
  Button,
  notification,
  Progress,
  Tooltip,
  message,
} from "antd";
import Upload from "../Upload";
import gql from "../../api/gql";
import _ from "lodash";
import PrintFilePreview from "../file/PrintFilePreview";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import { DownloadOutlined } from "@ant-design/icons";

const Container = styled.div`
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
  .pod-file-download {
    position: absolute;
    font-size: 14px;
    bottom: 12px;
    right: 8px;
    border-radius: 50%;
    background-color: #ff6666;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    i {
      color: #fff;
    }
  }
  .print_files {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 10px;
  }
  @media only screen and (max-width: 600px) {
    .print_files {
      grid-template-columns: repeat(3, auto);
    }
  }
`;
const UploadContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 67%;
  .upload-wrapper {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 67%;
    .upload-area {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
class UploadDesign extends React.Component {
  state = {
    file: this.props.file,
    uploading: false,
    percent: 0,
  };

  onUpload = (file) => {
    let query = `mutation createFile($input: NewFile!){
      createFile(input: $input){id}
    }`;
    gql
      .request(query, {
        input: {
          source: "aws",
          key: file.key,
          file_name: file.file.name,
          file_mime: file.file.type,
          file_size: file.file.size,
        },
      })
      .then(({ createFile }) => {
        const { file } = this.state;
        this.setState(
          {
            uploading: false,
            file: { ...file, file_id: createFile.id },
          },
          () => this.props.onChange(this.state.file)
        );
      })
      .catch((err) => {
        this.setState({
          uploading: false,
          percent: 0,
        });
      });
  };

  beforeUpload = (file, fileList) => {
    var regex = new RegExp("^(\\d|\\w)+(.jpg|.jpeg|.png|.tiff|.tif|.psd)$");
    var result = regex.test(file.name);
    if (result === false) {
      message["error"](
        "File name cannot contain empty space or any special characters"
      );
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { file, uploading, percent } = this.state;
    return (
      <UploadContainer>
        <Upload
          beforeUpload={this.beforeUpload}
          onUpload={this.onUpload}
          onProcess={(e) => {
            this.setState({
              uploading: true,
              percent: Math.round((e.loaded / e.total) * 100),
            });
          }}
          fileList={false}
          accept=".jpg, .png, .tiff, .psd"
          className="upload-wrapper"
        >
          <div className="upload-area">
            {uploading ? (
              <Progress
                type="circle"
                percent={percent}
                strokeColor="#1890ff"
                width={50}
              />
            ) : file.file_id ? (
              <PrintFilePreview file_id={file.file_id} />
            ) : (
              <span style={{ textAlign: "center" }}>
                Missing, click to upload or drag to upload
              </span>
            )}
          </div>
        </Upload>
      </UploadContainer>
    );
  }
}

export default class UploadCustomDesigns extends React.Component {
  state = {
    product: null,
    printFiles: [],
    loading: true,
    percent: 0,
    submitLoading: false,
    submitReadyLoading: false,
    uploadLoading: false,
  };

  componentDidMount() {
    this.loadPrintFiles();
  }

  loadPrintFiles = () => {
    const { order } = this.props;
    const pTPrintFiles = order.variant.product_type.print_files;
    const orderPrintFiles = order.print_files;
    const productTypeVariantId = order.variant.product_type_variant.id;
    let printFiles = [];
    if (!orderPrintFiles || orderPrintFiles.length === 0) {
      //need to get print file from product type
      printFiles = pTPrintFiles.filter(
        (ptp) =>
          _.includes(ptp.variant_ids, productTypeVariantId) ||
          ptp.variant_ids == null ||
          ptp.variant_ids.length === 0
      );
      //console.log("test", test)
    } else {
      //get print files from order
      printFiles = order.print_files;
    }
    this.setState({
      printFiles: printFiles,
      loading: false,
    });
  };
  savePrintFiles = (e, isReady) => {
    e.preventDefault();
    if (isReady) {
      this.setState({ submitReadyLoading: true });
    } else {
      this.setState({ submitLoading: true });
    }

    const { printFiles } = this.state;
    const { order } = this.props;
    let query = `mutation UpdateOrderPrintFiles($order_id: Int!, $print_files: [OrderPrintFileInput!], $is_ready: Boolean!){
      updateOrderPrintFiles(order_id: $order_id, print_files: $print_files, is_ready: $is_ready)
    }`;

    gql
      .request(query, {
        order_id: order.id,
        print_files: printFiles.map((printFile) =>
          _.omit(printFile, ["file", "variant_ids"])
        ),
        is_ready: isReady,
      })
      .then((res) => {
        notification["success"]({
          message: "Print Files saved",
        });
        this.props.onClose();
      })
      .catch((err) => {
        if (isReady) {
          this.setState({ submitReadyLoading: false });
        } else {
          this.setState({ submitLoading: false });
        }
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  };
  render() {
    const { printFiles, loading } = this.state;
    const { order } = this.props;
    if (loading || !order) {
      return <Skeleton />;
    }
    return (
      <Container>
        <div className="print_files">
          {printFiles &&
            printFiles.map((print_file, index) => (
              <div key={index}>
                <Card
                  hoverable
                  cover={
                    <UploadDesign
                      file={print_file}
                      onChange={(file) => {
                        const { printFiles } = this.state;
                        printFiles[index] = file;
                        this.setState({ printFiles });
                      }}
                    />
                  }
                >
                  <Card.Meta
                    title={print_file.title}
                    description={`${print_file.width} x ${print_file.height}`}
                  />
                  {print_file.note && (
                    <Card.Meta title={null} description={print_file.note} />
                  )}
                  {print_file.file && (
                    <div className="pod-file-download">
                      <a
                        href={`${CLOUDFRONT_URL}/${print_file.file.key}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Download Print File"
                      >
                        <Tooltip
                          placement="topRight"
                          title="Download print file"
                        >
                          <DownloadOutlined />
                        </Tooltip>
                      </a>
                    </div>
                  )}
                </Card>
              </div>
            ))}
        </div>
        {order.product.images && order.product.images.length > 0 && (
          <div>
            <h4 style={{ marginTop: 50 }}>Product Mockup</h4>
            <img
              src={order.product.images ? order.product.images[0] : null}
              alt=""
              style={{ width: "50%" }}
            />
          </div>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "right",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px",
          }}
        >
          {/* <Button type="primary" onClick={this.savePrintFiles} loading={this.state.submitLoading}>Save</Button> */}
          <Button
            type="primary"
            onClick={(e) => {
              this.savePrintFiles(e, false);
            }}
            loading={this.state.submitLoading}
          >
            Save
          </Button>

          {order &&
            (order.status === "sup_rejected" ||
              order.status === "sup_failed") && (
              <Button
                type="primary"
                onClick={(e) => {
                  this.savePrintFiles(e, true);
                }}
                loading={this.state.submitReadyLoading}
                style={{ marginLeft: "5px" }}
              >
                Save and Ready
              </Button>
            )}
        </div>
      </Container>
    );
  }
}
