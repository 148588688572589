import gql from "graphql-tag";

export const PRODUCTS = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      count
      hits {
        id
        sku
        title
        images
        created_at
        product_stores {
          id
          site_id
          origin_id
          permalink
        }
        is_custom_design
        variants {
          sku
          design_status
          product_type_id
          print_files {
            key
            title
            width
            height
            file_id
            variant_ids
          }
        }
        mockups {
          id
          image
        }
      }
    }
  }
`;

export const CAMPAIGNSTORES = gql`
  query campaignStores($filter: CampaignStoreFilter) {
    campaignStores(filter: $filter) {
      count
      hits {
        id
        siteId
        campaignId
        status
        pushFirstTime
        pushedAt
        createdAt
        updatedAt
        permalink
        site {
          id
          title
          domain
          platform
          status
          verified
          created_at
          updated_at
        }
        product {
          id
          title
          sku
          variants {
            id
            sku
            product_id
            product_type_id
            image
            design_status
            product {
              id
              title
              is_custom_design
            }
          }
        }
        site {
          id
          title
          domain
          platform
          user {
            id
            first_name
            last_name
            email
          }
          status
          verified
          created_at
          updated_at
        }
        productId
        log
      }
    }
  }
`;
