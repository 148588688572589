import React from "react";
// import MediaSelector from "components/Media/MediaSelector";
import { Avatar } from "antd";
import { BsCardImage } from "react-icons/bs";
import { CLOUDFRONT_URL } from "../../../config";
import CreateFile from "../../upload/CreateFile";
const ImageField = ({ value, onChange, size = 32, style = {} }) => {
  return (
    <div style={style}>
      <CreateFile
        accept=".jpg,.jpeg,.png"
        onChange={(file) => {
          onChange(file.key);
        }}
        loading="true"
      >
        <Avatar
          src={`${CLOUDFRONT_URL}/100/${value}`}
          // onClick={() => setShowMediaSelector(true)}
          icon={<BsCardImage className="anticon" />}
          shape="square"
          style={{ cursor: "pointer" }}
          size={size}
        />
      </CreateFile>
      {/* <Avatar
        src={`${CLOUDFRONT_URL}/${value}`}
        // onClick={() => setShowMediaSelector(true)}
        icon={<BsCardImage className="anticon" />}
        shape="square"
        style={{ cursor: "pointer" }}
        size={size}
      /> */}
      {/* <MediaSelector
        visible={showMediaSelector}
        onCancel={() => setShowMediaSelector(false)}
        onChange={(files) => {
          onChange(files[0].key);
          setShowMediaSelector(false);
        }}
      /> */}
    </div>
  );
};

export default ImageField;
