import React, { useEffect, useRef, useState } from "react";
import { Select, Form, Input, Button, notification, Card, Spin } from 'antd';
import gql from "../../api/gql";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { PRODUCTS } from "../../graphql/queries/product";
const { Option } = Select;

const BundledProducts = (props)=>{
    const [form] = Form.useForm();
    const {currentSite} = props
    const [dataChooseProduct, setDataChooseProduct] = useState([]);
    const [valueInput, setValueInput] = useState('');
    const [errorInputBundled, setErrorInputBundled] = useState('');
    const [Spinloading, setSpinloading] = useState(false);
    var filter
    const currentUser = useSelector((state) => state.app.currentUser);
    var seller = currentUser.roles.map((role) => role.name).includes("Seller");
    if(seller) {
        filter = {
            site_ids: currentSite ? [currentSite.id] : ["1"],
            limit: 1000000000000
        }
    };

    const textInput = useRef('');

    const { data, loading, refetch } = useQuery(PRODUCTS, {
        fetchPolicy: "network-only",
        variables: { filter: filter },
    });

    var loopProducts = {};

    if((typeof data) == 'object'){
        loopProducts = (data.products.hits)
    }

    const handleChange = (value) =>{
        setDataChooseProduct(value)
    }

    useEffect(()=>{
        const dataJson = dataChooseProduct;
        const data = [];
        for(var a=0; a<dataJson.length; a++){
            var item = JSON.parse(dataJson[a]);
            data.push(item);
        }
        props.CallbackLoopProducts(data);
    }, [dataChooseProduct]);

    const onFinishAddID = (v)=> {
        setSpinloading(true)
        setErrorInputBundled('');
        if(!valueInput){
            setErrorInputBundled('Please enter your ID!')
            setSpinloading(false)
        }else{
            const productId = valueInput;
            const queryProduct = `query($id: String!) {
                productByID(id: $id){
                  id
                  title
                  created_at
                  store_product_type
                  product_stores{
                    site_id
                    origin_id
                  }
                }
              }`
    
            gql.request(queryProduct, {
                id: productId
            }).then((res)=> {
                if((typeof res) === "object" && res.productByID.product_stores[0].site_id == currentSite.id){
                    if(dataChooseProduct.includes(JSON.stringify({id:res.productByID.id,origin_id:res.productByID.product_stores[0].origin_id})) == false){
                        const AddItem = JSON.stringify({id:res.productByID.id,origin_id:res.productByID.product_stores[0].origin_id})
                        setDataChooseProduct((dataChooseProduct)=> [
                            ...dataChooseProduct,
                            AddItem
                        ]);
                        notification.success({
                            message: "More successful products!"
                        });
                        setValueInput('')
                        textInput.current.focus();
                    }else{
                        notification.error({
                            message: "Product added!"
                        });
                        setValueInput('')
                        textInput.current.focus();
                    }  
                }else{
                    notification.error({
                        message: "Product does not exist or does not belong to the site!"
                    })
                }
                setSpinloading(false)
            }).catch((err) => {
                notification["error"]({
                  message: "Product does not exist!"
                });
                setSpinloading(false)
              });
        }
    }

    return (
        <div>
        {(typeof data) == 'object' && data.products.count > 0 ? 
        <Card title="Bundled products" style={{ width: "100%", marginTop: 10 }}>
        <div style={{
            marginBottom: "10px"
        }}>
            <div  style={{
                width: "100%",
            }}>
                <div  style={{
                display: "flex",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "row"
            }}>
                    <Input style={{
                        width: "75%"
                    }} ref={textInput} onInput={(v)=> {setValueInput(v.target.value)}} placeholder="Enter product id" value={valueInput} style={{width: "100%"}}  />
                    <Spin size="middle" spinning={Spinloading}>
                        <Button style={{
                            width: "100%",
                            marginLeft: "5px"
                        }} type="primary" onClick={onFinishAddID}>Add</Button>
                    </Spin>
                </div>

                <div style={{
                    color: "coral"
                }} className="errorInputBundled">
                    {errorInputBundled}
                </div>
                
            </div>
            
        </div>
        
        
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Choose a product to go with"
            defaultValue={dataChooseProduct}
            value={dataChooseProduct}
            onChange={handleChange}
            optionLabelProp="label"
        >
            {loopProducts.map(item => (
                <Option key={item.id} value={JSON.stringify({id:item.id,origin_id:item.product_stores[0].origin_id})} label={item.title}>
                    <div>
                    {item.title}
                    </div>
                </Option>
            ))}
        </Select>
        
        </Card>
        : null }
        </div>
    )
}

export default BundledProducts;