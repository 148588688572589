import gql from "graphql-tag";
export const CREATEMOCKUP = gql`
  mutation createMockup(
    $fileID: String!
    $productTypeID: String!
    $productID: String
    $ordering: Int
    $isProductTypeMockup: Boolean
  ) {
    createMockup(
      fileID: $fileID
      productTypeID: $productTypeID
      productID: $productID
      ordering: $ordering
      isProductTypeMockup: $isProductTypeMockup
    ) {
      id
      title
      productType {
        id
        product_type_variants {
          id
          product_type_id
          sku
          base_cost
          sale_cost
          retail_cost
          attributes {
            name
            slug
            option
          }
          active: is_active
        }
      }
      productID
      width
      height
      preview
      layers
      settings {
        defaultBgColor
      }
      ordering
      status
    }
  }
`;
