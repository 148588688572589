import React from "react";

let id = 0;
class ItemProduct extends React.Component {
  state = {
    data: [],
    items: [],
  };
  remove = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = (values) => {
    const { keys, names } = values;
    console.log("Received values of form: ", values);
    console.log(
      "Merged values:",
      keys.map((key) => names[key])
    );
  };

  handleOk = (e) => {};

  handleCancel = (e) => {};
  onSubmit = () => {
    const value = this.props.form.getFieldsValue(["name", "slug", "option"]);
    if (this.props.onSubmit) {
      this.props.onSubmit(value);
    }
  };
  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onFocus = () => {
    this.setState({ onShow: true });
  };
  onSearch = (val) => {
    console.log("search:", val);
  };

  addItem = () => {
    const { items } = this.state;
    this.setState({
      items: [...items, this.state.data],
      data: "",
    });
  };
  render() {
    return <div></div>;
  }
}

export default ItemProduct;
