import React, { Component } from "react";
import { Form, Input, Button, Select, notification } from "antd";
import _ from "lodash";
import gql from "../../api/gql";
import shortuuid from "short-uuid";
import { SHOPIFY_API, API_URL, APP_URL, SHOPBASE_API } from "../../config";
class SiteAdd extends Component {
  state = {
    loading: false,
    selectedPlatform: null,
    textDomain: false,
    text: null,
  };

  handleConnect = (values) => {
    const query = `mutation createSite($title: String!, $domain: String!, $platform: String!, $isConnect: Boolean!){
          createSite(title: $title, domain: $domain, platform: $platform, isConnect: $isConnect){
            id
            platform
            domain
          }
        }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        ...values,
        isConnect: this.state.selectedPlatform !== "import" ? true : false,
        domain:
          this.state.selectedPlatform !== "import"
            ? values.domain.replace(/\/$/, "")
            : shortuuid.generate(),
      })
      .then((res) => {
        this.setState({ loading: false });
        if (this.state.selectedPlatform !== "import") {
          const { id, platform, domain } = res.createSite;
          switch (platform) {
            case "woocommerce":
              var app_name = "POD Fulfillment";
              var return_url = `${APP_URL}/sites`;
              var callback_url = `${API_URL}/auth_callback/${id}`;
              var redirectUrl = `${domain}/wc-auth/v1/authorize?app_name=${app_name}&scope=read_write&user_id=1&return_url=${return_url}&callback_url=${callback_url}`;
              window.location = redirectUrl;
              break;
            case "shopify":
              var url = domain;
              var redirect_uri = `${API_URL}/auth_callback/shopify`;
              //var scopes = "write_products,write_orders,write_fulfillments";
		          var scopes = "read_script_tags,write_script_tags,read_products,write_products,read_product_listings,read_collection_listings,read_orders,write_orders,read_customers,read_themes,write_themes,read_content,read_fulfillments,write_fulfillments,write_merchant_managed_fulfillment_orders,read_shipping,write_shipping,read_discounts,write_discounts,read_inventory"
              var nonce = id;
              window.location = `${url}/admin/oauth/authorize?client_id=${SHOPIFY_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
              break;
            case "shopbase":
              // var url = domain
              // var redirect_uri = `${API_URL}/auth_callback/shopbase`
              // var scopes = 'write_products,write_orders,write_fulfillments'
              // var nonce = id
              window.location = `${url}/admin/oauth/authorize?client_id=${SHOPBASE_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
              break;
            default:
              break;
          }
        } else {
          notification["success"]({
            message: "Create site successful",
          });
          this.props.onSuccess();
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const platforms = [
      {
        title: "Shopify",
        value: "shopify",
      },
      {
        title: "Wordpress Woocommerce",
        value: "woocommerce",
      },
    ];
    return (
      <Form
        layout="vertical"
        onFinish={(value) => {
          this.handleConnect(value);
        }}
      >
        <Form.Item
          label="Site Name"
          name="title"
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Platform"
          name="platform"
          rules={[{ required: true, message: "Please input platform!" }]}
        >
          <Select
            onChange={(e) => {
              this.setState({ selectedPlatform: e });
            }}
          >
            {platforms.map((pf) => (
              <Select.Option key={pf.value}>{pf.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        {this.state.selectedPlatform !== "import" && (
          <Form.Item
            label="Site Url"
            name="domain"
            rules={[
              {
                required: true,
                message: "Please enter a store URL!",
              },
              {
                type: "url",
                message:
                  "Please enter a valid store URL! HTTPS protocol is required!",
              },
            ]}
          >
            <Input placeholder="https://" />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {this.state.selectedPlatform === "import" ? "Create" : "Connect"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default SiteAdd;
