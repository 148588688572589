import { Button, Input, Modal, notification, Table, Radio, Divider, Form, Spin } from "antd";
import {cloneDeep, get, pick, random, remove, union } from "lodash";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Layers from "./Layers";
import gql from "../../api/gql";
import { cloneLayer, getIDs, initNewLayer, uniqueID } from "./Utilities/helper";
import shortUUID from "short-uuid";
import { ARTWORK } from "../../types";
const Container = styled.div`
  height: 100%;
  padding-top: 9px;
  border-left: 1px solid #d9d9d9;
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse {
    background-color: transparent;
    border-radius: 0;
    border: none;
    .ant-collapse-item {
      border-color: hsla(0, 0%, 100%, 0.07);
      &:last-child {
        border-radius: 0;
      }
    }
    .ant-collapse-content {
      border-radius: 0;
      .ant-form-item {
        margin-bottom: 10px;
      }
    }
  }
  .ant-tabs {
    min-height: 100%;
    &.canvas-left-toolbars {
      .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-nav-list {
        svg {
          font-size: 24px;
        }
      }
      .ant-tabs-content-holder {
        border-left: none;
        margin-left: 0;
        overflow-y: auto;
      }
      .ant-tabs-tabpane {
        padding-left: 0;
      }
      .ant-collapse {
        background-color: transparent;
        border-radius: 0;
        border: none;
        .ant-collapse-item {
          border-color: hsla(0, 0%, 100%, 0.07);
          &:last-child {
            border-radius: 0;
          }
        }
        .ant-collapse-content {
          border-radius: 0;
          .ant-form-item {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

const LeftToolbar = (props) => {
  const { workspace } = props;
  // const dispatch = useDispatch();
  const { artwork, selectedTemplate } = workspace;
  const layers = get(artwork, `[${selectedTemplate}].layers`, []);
  
  const [isModelLayerArtwork, setIsModelLayerArtwork] = useState(false);
  const [valueArtworkIdInput, setValueArtworkIdInput] = useState('');
  const [Spinloading, setSpinloading] = useState(false);
  const [onSelectedRows, setOnSelectedRows] = useState([]);
  const [dataQuery, setDataQuery] = useState([]);
  const [idArtwork, setIdArtwork] = useState('');
  const dispatch = useDispatch();

  var idArtworks 
  if('templates' in artwork){
    const layerArt = artwork.templates[0].layers;
    if(typeof layerArt == "object"){
      for(var a=0;a<layerArt.length;a++){
        if('parentArtwork' in layerArt[a]){
          if(idArtworks == null){  
            idArtworks = layerArt[a].parentArtwork;
          }
        }
      }
    }
  }
  
  
  const OnSubmitGetArtWork = (v)=>{
    setSpinloading(true)
    const queryArtWork = `query($id: String!){
      artwork(id: $id) {
        id
        title
        categories {
          id
          title
        }
        width
        height
        templates {
          title
          isDefault
          layers
          preview
          thumbnail
        }
        lock
        sharedLayers
        templateDisplayMode
        templateDisplayLabel
      }
    }`;
    var idArt
    if(idArtworks){
      idArt = idArtworks;
    }else{
      idArt = v.artworkid;
    }

    gql.request(queryArtWork, {
      id: idArt
    })
    .then((res) => {
      setIsModelLayerArtwork(false) 
      if((typeof res) === "object" ){
        setIsModelLayerArtwork(true) 
        setIdArtwork(v.artworkid)
        const LayersData = res.artwork.templates[0].layers;
          var ArrayLayer = [];
          for(var a=0; a < LayersData.length; a++){
            LayersData[a].key = a;
            LayersData[a].parentArtwork = v.artworkid;
            if(LayersData[a].type == "Group" || LayersData[a].type == "Option"){
              ArrayLayer = [...ArrayLayer, LayersData[a]];
            }else{
              if('personalized' in LayersData[a] || LayersData[a].type == "Group"){
                if(LayersData[a].personalized.enable == true){
                  ArrayLayer = [...ArrayLayer, LayersData[a]];
                }
              }
            }
          }
          setDataQuery(ArrayLayer);
          setSpinloading(false)
          
      }else{
        notification.error({
          message: "Artwork does not exist!"
        });
        setSpinloading(false)

      }
    })
    .catch((err) => {
      notification["error"]({
        message: "Artwork does not exist!"
      });
      setSpinloading(false)
    });
   }

   interface DataTypes {
    key: React.Key;
    tite: string;
  }

   const columns:DataType[] = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    }
  ];

  var datas = dataQuery

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setOnSelectedRows(selectedRows)
    },
  };

  const OnApplyLayer = ()=>{
    const LayerSelected = onSelectedRows;
    const layer = artwork.templates[0].layers;

    const ArrayId = [];

    for(var l=0; l<layer.length;l++){
      ArrayId.push(layer[l].id)
    }
    for(var i=0; i<LayerSelected.length; i++){
      const LayerItem = LayerSelected[i];
      const checkId = ArrayId.includes(LayerSelected[i].id);
      if(checkId == false){
        const newLayer = initNewLayer();
        for(var a in LayerItem){
          newLayer[a] = LayerItem[a]
        }
        dispatch({
          type: ARTWORK.ADD_LAYER,
          payload: newLayer,
        });
        notification.success({
          message: "Apply "+LayerSelected[i].title+" success!"
        })
      }else{
        notification.error({
          message: "Layer id for "+LayerSelected[i].title+" already exists!"
        });
      }
    }

    setIsModelLayerArtwork(false)
  }

  const OnCancelLayer = ()=>{
    setIsModelLayerArtwork(false)
  }


  return (
    <Container>
      <div style={{ borderBottom: "1px solid #ddd" }}>
        <h2 style={{ marginLeft: 10 }}>Layers</h2>
      </div>
      <div style={{
        display: "flex",
        padding: "7px"
      }}>
        <Form 
        style={{
          display: "flex",
          width: "100%"
        }}
        onFinish={OnSubmitGetArtWork}
        autoComplete="off"
        >
          {idArtworks == null ?
            <Form.Item
            name="artworkid"
            rules={[{ required: true, message: 'Please enter artwork ID!' }]}
            style={{
              width: "75%"
            }}
            >
              <Input placeholder="Please enter artwork ID!"/>
            </Form.Item>
          : null}

          {idArtworks != null ?
            <Form.Item
            name="artworkid"
            style={{
              width: "75%"
            }}
            value={idArtworks}
            >
              <Input style={{
                background: "#ececec",
                cursor: "not-allowed"
              }} value={idArtworks} defaultValue={idArtworks} readOnly placeholder="Please enter artwork ID!"/>
            </Form.Item>
          : null}
          <Form.Item
          style={{
            width: "25%",
            paddingLeft: "5px"
          }}
          >
            <Spin size="middle" spinning={Spinloading}>
              <Button
              className="ant-btn ant-btn-primary" htmlType="submit"
              style={{
                width: "100%"
              }}
              >Submit
              </Button>
            </Spin>
           
          </Form.Item>
        </Form>
    
        <Modal 
        width={700} 
        title={"Layer list of ID" + valueArtworkIdInput}
        visible={isModelLayerArtwork} 
        onCancel={OnCancelLayer} 
        onOk={OnApplyLayer}
        okText="Apply"
        style={{ top: 20, position: "relative" }}
        >
          <Spin size="middle" spinning={Spinloading}>
                
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={datas}
          />
          </Spin>
        </Modal>
      </div>
      <div>
        <Layers layers={layers} />
      </div>
      {/* <Tabs
        tabPosition="top"
        className="canvas-left-toolbars"
        tabBarStyle={{ marginBottom: 0 }}
        type="card"
        tabBarStyle={{ marginLeft: 5, marginBottom: 0 }}
        onChange={(key) => {
          if (key === "shared-layers") {
            dispatch({
              type: ARTWORK.SET_SELECTED_TEMPLATE,
              payload: -1,
            });
          } else if (selectedTemplate === -1) {
            dispatch({
              type: ARTWORK.SET_SELECTED_TEMPLATE,
              payload: 0,
            });
          }
        }}
      >
        <Tabs.TabPane key="layers" tab="Layers">
          <Layers layers={layers} />
        </Tabs.TabPane>
        <Tabs.TabPane key="settings" tab="Template Settings">
          <TemplateSettingsForm />
        </Tabs.TabPane>
      </Tabs> */}
    </Container>
  );
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(LeftToolbar);
