import React, { useState, useEffect } from "react";
import ViewImageGeneration from "./ViewImageGeneration";
import ImagineGeneration from "./function-generation/ImagineGeneration";
import BlendGeneration from "./function-generation/BlendGeneration";
import DescribeGeneration from "./function-generation/DescribeGeneration";
import { URL_API_IMAGE_GENERATION_STREAM } from "../../../config";
import { CloseOutlined } from "@ant-design/icons";
import { SSE } from "../../../sse";
import { Row, Col, Tabs, Spin, Button, message, Progress } from "antd";
import SelectServerId from "./components/SelectServerId";
import { get } from "lodash";

export default function AIMidjourney() {
  const [data, setData] = useState({});
  const timeCout = 10;
  const [timeNext, setTimeNext] = useState(0);
  var localServerID = localStorage.getItem("_server_midjourney_id");
  if (!localServerID) {
    localServerID = "1";
  }
  const [serverID, setServerID] = useState(localServerID);
  const [loading, setLoading] = useState(false);
  const handleChange = (value) => {
    setData(value);
  };

  useEffect(() => {
    const value = data;
    if (
      get(value, "status") &&
      get(value, "status") == "done" &&
      get(value, "progress") &&
      get(value, "progress") == "100%"
    ) {
      renserTime();
    }
  }, [data]);

  const renserTime = () => {
    var time = timeCout;
    const i = setInterval(() => {
      setTimeNext(time);
      if (time <= 0) {
        setTimeNext(0);
        clearInterval(i);
      }
      time--;
    }, 1000);
  };
  const handeSelectOption = (v) => {
    if (v) {
      setData({ ...data, progress: "0%" });
      setLoading(true);
      v.caseFunction = "custom";
      v.action = "custom";
      if (v.descriptions) {
        v.action = "imagine";
        v.aspect = "1:1";
      }
      if (v?.picreadReset) {
        v.action = "describe";
        v.image = v.form.image;
      }
      var source = new SSE(URL_API_IMAGE_GENERATION_STREAM, {
        method: "POST",
        payload: JSON.stringify(v),
      });
      window.sourceMidjourney = source;
      source.addEventListener("error", function (e) {
        message.error("An error occurred, Please try again!");
        setLoading(false);
      });
      source.addEventListener("message", function (e) {
        if (e?.data) {
          const data = JSON.parse(e.data);
          if (v?.form) {
            data.form = v?.form;
          }
          if (data.status == "done") {
            data.progress = "100%";
            setLoading(false);
          }
          setData(data);
        } else {
          setLoading(false);
        }
      });
      source.stream();
    }
  };
  const handleChangeLoading = (v) => {
    setLoading(v);
  };

  const handleCancel = () => {
    if (window.sourceMidjourney) {
      window.sourceMidjourney.close();
    }
    setLoading(false);
    setData({ ...data, progress: "100%" });
  };

  return (
    <div>
      <Row>
        <Col
          span={12}
          style={{
            position: "relative",
          }}
        >
          {loading && (
            <div className="box-loading-generation">
              <p
                style={{
                  marginBottom: 0,
                  textAlign: "center",
                  color: "#894e00",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Images are being processed. <br /> Please wait a moment!
              </p>
              <Spin>
                <div
                  style={{
                    height: 40,
                    marginBottom: 10,
                  }}
                ></div>
              </Spin>
              <Button icon={<CloseOutlined />} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          )}
          <div className={`${loading ? "loadingGeneration" : ""}`}>
            <SelectServerId
              serverID={serverID}
              onSelect={(v) => {
                setServerID(v);
                localStorage.setItem("_server_midjourney_id", v);
              }}
            />
            <Tabs defaultActiveKey="item-1">
              <Tabs.TabPane tab="Imagine" key="item-1">
                <ImagineGeneration
                  onChange={handleChange}
                  spinning={loading}
                  onChangeLoading={handleChangeLoading}
                  emitdata={data}
                  serverID={serverID}
                  timeNext={timeNext}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Blend" key="item-2">
                <BlendGeneration
                  onChange={handleChange}
                  spinning={loading}
                  onChangeLoading={handleChangeLoading}
                  emitdata={data}
                  serverID={serverID}
                  timeNext={timeNext}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Describe" key="item-3">
                <DescribeGeneration
                  onChange={handleChange}
                  spinning={loading}
                  onChangeLoading={handleChangeLoading}
                  emitdata={data}
                  serverID={serverID}
                  timeNext={timeNext}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Col>
        <Col span={12}>
          <ViewImageGeneration
            onChangeLoading={(v) => {
              handleChangeLoading(v);
              setData({ ...data, progress: "100%" });
            }}
            onChangeData={(v) => {
              setData(v);
            }}
            data={data}
            onSelect={handeSelectOption}
            loading={loading}
            timeNext={timeNext}
          />
        </Col>
      </Row>
    </div>
  );
}
