import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Popconfirm } from "antd";
import {
  BsFillClockFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsFillTrashFill,
} from "react-icons/bs";
export default function HistoryGeneration({
  history,
  onSelect,
  onDeleteAll,
  onDeleteItem,
}) {
  const [currentIndex, setCurrentIndex] = useState(history.length - 1);
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const slider = useRef();

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      updateSlider(currentIndex);
    }, 1000);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const convertToHtmlLink = (text) => {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/g;
    const htmlText = text.replace(
      pattern,
      '<a  target="_blank" href="$2">$1</a>'
    );
    return htmlText;
  };

  const handleNext = () => {
    if (currentIndex < history.length - 1) {
      const newValue = currentIndex + 1;
      setCurrentIndex(newValue);
      updateSlider(newValue);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      const newValue = currentIndex - 1;
      setCurrentIndex(newValue);
      updateSlider(newValue);
    }
  };

  const updateSlider = (v) => {
    const offset = -v * 500; // 300 is the height of each slide
    slider.current.style.transform = `translateY(${offset}px)`;
  };

  const confirm = () => {
    setOpen(false);
    onDeleteAll(true);
  };

  const handleViewInfo = () => {
    if(openInfo){
      setOpenInfo(false);
      return;
    }
    setOpenInfo(true);
  }

  const getDataInfo = (data) => {
    var info = [];
    const keys = ['id', 'height', 'width', 'progress', 'prompt', 'serverId'];
    for (const [key, value] of Object.entries(data)) {
      if(keys.includes(key)){
        info = [...info, {
          name: key,
          value
        }]
      }
    }
    return info;
  }

  return (
    <>
      <div onClick={showModal} className="clock-history-btn">
        <BsFillClockFill
          size={35}
          style={{
            marginRight: "5px",
            fontWeight: "bold",
          }}
        />{" "}
        History
      </div>
      <Modal
        title="Basic Modal"
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        className="model-history"
        closable={false}
        width={700}
      >
        <div className="slider-container-history">
          <div>
            <button
              onClick={handleCancel}
              style={{
                zIndex: 9999999,
                position: "absolute",
                top: 2,
                right: 2,
                height: "35px",
                width: "35px",
                background: "#fff",
                border: "none",
                outline: "none",
                cursor: "pointer",
                fontSize: "20px",
                boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
                borderRadius: "3px",
              }}
            >
              &#10005;
            </button>
          </div>
          <div className="trash-all-history">
            <Popconfirm
              placement="left"
              title={"Are you sure you want to delete all history?"}
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
            >
              <div className="btn-trash-all-history" title="Delete all history">
                <BsFillTrashFill
                  style={{
                    marginRight: "5px",
                  }}
                />{" "}
                Delete all
              </div>
            </Popconfirm>
          </div>
          <div ref={slider} className="slider-history">
            {history.map((item, index) => (
              <div key={index} className="slide-history">
                {
                  openInfo && (
                    <div className="container_infomation">
                      <ul className="container_infomation__ul">
                        {getDataInfo(item).map((item, index) => (
                          <li className="container_infomation__li" key={index}>
                            <strong style={{
                              textTransform: 'capitalize'
                            }}>{(item.name)}: </strong>
                            <span>{item.value}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                }
                <div className="information-item">
                  <Button type={openInfo ? 'primary' : 'default'} className="btn_open_info" onClick={() => handleViewInfo(item)}>
                      {openInfo ? 'Close' : 'Information'}
                  </Button>
                 
                </div>
                
                <div className="slide-history-item">
                  {item?.descriptions ? (
                    <ul className="slide-history-list">
                      {item?.descriptions.map((e, inx) => (
                        <li
                          key={inx}
                          dangerouslySetInnerHTML={{
                            __html: convertToHtmlLink(e),
                          }}
                        ></li>
                      ))}
                    </ul>
                  ) : (
                    <img
                      className="slide-history-img"
                      style={{
                        width: "100%",
                      }}
                      src={item?.uri}
                    />
                  )}
                </div>
                <div className="box-slide-history-choose">
                  <div
                    onClick={() => {
                      onDeleteItem(index);
                      if (index == 0 && history.length >= 1) {
                        setCurrentIndex(0);
                        updateSlider(0);
                      }
                      if (history.length > 1 && index > 0) {
                        setCurrentIndex(index - 1);
                        updateSlider(index - 1);
                      }
                    }}
                    className="btn-delet-item-history"
                  >
                    <BsFillTrashFill />
                  </div>
                  <button
                    onClick={() => {
                      onSelect(item);
                      setOpen(false);
                    }}
                  >
                    Choose
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="group-btn-slider">
            {currentIndex > 0 && (
              <button onClick={handlePrev} id="prev">
                <BsFillCaretUpFill />
              </button>
            )}
            {currentIndex < history.length - 1 && (
              <button onClick={handleNext} id="next">
                <BsFillCaretDownFill />
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
