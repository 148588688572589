import React, {useRef, useState, useEffect} from "react";
import {URL_API_UPLOAD_FILE} from "../../../config";
import {CloseOutlined, UploadOutlined} from "@ant-design/icons";
import {Button} from "antd";
import axios from "axios";

const UploadSVGLyric = ({value, onChange, onChangeLoading}) => {
    const refInput = useRef();
    const [loading, setLoading] = useState(false);
    const [nameUpload, setNameUpload] = useState("Upload SVG");

    useEffect(()=> {
        if(value.name != null){
            setNameUpload(value.name)
        }
    }, [value]);

    useEffect(() => {
        onChangeLoading(loading)
    }, [loading])

    const handleRemoveFile = () => {
        setNameUpload("Upload SVG");
        onChange({name: null, value: ""});
    }

    const uploadFile = async (files) => {
        var formData = new FormData();
        formData.append("file", files);
        const upload = await axios.post(URL_API_UPLOAD_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (res){
            if(res.status == 200){
                return {
                    status: true,
                    url: res.data.url
                }
            }else {
                return {
                    status: false
                }
            }
        });
        return upload;
    }

    const handleChangeFile = async (value) => {
        setLoading(true);
        if(value.target.files.length > 0){
            const files = value.target.files[0];
            const checkType = files.name.toLowerCase().indexOf('.svg');
            if(checkType >= 0){
                const uploadFILE = await uploadFile(files);
                if(uploadFILE.status){
                    const newValue = {
                        name: files.name,
                        value: uploadFILE.url
                    }
                    onChange(newValue);
                }
            }else {
                alert('Please upload the correct font format (.svg)!');
            }
        }
        setTimeout(()=>{
            setLoading(false);
        }, 2000)

    }

    const OnClickUpload = () => {
        refInput.current.click();
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <Button type="primary" style={{
                background: "#535553",
                borderColor: "#535553"
            }} loading={loading} onClick={OnClickUpload} icon={<UploadOutlined />}>
                {nameUpload}
            </Button>
            <input style={{display: "none"}} type={"file"} ref={refInput} multiple={false} onChange={handleChangeFile}/>
            {value.name !== null ?
                <div onClick={handleRemoveFile} className="button-close-upload-file"><CloseOutlined /></div>
            : null}
        </div>
    )
}

export default UploadSVGLyric;