import { FilterOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Input, Popover, TreeSelect, Form } from "antd";
import React from "react";
import styled from "styled-components";
import { ARTWORK_CATEGORY } from "../../graphql/queries/artwork/artworkCategories";
import { history } from "../../history";
import { connect } from "react-redux";
// import { cloneDeep } from "lodash";
const Container = styled.div`
  .ant-form {
    display: flex;
  }
`;
const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      propName === "page" ||
      propName === "pageSize" ||
      propName === "seller_ids"
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const FilterSearch = (props) => {
  const { setFilter, filter, categoryID, currentUser, setCategoryID } = props;
  const seller = currentUser?.roles?.find((role) => role.name === "Seller");
  const [form] = Form.useForm();
  const { data } = useQuery(ARTWORK_CATEGORY, {
    variables: {
      userID: seller ? currentUser.id : null,
    },
  });

  const renderTree = (cats) => {
    return cats?.map((cat) => (
      <TreeSelect.TreeNode
        key={cat.id ? cat.id : "-"}
        value={cat.id}
        title={cat.title}
      >
        {cat.children && renderTree(cat.children)}
      </TreeSelect.TreeNode>
    ));
  };
  const onSearchChange = (value) => {
    setCategoryID(value.categoryID);
    setFilter({ ...filter, categoryID: value.categoryID, page: 1 });
    history.push({
      pathname: "/artworks",
      search:
        "?" +
        new URLSearchParams(
          clean({ ...filter, categoryID: value.categoryID })
        ).toString(),
    });
  };
  return (
    <Container>
      <div className="header-filter">
        <Input.Search
          style={{ marginBottom: 10 }}
          placeholder="Search..."
          onChange={(e) => {
            if (e.target.value) {
              setFilter({ ...filter, search: e.target.value, page: 1 });
              history.push({
                pathname: "/artworks",
                search:
                  "?" +
                  new URLSearchParams(
                    clean({ ...filter, search: e.target.value })
                  ).toString(),
              });
            } else {
              setFilter({ ...filter, search: null, page: 1 });
              history.push({
                pathname: "/artworks",
                search:
                  "?" +
                  new URLSearchParams(
                    clean({ ...filter, search: null })
                  ).toString(),
              });
            }
          }}
          addonBefore={
            <Popover
              trigger="click"
              placement="bottomLeft"
              content={
                <div style={{ width: 300 }}>
                  <Form
                    form={form}
                    name="control-hooks"
                    onValuesChange={onSearchChange}
                    layout="vertical"
                  >
                    <Form.Item
                      name="categoryID"
                      label="Category"
                      initialValue={categoryID ? categoryID : null}
                    >
                      <TreeSelect
                        style={{ width: "100%" }}
                        showSearch={true}
                        multiple={false}
                        treeNodeFilterProp="title"
                        treeDefaultExpandAll={true}
                        allowClear
                      >
                        {renderTree(data?.artworkCategories)}
                      </TreeSelect>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="button"
                        onClick={() => {
                          form.resetFields();
                          form.setFieldsValue({ categoryID: null });
                          if (filter.search) {
                            history.push({
                              pathname: "/artworks",
                              search: `?search=${filter.search}`,
                            });
                          } else {
                            history.push({
                              pathname: "/artworks",
                              search: ``,
                            });
                          }
                        }}
                      >
                        Reset Filter
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              }
            >
              Filter <FilterOutlined />
            </Popover>
          }
        />
      </div>
    </Container>
  );
};

export default connect((state) => {
  return {
    currentUser: state.app.currentUser,
    currentSite: state.app.currentSite,
  };
})(FilterSearch);
