import gql from "graphql-tag";

export const CAMPAIGN_STORE_SUBCRIPTION = gql`
  subscription campaignStoreChanged {
    campaignStoreChanged {
      id
      siteId
      campaignId
      status
      pushFirstTime
      pushedAt
      createdAt
      updatedAt
      permalink
      productId
      log
    }
  }
`;
