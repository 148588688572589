import React from "react";
const {Marked} = window.marked;
var {markedHighlight} = window.markedHighlight
var hljs = window.hljs;
export default function MarkdownPreviewer({children}){
  
const marked = new Marked(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang, info) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    }
  })
);
  var renderer = new marked.Renderer();
  renderer.list = function (body, ordered, start) {
    var type = ordered ? "ol" : "ul";
    return `<ul class="custom-list">${body}</ul>`;
  };
  marked.use({
    renderer: renderer,
    gfm: true, 
    tables: true, 
    breaks: true, 
    pedantic: false,
    sanitize: true, 
    smartLists: true,
    smartypants: false,
  });

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: marked.parse(children ? children : ""),
      }}
    ></div>
  );
}