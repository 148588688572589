import { Button, Card, Form, Popover } from "antd";
import { debounce, get, merge } from "lodash";
import React, { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ARTWORK } from "../../../types";
import Grid from "../../Grid";
import Slider from "./Slider";

const Container = styled.div`
  .ant-card {
    cursor: pointer;
    .ant-card-body {
      transition: all 0.2s ease;
    }
  }
  .selected {
    .ant-card-body {
      background: var(--primary-color);
      .ant-card-meta-description {
        color: #fff;
      }
    }
  }
`;
const filterProperties = {
  Blur: [
    {
      sample: "/sample-blur.png",
      key: "blurRadius",
      title: "Blur Radius",
      min: 0,
      max: 40,
      step: 0.05,
      default: 10,
    },
  ],
  Brighten: [
    {
      sample: "/sample-brighten.png",
      key: "brightness",
      title: "Brightness",
      min: -1,
      max: 1,
      step: 0.05,
      default: 0.1,
    },
  ],
  Contrast: [
    {
      sample: "/sample-contrast.png",
      key: "contrast",
      title: "Contrast",
      min: -100,
      max: 100,
      step: 1,
      default: 15,
    },
  ],
  // Emboss: [
  //   {
  //     key: "embossStrength",
  //     title: "Strength",
  //     min: 0,
  //     max: 10,
  //     step: 0.01,
  //   },
  //   {
  //     key: "embossWhiteLevel",
  //     title: "White Level",
  //     min: 0,
  //     max: 1,
  //     step: 0.01,
  //   },
  // ],
  Enhance: [
    {
      sample: "/sample-enhance.png",
      key: "enhance",
      title: "Enhance",
      min: -1,
      max: 1,
      step: 0.01,
      default: -0.5,
    },
  ],
  Grayscale: [
    {
      sample: "/sample-grayscale.png",
    },
  ],
  Pixelate: [
    {
      sample: "/sample-pixelate.png",
      key: "pixelSize",
      title: "Size",
      min: 1,
      max: 20,
      step: 1,
      default: 4,
    },
  ],
  HSV: [
    {
      sample: "/sample-hsv.png",
      key: "value",
      title: "Value",
      min: -2,
      max: 2,
      step: 0.1,
      default: -1.1
    }
  ],
  Invert: [
    {
      sample: "/sample-invert.png",
    }
  ],
  RGBA: [
    {
      sample: "/sample-rgba.png",
      key: "red",
      title: "Red",
      min: 0,
      max: 255,
      step: 1,
      default: 135
    },
    {
      key: "blue",
      title: "Blue",
      min: 0,
      max: 255,
      step: 1,
      default: 135
    },
    {
      key: "green",
      title: "Green",
      min: 0,
      max: 255,
      step: 0.01,
      default: 135
    },
    {
      key: "alpha",
      title: "Alpha",
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.25
    },
  ],
  RGB: [
    {
      sample: "/sample-rgb.png",
      key: "red",
      title: "Red",
      min: 0,
      max: 255,
      step: 1,
      default: 135
    },
    {
      key: "blue",
      title: "Blue",
      min: 0,
      max: 255,
      step: 0.01,
      default: 127.5
    },
    {
      key: "green",
      title: "Green",
      min: 0,
      max: 255,
      step: 0.01,
      default: 127.5
    },
  ],
  Sepia: [
    {
      sample: "/sample-sepia.png"
    }
  ],
  Solarize: [
    {
      sample: "/sample-solarize.png"
    }
  ],
  Classic: [
    {
      sample: "/sample-classic.png",
      key: "blue",
      title: "Blue",
      min: 0,
      max: 255,
      step: 0.01,
      default: 70
    },
    {
      key: "red",
      title: "Red",
      min: 0,
      max: 255,
      step: 0.01,
      default: 80
    },
    {
      key: "green",
      title: "Green",
      min: 0,
      max: 255,
      step: 0.01,
      default: 74
    },
    {
      key: "brightness",
      title: "Brightness",
      min: -1,
      max: 1,
      step: 0.01,
      default: 0.26,
    },
    {
      key: "contrast",
      title: "Contrast",
      min: -100,
      max: 100,
      step: 0.1,
      default: 48.5,
    },
    {
      key: "value",
      title: "Value",
      min: -2,
      max: 2,
      step: 0.01,
      default: 0.75
    },
    {
      key: "luminance",
      title: "Luminance",
      min: -2,
      max: 2,
      step: 0.01,
      default: 0.11
    }
  ]
};

const ImageFilter = ({ layer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const filter = get(layer, "_filters[0]");
  const onUpdate = (_, values) => {
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        ...values,
      },
    });
  };
  useEffect(() => {
    form.setFieldsValue(layer);
  }, [layer]);
  const filterForm = (filter) => {
    const properties = get(filterProperties, get(layer, "_filtersMethods[0]", filter), []);
    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={layer}
            onValuesChange={debounce(onUpdate, 50)}
            style={{ width: 300 }}
        >
          {properties
              .filter((pro) => pro.key)
              .map((pro) => (
                  <Form.Item label={pro.title} name={pro.key} key={pro.key}>
                    <Slider min={pro.min} max={pro.max} step={pro.step} />
                  </Form.Item>
              ))}
        </Form>
    );
  };

  return (
      <Popover
          content={
            <Scrollbars
                style={{ width: 300 }}
                autoHeight={true}
                autoHeightMax={"calc(100vh - 300px)"}
            >
              <Container>
                <Grid style={{
                  height: "240px",
                  overflow: "auto"
                }} width={90} gap={5}>
                  <Card
                      cover={<img src="/sample.webp" alt="" size="small" />}
                      bodyStyle={{ margin: 0, padding: 5, textAlign: "center" }}
                      className={filter ? "" : "selected"}
                      onClick={() => {
                        dispatch({
                          type: ARTWORK.SET_LAYER,
                          payload: {
                            ...layer,
                            _filters: [],
                          },
                        });
                      }}
                  >
                    <Card.Meta description={"Normal"} size="small" />
                  </Card>
                  {Object.keys(filterProperties).map((key) => (
                      <Card
                          key={key}
                          cover={
                            <img
                                src={`${
                                    filterProperties[key][0].sample || "/sample.webp"
                                }`}
                                alt=""
                                size="small"
                            />
                          }
                          bodyStyle={{ margin: 0, padding: 5, textAlign: "center" }}
                          className={
                            get(layer, "_filtersMethods[0]", get(layer, "_filters[0]")) === key ? "selected" : ""
                          }
                          onClick={() => {
                            let filtersMethods = [key];
                            switch (key) {
                              case 'Classic':
                                filtersMethods = ['RGB', 'Brighten', 'Contrast', 'HSV', 'HSL'];
                                break;
                            }
                            dispatch({
                              type: ARTWORK.SET_LAYER,
                              payload: {
                                ...merge(
                                    ...filterProperties[key].map((p) => {
                                      var obj = {};
                                      if (p.default) {
                                        obj[p.key] = p.default;
                                      }
                                      return obj;
                                    })
                                ),
                                ...layer,
                                _filtersMethods: [key],
                                _filters: filtersMethods,
                              },
                            });
                          }}
                      >
                        <Card.Meta description={key} size="small" />
                      </Card>
                  ))}
                </Grid>
                {filter ? filterForm(filter) : null}
              </Container>
            </Scrollbars>
          }
      >
        <Button type="link">Filter</Button>
      </Popover>
  );
};

export default ImageFilter;
