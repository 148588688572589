import React, { useEffect, useState } from "react";
import UploadMultiple from "./UploadMultiple";
import { FaPlus } from "react-icons/fa";
import { message } from "antd";

const AddBackgroundSrabble = ({ value, onChange }) => {
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState([]);
  const handleSuccessUpload = () => {
    setLoadingFile(false);
    const fileUp = files.map((e) => e.url);
    onChange([...value, ...fileUp]);
    setFiles([]);
    setLoadingFile(false);
    const key = "uploading_photos";
    message.success({
      content: "Uploading image successfully!",
      key,
      duration: 2,
    });
  };
  useEffect(() => {
    if (files.length > 0) {
      setLoadingFile(true);
    } else {
      setLoadingFile(false);
    }
  }, [files]);
  return (
    <>
      <div style={{
        position: "absolute",
        top: 0,
        zIndex: 999,
      }}>
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 5,
          }}
        >
          <li className="li-select-background-autobanner">
            <UploadMultiple
              disabled={loadingFile}
              accept=".png, .jpg, .jpeg"
              files={files}
              setFiles={setFiles}
              onSuccess={handleSuccessUpload}
            >
              <div
                style={{
                  padding: "10px 5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaPlus size={20} />
              </div>
            </UploadMultiple>
          </li>
        </ul>
      </div>
    </>
  );
};
export default AddBackgroundSrabble;
