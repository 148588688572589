import React, { useState, useEffect } from "react";
import Design from "./components/Design";
import { Col, Row } from "antd";
import DesignsCategorys from "./category";
import ChooseImage from "./chooseImage";
import ControllerDesign from "./components/ControllerDesign";
const DesignBanner = () => {
  const [selectedBackground, setSelectedBackground] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [configsImage, setConfigsImage] = useState({});
  const [backgroundDesign, setBackgroundDesign] = useState("https://d111oohu250q1p.cloudfront.net/c1370a70-7bd2-11ef-a3e4-4e5b5c96fb45__62wKGB9N7yWkCKo.png");
  const [draggableImageUrl, setDraggableImageUrl] = useState({
    width: 400,
    height: 400,
    url: "https://d111oohu250q1p.cloudfront.net/873f57f5-7c69-11ef-a3e4-4e5b5c96fb45__none-212.png"
  });
  
  const changeBackground = (v) => setBackgroundDesign(v);
  const changeDraggableImageUrl = (v) => setDraggableImageUrl(v);
  return (
    <Row>
      <Col
        span={7}
        style={{
          padding: "0px 10px",
        }}
      >
        <ChooseImage 
          values={selectedImage}
          onChange={setSelectedImage} 
          onChangeDraggableImageUrl={changeDraggableImageUrl}
          selectedBackground={selectedBackground}
        />
        <div className={(selectedImage.length <= 0) ? 'disabled-box': ''}>
        <DesignsCategorys 
          selected={selectedBackground} 
          onChange={setSelectedBackground} 
          onChangeBackground={changeBackground}
          selectedImage={selectedImage}
        />
        </div>
        
      </Col>
      <Col
        span={10}
        style={{
          padding: "0px 10px",
          overflow: 'auto'
        }}
      >
        <Design onChange={setConfigsImage} backgroundDesign={backgroundDesign} draggableImageUrl={draggableImageUrl} />
      </Col>
      <Col
        span={7}
        style={{
          padding: "0px 10px",
        }}
      >
        <ControllerDesign selectedBackground={selectedBackground} selectedImage={selectedImage} configsImage={configsImage} />
      </Col>
    </Row>
  );
};

export default DesignBanner;
