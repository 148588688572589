import React from "react";
import styled from "styled-components";
import {
  Button,
  Table,
  Input,
  Popconfirm,
  Divider,
  Modal,
  Tooltip,
  message,
} from "antd";
import gql from "../../api/gql";
import { setCurrentUser } from "../../actions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import ExportTemplateView from "./ExportTemplateView";
import { history } from "../../history";
import {
  EyeOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const Container = styled.div`
  .filter-export-template {
    display: flex;
  }
  @media only screen and (max-width: 700px) {
    .filter-export-template {
      flex-direction: column-reverse;
    }
  }
`;

class ExportTemplates extends React.Component {
  state = {
    templates: [],
    loading: true,
    filter: {
      limit: 20,
      offset: 0,
      name: "",
      // user_id: null,
    },
    page: 1,
    total: 0,
    showOrders: false,
    orders: null,
    visible: false,
    suppliers: [],
  };

  componentDidMount() {
    this.loadExportTemplates();
    const q = `query usersByRole{usersByRole( role: "Supplier", status: ${true} ){id first_name last_name}}`;
    gql.request(q).then((res) => {
      this.setState({
        suppliers: res.usersByRole,
      });
    });
  }

  loadExportTemplates = () => {
    this.setState({ loading: true });
    const { filter } = this.state;
    let query = `query exportTemplates($filter: TemplateFilter){
      exportTemplates(filter: $filter){
        count
        hits {
          id
          name
          author_id
          data{
            name
            type
            value
          }
        }
      }
    }`;
    gql
      .request(query, {
        filter,
      })
      .then((res) => {
        this.setState({
          templates: res.exportTemplates.hits,
          total: res.exportTemplates.count,
          loading: false,
        });
      });
  };

  downloadFile = (id) => {
    let query = `query downloadFile{downloadFile(id: ${id})}`;
    gql.request(query).then((res) => {
      window.location = res.downloadFile;
    });
  };
  deleteExportTemplate = (id) => {
    const mutation = `mutation deleteExportTemplate($id: String!){
      deleteExportTemplate(id: $id)
    }`;
    gql
      .request(mutation, { id: id })
      .then((res) => {
        this.loadExportTemplates();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };
  onSearch = async (value) => {
    if (value !== null) {
      this.setState({
        name: value,
      });
      this.loadExportTemplates();
    }
  };

  showModal = (record) => {
    this.setState({
      visible: record,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { currentUser } = this.props;
    const {
      filter,
      limit,
      total,
      page,
      templates,
      loading,
      visible,
    } = this.state;
    const isAdministrator = currentUser.roles.find((role) =>
      ["Administrator"].includes(role.name)
    );
    const columns = [
      {
        title: "Template Name",
        key: "Title",
        width: 200,
        dataIndex: "name",
      },
      {
        title: "Actions",
        key: "action",
        width: 150,
        align: "right",
        dataIndex: "id",
        render: (id, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="View template ">
              <Button size="small" onClick={() => this.showModal(record)}>
                <EyeOutlined theme="twoTone" twoToneColor="#ff9100" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Clone template ">
              <Button
                size="small"
                onClick={(e) => history.push(`/export-templates/clone/${id}`)}
              >
                <CopyOutlined />
              </Button>
            </Tooltip>
            {isAdministrator || record.author_id === +currentUser.id ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Divider type="vertical" />
                <Button size="small">
                  <Link to={`/export-templates/edit/${id}`}>
                    <EditOutlined theme="twoTone" />
                  </Link>
                </Button>
                <Divider type="vertical" />
                <Popconfirm
                  title="Are you sure delete this template?"
                  onConfirm={() => {
                    this.deleteExportTemplate(id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small">
                    <DeleteOutlined style={{ color: "red" }} />
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
          </div>
        ),
      },
    ];
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadExportTemplates()
        );
      },
    };
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Container>
        <div className="filter-export-template">
          <div
            style={{
              marginBottom: 10,
              width: "100%",
              marginRight: 20,
              display: "flex",
            }}
          >
            <Input.Search
              style={{ marginBottom: 10 }}
              enterButton
              placeholder="search for template..."
              value={filter.name}
              onChange={(e) =>
                this.setState({
                  filter: { ...filter, name: e.target.value },
                })
              }
              onSearch={(e) => {
                this.loadExportTemplates();
              }}
            />
          </div>
          <div
            className="btn-add"
            style={{ marginBottom: "10px", textAlign: "end" }}
          >
            <Button type="primary">
              <Link to="/export-templates/add">
                <PlusOutlined /> Add Export Template
              </Link>
            </Button>
          </div>
        </div>
        <Table
          scroll={{ x: tableWidth }}
          columns={columns}
          dataSource={templates}
          pagination={pagination}
          loading={loading}
          rowKey={(row) => row.id}
        />
        <Modal
          key={visible.id}
          title="View export template"
          visible={_.isObject(visible)}
          footer={null}
          onCancel={this.handleCancel}
        >
          <ExportTemplateView value={visible.data} />
        </Modal>
      </Container>
    );
  }
}

export default connect(
  (state) => {
    return {
      currentUser: state.app.currentUser,
    };
  },
  { setCurrentUser }
)(ExportTemplates);
