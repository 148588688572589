export const INIT_APP = "INIT_APP";
export const INIT_APP_2 = "INIT_APP_2";
export const SET_DESTINATION = "SET_DESTINATION";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_SITE = "SET_CURRENT_SITE";
export const SET_ORDERS_FILTER = "SET_ORDERS_FILTER";
export const RESET_ORDERS_FILTER = "RESET_ORDERS_FILTER";
export const SET_SELLER_SITES = "SET_SELLER_SITES";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const RESET_ORDER_ID = "RESET_ORDER_ID";
export const SET_PRODUCT = "SER_PRODUCT";
export const ARTWORK = {
  SET: "SET_ARTWORK",
  SET_SELECTED_TEMPLATE: "SET_ARTWORK_SELECTED_TEMPLATE",
  SET_SELECTED_LAYERS: "SET_ARTWORK_SELECTED_LAYERS",
  SET_LAYERS: "SET_ARTWORK_LAYERS",
  SET_LAYER: "SET_ARTWORK_LAYER",
  SET_CLIPBOARD: "SET_ARTWORK_CLIPBOARD",
  SET_CTRL: "SET_ARTWORK_CTRL",
  SET_THUMBNAIL: "SET_ARTWORK_THUMBNAIL",
  ADD_LAYER: "ARTWORK_ADD_LAYER",
  UNDO: "ARTWORK_REDO",
};
export const MOCKUP = {
  SET: "SET_MOCKUP",
  SET_SELECTED_LAYERS: "SET_MOCKUP_SELECTED_LAYERS",
  SET_LAYERS: "SET_MOCKUP_LAYERS",
  SET_LAYER: "SET_MOCKUP_LAYER",
  SET_CTRL: "SET_MOCKUP_CTRL",
  SET_CLIPBOARD: "SET_MOCKUP_CLIPBOARD",
  SET_MOCKUPS: "SET_MOCKUPS_MANAGE",
  SET_VARIANT_IDS: "SET_VARIANT_IDS",
};
export const CAMPAIGN = {
  SET: "SET_CAMPAIGN",
  SET_SELECTED_ARTWORK: "CAMPAIGN_SET_SELECTED_ARTWORK",
  RESET: "RESET_CAMPAIGN",
};
