import { LoadingOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Divider, Image, notification, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import {
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlineUpload,
} from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import {
  REGENDER_PRINTFILE,
  UPLOAD_DESIGN,
} from "../../graphql/mutation/orders/orderAction";
import CreateFile from "../upload/CreateFile";
const Container = styled.div`
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag {
    border: none;
    background: none;
  }
`;

const OrderDesignFile = ({ orderItem, refetch }) => {
  const [designs, setDesigns] = useState([]);
  const [customModal, setCustomModal] = useState({
    visible: false,
    type: null,
    applyTo: null,
  });
  const [Rengender, { loading: loadingRegender }] =
    useMutation(REGENDER_PRINTFILE);
  const [viewImage, setViewImage] = useState(null);
  const [uploadDesign, { loading }] = useMutation(UPLOAD_DESIGN);

  useEffect(() => {
    if (orderItem) {
      setDesigns(orderItem.designs);
    }
  }, [orderItem]);

  const handleRegender = (id) => {
    if (id) {
      Rengender({
        variables: {
          designID: id,
          orderId: orderItem.id,
        },
      }).catch((err) => notification.error({ message: err.message }));
    } else {
      Rengender({
        variables: {
          orderId: orderItem.id,
        },
      }).catch((err) => notification.error({ message: err.message }));
    }
  };
  return (
    <Container>
      {" "}
      {designs?.map((design, index) => {
        const imageFile = `${CLOUDFRONT_URL}/500/${design.file?.key}`;
        const fileOriginal = `${CLOUDFRONT_URL}/auto/${design.file?.key}`;
        return (
          <div key={design.id}>
            <div className="flex space-between item-center">
              <span>{design.name}</span>
              <div style={{ display: "flex" }}>
                <Button onClick={() => handleRegender(design.id)} type="link">
                  {["Processing", "Pending"].includes(design.status)
                    ? design.status
                    : "Regenerate"}
                </Button>
                <Button
                  type="link"
                  onClick={() => window.open(fileOriginal, "_blank")}
                  icon={<AiOutlineDownload className="anticon custom-icon" />}
                >
                  Download
                </Button>
                <div style={{ display: "inline-block" }}>
                  <CreateFile
                    accept=".jpg,.jpeg,.png"
                    onChange={(file) => {
                      const newDesigns = [...designs];
                      newDesigns[index] = {
                        ...newDesigns[index],
                        file: file,
                      };
                      setDesigns(newDesigns);
                      const designInput = newDesigns.map((ds) => {
                        return {
                          id: ds.order_id,
                          file_id: ds.file ? ds.file.id : null,
                        };
                      });
                      uploadDesign({
                        variables: {
                          orderId: orderItem.id,
                          input: designInput.filter((item) => item.file_id),
                        },
                      })
                        .then(() => {
                          notification.success({ message: "Upload success" });
                          setCustomModal({
                            visible: false,
                            type: null,
                            apllyTo: null,
                          });
                          refetch();
                        })
                        .catch((err) =>
                          notification.error({ message: err.message })
                        );
                    }}
                  >
                    <Button
                      type="link"
                      icon={<AiOutlineUpload className="anticon custom-icon" />}
                    >
                      Upload custom
                    </Button>
                  </CreateFile>
                </div>
              </div>
            </div>
            <div
              className="flex content-center"
              style={{
                background: "lightgray",
                boxShadow:
                  "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
              }}
            >
              {loading ? (
                <div className="screen-loading">
                  <div className="bounceball"></div>
                  <p
                    style={{
                      fontSize: 20,
                      marginBottom: 0,
                      lineHeight: 37,
                      marginLeft: 10,
                    }}
                  >
                    Your design is uploading. Please wait for completion!
                  </p>
                </div>
              ) : (
                <Image
                  style={{ backgroundColor: "#f5f5f5", objectFit: "contain" }}
                  width="auto"
                  height="auto"
                  preview={{
                    mask: (
                      <div>
                        <div className="flex item-center">
                          <AiOutlineEye /> Preview
                        </div>
                        <Tooltip title="View original">
                          <a
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                            href={fileOriginal}
                            style={{
                              zIndex: 200000,
                              position: "absolute",
                              right: 0,
                              top: 0,
                            }}
                          >
                            <HiOutlineExternalLink
                              style={{
                                color: "white",
                                size: 30,
                              }}
                              className="anticon custom-icon"
                            />
                          </a>
                        </Tooltip>
                      </div>
                    ),
                    src: fileOriginal,
                  }}
                  src={imageFile}
                  fallback={`/default-img.png`}
                />
              )}
            </div>
            {designs?.length - 1 > index && <Divider type="horizontal" />}
          </div>
        );
      })}
      <div style={{ textAlign: "right" }}>
        {/* <Button
          hidden={!orderItem?.designs?.length.toString()}
          className="mt-15"
          onClick={() => handleRegender()}
          loading={loadingRegender}
          disabled={designs.some((ds) =>
            ["Pending", "Processing"].includes(ds.status)
          )}
        >
          Re-generate all
        </Button> */}
      </div>
      <Modal
        title="Design"
        visible={viewImage}
        footer={null}
        onCancel={() => setViewImage(false)}
      >
        <div className="align-center">
          <img
            src={viewImage}
            style={{
              backgroundColor: "#f5f5f5",
              objectFit: "contain",
              width: "100%",
              height: "100%",
              marginBottom: 15,
            }}
          />
          <a href={viewImage} target="_blank">
            View design
          </a>
        </div>
      </Modal>
    </Container>
  );
};

export default OrderDesignFile;
