import { Stage, Layer, Image, Transformer } from "react-konva";
import React, { useState, useRef, useEffect } from "react";
import useImage from "use-image";

const Design = ({ draggableImageUrl, backgroundDesign, onChange }) => {
  const box_preview = useRef();
  const [widthCanvas, setWidthCanvas] = useState(600);
  const [ratio, setRatio] = useState(1);
  const moveStep = 1;

  const IMAGE_WIDTH = draggableImageUrl.width;
  const IMAGE_HEIGHT = draggableImageUrl.height;

  const [imageProps, setImageProps] = useState({
    x: (widthCanvas - IMAGE_WIDTH) / 2,
    y: (widthCanvas - IMAGE_HEIGHT) / 2,
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    rotation: 0,
  });

  useEffect(() => {
    const { width } = imageProps;
    const widthImage = draggableImageUrl.width;
    const heigthImage = draggableImageUrl.height;
    const scaleNewImage = width / widthImage;
    setImageProps({
      ...imageProps,
      width: widthImage * scaleNewImage,
      height: heigthImage * scaleNewImage,
    });
  }, [draggableImageUrl]);

  const backgroundImage = useImage(backgroundDesign)[0];
  const draggableImage = useImage(draggableImageUrl.url)[0];
  const draggableImageRef = useRef();
  const transformerRef = useRef();

  var isCtrlPressed = false;

  useEffect(() => {
    onChange(imageProps);
  }, [imageProps]);
  useEffect(() => {
    if (transformerRef.current) {
      transformerRef.current.nodes([draggableImageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Control") {
      isCtrlPressed = true;
    }
    if (isCtrlPressed) {
      switch (e.key) {
        case "ArrowUp":
          setImageProps((prev) => ({ ...prev, y: prev.y - moveStep }));
          break;
        case "ArrowDown":
          setImageProps((prev) => ({ ...prev, y: prev.y + moveStep }));
          break;
        case "ArrowLeft":
          setImageProps((prev) => ({ ...prev, x: prev.x - moveStep }));
          break;
        case "ArrowRight":
          setImageProps((prev) => ({ ...prev, x: prev.x + moveStep }));
          break;
        default:
          break;
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Control") isCtrlPressed = false;
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const widthCV = box_preview.current.offsetWidth;
    setWidthCanvas(widthCV);
    const newRatio = 1200 / widthCV;
    setRatio(newRatio);
    const propsImage = {
      x: (widthCV - IMAGE_WIDTH) / 2,
      y: (widthCV - IMAGE_HEIGHT) / 2,
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
      rotation: 0,
      ratio: newRatio,
    };
    setImageProps(propsImage);
  }, []);

  const handleDragEnd = (e) => {
    const node = draggableImageRef.current;
    const attrs = {};
    for (const [key, value] of Object.entries(node.attrs)) {
      if (
        ![
          "image",
          "draggable",
          "width",
          "height",
          "scaleX",
          "scaleY",
          "x",
          "y",
        ].includes(key)
      ) {
        attrs[key] = value;
      }
    }
    setImageProps({
      ...attrs,
      ...imageProps,
      x: node.x(),
      y: node.y(),
      ratio,
    });
  };

  const handleTransformEnd = (e) => {
    const node = draggableImageRef.current;
    const scaleX = isCtrlPressed ? 1 : node.scaleX();
    const scaleY = isCtrlPressed ? 1 : node.scaleY();
    const attrs = {};
    for (const [key, value] of Object.entries(node.attrs)) {
      if (
        ![
          "image",
          "draggable",
          "width",
          "height",
          "scaleX",
          "scaleY",
          "x",
          "y",
        ].includes(key)
      ) {
        attrs[key] = value;
      }
    }
    setImageProps({
      ...attrs,
      ...imageProps,
      x: node.x(),
      y: node.y(),
      width: node.width() * scaleX,
      height: node.height() * scaleY,
      rotation: node.rotation(),
      ratio,
    });
    if (!isCtrlPressed) {
      node.scaleX(1);
      node.scaleY(1);
    }
  };

  return (
    <div ref={box_preview}>
      <Stage width={widthCanvas} height={widthCanvas}>
        <Layer>
          <Image
            image={backgroundImage}
            width={widthCanvas}
            height={widthCanvas}
          />
          <Image
            ref={draggableImageRef}
            image={draggableImage}
            {...imageProps}
            draggable={!isCtrlPressed}
            onDragEnd={handleDragEnd}
            onTransformEnd={handleTransformEnd}
          />
          <Transformer
            ref={transformerRef}
            rotateEnabled
            anchorSize={8}
            strokeScaleEnabled
            enabledAnchors={[
              "top-left",
              "top-right",
              "bottom-left",
              "bottom-right",
            ]}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default Design;
