import { Button, Form, Input, InputNumber, Radio, Space } from "antd";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { ARTWORK } from "../../../types";
import ImageField from "../Utilities/ImageField";
import { BiPlus } from "react-icons/bi";
import ColorField from "../Utilities/ColorField";
import { FaTimes } from "react-icons/fa";

const Container = styled.div`
  .options-list {
    .option:not(:first-child) {
      .ant-form-item-label {
        opacity: 0;
        height: 0px;
        overlay: hidden;
      }
    }
  }
`;
const OptionForm = ({ layer, workspace }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue(layer);
  }, [layer]);

  const handleOnValuesChange = (_, values) => {
    Object.assign(layer, values);
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: layer, //updateLayers(layers, layer),
    });
  };

  return (
    <Container>
      <Form layout="vertical" form={form} onValuesChange={handleOnValuesChange}>
        <Form.Item
          label="Ordering"
          name={"ordering"}
          initialValue={layer.ordering ? layer.ordering : 0}
        >
          <InputNumber min={0} max={99} />
        </Form.Item>
        <Form.Item label="Label" name="title">
          <Input placeholder="Option" />
        </Form.Item>
        <Form.Item label="Display mode" name="display_mode">
          <Radio.Group>
            <Radio value="dropdown" style={{ display: "block" }}>
              Dropdown
            </Radio>
            <Radio value="button" style={{ display: "block" }}>
              Button
            </Radio>
            <Radio value="image" style={{ display: "block" }}>
              Image switcher
            </Radio>
            <Radio value="color" style={{ display: "block" }}>
              Color switcher
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div className="options-list">
          <Form.List name="options" label="Options">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <div key={field.key} className="option">
                    <Space align="baseline">
                      <Form.Item label="Default">
                        <Radio
                          style={{ position: "absolute", top: 7, right: 7 }}
                          checked={
                            layer.options[field.name]?.default
                              ? layer.options[field.name].default
                              : false
                          }
                          onChange={(e) => {
                            layer.options = layer.options.map((item, index) => {
                              if (index === field.name) {
                                return { ...item, default: true };
                              } else {
                                return { ...item, default: false };
                              }
                            });
                            dispatch({
                              type: ARTWORK.SET_LAYER,
                              payload: layer, //updateLayers(layers, layer),
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.label !== curValues.label
                        }
                      >
                        {
                          () => {
                            switch (form.getFieldValue("display_mode")) {
                              case "image":
                                return (
                                  <Form.Item
                                    {...field}
                                    label="Image"
                                    name={[field.name, "image"]}
                                    fieldKey={[field.fieldKey, "image"]}
                                  >
                                    <ImageField />
                                  </Form.Item>
                                );
                              case "color":
                                return (
                                  <Form.Item
                                    {...field}
                                    label="Color"
                                    name={[field.name, "color"]}
                                    fieldKey={[field.fieldKey, "color"]}
                                  >
                                    <ColorField type="sharp" />
                                  </Form.Item>
                                );
                              default:
                                return null;
                            }
                          }

                          // form.getFieldValue("display_mode") === "image" && (
                          //   <Form.Item
                          //     {...field}
                          //     label="Image"
                          //     name={[field.name, "image"]}
                          //     fieldKey={[field.fieldKey, "image"]}
                          //   >
                          //     <ImageField />
                          //   </Form.Item>
                          // )
                        }
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.label !== curValues.label
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Label"
                            name={[field.name, "label"]}
                            fieldKey={[field.fieldKey, "label"]}
                          >
                            <Input size="small" />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.label !== curValues.label
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Value"
                            name={[field.name, "value"]}
                            fieldKey={[field.fieldKey, "value"]}
                          >
                            <Input size="small" />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item label={<span></span>}>
                        <FaTimes
                          className="anticon"
                          style={{
                            color: "var(--error-color)",
                            cursor: "pointer",
                          }}
                          onClick={() => remove(field.name)}
                        />
                      </Form.Item>
                    </Space>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    onClick={() => {
                      let valueDefault = layer.options.length + 1;
                      add(
                        { value: `option-${valueDefault}` },
                        valueDefault + 1
                      );
                    }}
                    icon={<BiPlus className="anticon" />}
                    type="dashed"
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </Container>
  );
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(OptionForm);
