import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Row, Col, message } from "antd";
import CREATEATRWORK from "../../graphql/mutation/artwork/createArtwork";
import { useMutation } from "@apollo/client";
import ArtworkCategoriesField from "../artworks/ArtworkCategoriesField";

const ArtworkForm = ({
  id = "artwork-form",
  artwork = null,
  onChange = () => {},
}) => {
  const [form] = Form.useForm();
  const [showAddCategory, setShowAddCategory] = useState(null);
  const [createArtwork, { data, loading, error }] = useMutation(CREATEATRWORK);

  useEffect(() => {
    if (artwork) {
      form.setFieldsValue({
        ...artwork,
        categoryIds: artwork?.categories?.map((cat) => cat.id),
      });
    }
  }, [artwork]);
  const handleSubmit = (values) => {
    if (artwork && artwork.id) {
      onChange(values);
    } else {
      createArtwork({ variables: { input: { ...values } } }).catch((err) => {
        message.error(err.message);
      });
    }
  };

  return [
    <Form form={form} onFinish={handleSubmit} id={id} layout="vertical">
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Artwork title is requried" }]}
        style={{ padding: 0 }}
      >
        <Input placeholder="Artwork title" />
      </Form.Item>
      <Form.Item
        name="categoryIds"
        label="Categories"
        // style={{ marginBottom: 0 }}
        rules={[{ required: true }]}
      >
        <ArtworkCategoriesField
          onShowAddNewCatStateChange={(state) => setShowAddCategory(state)} addNewable={false}
        />
      </Form.Item>
      <Form.Item label="Size" style={{ padding: 0, marginBottom: 0 }}>
        <Row gutter={[30]}>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: "Artwork with is required" }]}
              name="width"
              style={{ marginBottom: 5 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                placeholder="Width"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                { required: true, message: "Artwork height is required" },
              ]}
              name="height"
              style={{ marginBottom: 5 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                placeholder="Height"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Form>,
    { form, data, loading, error, showAddCategory },
  ];
};

export default ArtworkForm;
