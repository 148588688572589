import React, { useRef } from "react";
import { useMemo } from "react";
import { Label, Text, Tag, Rect, Group } from "react-konva";
import useImage from "use-image";
import { get, pick } from "lodash";
import { CLOUDFRONT_URL } from "../../config";
const PrintArea = ({
  layer,
  mockup = { width: 1000, height: 1000 },
  onClick = () => {},
}) => {
  console.log(layer);
  const [previewImg] = useImage(
    CLOUDFRONT_URL + `auto/` + get(layer, "previewArtwork")
  );
  const { printArea = { width: 1000, height: 1000 } } = layer;
  const layerRef = useRef();
  const clippingMaskRef = useRef();
  const {
    clippingMaskEnable,
    clippingMask = {
      x: 0,
      y: 0,
      width: layer.width,
      height: layer.height,
    },
  } = layer;
  const props = { ...layer };
  const printAreaRatio = printArea.width / printArea.height;
  if (!props.width) {
    props.width = printArea.width;
  }
  if (props.width > mockup.width) {
    props.width = mockup.width;
  }
  props.height = props.width / printAreaRatio;
  const onTransformEnd = (e) => {
    const node = e.currentTarget;
    node.setAttrs({
      width: node.width() * node.scaleX(),
      height: node.height() * node.scaleY(),
      scale: { x: 1, y: 1 },
    });
    const { x, y, width, height } = node.getAttrs();
    layerRef.current.setAttrs({
      clippingMask: {
        x,
        y,
        width,
        height,
      },
    });
    layerRef.current.getLayer().dispatchEvent(new Event("update"));
  };

  const imgProps = useMemo(() => {
    const props = pick(layer, ["width", "height"]);
    props.x = 0;
    props.y = 0;
    const layerRatio = layer.width / layer.height;
    if (previewImg) {
      const imgRatio = previewImg.width / previewImg.height;
      if (imgRatio < layerRatio) {
        props.width = props.height * imgRatio;
        props.x = (layer.width - props.width) / 2;
      } else if (imgRatio > layerRatio) {
        props.height = props.width / imgRatio;
      }
    }
    return props;
  }, [previewImg, layer]);

  const printAreaPattern = useMemo(() => {
    return {
      fill: previewImg ? null : "#5c6ac4",
      fillPatternImage: previewImg,
      fillPatternRepeat: "no-repeat",
      fillPatternOffset: {
        x: -imgProps.x,
        y: -imgProps.y,
      },
      fillPatternScale: {
        x: previewImg ? imgProps.width / previewImg?.width : 1,
        y: previewImg ? imgProps.width / previewImg?.width : 1,
      },
    };
  }, [imgProps, previewImg]);

  const clippingMaskPattern = useMemo(() => {
    if (!(layer.clippingMaskEnable && layer.clippingMask)) return {};
    return {
      fillPatternImage: previewImg,
      fillPatternRepeat: "no-repeat",
      fillPatternOffset: {
        x: layer.clippingMask.x - imgProps.x,
        y: layer.clippingMask.y - imgProps.y,
      },
      fillPatternScale: {
        x: imgProps.width / previewImg?.width,
        y: imgProps.width / previewImg?.width,
      },
    };
  }, [layer, imgProps, previewImg]);

  return (
    <Group
      {...props}
      ref={layerRef}
      onClick={onClick}
      fill="#5c6ac4"
      class="Printarea"
    >
      <Rect
        width={props.width}
        height={props.height}
        opacity={clippingMaskEnable ? 0 : 1}
        {...printAreaPattern}
      />
      <Label width={props.width} height={props.height} opacity={0.2}>
        <Tag fill="#5c6ac4" opacity={0.8} />
        <Text
          text={`Printarea\n${layer.printArea.name}`}
          align="center"
          verticalAlign="middle"
          width={props.width}
          height={props.height}
          fontSize={props.width / 8}
          fill={"#f0f000"}
        />
      </Label>
      {clippingMaskEnable && (
        <Rect
          {...clippingMask}
          //fill="green"
          {...clippingMaskPattern}
          opacity={0.8}
          onClick={(e) => {
            if (
              clippingMaskRef &&
              clippingMaskRef.current &&
              window.clippingMaskRef &&
              window.clippingMaskRef.current
            ) {
              window.clippingMaskRef.current.nodes([clippingMaskRef.current]);
            }
          }}
          draggable={true}
          ref={clippingMaskRef}
          onDragEnd={onTransformEnd}
          onTransform={onTransformEnd}
          onTransformEnd={onTransformEnd}
          class="clippingMask"
        />
      )}
    </Group>
  );
};
export default PrintArea;
