import { Button, Checkbox, Collapse } from "antd";
import { Form } from "antd";
import { get } from "lodash-es";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { MOCKUP } from "../../types";
import ArtworkSelector from "../artworks/ArtworkSelector";
import S3Image from "../S3Image";

const LayerSettings = () => {
  const mockupWorkspace = useSelector((state) => state.mockup.mockupWorkspace);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { mockup, selectedLayers } = mockupWorkspace;
  const { layers } = mockup;
  const [selectAtwork, setSelectArtwork] = useState(false);
  const selectedLayer = useMemo(() => {
    if (selectedLayers.length !== 1) return null;
    if (layers.length === 0) return null;
    return layers.find((layer) => layer.id === selectedLayers[0]);
  }, [layers, selectedLayers]);

  useEffect(() => {
    if (selectedLayer) {
      form.setFieldsValue({ clippingMaskEnable: false, ...selectedLayer });
    }
  }, [selectedLayer, form]);

  const handleValuesChange = (values) => {
    dispatch({
      type: MOCKUP.SET_LAYER,
      payload: { ...selectedLayer, ...values },
    });
  };

  if (!selectedLayer) {
    return null;
  }
  if (selectedLayer.type !== "Printarea") {
    return null;
  }
  return (
    <Collapse activeKey="layer-settings" style={{ marginTop: 20 }}>
      <Collapse.Panel header="Layer Settings" key="layer-settings">
        <Form form={form} onValuesChange={handleValuesChange}>
          <Form.Item valuePropName="checked" name="clippingMaskEnable">
            <Checkbox>Enable clipping mask</Checkbox>
          </Form.Item>
          <Form.Item label="Preview">
            <Button
              onClick={() => setSelectArtwork(true)}
              style={{ marginBottom: 10 }}
            >
              Select Artwork
            </Button>
            <ArtworkSelector
              visible={selectAtwork}
              onCancel={() => setSelectArtwork(false)}
              onSelect={(artwork) => {
                dispatch({
                  type: MOCKUP.SET_LAYER,
                  payload: {
                    ...selectedLayer,
                    previewArtwork: get(artwork, "templates[0].preview"),
                  },
                });
                setSelectArtwork(false);
              }}
            />
            {selectedLayer?.previewArtwork && (
              <S3Image src={selectedLayer.previewArtwork} size={200} />
            )}
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
export default LayerSettings;
