import React, { useEffect, useMemo, useState } from "react";
import {Input, Space, Select, AutoComplete, Spin, InputNumber, Button} from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import axios from "axios";
import {URL_API_SEARCH_LYRIC} from "../../../config";

const { Search, TextArea } = Input;
const { Option } = Select;
const SongLyric = ({
   value,
   onChange,
   min,
   max,
   onChangeMinCharacters,
   onChangeMaxCharacters
}) => {
    const [dataSongs, setDataSong] = useState([]);
    const [lyric, setLyric] = useState(value ? value : "Heart beats fast Colors and promises I have loved you for a thousand years I'll love you for a thousand more And all along I believed, I would find you Time has brought your heart to me, I have loved you for a thousand years I'll love you for a thousand more One step closer One step closer I have died everyday, waiting for you Darling, don't be afraid, I have loved you for a thousand years I'll love you for a thousand more And all along I believed, I would find you Time has brought your heart to me, I have loved you for a thousand years I'll love you for a thousand more.");
    const [searchLoading, setSearchLoading] = useState(false);
    const [spinningSpin, setSpinningSpin] = useState(false)
    const [keyInput, setKeyInput] = useState("");
    useEffect(()=> {
        onChange(lyric)
    }, [lyric]);

    const handleMinCharacters = (value) => {
        onChangeMinCharacters(value)
    }

    const handleMaxCharacters = (value) => {
        onChangeMaxCharacters(value)
    }

    //{ value: 'text 2', label: "cc" }
    const convertViToEn = (str, toUpperCase = false) => {
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
        str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư

        return toUpperCase ? str.toUpperCase() : str;
    }

    const handleSearch = async () => {
        const value = keyInput;
        setSearchLoading(true);
        try{
            const search = await axios.post(URL_API_SEARCH_LYRIC, {
                isSearch: true,
                song: convertViToEn(value)
            }).then(function (res){
                return JSON.parse(res.data);
            });
            const dataReturn = search.response.hits;

            let newData = [];
            dataReturn.forEach(item => {
                const result = item.result;
                const itemValue = {
                    key: result.path,
                    value: result.title + " - " + result.primary_artist.name,
                    label: result.title + " - " + result.primary_artist.name
                }
                newData = [...newData, itemValue];
            });
            setDataSong(newData);
            setSearchLoading(false)
        }catch(error){
            setSearchLoading(false)
        }
    };

    const OnChangeLyric = e => {
        setLyric(e.target.value);
    }

    const handleOnChangeSong = async (v) => {
        setSpinningSpin(true)
        const trackId = v.key;
        const getLyric = await axios.post(URL_API_SEARCH_LYRIC, {
            isSearch: false,
            song: trackId
        }).then(function (res){
            return res;
        });
        if(getLyric.status == 200){
            setLyric(getLyric.data);
        }
        setSpinningSpin(false);
    }

    return (
        <div>
            <div className="box-search-lyric">
                <AutoComplete
                    style={{ width: '100%' }}
                    // onSearch={handleSearch}
                    // onClick={handleSearch}
                    onSearch={(value) => setKeyInput(value)}
                    onSelect={handleOnChangeSong}
                    labelInValue={"label"}
                    options={dataSongs}
                >
                    <Input
                        suffix={searchLoading ? <LoadingOutlined  className="site-form-item-icon"/> : <SearchOutlined onClick={handleSearch} />}
                        size="large" placeholder="Search song title..." />
                </AutoComplete>
                <br/>
                <br/>
                <label>Lyrics: </label>
                {/*<div style={{*/}
                {/*    display: "flex",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "space-between",*/}
                {/*    marginBottom: "5px"*/}
                {/*}}>*/}
                {/*    <div>*/}
                {/*        <strong>Number of characters:</strong> {lyric.length}*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        Recommended from <InputNumber style={{marginRight: "5px"}} min={0} defaultValue={min} onChange={handleMinCharacters} />*/}
                {/*        to <InputNumber min={2} defaultValue={max} onChange={handleMaxCharacters} />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Spin spinning={spinningSpin} tip="Loading...">
                    <TextArea onChange={OnChangeLyric} rows={7} value={lyric}/>
                </Spin>
            </div>


        </div>
    )
}

export default SongLyric;