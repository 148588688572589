import gql from "graphql-tag";

export const CREATE_CLIPARTS = gql`
  mutation createClipart(
    $title: String!
    $file: NewFile!
    $categoryID: String!
  ) {
    createClipart(title: $title, file: $file, categoryID: $categoryID) {
      id
      title
      author {
        id
        first_name
        last_name
        is_subscription
        email
        roles {
          id
          name
        }
      }
      file {
        id
        key
        sku
        url
      }
      category {
        id
        title
        parentID
        hasChild
        children {
          id
          title
        }
      }
      createdAt
    }
  }
`;
export const UPDATE_CLIPART_TITLE = gql`
  mutation updateClipartTitle($id: String!, $title: String!) {
    updateClipartTitle(id: $id, title: $title) {
      id
      title
      createdAt
    }
  }
`;
export const DELETE_CLIPARTS = gql`
  mutation deleteClipart($id: String!) {
    deleteClipart(id: $id)
  }
`;

export const UPDATE_CLIPART_THUMBNAIL = gql`
  mutation updateClipartThumbnail($id: String!, $thumbnail_id: String!) {
    updateClipartThumbnail(id: $id, thumbnail_id: $thumbnail_id){
    id
    title
    file{
      id
      source
      key
      file_name
      file_mime
      file_size
      url
      sku
    }
    createdAt
    thumbnail{
      id
      source
      key
      file_name
      file_mime
      file_size
      url
      sku
    }
  }
  }
`;