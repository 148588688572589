import React from "react";
import { connect } from "react-redux";
import { setOrdersFilter } from "../../actions";
import { Select, Input, Button, message, Modal, Popover } from "antd";
import gql from "../../api/gql";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import DateRange from "../DateRange";
import styled from "styled-components";
import { FilterOutlined } from "@ant-design/icons";
import { history } from "../../history";
import qs from "query-string";

const Container = styled.div`
  display: flex;
  .date-range {
    max-width: 280px;
    width: 100%;
    margin-bottom: 20px;
  }
  .filter {
    width: 90px;
    margin: 0 5px 20px 0;
  }
  .filter-and-date-range {
    display: flex;
    margin-right: 5px;
  }
  .od-search {
    width: 100%;
    min-width: 180px;
    margin: 0 5px 10px 0;
  }
  .search-field {
    display: flex;
    width: 100%;
  }
  @media only screen and (max-width: 1140px) {
    display: grid;
    .od-search {
      display: block;
      margin: 0 0 10px 0;
    }
    .date-range {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .filter {
      width: 90px;
      margin-bottom: 10px;
      padding-right: 5px;
    }
    .filter-and-date-range {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 700px) {
    display: grid;
    .od-search {
      display: block;
      margin: 0 0 10px 0;
    }
    .date-range {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .filter {
      width: 90px;
      margin-bottom: 10px;
      padding-right: 5px;
    }
    .filter-and-date-range {
      margin-right: 0;
    }
  }
`;

class OrdersFilter extends React.Component {
  state = {
    sites: [],
    productTypes: [],
    suppliers: [],
    sellers: [],
    filter: { ...this.props.filter },
    orders: this.props.orders,
    supplierSelect: null,
    open: false,
    openCarrier: false,
    carrierSelect: null,
    shipping_cost: 0,
    defaultCarrier: null,
  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState({
      filter: {
        ...this.state.filter,
        ...query,
        product_type_ids: query.product_type_ids
          ? [query.product_type_ids]
          : null,
        site_ids: query.site_ids ? [query.site_ids] : null,
        seller_ids: query.seller_ids ? [query.seller_ids] : null,
      },
    });

    this.loadSites();
    this.loadProductType();
    this.loadseller();
    this.loadSupplier();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        filter: { ...this.props.filter },
      });
    }
    if (prevProps.orders !== this.props.orders) {
      const carriers =
        this.props.orders.length > 0 &&
        this.props.orders[0].variant.product_type.product_type_carriers;
      let defaultCarrier =
        carriers && carriers.find((el) => el.default === true);
      if (defaultCarrier) {
        this.setState({
          orders: this.props.orders,
          defaultCarrier: defaultCarrier,
        });
      }
      this.setState({ orders: this.props.orders });
    }
  }
  check = () => {
    const { orders, filter } = this.state;
    if (orders.length < 1) {
      return true;
    }
    if (orders.find((el) => el.supplier)) {
      return true;
    }
    if (filter.product_type_ids && filter.product_type_ids.length > 0) {
      if (
        orders.find(
          (el) => el.variant.product_type_id !== filter.product_type_ids[0]
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  checkv2 = () => {
    const { orders, filter } = this.state;
    if (orders.length < 1) {
      return true;
    }
    if (orders.find((el) => el.carrier)) {
      return true;
    }
    if (filter.product_type_ids && filter.product_type_ids.length > 0) {
      if (
        orders.find(
          (el) => el.variant.product_type_id !== filter.product_type_ids[0]
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  loadSites() {
    const query = `query sites{sites(filter:{limit:9999}){hits{id,title}}}`;
    gql.request(query).then((res) => {
      this.setState({ sites: res.sites.hits || [] });
    });
  }

  loadProductType() {
    const query = `query productTypes{
      productTypes(filter: {limit: 9999}){
        hits{
          id title
        }
      }
    }`;
    gql
      .request(query, {
        filter: {
          status: true,
          limit: 1000,
        },
      })
      .then((res) => {
        this.setState({ productTypes: res.productTypes.hits || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  loadSupplier() {
    const query = `query usersByRole(){
      usersByRole(
        role: "Supplier",
        status: true
      ){
        id first_name last_name status
      }
    }`;
    gql
      .request(query)
      .then((res) => {
        this.setState({ suppliers: res.usersByRole || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  loadseller() {
    const query = `query usersByRole(){
      usersByRole(
        role: "Seller",
        status: true
      ){
        id first_name last_name status
      }
    }`;
    gql
      .request(query)
      .then((res) => {
        this.setState({ sellers: res.usersByRole || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  onChangeDate = (range, callback = () => {}) => {
    if (range.from !== null && range.to !== null) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            from: moment(range.from).format("YYYY-MM-DD[T]00:00:00[Z]"),
            to: moment(range.to).format("YYYY-MM-DD[T]00:00:00[Z]"),
          },
        },
        callback
      );
    } else {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            from: null,
            to: null,
          },
        },
        callback
      );
    }
  };
  clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  handleChange(key, value, callback = () => {}) {
    if (_.isArray(value)) {
      value = value.filter((item) => item !== null);
    }
    const { filter } = this.state;
    filter[key] = value;
    this.setState({ filter: { ...filter } }, callback);
  }

  applyFilter = () => {
    const { setOrdersFilter } = this.props;
    const { filter } = this.state;
    let newFilter = cloneDeep(filter);
    history.push({
      pathname: "/orders",
      search: "?" + new URLSearchParams(this.clean(newFilter)).toString(),
    });
    setOrdersFilter(this.state.filter);
  };

  resetFilter = () => {
    const { setOrdersFilter, filter } = this.props;
    setOrdersFilter({
      ...filter,
      site_ids: null,
      product_type_ids: null,
      seller_ids: null,
      // from: null,
      // to: null,
      // search: null,
      // status: null,
      // field: "all",
    });
    history.push({
      pathname: "/orders",
      search:
        "?" +
        new URLSearchParams(
          this.clean({
            ...filter,
            site_ids: null,
            product_type_ids: null,
            seller_ids: null,
          })
        ).toString(),
    });
  };

  assignCarrier = () => {
    const { defaultCarrier, orders } = this.state;
    const mutation = `mutation assignOrderCarrier($order_ids: [Int!], $carrier_id: Int!, $price: Float!){
      assignOrderCarrier(order_ids: $order_ids, carrier_id: $carrier_id, price: $price){
        id,
          origin_id,
          price,
          quantity,
          created_at
          status
          shipping_cost
          customer{
            first_name
            last_name
            email
            phone
          }
          supplier{
            id
            last_name
            first_name
          }
          carrier{
            name
          }
          billing{
            id
            address1
            address2
            city
            state
            state_code
            zip
            country
            country_code
          }
          shipping{
            id
            address1
            address2
            city
            state
            state_code
            zip
            country
            country_code
          }
          product{
            id
            title
          }
          variant{
            id
            product_type_id
            design_status
            product_type{
              suppliers{
                id
                first_name
                last_name
              }
            }
          }
      }
    }`;
    gql
      .request(mutation, {
        order_ids: orders.map((el) => el.id),
        carrier_id: defaultCarrier.carrier.id,
        price: defaultCarrier.price,
      })
      .then((res) => {
        message["success"]("Assign supplier successfully");
        this.setState({ openCarrier: false, defaultCarrier: null });
        this.props.onCompleted(res.assignOrderCarrier);
      });
  };
  render() {
    const {
      sellers,
      sites,
      productTypes,
      filter,
      openCarrier,
      orders,
      defaultCarrier,
    } = this.state;
    const { currentUser } = this.props;
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const content = (
      <div style={{ width: 250 }}>
        <Select
          showSearch
          optionFilterProp="children"
          value={filter.product_type_ids ? filter.product_type_ids[0] : []}
          placeholder="Product types"
          style={{
            Width: 250,
            width: "100%",
            marginTop: 10,
            marginBottom: 15,
          }}
          // defaultValue={query.product_type_ids ? query.product_type_ids : null}
          onChange={(v) =>
            this.handleChange("product_type_ids", [v], this.applyFilter)
          }
        >
          <Select.Option value={null}>All Product Types</Select.Option>
          {productTypes.map((type) => (
            <Select.Option key={type.id}>{type.title}</Select.Option>
          ))}
        </Select>
        {currentUser.roles.find((role) =>
          ["Administrator", "Supporter"].includes(role.name)
        ) && (
          <Select
            showSearch
            optionFilterProp="children"
            value={filter.seller_ids ? filter.seller_ids[0] : []}
            placeholder="Seller"
            style={{
              maxWidth: 250,
              width: "100%",
              marginBottom: 15,
            }}
            onChange={(v) =>
              this.handleChange("seller_ids", [v], this.applyFilter)
            }
          >
            <Select.Option value={null}>All Sellers</Select.Option>
            {sellers.map((seller) => (
              <Select.Option key={seller.id}>
                {seller.first_name} {seller.last_name}
              </Select.Option>
            ))}
          </Select>
        )}
        {currentUser.roles.find((role) =>
          ["Administrator", "Supporter"].includes(role.name)
        ) && (
          <Select
            showSearch
            optionFilterProp="children"
            value={filter.site_ids ? filter.site_ids[0] : []}
            placeholder="Site"
            style={{
              maxWidth: 250,
              width: "100%",
              marginBottom: 15,
            }}
            onChange={(v) =>
              this.handleChange("site_ids", [v], this.applyFilter)
            }
          >
            <Select.Option value={null}>All Sites</Select.Option>
            {sites.map((site) => (
              <Select.Option key={site.id}>{site.title}</Select.Option>
            ))}
          </Select>
        )}
        <Button
          type="default"
          style={{ marginBottom: 10 }}
          onClick={(e) => {
            e.preventDefault();
            this.resetFilter();
          }}
        >
          Reset Filter
        </Button>
      </div>
    );
    return (
      <Container>
        <div className="filter-and-date-range">
          <div className="filter">
            <Popover placement="bottomLeft" content={content} trigger="click">
              <Button>
                Filter <FilterOutlined />
              </Button>
            </Popover>
          </div>
          <div className="date-range">
            <DateRange
              defaultDate={
                filter.from && filter.to
                  ? [moment(filter.from), moment(filter.to)]
                  : null
              }
              onChange={(dateRange) =>
                this.onChangeDate(dateRange, this.applyFilter)
              }
            />
          </div>
        </div>
        <div className="search-field">
          <Select
            showSearch
            optionFilterProp="children"
            // value={filter.field}
            defaultValue={query.field ? query.field : null}
            placeholder="Select Field"
            style={{
              width: 150,
              marginBottom: 15,
            }}
            onChange={(v) => {
              this.handleChange("field", v, null);
              if (filter.search) {
                this.applyFilter();
              }
            }}
          >
            <Select.Option value="all">All Fields</Select.Option>
            <Select.Option value="name">Origin Number</Select.Option>
            <Select.Option value="origin_id">Origin ID</Select.Option>
            <Select.Option value="id">App ID</Select.Option>
          </Select>
          <div className="od-search">
            <Input.Search
              placeholder="search..."
              defaultValue={query.search}
              // value={filter.search}
              onChange={(e) => {
                this.handleChange("search", e.target.value);
              }}
              onSearch={(e) => {
                this.applyFilter();
              }}
            />
          </div>
        </div>
        <Modal
          visible={openCarrier}
          title="Assign carrier for multiple order"
          onCancel={() => this.setState({ openCarrier: false })}
          onOk={this.assignCarrier}
        >
          Carrier:
          <Select
            onChange={(value) => {
              const carrier =
                orders.length > 0 &&
                orders[0].variant.product_type.product_type_carriers &&
                orders[0].variant.product_type.product_type_carriers.find(
                  (el) => el.carrier.id === value
                );
              this.setState({ defaultCarrier: carrier });
            }}
            value={defaultCarrier ? defaultCarrier.carrier.id : []}
            style={{ width: 200, marginLeft: 20 }}
            placeholder="Select carrier"
          >
            {orders.length > 0 &&
              orders[0].variant.product_type.product_type_carriers &&
              orders[0].variant.product_type.product_type_carriers.map(
                (car) => (
                  <Select.Option key={car.carrier.id} value={car.carrier.id}>
                    {car.carrier.name}: ${car.price}
                  </Select.Option>
                )
              )}
          </Select>
        </Modal>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    filter: state.orders.filter,
  }),
  { setOrdersFilter }
)(OrdersFilter);
