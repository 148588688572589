import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Popover,
  Row,
  Select,
  Tabs,
} from "antd";
import gql from "../../api/gql";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fontsQuery from "../../graphql/queries/fonts";
import publicFontsQuery from "../../graphql/queries/publicFonts";
import createFontMutate from "../../graphql/mutation/createFont";
import deleteFontMutate from "../../graphql/mutation/deleteFont";
import { Query } from "@apollo/client/react/components";
import fontQuery from "../../graphql/queries/font";
import Scrollbars from "react-custom-scrollbars";
import { get } from "lodash";
import CreateFile from "../upload/CreateFile";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const Container = styled.div`
  .ant-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      text-overflow: ellipsis;
    }
  }
`;

const ContainerItem = styled.div`
  .remover-form {
    display: none;
  }
  .text-form:hover {
    .remover-form {
      display: block;
    }
  }
`;

const FontSelector = ({ fontFamily = "", onSelect = () => {} }) => {
  const currentUser = useSelector((state) => state.app.currentUser);
  const isSeller = currentUser.roles.find((role) => role.name === "Seller");
  const [id, variant] = fontFamily.split("-");
  const [update, setUpdate] = useState(false);
  const [fonts, setFonts] = useState([]);
  const [upload, setUpload] = useState();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    search: null,
    page: 1,
    pageSize: 900,
    userID: null,
  });
  const [keyActive, setKeyActive] = useState("global");
  const [selectedFont, setSelectedFont] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const { data, loading, refetch } = useQuery(fontsQuery, {
    variables: { ...filter, userID: isSeller ? currentUser.id : null },
  });
  // console.log(data, currentUser);
  const publicFonts = useQuery(publicFontsQuery, {
    variables: filter,
  }).data?.fonts.hits.filter((item) => item.global === true);

  const isAdmin = currentUser.roles.find((role) =>
    ["Administrator"].includes(role.name)
  );
  useEffect(() => {
    if (selectedFont) {
      setSelectedVariant(
        selectedFont.variants
          .map((v) => v.variant)
          .find((v) => v === variant) || selectedFont.variants[0].variant
      );
    }
  }, [selectedFont, variant]);

  useEffect(() => {
    if (
      update && //Only send update if data changed by humans
      selectedFont &&
      selectedVariant &&
      selectedFont.variants.map((v) => v.variant).includes(selectedVariant) &&
      `${selectedFont.id}-${selectedVariant}` !== fontFamily
    ) {
      onSelect(`${selectedFont.id}-${selectedVariant}`);
      setUpdate(false);
    }
  }, [selectedVariant, selectedFont, update, fontFamily, onSelect]);

  useEffect(() => {
    if (data) {
      setFonts(data.fonts.hits.filter((item) => item.global === false));
    }
  }, [data]);
  // console.log(fonts);

  const handleFontChange = (font) => {
    setOpen(false);
    setUpdate(true);
    setSelectedFont(font);
  };

  const handleVariantChange = (variant) => {
    setUpdate(true);
    setSelectedVariant(variant);
  };

  const [createFontMutation] = useMutation(createFontMutate);
  const [deleteFontMutation] = useMutation(deleteFontMutate);

  useEffect(() => {
    if (upload) {
      createFontMutation({
        variables: {
          family: upload.file_name,
          file: {
            key: upload.key,
            file_name: upload.file_name,
            file_size: upload.file_size,
            file_mime: upload.type || "font/ttf",
            source: "aws",
          },
        },
      })
        .then((res) => {
          handleFontChange(res.data.createFont);
          refetch();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [createFontMutation, refetch, upload]);

  const handleClickChange = (open) => {
    setOpen(open);
  };

  useEffect(() => {
    const query = `
    query($id: String!) {
      font(id: $id) {
        id
        family
        variants {
          variant
          file {
            url
          }
        }
      }
    }
  `;
    gql.request(query, {id: String(id)}).then((res) => {
      setSelectedFont(res.font)
    }).catch((error) => {
      setSelectedFont(null);
    });
  }, [id])

  return (
    <Container className="font-select-group-tab">
      {/* {id && (
        <Query
          query={fontQuery}
          variables={{ id: id }}
          onCompleted={({ font }) => {
            console.log('sds', font)
            setSelectedFont(font)
          }}
        >
          {() => null}
        </Query>
      )} */}
      <Popover
        placement="bottomLeft"
        trigger="click"
        visible={open}
        onVisibleChange={handleClickChange}
        content={
          <Tabs
            onChange={(key) => {
              setKeyActive(key);
            }}
            tabBarExtraContent={
              <Row gutter={10}>
                <Col flex="auto">
                  <Input
                    placeholder="Search"
                    onChange={(e) =>
                      setFilter({ ...filter, search: e.target.value })
                    }
                  />
                </Col>
                <Col flex="100px">
                  {/* <Upload
                    accept=".ttf"
                    dragger={false}
                    onUpload={(files) => {
                      setUpload(last(files));
                    }}
                    showUploadList={false}
                  >
                    <Button block>Upload Font</Button>
                  </Upload> */}
                  <div className="upload-font">
                    {isAdmin && keyActive === "global" ? (
                      <CreateFile
                        accept=".ttf"
                        onChange={(file) => {
                          setUpload(file);
                        }}
                      >
                        <Button block>Upload Font</Button>
                      </CreateFile>
                    ) : !isAdmin && keyActive === "my-fonts" ? (
                      <CreateFile
                        accept=".ttf"
                        onChange={(file) => {
                          setUpload(file);
                        }}
                      >
                        <Button block>Upload Font</Button>
                      </CreateFile>
                    ) : null}
                  </div>
                </Col>
              </Row>
            }
            defaultActiveKey={keyActive}
          >
            <Tabs.TabPane tab="Global Fonts" key="global">
              <Scrollbars style={{ width: 600, height: 400 }}>
                <Row type="flex" style={{ width: 600 }} gutter={[30, 20]}>
                  {publicFonts &&
                    publicFonts.map((font) => (
                      <Col
                        span={24}
                        lg={12}
                        key={font.id}
                        onClick={() => handleFontChange(font)}
                        style={{ overflow: "hidden", cursor: "pointer" }}
                      >
                        <ContainerItem>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            className="text-form"
                          >
                            <span>{font.family}</span>
                            {isAdmin ? (
                              <span
                                style={{ color: "red" }}
                                className="remover-form"
                              >
                                <Popconfirm
                                  title="This action will delete font. Are you sure?"
                                  onConfirm={(e) => {
                                    deleteFontMutation({
                                      variables: {
                                        id: font.id,
                                      },
                                    })
                                      .then((res) => {
                                        message.success("delete font success");
                                        refetch();
                                      })
                                      .catch((err) => {
                                        message.error(err.message);
                                      });
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined
                                    style={{ color: "red" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Popconfirm>
                              </span>
                            ) : null}

                            {/* <img
                            alt=""
                            src={`${CLOUDFRONT_URL}font?font=${font.id}-${font.variants[0].variant}&text=Sample`}
                          /> */}
                          </div>
                        </ContainerItem>
                      </Col>
                    ))}
                </Row>
              </Scrollbars>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Custom Fonts" key="my-fonts">
              <Row type="flex" style={{ width: 600 }} gutter={[30, 20]}>
                {fonts.map((font) => (
                  <Col
                    span={24}
                    lg={12}
                    key={font.id}
                    style={{ overflow: "hidden", cursor: "pointer" }}
                  >
                    <ContainerItem>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        className="text-form"
                      >
                        <span
                          style={{ width: "100%" }}
                          onClick={() => handleFontChange(font)}
                        >
                          {font.family}
                        </span>

                        <span style={{ color: "red" }} className="remover-form">
                          <Popconfirm
                            title="This action will delete font. Are you sure?"
                            onConfirm={(e) => {
                              deleteFontMutation({
                                variables: {
                                  id: font.id,
                                },
                              })
                                .then((res) => {
                                  message.success("delete font success");
                                  refetch();
                                })
                                .catch((err) => {
                                  message.error(err.message);
                                });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              style={{ color: "red" }}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </Popconfirm>
                        </span>
                        {/* <img
                        alt=""
                        src={`${CLOUDFRONT_URL}font?font=${font.id}-${font.variants[0].variant}&text=Sample`}
                      /> */}
                      </div>
                    </ContainerItem>
                  </Col>
                ))}
              </Row>
            </Tabs.TabPane>
          </Tabs>
        }
      >
        <Button block style={{ width: 150 }}>
          {get(selectedFont, "family", "Select font")}
        </Button>
      </Popover>
      {selectedFont && (
        <Select
          style={{ width: 100, marginLeft: 10 }}
          value={selectedVariant}
          onChange={handleVariantChange}
          className="fontWidth"
        >
          {selectedFont.variants.map((v) => (
            <Select.Option key={v.variant}>{v.variant}</Select.Option>
          ))}
        </Select>
      )}
    </Container>
  );
};

export default FontSelector;
