import gql from "graphql-tag";
export const CLIPART_CATEGORIES_QUERY = gql`
  query ($parentID: String, $userID: String) {
    categories: clipartCategories(parentID: $parentID, userID: $userID) {
      numberOfCliparts
      hits {
        id
        key: id
        parentID
        value: id
        title
        numberOfCliparts
        displaySettings
        hasChild
        userID
        children {
          id
          title
        }
      }
    }
  }
`;
export const GET_CATEGORIES_CLIPART = gql`
  query getClip($userID: String) {
    categories: cliparts(userID: $userID) {
      hits {
        category {
          id
          title
          hasChild
          value: id
          parentID
          children {
            id
            title
          }
        }
      }
    }
  }
`;
