import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import gql from "../../api/gql";
import rest from "../../api/rest";
import {
  notification,
  Skeleton,
  Select,
  Form,
  Alert,
  Table,
  Button,
  Modal,
  Tabs,
  Avatar,
  InputNumber,
  Row,
  Col,
} from "antd";
import ProductTypeSelector from "../product_type/ProductTypeSelector";
import CurrencyFormat from "react-currency-format";
import { Woocommerce } from "../../helper/woocommerce";
import { Shopify } from "../../helper/shopify";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { DeleteOutlined, DollarOutlined } from "@ant-design/icons";

function mapStateToProps(state) {
  return {};
}

class CategorySelector extends Component {
  state = {
    categories: [],
  };

  componentDidMount() {
    const { site } = this.props || {};
    if (site.platform === "woocommerce") {
      this.loadWooCategories();
    }
    if (site.platform === "shopify") {
      this.loadShopifyCategories();
    }
  }

  loadWooCategories(page = 1) {
    const { site } = this.props || {};
    rest
      .request(
        "get",
        site.id,
        `/wp-json/wc/v3/products/categories?page=${page}&per_page=100`
      )
      .then((categories) => {
        this.setState(
          {
            categories: [
              ...this.state.categories,
              ...categories.map((cat) => ({ id: cat.id, title: cat.name })),
            ],
          },
          () => {
            if (categories.length === 100) {
              this.loadWooCategories(page + 1);
            }
          }
        );
      });
  }

  loadShopifyCategories() {
    const { site } = this.props;
    rest
      .request("get", site.id, `/admin/api/2020-01/custom_collections.json`)
      .then((categories) => {
        this.setState({
          categories: [
            ...this.state.categories,
            ...categories.custom_collections,
          ],
        });
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "response.data.errors"),
        });
      });
  }

  render() {
    const { categories } = this.state;
    return (
      <Form.Item label="Category">
        <Select
          style={{ width: "100%" }}
          onChange={this.props.onChange}
          showSearch
          optionFilterProp="children"
        >
          <Select.Option key={0} value={0}>
            Load All
          </Select.Option>
          {categories.map((cat) => (
            <Select.Option key={cat.id} value={cat.id}>
              {cat.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
}

class TagSelector extends Component {
  state = {
    tags: [],
  };

  componentDidMount() {
    const { site } = this.props || {};
    if (site.platform === "woocommerce") {
      this.loadWooTags();
    }
  }

  loadWooTags(page = 1) {
    const { site } = this.props || {};
    rest
      .request(
        "get",
        site.id,
        `/wp-json/wc/v3/products/tags?page=${page}&per_page=100`
      )
      .then((tags) => {
        this.setState(
          {
            tags: [
              ...this.state.tags,
              ...tags.map((tag) => ({ id: tag.id, title: tag.name })),
            ],
          },
          () => {
            if (tags.length === 100) {
              this.loadWooTags(page + 1);
            }
          }
        );
      });
  }

  render() {
    const { tags } = this.state;
    return (
      <Form.Item label="Tag">
        <Select
          style={{ width: "100%" }}
          onChange={this.props.onChange}
          showSearch
          optionFilterProp="children"
        >
          <Select.Option key={0} value={0}>
            Load All
          </Select.Option>
          {tags.map((tag) => (
            <Select.Option key={tag.id} value={tag.id}>
              {tag.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
}

class MapProducts extends Component {
  state = {
    site: null,
    loading: true,
    productsLoading: false,
    mappingLoading: false,
    category: null,
    tag: null,
    products: [],
    selectedProducts: [],
    product_types: [],
    variations: [],
    step: 0,
    selectedVariants: [], // Check here to configure the default column
    editVariantsPrice: false,
    bulkPrices: {
      retail_cost: null,
      sale_cost: null,
    },
    selectedVariations: [],
  };

  componentDidMount() {
    var {
      match: {
        params: { id },
      },
    } = this.props;
    let query = `query siteById{
      siteById(id: ${id}){id,platform}
    }`;
    gql
      .request(query)
      .then((res) => {
        this.setState({
          site: res.siteById,
          loading: false,
        });
      })
      .catch((err) => {
        notification.error({
          message: _.get(err, "[0].message"),
        });
      });
  }

  loadProducts = () => {
    const { site } = this.state;
    this.setState({ productsLoading: true, products: [] });
    if (site.platform === "woocommerce") {
      this.loadWooProducts();
    }
    if (site.platform === "shopify") {
      this.loadShopifyProducts();
    }
  };

  loadWooProducts = (page = 1) => {
    const { site, category, tag } = this.state;
    rest
      .request(
        "get",
        site.id,
        `/wp-json/wc/v3/products?page=${page}&per_page=100${
          category > 0 ? `&category=${category}` : ""
        }${tag > 0 ? `&tag=${tag}` : ""}`
      )
      .then((products) => {
        let query = `query p($site_id: Int!, $origin_ids: [String!]!){productCheckOriginIDs(site_id: $site_id, origin_ids: $origin_ids)}`;
        gql
          .request(query, {
            site_id: site.id,
            origin_ids: products.map((p) => `${p.id}`),
          })
          .then((res) => {
            let exists = res.productCheckOriginIDs || [];
            this.setState(
              {
                products: [
                  ...this.state.products,
                  ...products.map((p) => ({
                    id: p.id,
                    title: p.name,
                    images: p.images.map((image) => image.src),
                  })),
                ].filter((p) => exists.indexOf(`${p.id}`) === -1),
                productsLoading: false,
              },
              () => {
                if (products.length === 100) {
                  this.loadWooProducts(page + 1);
                }
              }
            );
          });
      });
  };

  loadShopifyProducts = (page = 1) => {
    const { site, category } = this.state;
    rest
      .request(
        "get",
        site.id,
        `/admin/api/2020-01/products.json?limit=250&collection_id=${category}`
      )
      .then(({ products }) => {
        let query = `query p($site_id: Int!, $origin_ids: [String!]!){productCheckOriginIDs(site_id: $site_id, origin_ids: $origin_ids)}`;
        gql
          .request(query, {
            site_id: site.id,
            origin_ids: products.map((p) => `${p.id}`),
          })
          .then((res) => {
            let exists = res.productCheckOriginIDs || [];
            this.setState(
              {
                products: [
                  ...this.state.products,
                  ...products.map((p) => ({
                    id: p.id,
                    title: p.title,
                    images: p.images.map((image) => image.src),
                  })),
                ].filter((p) => exists.indexOf(`${p.id}`) === -1),
                productsLoading: false,
              },
              () => {
                if (products.length === 250) {
                  //this.loadShopifyProducts(page + 1)
                }
              }
            );
          });
      });
  };

  mapProducts = (e) => {
    e.preventDefault();
    const { site, variations, selectedProducts, product_types } = this.state;
    switch (site.platform) {
      case "woocommerce":
        this.setState({ mappingLoading: true });
        Promise.all(
          selectedProducts.map((product) => {
            return new Promise((resolve) => {
              Woocommerce.mapProduct(
                site.id,
                product,
                product_types,
                variations
              )
                .then((product_id) => {
                  let query = `mutation fetchProduct{
                fetchProductFromSite(site_id: ${site.id}, product_id:"${product_id}")
              }`;
                  gql
                    .request(query)
                    .then((res) => {
                      notification.success({
                        message: `Successfuly to map product #${product_id}`,
                      });
                      resolve(true);
                    })
                    .catch((err) => {
                      notification.error({
                        message: _.get(err, "[0].message"),
                      });
                      resolve(false);
                    });
                })
                .catch((err) => {
                  resolve(false);
                });
            });
          })
        ).then((res) => {
          this.setState({ mappingLoading: false, selectedProducts: [] });
        });
        break;
      case "shopify":
        Promise.all(
          selectedProducts.map((product) => {
            return new Promise((resolve, reject) => {
              Shopify.mapProduct(site.id, product, product_types, variations)
                .then((product_id) => {
                  let query = `mutation fetchProduct{
                fetchProductFromSite(site_id: ${site.id}, product_id:"${product_id}")
              }`;
                  gql
                    .request(query)
                    .then((res) => {
                      notification.success({
                        message: `Successfuly to map product #${product_id}`,
                      });
                      resolve(true);
                    })
                    .catch((err) => {
                      notification.error({
                        message: _.get(err, "[0].message"),
                      });
                      resolve(false);
                    });
                })
                .catch((err) => {
                  notification.error({ message: _.get(err, "[0].message") });
                  reject(false);
                });
            });
          })
        ).then((res) => {
          this.setState({ mappingLoading: false, selectedProducts: [] });
        });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      site,
      products,
      selectedProducts,
      product_types,
      productsLoading,
      loading,
      step,
      selectedVariants,
      selectedVariations,
      editVariantsPrice,
      bulkPrices,
      variations,
    } = this.state;
    if (loading) return <Skeleton />;
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        render: (title, product) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "70px auto",
              alignItems: "center",
            }}
          >
            {product.images ? (
              <img
                src={product.images[0]}
                style={{ width: 60, height: "auto" }}
                alt=""
              />
            ) : (
              <div />
            )}
            <div>{title}</div>
          </div>
        ),
      },
    ];
    const rowSelection = {
      selectedRowKeys: selectedProducts,
      onChange: (selectedProducts) => this.setState({ selectedProducts }),
    };
    const variantColumns = [
      {
        title: "Image",
        dataIndex: "id",
        key: "image",
        render: (id, variant) => (
          <Avatar
            shape="square"
            icon="picture"
            size={50}
            style={{ cursor: "pointer" }}
            src={variant.image ? variant.image.url : null}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                selectedVariants: [id],
                editVariantsImage: true,
              });
            }}
          />
        ),
      },
      {
        title: "Variant",
        dataIndex: "sku",
        key: "sku",
        render: (sku, { attributes }) => {
          return (
            <div>
              {attributes
                .map((attr) => `${attr.name}: ${attr.option}`)
                .join(", ")}{" "}
              <br />
              <span style={{ color: "#999" }}>SKU: {sku}</span>
            </div>
          );
        },
      },
      {
        title: "Base Cost",
        dataIndex: "base_cost",
        key: "base_cost",
        render: (base_cost) => `$${base_cost}`,
        align: "center",
      },
      {
        title: "Regular Price",
        dataIndex: "retail_cost",
        render: (retail_cost, { id, base_cost }) => (
          <InputNumber
            formatter={(value) => `$${value}`}
            min={base_cost}
            value={retail_cost}
            onChange={(v) => {
              const { variations } = this.state;
              variations.find((v) => v.id === id).retail_cost = v;
              this.setState({
                variations: variations,
              });
            }}
          />
        ),
      },
      {
        title: "Sale Price",
        dataIndex: "sale_cost",
        render: (sale_cost, { base_cost, id }) => (
          <InputNumber
            formatter={(value) => `$${value}`}
            min={base_cost}
            value={sale_cost}
            onChange={(v) => {
              const { variations } = this.state;
              variations.find((v) => v.id === id).sale_cost = v;
              this.setState({
                variations: variations,
              });
            }}
          />
        ),
      },
      {
        title: "Profit",
        dataIndex: "id",
        key: "profit",
        render: (id, { sale_cost, base_cost }) => (
          <CurrencyFormat
            value={sale_cost - base_cost}
            displayType="text"
            prefix={"$"}
            decimalScale={2}
          />
        ),
      },
    ];
    return (
      <div>
        <Alert
          type="info"
          message="Select a category bellow to start mapping"
        ></Alert>
        <Alert
          type="warning"
          message="This action will remove all current variants from your site"
          showIcon={true}
          style={{ marginTop: 10 }}
        ></Alert>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <CategorySelector
              site={site}
              onChange={(c) =>
                this.setState({ category: c }, () => this.loadProducts())
              }
            />
          </Col>
          <Col className="gutter-row" span={6}>
            <TagSelector
              site={site}
              onChange={(c) =>
                this.setState({ tag: c }, () => this.loadProducts())
              }
            />
          </Col>
        </Row>
        <Button
          disabled={selectedProducts.length === 0}
          type="primary"
          onClick={(e) => {
            this.setState({
              step: 1,
            });
          }}
        >
          Map Products
        </Button>
        <Table
          loading={productsLoading}
          columns={columns}
          dataSource={products}
          rowSelection={rowSelection}
          pagination={{ pageSize: 20 }}
          rowKey={(row) => row.id}
        />
        <Modal
          visible={step === 1 && selectedProducts.length > 0}
          width={800}
          maskClosable={false}
          title="Select Product Type"
          okText="Next"
          okButtonProps={{ disabled: product_types.length === 0 }}
          onOk={(e) => this.setState({ step: 2 })}
          onCancel={(e) => this.setState({ step: 0 })}
        >
          <ProductTypeSelector
            onSelect={(types) => {
              this.setState({
                product_types: types,
                variations: [].concat.apply(
                  [],
                  types.map((type) => type.product_type_variants)
                ),
                selectedVariations:
                  types && types.length ? types[0].product_type_variants : [],
              });
            }}
          />
        </Modal>
        <Modal
          title="Update variants price"
          visible={editVariantsPrice}
          onCancel={(e) => this.setState({ editVariantsPrice: false })}
          onOk={(e) => {
            e.preventDefault();
            const { variations, selectedVariants } = this.state;
            if (bulkPrices.retail_cost) {
              variations.forEach((v) => {
                if (selectedVariants.includes(v.id)) {
                  v.retail_cost = bulkPrices.retail_cost;
                }
              });
            }
            if (bulkPrices.sale_cost) {
              variations.forEach((v) => {
                if (selectedVariants.includes(v.id)) {
                  v.sale_cost = bulkPrices.sale_cost;
                }
              });
            }
            this.setState({ variations, editVariantsPrice: false });
          }}
        >
          <Form.Item label="Regular Price">
            <InputNumber
              formatter={(value) => `$${value}`}
              value={bulkPrices.retail_cost}
              onChange={(v) =>
                this.setState({
                  bulkPrices: { ...bulkPrices, retail_cost: v },
                })
              }
            />
          </Form.Item>
          <Form.Item label="Sale Price">
            <InputNumber
              formatter={(value) => `$${value}`}
              value={bulkPrices.sale_cost}
              onChange={(v) =>
                this.setState({
                  bulkPrices: { ...bulkPrices, sale_cost: v },
                })
              }
            />
          </Form.Item>
        </Modal>
        <Modal
          visible={
            step === 2 &&
            selectedProducts.length > 0 &&
            product_types.length > 0
          }
          width={800}
          maskClosable={false}
          title="Variants"
          okText="Start Mapping"
          okButtonProps={{ disabled: product_types.length === 0 }}
          onOk={this.mapProducts}
          onCancel={(e) => this.setState({ step: 0 })}
          confirmLoading={this.state.mappingLoading}
        >
          <Tabs
            onChange={(e) => {
              this.setState({
                selectedVariants: [],
                productTypeIndex: e,
                selectedVariations: variations.filter(
                  (v) => v.product_type_id === e
                ),
              });
            }}
          >
            {product_types.map((product_type) => (
              <Tabs.TabPane key={product_type.id} tab={product_type.title}>
                <DndProvider backend={HTML5Backend}>
                  <Table
                    dataSource={
                      selectedVariations && selectedVariations.length
                        ? selectedVariations
                        : variations.filter(
                            (v) => v.product_type_id === product_type.id
                          )
                    }
                    columns={variantColumns}
                    rowKey={(row) => row.id}
                    pagination={false}
                    rowSelection={{
                      selectedRowKeys: selectedVariants,
                      onChange: (selectedVariants) =>
                        this.setState({ selectedVariants }),
                      hideDefaultSelections: true,
                      selections:
                        selectedVariants.length === 0
                          ? []
                          : [
                              {
                                key: "price",
                                text: (
                                  <>
                                    <DollarOutlined /> Price
                                  </>
                                ),
                                onSelect: (e) => {
                                  const {
                                    selectedVariants,
                                    selectedVariations,
                                  } = this.state;
                                  if (selectedVariants.length === 1) {
                                    let v = selectedVariations.find(
                                      (v) => v.id === selectedVariants[0]
                                    );
                                    this.setState(
                                      {
                                        bulkPrices: {
                                          retail_cost: v.retail_cost,
                                          sale_cost: v.sale_cost,
                                        },
                                      },
                                      () =>
                                        this.setState({
                                          editVariantsPrice: true,
                                        })
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        bulkPrices: {
                                          retail_cost: null,
                                          sale_cost: null,
                                        },
                                      },
                                      () =>
                                        this.setState({
                                          editVariantsPrice: true,
                                        })
                                    );
                                  }
                                },
                              },
                              {
                                key: "remove",
                                text: (
                                  <>
                                    <DeleteOutlined /> Remove
                                  </>
                                ),
                                onSelect: (e) => {
                                  const {
                                    selectedVariants,
                                    selectedVariations,
                                  } = this.state;
                                  this.setState({
                                    selectedVariations: selectedVariations.filter(
                                      (v) => !selectedVariants.includes(v.id)
                                    ),
                                    variations: variations.filter(
                                      (v) => !selectedVariants.includes(v.id)
                                    ),
                                    selectedVariants: [],
                                  });
                                },
                              },
                            ],
                    }}
                  />
                </DndProvider>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps)(MapProducts);
