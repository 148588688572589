import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Image,
  message,
  Modal,
  Checkbox,
  Skeleton,
  Space,
  Badge,
  Tooltip,
} from "antd";
import HistoryDesign from "./HistoryDesign";
import { BsImageAlt } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { URL_API_AUTO_BANNER, ASSET_API_AUTO_BANNER } from "../../../../config";
import { FaEye } from "react-icons/fa";

import { v4 as uuidv4 } from "uuid";

import { SSE } from "../../../../sse";

import PreviewCurrent from "./PreviewCurrent";
const ControllerDesign = ({
  configsImage,
  selectedBackground,
  selectedImage,
}) => {
  const [loading, setLoading] = useState(false);
  const [urlRender, setUrlRender] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [urlChoose, setUrlChoose] = useState([]);
  const [openModelChoose, setOpenModelChoose] = useState(false);
  const [visibleImg, setVisibleImg] = useState(false);
  const [visibleImgUrl, setVisibleImgUrl] = useState("");

  const [keysUpload, setsKeysUpload] = useState([]);
  const getLocalhostHistory = localStorage.getItem("historyAutoBannerData");
  const [historyData, setHistoryData] = useState(
    getLocalhostHistory ? JSON.parse(getLocalhostHistory) : []
  );
  const [historyCurrentIndex, setHistoryCurrentIndex] = useState(null);

  useEffect(() => {
    localStorage.setItem("historyAutoBannerData", JSON.stringify(historyData));
    if (historyData.length > 0) {
      setHistoryCurrentIndex(historyData.length - 1);
    }
  }, [historyData]);

  const onCreateImage = () => {
    const backgrounds = selectedBackground || [];
    const images = selectedImage || [];

    if (Math.floor(backgrounds.length * images.length) > 20) {
      message.error(
        "Please delete photos. The system can only create 20 images at a time. Thank!"
      );
      return;
    }
    const ratio = configsImage.ratio || 1;
    if (backgrounds.length <= 0) {
      message.error("Please select at least one background!");
      return;
    }

    if (images.length <= 0) {
      message.error("Please select at least one image!");
      return;
    }
    setUrlRender([]);
    setUrlChoose([]);
    setsKeysUpload([]);
    setOpenModelChoose(true);
    setLoading(true);
    const configs = {};
    const arrayRatio = ["height", "width", "x", "y"];
    for (const [key, value] of Object.entries(configsImage)) {
      if (arrayRatio.includes(key)) {
        configs[key] = value * ratio;
      } else {
        configs[key] = value;
      }
    }
    configs.images = images;
    configs.backgrounds = backgrounds.map((e) => e.imageUrl);

    try {
      var source = new SSE(URL_API_AUTO_BANNER, {
        method: "POST",
        payload: JSON.stringify(configs),
      });
      window.sourceAutoBanner = source;

      source.addEventListener("message", function (e) {
        if (!e.data) {
          setLoading(false);
          return;
        }
        const data = JSON.parse(e.data);
        switch (data.status) {
          case "done":
            setLoading(false);
            break;
          case "running":
            if (data.url) {
              setUrlRender((prev) => [
                {
                  uid: uuidv4(),
                  base64: data.url,
                },
                ...prev,
              ]);
            }
            break;
          case "error":
            setUrlRender([]);
            setOpenModelChoose(false);
            setLoading(false);
            message.error("An error occurred. Please check again!");
            break;
          default:
            break;
        }
      });
      source.stream();
    } catch (error) {
      setUrlRender([]);
      setOpenModelChoose(false);
      setLoading(false);
      message.error("An error occurred. Please check again!");
    }
  };

  const handeGeyKeyNext = (arrayBase64 = [], index = 0, dataKeys = []) => {
    if (!arrayBase64[index]) {
      setOpenModelChoose(false);
      setLoadingSave(false);
      setHistoryData((prev) => [
        ...prev,
        {
          id: uuidv4(),
          keys: dataKeys,
        },
      ]);
      setUrlRender([]);
      setUrlChoose([]);
      return;
    }
    const bodySourc = {
      base64s: [arrayBase64[index]],
      action: "save-compress",
    };
    try {
      var source = new SSE(URL_API_AUTO_BANNER, {
        method: "POST",
        payload: JSON.stringify(bodySourc),
      });
      window.sourceSaveCompress = source;

      source.addEventListener("message", function (e) {
        if (!e.data) {
          setLoadingSave(false);
          dataKeys = [];
          message.error("An error occurred. Please check again!");
          return;
        }
        const data = JSON.parse(e.data);
        switch (data.status) {
          case "running":
            if (data.key) {
              dataKeys = [...dataKeys, data.key];
              index++;
              handeGeyKeyNext(arrayBase64, index, dataKeys);
            }
            break;
          case "error":
            setLoadingSave(false);
            dataKeys = [];
            message.error("An error occurred. Please check again!");
            break;
          default:
            break;
        }
      });
      source.stream();
    } catch (error) {
      setLoadingSave(false);
      dataKeys = [];
      message.error("An error occurred. Please check again!");
    }
  };

  const handleOkChooseImage = () => {
    setLoadingSave(true);
    handeGeyKeyNext(
      urlRender.filter((e) => urlChoose.includes(e.uid)).map((i) => i.base64)
    );
  };
  const handleCancelChooseImage = () => {
    setOpenModelChoose(false);
  };
  const onChangeCheckBoxChoose = (url) => {
    var newValue = [...urlChoose];
    if (urlChoose.includes(url)) {
      newValue = newValue.filter((e) => e != url);
    } else {
      newValue = [...urlChoose, url];
    }
    setUrlChoose(newValue);
  };
  const handleDeselectAll = () => {
    setUrlChoose([]);
  };
  const handleSelectAll = () => {
    setUrlChoose(urlRender.map((e) => e.uid));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          border: "1px solid #eeeeee",
          borderRadius: "5px",
          overflow: "visible",
        }}
      >
        <Button
          onClick={onCreateImage}
          loading={loading}
          style={{
            width: "100%",
            height: 40,
            border: "none",
          }}
          disabled={
            !(selectedBackground.length > 0 && selectedImage.length > 0)
          }
          type="primary"
          htmlType="submit"
        >
          Create Banner
        </Button>
        {urlRender.length > 0 && (
          <Badge count={urlRender.length}>
            <Tooltip placement="topRight" title="Select the appropriate images">
              <Button
                style={{
                  height: 40,
                  border: "none",
                }}
                onClick={() => setOpenModelChoose(true)}
              >
                <BsImageAlt />
              </Button>
            </Tooltip>
          </Badge>
        )}
      </div>

      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <HistoryDesign
          historyData={historyData}
          indexActive={historyCurrentIndex}
          onSelect={setHistoryCurrentIndex}
          onDeleteAll={() => setHistoryData([])}
          onDeleteItem={(index) => {
            const newDataHistory = historyData.filter((e, i) => i !== index);
            setHistoryData(newDataHistory);
          }}
        />
        <PreviewCurrent
          historyData={historyData}
          indexActive={historyCurrentIndex}
        />

        <Modal
          confirmLoading={loading || loadingSave}
          title={
            loading || loadingSave ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  color: "#ff9100",
                }}
              >
                <FaSpinner className="fa-spin" style={{ marginRight: 5 }} />{" "}
                Image is being processed...
              </div>
            ) : (
              "Select the images"
            )
          }
          visible={openModelChoose}
          onCancel={handleCancelChooseImage}
          footer={
            <>
              <Button onClick={handleCancelChooseImage}>Cancel</Button>
              <Button
                disabled={urlChoose <= 0}
                loading={loading || loadingSave}
                onClick={handleOkChooseImage}
                type="primary"
              >
                Save
              </Button>
            </>
          }
        >
          <Space
            style={{
              marginBottom: 10,
            }}
          >
            <Button
              disabled={loading || loadingSave}
              style={{
                borderRadius: 5,
              }}
              onClick={handleSelectAll}
            >
              Select all
            </Button>
            <Button
              disabled={loading || loadingSave}
              style={{
                borderRadius: 5,
              }}
              onClick={handleDeselectAll}
            >
              Deselect all
            </Button>
          </Space>
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 5,
            }}
          >
            {loading && (
              <Skeleton.Image
                style={{
                  width: 70,
                  height: 70,
                  borderRadius: 5,
                  marginRight: 5,
                }}
                active={true}
              />
            )}
            {urlRender.map((i, index) => (
              <li key={index} className="li-select-background-autobanner">
                <Checkbox
                  checked={
                    urlChoose.map((e) => e).includes(i.uid) ? true : false
                  }
                  onChange={(v) => onChangeCheckBoxChoose(i.uid)}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={`${i.base64}`}
                    />
                  </div>
                </Checkbox>
                <div
                  style={{
                    height: 0,
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      bottom: 3,
                      left: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "25px",
                      width: "25px",
                      background: "#fffffff2",
                      outline: "none",
                      border: "none",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 5px #000000",
                      color: "#ff9100",
                      cursor: "pointer",
                      fontSize: "20px !important",
                    }}
                    type="text"
                    onClick={() => {
                      setVisibleImgUrl(i.base64);
                      setVisibleImg(true);
                    }}
                  >
                    <FaEye />
                  </button>
                  <div
                    style={{
                      display: "none",
                    }}
                  >
                    <Image
                      width={200}
                      style={{
                        display: "none",
                      }}
                      src={visibleImgUrl}
                      preview={{
                        visible: visibleImg,
                        scaleStep: 0.1,
                        src: visibleImgUrl,
                        onVisibleChange: (value) => {
                          setVisibleImg(value);
                        },
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Modal>
      </div>
    </>
  );
};

export default ControllerDesign;
