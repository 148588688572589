import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, notification, Select } from "antd";
import React, { useState } from "react";
import { CREATE_COLLECTION } from "../../graphql/mutation/createCollection";
import { COLLECTION } from "../../graphql/queries/collections";
import _, { debounce } from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";
const Container = styled.div`
  .hover-backgroud:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;


const ColectionSelect = ({ value, onChange = () => {}, currentUser }) => {
  const isSeller = currentUser?.roles.find((role) => role.name === "Seller");
  const [search, setSearch] = useState("");
  const { data, refetch } = useQuery(COLLECTION, {
    variables: {
      filter: {
        limit: -1,
        search: search,
        userID: isSeller ? currentUser.id : null,
      },
    },
  });
  let collections = data ? data.collections.hits : [];
  const [createCollerction] = useMutation(CREATE_COLLECTION);
  const onFinish = (valueForm) => {
    createCollerction({ variables: { input: { title: valueForm } } })
    .then((res) => {
        setSearch("");
        refetch().then(() => {
            if (value && value.length > 0) {
                onChange([...value, res.data.createCollection.id]);
            } else {
                onChange([res.data.createCollection.id]);
            }
        });
    })
    .catch((err) => {
        notification["error"]({
            message: "Error",
            description: _.get(err, "[0].message"),
        });
    });
    };
  return (
    <>
    <Select
        value={value}
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={debounce((value) => {
            setSearch(value);
        }, 300)}
        onChange={onChange}
        dropdownRender={(menu) => (
            <Container>
                {collections.length === 0 && search?.length > 0 ? (
                    <div
                        className="hover-backgroud"
                        onClick={() => {
                            onFinish(search);
                        }}
                        style={{ paddingLeft: 10 }}
                    >
                        <Button type="link">
                            <PlusOutlined />
                            Add item
                        </Button>
                    </div>
                ) : (
                    menu
                )}
            </Container>
        )}
    >
        {collections?.map((item) => (
            <Select.Option value={item.id} key={item.id}>
                {item.title}
            </Select.Option>
        ))}
    </Select>
    </>
  );
};

export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(ColectionSelect);
