import React, { useState, useRef } from "react";
import gql from "../../../../../api/gql";
import Upload from "../../../../../components/upload/Upload";
import { FaPlus } from "react-icons/fa";
import { RiUploadCloudFill } from "react-icons/ri";
import { MdDeleteForever } from "react-icons/md";
import { ASSET_URL } from "../../../../../config";
import UploadMultiple from "../../../components/UploadMultiple";
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Progress,
  Spin,
} from "antd";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
const AddBackground = ({ onChange, currentUser, category_id }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categorys, setCategorys] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [form] = Form.useForm();
  const showDrawer = () => {
    getCaterotyAutoBanner();
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getCaterotyAutoBanner = () => {
    setLoading(true);
    const query = `query{
			bannerAllCategory{
        numberOfBanners
        hits{
          id
          title
        }
			}
		}`;

    gql
      .request(query)
      .then((res) => {
        setCategorys(res.bannerAllCategory.hits || []);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again!");
        setLoading(false);
      });
  };

  const handleCreateBanner = async ({
    title = `${uuidv4()}-${currentUser.last_name}-${currentUser.first_name}`,
    categoryID = category_id,
    imageUrl,
  }) => {
    const mutation = `mutation createBannerAdd($title: String!, $imageUrl: String!, $categoryID: String!){
      createBanner(title: $title, imageUrl: $imageUrl, categoryID: $categoryID){
            id
        }
    }`;
    return await gql
      .request(mutation, {
        title,
        categoryID,
        imageUrl,
      })
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const onFinish = async (values) => {
    const { categoryID } = values;
    const cateID = categoryID ? categoryID : category_id;
    if (!cateID) {
      message.error("Please input category!");
      return;
    }
    if (files.length <= 0) {
      message.error("Please choose files!");
      return;
    }
    setLoading(true);
    for (var a = 0; a < files.length; a++) {
      const file = files[a];
      const urlFile = file.key;
      const create = await handleCreateBanner({
        imageUrl: urlFile,
        categoryID,
      });
      if (!create) {
        message.error("Something went wrong, please try again!");
      }
    }
    setLoading(false);
    onChange();
    form.resetFields();
    setFiles([]);
    onClose();
  };
  const onFinishFailed = (errorInfo) => {};
  const handleDeleteFile = (index, data) => {
    setFiles(prev => prev.filter((e, i) => i != index));
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          aspectRatio: "1/1",
          paddingBottom: 5,
          paddingRight: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          onClick={showDrawer}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#dfdfdf",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          <FaPlus />
        </div>
      </div>
      <Drawer
        title="Add new background"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={open}
      >
        {loading ? (
          <Spin />
        ) : (
          <Form
            layout="vertical"
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="Category" name="categoryID">
              <Select loading={loading} defaultValue={category_id}>
                {categorys.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <ul
              style={{
                padding: 0,
                listStyle: "none",
                maxHeight: "300px",
                overflow: "auto",
                paddingRight: "5px",
              }}
            >
              {files
                .map((i, index) => {
                  return (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "14px",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        {i.status == "done" ? (
                          i.name
                        ) : (
                          <Progress
                            percent={Math.floor(i.percent)}
                            status="active"
                            percentPosition={{
                              align: "start",
                              type: "outer",
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          onClick={() => handleDeleteFile(index, i)}
                          style={{
                            fontSize: "18px",

                            color: "red",
                            cursor: "pointer",
                            background: "rgb(239 239 239 / 85%)",
                            width: "25px",
                            aspectRatio: "1 / 1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <MdDeleteForever />
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
            <Form.Item label="Image Url" name="imageUrl">
              <UploadMultiple
                disabled={loadingFile || loading}
                onChange={(file) => {
                  setLoadingFile(true);
                  setFiles(file);
                  const check = file.find((e) => e.percent != "100");
                  if (!check) {
                    setLoadingFile(false);
                    const key = "uploading_photos";
                    setTimeout(() => {
                      message.success({
                        content: "Uploading image successfully!",
                        key,
                        duration: 2,
                      });
                    }, 1000);
                  }
                }}
                onUpload={(file) => {
                  setFiles(file);
                }}
                accept=".png, .jpg, .jpeg"
              >
                <div style={{ 
                  padding: "10px 5px",
                  background: 'rgb(243 243 243)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                  borderRadius: 5
                }}>
                  <RiUploadCloudFill size={30} />
                  <p>Click or drag and drop .png or .jpg file here to upload</p>
                </div>
              </UploadMultiple>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingFile || loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default AddBackground;
