import { CheckSquareOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Card, Image, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";
import clipartsByCatgeory from "../../graphql/queries/clipart/clipartsByCatgeory";
import UploadClipart from "../cliparts/UploadClipart";
import Grid from "../Grid";
import FileField from "../upload/FileField";
const Container = styled.div`
  .ant-card-bordered .ant-card-cover {
    margin: 0;
  }
  .item-selected {
    position: relative;
  }
  .item-selected .ant-card-bordered {
    box-shadow: 0 0 0 2px rgba(92, 106, 196, 0.5),
    0 2px 3px 0 rgba(92, 106, 196, 0.15);
  }
  .custom-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #5c6ac4;
  }
  .ant-upload.ant-upload-drag {
    border: none;
  }
`;

const ChangeImageMockup = ({ record, onSave }) => {
    const { data, loading, refetch } = useQuery(clipartsByCatgeory, {
        variables: { categoryID: record.categoryID ? record.categoryID : null },
        fetchPolicy: "network-only",
        skip: !record.categoryID,
    });
    const [clipart, setClipart] = useState(record);
    useEffect(() => {
        setClipart(record);
    }, [record]);
    if (loading) return <Skeleton />;
    return (
        <Container>
            {record.categoryID ? (
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: 10,
                        }}
                    >
                        <UploadClipart categoryID={record.categoryID} refetch={refetch} />
                    </div>
                    <Grid style={{ margin: 1 }} gap={20} width={100}>
                        {data?.cliparts.map((el) => (
                            <div
                                key={el.id}
                                style={{ marginBottom: 2 }}
                                className={clipart.id === el.file.id ? "item-selected" : null}
                            >
                                <Card
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (el.file.id === clipart.id) {
                                            setClipart(null);
                                            //   onChange(null);
                                        } else {
                                            setClipart({
                                                id: el.file.id,
                                                key: el.file.key,
                                                categoryID: record.categoryID,
                                            });
                                        }
                                    }}
                                >
                                    <Image
                                        src={`${CLOUDFRONT_URL}/100/${el.file.key}`}
                                        preview={false}
                                    ></Image>
                                </Card>
                                {clipart.id === el.file.id ? (
                                    <CheckSquareOutlined className="anticon custom-icon" />
                                ) : null}
                            </div>
                        ))}
                    </Grid>
                </div>
            ) : (
                <div>
                    <FileField
                        size={64}
                        accept=".jpg, .png, .jpeg"
                        loading={true}
                        value={clipart}
                        onChange={(value) => {
                            setClipart({ key: value.key });
                        }}
                    />
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        onSave(clipart);
                    }}
                >
                    Save
                </Button>
            </div>
        </Container>
    );
};

export default ChangeImageMockup;
