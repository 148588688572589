import React from "react";
import { Button, Popconfirm, message } from "antd";
import { MdDeleteForever } from "react-icons/md";
import gql from "../../../../../api/gql";
import _ from "lodash";

const DeleteBackground = ({id, onChange}) => {
    const deleteBackground = () => {
        const mutation = `mutation{
            deleteBanner(id: "${id}")
        }`;
        gql.request(mutation)
        .then(res => {
            onChange();
        })
        .catch((error) => {
            message.error('Something went wrong, please try again!');
        });
    }
    return (
        <>
        <Popconfirm placement="bottom" title="Are you sure to delete?" onConfirm={deleteBackground} okText="Yes" cancelText="No">
            <div style={{
                background: "#d90000",
                margin: "5px",
                width: "30px",
                aspectRatio: "1 / 1",
                color: "#ffffff",
                fontSize: "21px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 0px 5px #333333",
                borderRadius: "50%",
                cursor: "pointer",
            }}>
                <MdDeleteForever />
            </div>
        </Popconfirm>
        </>
    )
}

export default DeleteBackground;