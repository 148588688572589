import gql from "graphql-tag";

export const ORDER = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      origin_id
      product {
        title
        sku
        images
        mockups {
          id
          mockupId
          productId
          variantIds
          image
          sorting
        }
      }
      variant {
        mockup {
          image
        }
        product_type_variant {
          attributes {
            name
            slug
            option
          }
        }
      }
      history {
        id
        message
        user {
          id
          first_name
          last_name
        }
        type
        created_at
        updated_at
      }
      customer {
        id
        first_name
        last_name
        email
        phone
      }
      site {
        id
        title
        domain
      }
      billing {
        address1
        city
        country_code
        zip
      }
      shipping {
        address1
        address2
        city
        country_code
        zip
        country
        state
        state_code
        # first_name
        # last_name
      }
      designs {
        id
        order_id
        file_key
        base_print_area_id
        artwork_id
        file_id
        file {
          id
          source
          key
          file_name
          file_mime
          file_size
          url
          sku
        }
        base_print_area_name
        status
        log
      }
      design_status
      price
      quantity
      status
      created_at
      personalizedData {
        printArea
        personalized
        artwork {
          id
          templates {
            layers
          }
        }
      }
    }
  }
`;
