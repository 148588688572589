import { useMutation, useQuery } from "@apollo/client";
import { Button, Drawer, Form, Input, Radio, TreeSelect } from "antd";
import { debounce } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { ARTWORK_CATEGORY } from "../graphql/queries/artwork/artworkCategories";
import { history } from "../history";
const Container = styled.div`
  .ant-form {
    display: flex;
  }
`;

const CustomizeSearch = ({ filter, setFilter, categoryID = null }) => {
  const [form] = Form.useForm();
  const onSearchChange = (value, { search }) => {
    if (search) {
      setFilter({ ...filter, search, page: 1 });
    } else {
      setFilter({ ...filter, search: null, page: 1 });
    }
  };
  const { data } = useQuery(ARTWORK_CATEGORY);
  const [drawerFilter, showDrawerFilter] = useState(false);
  const renderTree = (cats) => {
    return cats?.map((cat) => (
      <TreeSelect.TreeNode
        key={cat.id ? cat.id : "-"}
        value={cat.id}
        title={cat.title}
      >
        {cat.children && renderTree(cat.children)}
      </TreeSelect.TreeNode>
    ));
  };
  return (
    <Container>
      <div className="header-filter">
        <Form
          style={{ width: "100%" }}
          form={form}
          layout="vertical"
          onValuesChange={debounce(onSearchChange, 300)}
        >
          <Form.Item noStyle name="search" initialValue={filter?.search}>
            <Input.Search className="input-tag" placeholder="Search..." />
          </Form.Item>
          {/* <Button className="ml-15" onClick={() => showDrawerFilter(true)}>
            More filters
          </Button> */}
        </Form>
        {/* <Drawer
          width={400}
          title="More filters"
          onClose={() => {
            showDrawerFilter(false);
          }}
          visible={drawerFilter}
        >
          <div>
            <p>Filter by categories</p>
            <TreeSelect
              style={{ width: "100%" }}
              showSearch={true}
              multiple={false}
              treeNodeFilterProp="title"
              treeDefaultExpandAll={true}
              allowClear
              defaultValue={categoryID}
              onChange={(value) => {
                if (value) {
                  history.push({
                    pathname: "/artworks",
                    search: `?categoryID=${value}`,
                  });
                } else {
                  history.push({
                    pathname: "/artworks",
                    search: ``,
                  });
                }
              }}
            >
              {renderTree(data?.artworkCategories)}
            </TreeSelect>
          </div>
        </Drawer> */}
      </div>
    </Container>
  );
};

export default CustomizeSearch;
