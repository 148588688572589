import React, { Component } from "react";
import { Form, notification, Input, Button, Card, Skeleton } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import { history } from "../../history";
import { setCurrentUser } from "../../actions";
import TemplateConfigs from "./TemplateConfigs";
import { connect } from "react-redux";

class ExportTemplateForm extends Component {
  state = {
    loading: false,
    templateById: null,
    defaultExportColumns: [],
    done: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    id && this.templateById(id);
    const q = `query defaultExportColumns{defaultExportColumns{name type value}}`;
    gql.request(q).then((res) => {
      if (id) {
        this.setState({
          defaultExportColumns: res.defaultExportColumns,
        });
      } else {
        this.setState({
          done: true,
          defaultExportColumns: res.defaultExportColumns,
        });
      }
    });
  }

  templateById(id) {
    const q = `query exportTemplate($id: String!){
      exportTemplate( id: $id ){
        name
        author_id
        data{
          name
          type
          value
        }
      }
    }`;
    gql.request(q, { id: id }).then((res) => {
      this.setState({
        done: true,
        templateById: res.exportTemplate,
      });
    });
  }

  onhandleSubmit = (values) => {
    const { id } = this.props.match.params;
    const isClone = this.props.match.path === "/export-templates/clone/:id";
    // values.user_ids = values.user_ids ? values.user_ids : [];
    if (!id || isClone) {
      const mutation = `mutation createExportTemplate($input: ExportTemplateInput!){
            createExportTemplate(input: $input)
          }`;
      this.setState({ loading: true });
      gql
        .request(mutation, {
          input: values,
        })
        .then((res) => {
          notification["success"]({
            message: isClone
              ? "Clone template successfully!"
              : "Export template successfully created!",
          });
          this.setState({
            loading: false,
          });
          history.push("/export-templates");
        })
        .catch((err) => {
          notification["error"]({
            message: _.get(err, "[0].message"),
          });
          this.setState({ loading: false });
        });
    } else {
      const mutation = `mutation updateExportTemplate($id: String! $input: ExportTemplateInput!){
            updateExportTemplate(id: $id input: $input)
          }`;
      this.setState({ loading: true });
      gql
        .request(mutation, {
          id,
          input: values,
        })
        .then((res) => {
          notification["success"]({
            message: "Export template successfully updated!",
          });
          this.setState({
            loading: false,
          });
          history.push("/export-templates");
        })
        .catch((err) => {
          notification["error"]({
            message: _.get(err, "[0].message"),
          });
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { loading, templateById, defaultExportColumns, done } = this.state;
    const { id } = this.props.match.params;
    if (!done) return <Skeleton active={true} />;
    return (
      <Form layout="vertical" onFinish={this.onhandleSubmit}>
        <Card title="Detail" style={{ marginBottom: 20 }}>
          <Form.Item
            label="Template Name"
            name="name"
            initialValue={templateById ? templateById.name : ""}
            rules={[
              {
                required: true,
                message: "Template name is required.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Template configs"
            name="data"
            rules={[
              {
                required: true,
                message: "Template configs is required.",
              },
            ]}
            initialValue={
              templateById ? templateById?.data : defaultExportColumns
            }
          >
            <TemplateConfigs
              defaultExportColumns={defaultExportColumns}
              byId={id}
            />
          </Form.Item>
        </Card>
        <Form.Item>
          <Button
            style={{ marginRight: 5 }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {id ? "Save" : "Create Template"}
          </Button>
          <Button
            onClick={() => {
              history.push("/export-templates");
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default connect(
  (state) => {
    return {
      currentUser: state.app.currentUser,
    };
  },
  { setCurrentUser }
)(ExportTemplateForm);
