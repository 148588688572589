import React, { useState } from "react";
import { ASSET_API_AUTO_BANNER, URL_API_AUTO_BANNER } from "../../../../config";
import { Image, Button, message } from "antd";
import JSZip from "jszip";
import { IoMdDownload } from "react-icons/io";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { SSE } from "../../../../sse";
const PreviewCurrent = ({ historyData, indexActive }) => {
  const [loadingDownload, setLoadingDownload] = useState(false);
  function getFileExtensionFromDataURL(dataURL) {
    const mimeToExtension = {
      "image/jpeg": "jpg",
      "image/jpg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "image/webp": "webp",
    };

    const matches = dataURL.match(/^data:(.+?);base64,/);
    if (matches) {
      const mimeType = matches[1];
      return mimeToExtension[mimeType] || null;
    }
    return null;
  }

  const handleDownloadAll = async () => {
    setLoadingDownload(true);
    const keys = historyData[indexActive].keys;

    var urls = [];
    var source = new SSE(URL_API_AUTO_BANNER, {
      method: "POST",
      payload: JSON.stringify({
        keys,
        action: "query-base64",
      }),
    });
    window.sourceGetBase64 = source;

    source.addEventListener("message", async function (e) {
      if (!e.data) {
        setLoadingDownload(false);
        message.error("An error occurred. Please check again!");
        return;
      }
      const data = JSON.parse(e.data);
      switch (data.status) {
        case "done":
          const zip = new JSZip();

          for (const url of urls) {
            try {
              const fileType = getFileExtensionFromDataURL(url) || "png";
              const response = await fetch(url);
              const data = await response.blob();
              const imageName = uuidv4() + "." + fileType;
              zip.file(imageName, data);
            } catch (error) {
              console.error(`Không thể tải hình ảnh từ ${url}:`, error.message);
            }
          }

          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `${uuidv4()}.zip`);
            setLoadingDownload(false);
          });
          break;
        case "running":
          if (data.image) {
            urls = [...urls, data.image];
          }
          break;
        case "error":
          setLoadingDownload(false);
          message.error("An error occurred. Please check again!");
          break;
        default:
          break;
      }
    });
    source.stream();
  };
  return (
    <>
      {historyData.length > 0 && (historyData[indexActive]) && (
        <>
        <Button
            loading={loadingDownload}
            type="default"
            icon={<IoMdDownload />}
            onClick={handleDownloadAll}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              height: 35,
              width: "68%"
            }}
          >
             <span style={{ marginLeft: 5 }}>Download all</span>
          </Button>
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 5,
              maxHeight: "444px",
              overflow: "auto",
              marginTop: 5,
            }}
          >
            {historyData[indexActive].keys.map((i, index) => (
              <li key={index} className="li-select-background-autobanner">
                <Image
                  style={{
                    width: "100%",
                  }}
                  src={`${ASSET_API_AUTO_BANNER + i}`}
                />
              </li>
            ))}
          </ul>
          
        </>
      )}
    </>
  );
};
export default PreviewCurrent;
