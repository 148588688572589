import React from "react";
import { MdDelete } from "react-icons/md";
const SelectBackgroundScrabble = ({ value, onChange, bgsScrabble, onChangeBgsScrabble }) => {  
  const handleDelete = (index) => {
    const newBgs = bgsScrabble.filter((i, inx) => inx != index);
    onChangeBgsScrabble(newBgs);
  }
  return (
    <>
      <div>
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 5,
          }}
        >
          <li
            className="li-select-background-autobanner"
          >
          </li>
          {(bgsScrabble || []).map((i, index) => (
            <li
              key={index}
              className="li-select-background-autobanner"
              
              style={{
                boxShadow: value == i ? "#ff9100 0px 0px 0px 2px" : "none",
                transform: value == i ? "scale(0.97" : "scale(1)",
                padding: value == i ? 3 : 0,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <button 
                  onClick={() => handleDelete(index)}
                  type="button" 
                  style={{
                    position: 'absolute',
                    top: 3,
                    right: 3,
                    background: 'red',
                    color: '#ffffff',
                    borderRadius: 2,
                    border: 'none',
                    height: 25,
                    width: 25,
                    fontSize: 25,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <MdDelete />
                </button>
                <div
                onClick={() => onChange(i)}
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 3,
                    }}
                    src={i}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SelectBackgroundScrabble;
