import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
export default function ModelImageDetait({ src }) {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  function imageURLToBase64(url) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  const uniqueID = (length = 15) => {
    var result = "";
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  const handleDowload = async () => {
    const base64 = await imageURLToBase64(src);
    const a = document.createElement("a");
    a.href = base64;
    a.download = uniqueID();
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  return (
    <>
      <div onClick={handleToggle} className="kling-group-item">
        <img className="kling-group-item__img" src={src} />
      </div>
      <Modal
        title="Basic Modal"
        visible={open}
        onOk={handleToggle}
        onCancel={handleToggle}
        className="modal-preview-image"
        width={650}
        footer={
          <div>
            <Button onClick={handleDowload}>Download</Button>
          </div>
        }
      >
        <img
          style={{
            width: "100%",
          }}
          src={src}
        />
      </Modal>
    </>
  );
}
