import { cloneDeep, get, pick, random, remove, union } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ARTWORK } from "../../types";
import arrayMove from "array-move";
import { BiText, BiMoveVertical } from "react-icons/bi";
import { RiImageAddFill } from "react-icons/ri";
import { ImStackoverflow, ImMagicWand } from "react-icons/im";
import { MdTextFields } from "react-icons/md";
import { IoMdOptions } from "react-icons/io";
import { FaFolderOpen } from "react-icons/fa";
import { BsMusicNoteList, BsPersonBoundingBox } from "react-icons/bs";
import { SiGooglemaps, SiSahibinden } from "react-icons/si"
import { FaShapes } from "react-icons/fa"
import { GiStarSwirl } from "react-icons/gi"
import SongLyric from './Utilities/SongLyric';
import ColorField from "./Utilities/ColorField";
import FontSize from "./Utilities/FontSize";
import FontSelector from "../Font/FontSelector";
import createSignedUrl from "../../graphql/mutation/createSignedUrl";
import { useMutation } from "@apollo/client";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
// import { TiLockClosed, TiLockOpen } from "react-icons/ti";
import LayerSettings from "./Form/LayerSettings";
import {
  FaRegEyeSlash,
  FaRegEye,
  FaImage,
  FaShareSquare,
} from "react-icons/fa";
import { cloneLayer, getIDs, initNewLayer, uniqueID } from "./Utilities/helper";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Scrollbars from "react-custom-scrollbars";
import {
  Button,
  Collapse,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Popover,
  Tooltip,
  Steps,
  message
} from "antd";
import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import ClipartSelector from "../cliparts/ClipartSelector";
import CreateFile from "../upload/CreateFile";
import styled from "styled-components";
import { AiFillEyeInvisible, AiOutlineRetweet } from "react-icons/ai";
import { BsPersonLinesFill, BsChatSquareText } from "react-icons/bs";
import confirm from "antd/lib/modal/confirm";
import {
    DEFAULT_FONT,
    AWS_API,
    ASSET_URL,
    CLOUDFRONT_URL,
    URL_API_RENDER_SPIRAL_LYRICS,
    URL_API_RENDER_SPIRAL_CUSTOM_LYRICS
} from "../../config";
import {set} from "immutable";
import {layer} from "@fortawesome/fontawesome-svg-core";
import { Modal, Col, Row, Radio } from 'antd';
import axios from "axios";
import upload from "../upload/Upload";
import UploadFontLyric from "./Utilities/UploadFontLyric";
import FontSizeChooseLyric from "./Utilities/FontSizeChooseLyric";
import UploadSVGLyric from "./Utilities/UploadSVGLyric";
const Container = styled.div`
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag {
    border: none;
  }
  .ant-btn-icon-only > .anticon {
    display: initial;
  }
`;

const DragHandle = SortableHandle(() => (
    <BiMoveVertical
        className="anticon"
        style={{ fontSize: 15, width: 20, marginBottom: 2, marginRight: 5 }}
    />
));
const SortableList = SortableContainer(
    ({ layers, layerExtra, setSelectedLayer, setMenuVisible }) => (
        <ul className="layer-list">
          {layers.map((_, index) => {
            var idx = layers.length - index - 1;
            var layer = layers[idx];
            return (
                <SortableItem
                    key={layer.id}
                    index={index}
                    layer={layer}
                    layers={layers}
                    layerExtra={layerExtra}
                    setSelectedLayer={setSelectedLayer}
                    setMenuVisible={setMenuVisible}
                />
            );
          })}
        </ul>
    )
);

const layerExtra = (layer, dispatch, _, setOrdering, ordering) => {
  return (
      <div
          onClick={(e) => e.stopPropagation()}
          className="action-icons"
          style={{
            display: "flex",
            // gap: 10,
            alignItems: "center",
            // marginTop: 4,
            fontSize: 13,
          }}
      >
        {layer.personalized?.enable ||
        layer.type === "Option" ||
        layer.type === "Group" ? (
            <Tooltip
                title="Ordering"
                // onDoubleClick={(e) => {
                //   e.stopPropagation();
                //   setOrdering(layer.id);
                // }}
            >
              <div
                  className="site-input-number-wrapper"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrdering(layer.id);
                  }}
              >
                <InputNumber
                    size="small"
                    min={0}
                    max={199}
                    style={{ width: 50, marginRight: 5 }}
                    value={layer.ordering ? layer.ordering : 0}
                    onChange={(e) => {
                      dispatch({
                        type: ARTWORK.SET_LAYER,
                        payload: { ...layer, ordering: e },
                      });
                    }}
                />
                {/* {ordering === layer.id ? (
              <InputNumber
                min={0}
                max={99}
                size="small"
                style={{ width: 50 }}
                value={layer.ordering ? layer.ordering : 0}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                autoFocus={true}
                onPressEnter={() => {
                  setOrdering(false);
                }}
                onChange={(e) => {
                  dispatch({
                    type: ARTWORK.SET_LAYER,
                    payload: { ...layer, ordering: e },
                  });
                }}
                onBlur={() => setOrdering(false)}
              />
            ) : layer.ordering ? (
              <div style={{ width: 20, textAlign: "center", color: "#ff9100" }}>
                {layer.ordering}
              </div>
            ) : (
              <div style={{ width: 20, textAlign: "center", color: "#ff9100" }}>
                0
              </div>
            )} */}
              </div>
            </Tooltip>
        ) : null}
        {layer.type === "Image" ? (
            <Tooltip title="Convert to text layer">
              <Popconfirm
                  disabled={layer.lock}
                  title={`Are you sure to convert this to text layer?`}
                  onConfirm={() => {
                    dispatch({
                      type: ARTWORK.SET_LAYER,
                      payload: {
                        ...layer,
                        align: "left",
                        type: "Text",
                        autofit: true,
                        values: [{ active: true, text: layer.title }],
                        fontSize: layer.height > 20 ? 20 : layer.height,
                        fontFamily: DEFAULT_FONT,
                        letterSpacing: 1,
                        lineHeight: 1,
                      },
                    });
                  }}
              >
                <AiOutlineRetweet
                    style={{ color: layer.lock ? "#9a9a9a" : null, marginRight: 5 }}
                />
              </Popconfirm>
            </Tooltip>
        ) : (
            // <div style={{ width: 18 }}></div>
            <AiOutlineRetweet
                disabled
                style={{ color: "#a5a2a2", marginRight: 5 }}
            />
        )}
        {/* <DragHandle /> */}

        {/* {layer.type !== "Option" && (
        <span
          style={{ display: "inline-flex" }}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: ARTWORK.SET_LAYER,
              payload: { ...layer, lock: !layer.lock },
            });
          }}
        >
          {layer.lock === false ? (
            <TiLockOpen className="anticon" />
          ) : (
            <TiLockClosed className="anticon" />
          )}
        </span>
      )} */}
        {layer.type !== "Option" ? (
            <span
                style={{ display: "inline-flex" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: ARTWORK.SET_LAYER,
                    payload: { ...layer, visible: !layer.visible },
                  });
                }}
            >
          {layer.visible === false ? (
              <FaRegEyeSlash className="anticon" />
          ) : (
              <FaRegEye className="anticon" />
          )}
        </span>
        ) : (
            <div style={{ width: 15 }}></div>
        )}
      </div>
  );
};

const SortableItem = SortableElement(
    ({
       layer,
       layers,
       onChange,
       layerExtra,
       setSelectedLayer,
       setMenuVisible,
     }) => {
      const workspace = useSelector((state) => state.artwork.workspace);
      const dispatch = useDispatch();
      const { selectedLayers, ctrl } = workspace;
      const [edit, setEdit] = useState(false);
      const [ordering, setOrdering] = useState(false);
      const onLayerSelect = (key) => {
        // if (key) {
        if (ctrl) {
          dispatch({
            type: ARTWORK.SET_SELECTED_LAYERS,
            payload: union([...selectedLayers, key]),
          });
        } else {
          dispatch({
            type: ARTWORK.SET_SELECTED_LAYERS,
            payload: key ? [key] : layer.parent ? [layer.parent] : [],
          });
        }
        // }
      };
      const onSortEnd = ({ oldIndex, newIndex }) => {
        dispatch({
          type: ARTWORK.SET_LAYER,
          payload: {
            ...layer,
            layers: arrayMove(
                layer.layers,
                layer.layers.length - oldIndex - 1,
                layer.layers.length - newIndex - 1
            ),
          },
        });
      };
      return (
          <ContextMenuTrigger
              id="item-layer"
              key={layer.id}
              collect={() => {
                setSelectedLayer(layer);
                setMenuVisible(true);
                onLayerSelect(layer.id);
              }}
          >
            <li style={{ listStyle: "none" }} key={layer.id}>
              <Collapse
                  accordion={true}
                  activeKey={
                    selectedLayers.length >= 1 &&
                    selectedLayers.find((id) => getIDs(layers, id).includes(layer.id))
                        ? layer.id
                        : null
                  }
                  onChange={onLayerSelect}
                  expandIconPosition={"left"}
                  expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
              >
                <Collapse.Panel
                    showArrow={layer.type === "Group"}
                    header={
                      <span
                          className="layer-title"
                          onClick={(e) => {
                            e.stopPropagation();
                            onLayerSelect(layer.id);
                          }}
                      >
                  <DragHandle />
                  <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                  >
                    {(({ type, shared_layer_id }) => {
                      if (shared_layer_id) {
                        return <FaShareSquare className="layer-icon" />;
                      }
                      if (type === "Text") {
                        return <BiText className="layer-icon" />;
                      }
                      if (type === "Group") {
                        return <FaFolderOpen className="layer-icon" />;
                      }
                      if (type === "Image") {
                        return <FaImage className="layer-icon" />;
                      }
                      if (type === "Option") {
                        return <IoMdOptions className="layer-icon" />;
                      }
                      if(type == "Label") {
                          return <BsChatSquareText className="layer-icon" />
                      }
                    })(layer)}

                    {edit ? (
                        <Input
                            size="small"
                            value={
                              layer.title === null
                                  ? `Layer ${layer.id}`
                                  : layer.title
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            autoFocus={true}
                            onPressEnter={() => {
                              setEdit(false);
                            }}
                            onChange={(e) => {
                              dispatch({
                                type: ARTWORK.SET_LAYER,
                                payload: { ...layer, title: e.target.value },
                              });
                            }}
                            onBlur={() => setEdit(false)}
                        />
                    ) : (
                        <>
                        <span
                            className="abc"
                            onDoubleClick={(e) => {
                              e.stopPropagation();
                              setEdit(true);
                            }}
                        >
                          {layer.title || `Layer ${layer.id}`}
                        </span>
                          {layer.personalized?.enable ? (
                              <BsPersonLinesFill
                                  style={{ color: "#ff9100", margin: "0 5px" }}
                              />
                          ) : null}
                          {layer.hidden ? (
                              <Tooltip placement="top" title={"Hidden layer"}>
                                <AiFillEyeInvisible
                                    style={{ color: "#ff9100", margin: "0 5px" }}
                                />
                              </Tooltip>
                          ) : null}
                          <EditOutlined
                              className="edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                              }}
                          />
                        </>
                    )}
                  </span>
                </span>
                    }
                    key={layer.id}
                    extra={layerExtra(
                        layer,
                        dispatch,
                        onChange,
                        setOrdering,
                        ordering
                    )}
                    className={`${layer.type} ${
                        layer.shared_layer_id ? "shared-layer" : ""
                    }`}
                >
                  {(() => {
                    if (layer.type === "Group" && !layer.shared_layer_id) {
                      return (
                          <div>
                            <SortableList
                                layers={layer.layers}
                                selectedLayers={selectedLayers}
                                setMenuVisible={setMenuVisible}
                                setSelectedLayer={setSelectedLayer}
                                lockAxis="y"
                                useDragHandle
                                onSortEnd={onSortEnd}
                                onChange={onChange}
                                layerExtra={layerExtra}
                                helperClass="draging"
                                ordering={ordering}
                                setOrdering={setOrdering}
                                helperContainer={
                                  document.getElementsByClassName("layer-list")[0]
                                }
                            />
                          </div>
                      );
                    }
                    return null;
                  })()}
                </Collapse.Panel>
              </Collapse>
            </li>
          </ContextMenuTrigger>
      );
    }
);
const Layers = (props) => {
  const { workspace, currentUser } = props;
  const dispatch = useDispatch();
  const [selectedLayer, setSelectedLayer] = useState();
  const [menuVisible, setMenuVisible] = useState(false);

  const [showClipartSelector, setShowClipartSelector] = useState(false);
  const { artwork, selectedTemplate, selectedLayers } = workspace;
  const layers =
      get(artwork, `templates[${selectedTemplate}].layers`, []) || [];
  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch({
      type: ARTWORK.SET_LAYERS,
      payload: arrayMove(
          layers,
          layers.length - oldIndex - 1,
          layers.length - newIndex - 1
      ),
    });
  };
  // const [showMediaSelector, setShowMediaSelector] = useState(false);
  const addText = (e) => {
    e.preventDefault();
    const newTextLayer = initNewLayer();
    newTextLayer.type = "Text";
    newTextLayer.fontSize = 30;
    newTextLayer.letterSpacing = 1;
    newTextLayer.lineHeight = 1;
    newTextLayer.x = random(10, 100);
    newTextLayer.y = random(10, 100);
    newTextLayer.align = "left";
    newTextLayer.width = 160;
    newTextLayer.values[0].text = "Your Text";
    newTextLayer.fillAfterStrokeEnabled = true;
    newTextLayer.wrapConfig = "word";
    // newTextLayer.fontFamily = "fZ2MKQOI9-regular";
    //todo
    // newTextLayer.fontFamily = "feCBSydy73-regular";
    newTextLayer.fontFamily = DEFAULT_FONT;
    newTextLayer.fill = "#000";
    newTextLayer.title = `Text #${
        layers.filter((l) => l.type === "Text").length + 1
    }`;
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newTextLayer,
    });
  };
  const addLabel = (e) => {
      e.preventDefault();
      const newLableLayer = initNewLayer();
      newLableLayer.type = "Label";
      newLableLayer.values[0].text = "* Look for preview via email 1-2 days after order. Your approval is required before production!";
      newLableLayer.align = "left";
      newLableLayer.fill = "#000";
      newLableLayer.background = "";
      newLableLayer.letterSpacing = 0;
      newLableLayer.fontSize = 16;
      newLableLayer.lineHeight = 1;
      newLableLayer.fontWeight = 100;
      newLableLayer.textTransform = "";
      newLableLayer.personalized = {
          enable: true
      };
      newLableLayer.title = `Label #${
          layers.filter((l) => l.type === "Label").length + 1
      }`;
      dispatch({
          type: ARTWORK.ADD_LAYER,
          payload: newLableLayer,
      });
  }
  const addOption = (e) => {
    e.preventDefault();
    const newOptionLayer = initNewLayer();
    newOptionLayer.type = "Option";
    newOptionLayer.title = `Option #${
        layers.filter((l) => l.type === "Option").length + 1
    }`;
    newOptionLayer.display_mode = "dropdown";
    newOptionLayer.options = [
      { label: "Option 1", value: "option-1", default: true },
    ];
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newOptionLayer,
    });
  };

  const addImageScrabble = (file) => {
    const newImageLayer = initNewLayer();

    newImageLayer.type = "Image";
    newImageLayer.values[0].file = pick(file, ["id", "key"]);
    newImageLayer.imageScrabble = true;
    newImageLayer.personalized = {
      enable: true
    };
    newImageLayer.backgroundRender = "";
    newImageLayer.configsScrabble = {
      borderColor: "#ac5d1d", 
      borderWidth: 0, 
      borderRadius: 5, 
      shadowColor: "rgba(0, 0, 0, 0.5)", 
      shadowBlur: 5,
      shadowOffsetX: 5,
      shadowOffsetY: 0,
      shadowOpacity: 1,
      textColor: "#000000",
      textOpacity: 0.9,
      fontSize: 80,
      backgroundImage: "",
      backgroundScrabbleImages: [],
      titleBackground: `Crossword image puzzle background #${
        layers.filter((l) => l.imageScrabble).length + 1
      }`,
      maxLength: 25,
      margin: 5,
      fontFamily: "f20Mjs9bYf-regular",
      tags: [],
      letterPointsEnabled: true,
      letterPointsFontSize: 20,
      letterPointsFontFamily: "f20Mjs9bYf-regular"
    }
    newImageLayer.title = `Image Crossword Puzzle #${
        layers.filter((l) => l.imageScrabble).length + 1
    }`;
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newImageLayer,
  });
  }

  const addImage = (file, imageLyric, imageMap, imageShape, ImageStarMap, ImageCutFace, ImageRemoveBackground) => {
    // setShowMediaSelector(false);
    const newImageLayer = initNewLayer();
    newImageLayer.type = "Image";
    newImageLayer.values[0].file = pick(file, ["id", "key"]);
    newImageLayer.title = `Image #${
        layers.filter((l) => l.type === "Image").length + 1
    }`;
      if(imageLyric){
          const nameLayerLyric = uniqueID();
          const countNameLayerLyric = layers.filter((l)=> l.imageLyric === true).length + 1;
          // Start text title song
          const newTextTilteSong = initNewLayer();
          newTextTilteSong.type = "Text";
          newTextTilteSong.fontSize = 30;
          newTextTilteSong.letterSpacing = 1;
          newTextTilteSong.lineHeight = 1;
          newTextTilteSong.x = random(10, 100);
          newTextTilteSong.y = random(10, 100);
          newTextTilteSong.align = "left";
          newTextTilteSong.width = 160;
          newTextTilteSong.values[0].text = "Your Song Title";
          newTextTilteSong.fillAfterStrokeEnabled = true;
          newTextTilteSong.fontFamily = DEFAULT_FONT;
          newTextTilteSong.fill = "#000";
          newTextTilteSong.autofit = true;
          newTextTilteSong.LyricSongTitleChildId = nameLayerLyric;
          newTextTilteSong.textTitleSongLyric = true;
          newTextTilteSong.personalized = {
              enable: true
          };
          newTextTilteSong.title = `Title song #${countNameLayerLyric}`;
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newTextTilteSong,
          });
          // end text title song

          setTimeout(()=> {
              const newImageLyricLayer = initNewLayer();
              newImageLyricLayer.type = "Image";
              newImageLyricLayer.values[0].file = pick(file, ["id", "key"]);
              newImageLyricLayer.imageLyric = true;
              newImageLyricLayer.LyricIdParent = nameLayerLyric;
              newImageLyricLayer.title = `Lyric #${countNameLayerLyric}`;
              newImageLyricLayer.personalized = {
                  enable: true
              };
              dispatch({
                  type: ARTWORK.ADD_LAYER,
                  payload: newImageLyricLayer,
              });
          }, 2000);
      }else if(imageMap){
          const NameLocationID = uniqueID();
          const NumberLocationID = layers.filter((l)=> l.imageMap === true).length + 1;
          // start location text
          const newTextLocationMapLayer = initNewLayer();
          newTextLocationMapLayer.type = "Text";
          newTextLocationMapLayer.fontSize = 30;
          newTextLocationMapLayer.letterSpacing = 1;
          newTextLocationMapLayer.lineHeight = 1;
          newTextLocationMapLayer.x = random(10, 100);
          newTextLocationMapLayer.y = random(10, 100);
          newTextLocationMapLayer.align = "left";
          newTextLocationMapLayer.width = 160;
          newTextLocationMapLayer.values[0].text = "Location Map";
          newTextLocationMapLayer.fillAfterStrokeEnabled = true;
          newTextLocationMapLayer.fontFamily = DEFAULT_FONT;
          newTextLocationMapLayer.fill = "#000";
          newTextLocationMapLayer.autofit = true;
          newTextLocationMapLayer.MapIdChild = NameLocationID;
          newTextLocationMapLayer.textLocationMap = true;
          newTextLocationMapLayer.personalized = {
              enable: true
          };
          newTextLocationMapLayer.title = `Text Location Map #${NumberLocationID}`;
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newTextLocationMapLayer,
          });
          // end start location text

          // start text lng - lat map
          const newTextCoordinatesMap = initNewLayer();
          newTextCoordinatesMap.type = "Text";
          newTextCoordinatesMap.fontSize = 30;
          newTextCoordinatesMap.letterSpacing = 1;
          newTextCoordinatesMap.lineHeight = 1;
          newTextCoordinatesMap.x = random(10, 100);
          newTextCoordinatesMap.y = random(10, 100);
          newTextCoordinatesMap.align = "left";
          newTextCoordinatesMap.width = 160;
          newTextCoordinatesMap.values[0].text = "3.745° S, 38.523° W";
          newTextCoordinatesMap.fillAfterStrokeEnabled = true;
          newTextCoordinatesMap.fontFamily = DEFAULT_FONT;
          newTextCoordinatesMap.fill = "#000";
          newTextCoordinatesMap.autofit = true;
          newTextCoordinatesMap.MapIdCoordinatesChild = NameLocationID;
          newTextCoordinatesMap.textCoordinatesMap = true;
          newTextCoordinatesMap.personalized = {
              enable: true
          };
          newTextCoordinatesMap.title = `Text Coordinates Map #${NumberLocationID}`;
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newTextCoordinatesMap,
          });
          // end text lng - lat map

          setTimeout(()=> {
              const newImageLayerMap = initNewLayer();
              newImageLayerMap.type = "Image";
              newImageLayerMap.values[0].file = pick(file, ["id", "key"]);
              newImageLayerMap.imageMap = true;
              newImageLayerMap.typecustom = false;
              newImageLayerMap.title = `Map # ${
                  layers.filter((l) => l.imageMap === true).length + 1
              }`;
              newImageLayerMap.shapes = [{
                  file: pick(file, ["id", "key"])
              }];
              newImageLayerMap.settingDefault = true;
              newImageLayerMap.makerstatus = false;
              newImageLayerMap.personalized = {
                  enable: true
              };
              newImageLayerMap.MapIdParent = NameLocationID;
              newImageLayerMap.backgroundcolors = ['#000000']
              newImageLayerMap.typeMap = 'roadmap';
              newImageLayerMap.typeMapId = 'roadmap';

              dispatch({
                  type: ARTWORK.ADD_LAYER,
                  payload: newImageLayerMap,
              });
          }, 2000)
      }else if(imageShape){
        newImageLayer.imageShape = true;
        newImageLayer.title = `Shape # ${
          layers.filter((l)=> l.imageShape === true).length + 1
        }`
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newImageLayer,
          });
      }else if(ImageCutFace){
          newImageLayer.imageCutFace = true;
          newImageLayer.title = `Face cut # ${
              layers.filter((l)=> l.imageCutFace === true).length + 1
          }`
          newImageLayer.personalized = {
              enable: true
          };
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newImageLayer,
          });
      }else if(ImageStarMap){
          const NameStarMapID =  `skyStarID${uniqueID()}`;
          const NumberStarMapID = layers.filter((l)=> l.imageStarMap === true).length + 1;
          const newImageLayerStarMap = initNewLayer();
          newImageLayerStarMap.type = "Image";
          newImageLayerStarMap.starcolor = "#ffffff";
          newImageLayerStarMap.values[0].file = pick(file, ["id", "key"]);
          newImageLayerStarMap.personalized = {
              enable: true
          };
          newImageLayerStarMap.scaleStars = 1;
          newImageLayerStarMap.transparentbackground = false;
          newImageLayerStarMap.imageStarMap = true;
          newImageLayerStarMap.typeSkyStar = 'polar';
          newImageLayerStarMap.title = `Sky star # ${NumberStarMapID}`;
          newImageLayerStarMap.skyStarIdParent = NameStarMapID;

          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newImageLayerStarMap,
          });

          // start Sky text date
          const newTextSkyDateLayer = initNewLayer();
          newTextSkyDateLayer.type = "Text";
          newTextSkyDateLayer.fontSize = 30;
          newTextSkyDateLayer.letterSpacing = 1;
          newTextSkyDateLayer.lineHeight = 1;
          newTextSkyDateLayer.x = random(10, 100);
          newTextSkyDateLayer.y = random(10, 100);
          newTextSkyDateLayer.align = "left";
          newTextSkyDateLayer.width = 160;
          newTextSkyDateLayer.values[0].text = "Your Text Sky Date";
          newTextSkyDateLayer.fillAfterStrokeEnabled = true;
          newTextSkyDateLayer.fontFamily = DEFAULT_FONT;
          newTextSkyDateLayer.fill = "#000";
          newTextSkyDateLayer.autofit = true;
          newTextSkyDateLayer.skyStarIdChild = NameStarMapID;
          newTextSkyDateLayer.textSkyDate = true;
          newTextSkyDateLayer.personalized = {
              enable: true
          };
          newTextSkyDateLayer.title = `Text Sky Date #${NumberStarMapID}`;
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newTextSkyDateLayer,
          });
          // end Sky text date

          // start Sky text Address
          const newTextSkyAddressLayer = initNewLayer();
          newTextSkyAddressLayer.type = "Text";
          newTextSkyAddressLayer.fontSize = 30;
          newTextSkyAddressLayer.letterSpacing = 1;
          newTextSkyAddressLayer.lineHeight = 1;
          newTextSkyAddressLayer.x = random(10, 100);
          newTextSkyAddressLayer.y = random(10, 100);
          newTextSkyAddressLayer.align = "left";
          newTextSkyAddressLayer.width = 160;
          newTextSkyAddressLayer.values[0].text = "Your Text Sky Address";
          newTextSkyAddressLayer.fillAfterStrokeEnabled = true;
          newTextSkyAddressLayer.fontFamily = DEFAULT_FONT;
          newTextSkyAddressLayer.fill = "#000";
          newTextSkyAddressLayer.autofit = true;
          newTextSkyAddressLayer.skyStarIdChild = NameStarMapID;
          newTextSkyAddressLayer.textSkyAddress = true;
          newTextSkyAddressLayer.title = `Text Sky Address #${NumberStarMapID}`;
          newTextSkyAddressLayer.personalized = {
              enable: true
          };
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newTextSkyAddressLayer,
          });
          // end Sky text Address

          // start text lng - lat Star
          const newTextCoordinatesStar = initNewLayer();
          newTextCoordinatesStar.type = "Text";
          newTextCoordinatesStar.fontSize = 30;
          newTextCoordinatesStar.letterSpacing = 1;
          newTextCoordinatesStar.lineHeight = 1;
          newTextCoordinatesStar.x = random(10, 100);
          newTextCoordinatesStar.y = random(10, 100);
          newTextCoordinatesStar.align = "left";
          newTextCoordinatesStar.width = 160;
          newTextCoordinatesStar.values[0].text = "3.745° S, 38.523° W";
          newTextCoordinatesStar.fillAfterStrokeEnabled = true;
          newTextCoordinatesStar.fontFamily = DEFAULT_FONT;
          newTextCoordinatesStar.fill = "#000";
          newTextCoordinatesStar.autofit = true;
          newTextCoordinatesStar.skyStarIdChild = NameStarMapID;
          newTextCoordinatesStar.textCoordinatesStar = true;
          newTextCoordinatesStar.personalized = {
              enable: true
          };
          newTextCoordinatesStar.title = `Text Coordinates Star #${NumberStarMapID}`;
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newTextCoordinatesStar,
          });
          // end text lng - lat map
      }else if(ImageRemoveBackground){
          newImageLayer.imageRemoveBackground = true;
          newImageLayer.title = `Remove Background # ${
              layers.filter((l)=> l.imageRemoveBackground === true).length + 1
          }`
          newImageLayer.personalized = {
              enable: true
          };
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newImageLayer,
          });
      }else {
          dispatch({
              type: ARTWORK.ADD_LAYER,
              payload: newImageLayer,
          });
      }
  };

  // start edit lyric
    const [visibleModelChooseTypeLyric, setVisibleModelChooseTypeLyric] = useState(false);
    const [typeLyricChooseModal, setTypeLyricChooseModal] = useState('default');
    const [typeSpiralLyric, setTypeSpiralLyric] = useState('1');
    const [onNextChooseLyric, setOnNextChooseLyric] = useState(1);
    const [songLyric, setSongLyric] = useState("");
    const [colorTextLyric, setColorTextLyric] = useState("#ffffff");
    const [fontTextLyric, setFontTextLyric] = useState("");
    const [loadingApply, setLoadingApply] = useState(false);
    const [fontSizeLyric, setFontSizeLyric] = useState(16);
    const [svgUrlLyric, setSvgUrlLyric] = useState({name: null, value: ""});
    const [minCharacters, setMinCharacters] = useState(0);
    const [maxCharacters, setMaxCharacters] = useState(1200);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const CreateFileRef = useRef();
    const { Step } = Steps;
    const handChangeFontSizeLyric = (value) => {
        setFontSizeLyric(value);
    }

    const handleOnChangeLoadingUpload = (v) => {
        setLoadingUpload(v);
    }

    const handleChangeMinCharacters = (value) => {
        setMinCharacters(value)
    }

    const handleChangeMaxCharacters = (value) => {
        setMaxCharacters(value)
    }

    const handleClickChooseLyric = () => {
        setVisibleModelChooseTypeLyric(true);
    }

    const handleOnSelectFontFamilyLyric = async (v) => {
        setFontTextLyric(v);
    }

    const handleChangeUrlSvgLyric = (value) => {
        setSvgUrlLyric(value);
    }

    const handleAddImageSpiralLyric = (file, custom) => {
        const nameLayerLyric = uniqueID();
        const countNameLayerLyric = layers.filter((l)=> l.imageLyric === true).length + 1;
        // Start text title song
        const newTextTilteSong = initNewLayer();
        newTextTilteSong.type = "Text";
        newTextTilteSong.fontSize = 30;
        newTextTilteSong.letterSpacing = 1;
        newTextTilteSong.lineHeight = 1;
        newTextTilteSong.x = random(10, 100);
        newTextTilteSong.y = random(10, 100);
        newTextTilteSong.align = "left";
        newTextTilteSong.width = 160;
        newTextTilteSong.values[0].text = "Your Song Title";
        newTextTilteSong.fillAfterStrokeEnabled = true;
        newTextTilteSong.fontFamily = DEFAULT_FONT;
        newTextTilteSong.fill = "#000";
        newTextTilteSong.autofit = true;
        newTextTilteSong.LyricSongTitleChildId = nameLayerLyric;
        newTextTilteSong.textTitleSongLyric = true;
        newTextTilteSong.personalized = {
            enable: true
        };
        newTextTilteSong.title = `Title song #${countNameLayerLyric}`;
        dispatch({
            type: ARTWORK.ADD_LAYER,
            payload: newTextTilteSong,
        });
        // end text title song

        setTimeout(()=> {
            const newImageLyricLayer = initNewLayer();
            newImageLyricLayer.type = "Image";
            newImageLyricLayer.values[0].file = pick(file, ["id", "key"]);
            newImageLyricLayer.imageLyric = true;
            newImageLyricLayer.imageSpiralLyric = true;
            if(custom){
                newImageLyricLayer.svgUrlSpiralLyric = svgUrlLyric;
                newImageLyricLayer.defaultFontSizeSpiralLyric = fontSizeLyric;
            }
            newImageLyricLayer.minCharacters = minCharacters;
            newImageLyricLayer.maxCharacters = maxCharacters;
            newImageLyricLayer.typeSpiral = typeSpiralLyric;
            newImageLyricLayer.fontJsonSpiral = fontTextLyric;
            newImageLyricLayer.colorTextSpiral = colorTextLyric;
            newImageLyricLayer.lyricTextDefault = songLyric
            newImageLyricLayer.LyricIdParent = nameLayerLyric;
            newImageLyricLayer.title = `Spiral lyric  #${countNameLayerLyric}`;
            newImageLyricLayer.personalized = {
                enable: true
            };
            dispatch({
                type: ARTWORK.ADD_LAYER,
                payload: newImageLyricLayer,
            });
        }, 2000);


    }

    const handleApplySpiralChooseType = async () => {
        setLoadingApply(true);
        let rederLyric
        if(typeSpiralLyric == "5"){
            rederLyric = await axios.post(URL_API_RENDER_SPIRAL_CUSTOM_LYRICS, {
                "svgUrl": svgUrlLyric.value,
                "lyrics": songLyric,
                "defaultFontSize": fontSizeLyric,
                "fontFamily": fontTextLyric,
                "color": colorTextLyric
            });
        }else {
            rederLyric = await axios.post(URL_API_RENDER_SPIRAL_LYRICS, {
                type: typeSpiralLyric,
                lyrics: songLyric,
                fontFamily: fontTextLyric,
                color: colorTextLyric
            });
        }

        if(rederLyric.status == 200){
            const url_res = rederLyric.data.response_url;
            const file = {
                id: uniqueID(),
                key: url_res
            }
            if(typeSpiralLyric == "5"){
                handleAddImageSpiralLyric(file, true);
            }else {
                handleAddImageSpiralLyric(file);
            }
            setVisibleModelChooseTypeLyric(false);
            setTypeLyricChooseModal("default");
            setTypeSpiralLyric("1");
            setOnNextChooseLyric(1);
            setSongLyric("");
            setColorTextLyric("#ffffff");
            setFontTextLyric("");
            setSvgUrlLyric({name: null, value: ""});
            setFontSizeLyric(16);
        }else {
            message.error('An incident has occurred. Please try again!');
        }

        setLoadingApply(false);
    }

    const handChangeTypeLyricChoose = (v) => {
        setTypeLyricChooseModal(v.target.value)
    }

    const handChangeTypeSpiral = (v) => {
        setTypeSpiralLyric(v.target.value)
    }

    const OnChangeSongLyric = v => {
        setSongLyric(v);
    }

    const handlePrev1ChooseType = () => {
        setOnNextChooseLyric(1)
    }

    const handleOnchangeColorLyricText = (v) => {
        setColorTextLyric(v);
    }

    const handlePrev2ChooseType = () => {
        setOnNextChooseLyric(2);
    }

    const handleCancelModelChooseTypeLyric = () => {
        setOnNextChooseLyric(1)
        setVisibleModelChooseTypeLyric(false);
    }

    const typesSpiral = [
        {
            label: "default",
            value: "1",
            src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/rKgLvk245SSHnrWR1EXTPd.png"
        },
        {
            label: "1500",
            value: "3",
            src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/7KkdJW3tqpvv81nkxTMX3K.png"
        },
        {
            label: "2000",
            value: "4",
            src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/sDvCpkZmsfrbGYbACx3sT7.png"
        },
        // {
        //     label: "Custom",
        //     value:"5",
        //     src: "https://d7re1xv4rs2gf.cloudfront.net/upload-custom/iwLFF1113DMbHw2KQjw1sv.png"
        // },
    ];

    const handleNextChooseType = () => {
        switch (typeLyricChooseModal) {
            case 'default':
                CreateFileRef.current.click();
                setTypeLyricChooseModal('default');
                setVisibleModelChooseTypeLyric(false);
                break;
            case 'spiral':
                setOnNextChooseLyric(2);
                break;
            default:
                setTypeLyricChooseModal('default');
                setVisibleModelChooseTypeLyric(false);
                break;
        }
    }

    const handleNextSpiralChooseType = () => {
        setOnNextChooseLyric(3);
    }

  //end edit lyric


  const addClipart = (clipart) => {
    setShowClipartSelector(false);
    const newImageLayer = initNewLayer();
    newImageLayer.type = "Image";
    newImageLayer.values[0].file = pick(clipart.file, ["id", "key"]);
    newImageLayer.values[0].file.categoryID = clipart.category.id;
    //newImageLayer.categoryID = clipart.category.id;
    newImageLayer.title = `Image #${
        layers.filter((l) => l.type === "Image").length + 1
    }`;
    newImageLayer.personalized = {
      enable: true,
      type: "clipartCategory",
      clipartCategory: clipart.category.id,
    };
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newImageLayer,
    });
  };

  const addSharedLayer = (l) => {
    const newLayer = cloneDeep(l);
    newLayer.id = uniqueID();
    newLayer.shared_layer_id = l.id;
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newLayer,
    });
  };
  const [offsetTop, setOffsetTop] = useState(0);
  const containerRef = useRef();
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setOffsetTop(containerRef.current.getBoundingClientRect().top);
    }
  }, [containerRef]);
  const deleteLayer = (layers, ids) => {
    if (remove(layers, (layer) => ids.includes(layer.id)).length < ids.length) {
      layers.forEach((layer, index) => {
        if (layer.layers && layer.layers.length) {
          deleteLayer(layers[index].layers, ids);
        }
      });
    }
  };
  const handleMenuClick = ({ item, key }) => {
    setMenuVisible(false);
    switch (key) {
      case ".$duplicate":
        dispatch({
          type: ARTWORK.SET_CLIPBOARD,
          payload: selectedLayers.map((id) => layers.search(id)),
        });
        const dataNew = selectedLayers.map((id) => layers.search(id));
        if (Array.isArray(dataNew)) {
          var newLayers = [];
          dataNew.forEach((l) => {
            var _l = cloneLayer(l);
            _l.title = `Copy of ${l.title || l.id}`;
            _l.parent = null;
            //_l.x = l.x + 10;
            //_l.y = l.y + 10;
            newLayers.push(_l);
          });
          dispatch({
            type: ARTWORK.ADD_LAYER,
            payload: newLayers.map((item) => {
              delete item.custom;
              return item;
            }),
          });
          setSelectedLayer();
          dispatch({
            type: ARTWORK.SET_SELECTED_LAYERS,
            payload: newLayers.map((l) => l.id),
          });
        }

        break;
      case ".$delete":
        confirm({
          title: `Are you sure to delete layer ${selectedLayer?.title}?`,
          icon: <QuestionCircleOutlined />,
          type: "warning",
          okButtonProps: { danger: true },
          okText: "Delete",
          onOk: () => {
            var parentID = null;
            dispatch({
              type: ARTWORK.SET_SELECTED_LAYERS,
              payload: parentID ? [parentID] : [],
            });
            deleteLayer(layers, selectedLayer?.id);
            dispatch({
              type: ARTWORK.SET_LAYERS,
              payload: layers,
            });
            setSelectedLayer();
          },
        });
        break;
      case ".$convert":
        confirm({
          title: `Are you sure to convert image layer to text?`,
          icon: <QuestionCircleOutlined />,
          type: "warning",
          okButtonProps: { primary: true },
          okText: "Convert",
          onOk: () => {
            dispatch({
              type: ARTWORK.SET_LAYER,
              payload: {
                ...selectedLayer,
                align: "left",
                type: "Text",
                values: [{ active: true, text: selectedLayer.title }],
                fontSize: selectedLayer.height > 20 ? 20 : selectedLayer.height,
                fontFamily: "fZ2MKQOI9-regular",
                letterSpacing: 1,
                lineHeight: 1,
              },
            });
          },
        });
        break;
      default:
        break;
    }
  };
  return (
      <Container id="artwork-design-sidebar" ref={containerRef}>
        <Scrollbars autoHeight autoHeightMax={`calc(100vh - ${offsetTop}px)`}>
          <SortableList
              layers={layers}
              lockAxis="y"
              useDragHandle
              onSortEnd={onSortEnd}
              layerExtra={layerExtra}
              helperClass="draging"
              helperContainer={document.getElementsByClassName("layer-list")[0]}
              setSelectedLayer={setSelectedLayer}
              setMenuVisible={setMenuVisible}
          />
          <Button.Group
              style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "repeat(4,auto)",
                borderRadius: 0,
              }}
          >
            <Tooltip title="Add Image">
              <CreateFile
                  accept=".jpg, .png, .jpeg"
                  onChange={(file) => {
                    addImage(file);
                  }}
              >
                <Button
                    style={{
                      width: "100%",
                      borderRadius: 0,
                      borderLeftWidth: 0,
                      padding: 0,
                    }}
                    icon={<RiImageAddFill className="anticon" />}
                ></Button>
              </CreateFile>
            </Tooltip>
            <Tooltip title="Add Clipart">
              <Popover
                  placement="bottom"
                  trigger="click"
                  visible={showClipartSelector}
                  onVisibleChange={(v) => setShowClipartSelector(v)}
                  content={
                    <ClipartSelector
                      currentUser = {currentUser}
                        style={{ width: 355 }}
                        onSelect={(clipart) => addClipart(clipart)}
                    />
                  }
              >
                <Button
                    style={{ width: "100%" }}
                    icon={<ImStackoverflow className="anticon" />}
                />
              </Popover>
            </Tooltip>
            <Tooltip title="Add Option">
              <Button
                  style={{ width: "100%" }}
                  icon={<IoMdOptions className="anticon" />}
                  onClick={addOption}
              />
            </Tooltip>
            <Tooltip title="Add Text">
              <Button
                  style={{ width: "100%" }}
                  icon={<MdTextFields className="anticon" />}
                  onClick={addText}
              />
            </Tooltip>

            <Tooltip title="Add Image Change Lyric">
                <Button
                    onClick={handleClickChooseLyric}
                    style={{
                        width: "100%",
                        borderRadius: 0,
                        borderLeftWidth: 0,
                        padding: 0,
                    }}
                    icon={<BsMusicNoteList className="anticon" />}
                ></Button>
              {/*<CreateFile*/}
              {/*    accept=".jpg, .png, .jpeg"*/}
              {/*    onChange={(file) => {*/}
              {/*        addImage(file, true);*/}
              {/*    }}*/}
              {/*>*/}
              {/*    <Button*/}
              {/*        style={{*/}
              {/*            width: "100%",*/}
              {/*            borderRadius: 0,*/}
              {/*            borderLeftWidth: 0,*/}
              {/*            padding: 0,*/}
              {/*        }}*/}
              {/*        icon={<BsMusicNoteList className="anticon" />}*/}
              {/*    ></Button>*/}
              {/*</CreateFile>*/}
            </Tooltip>

            <Tooltip title="Add Image Map">
               <CreateFile
                  accept=".jpg, .png, .jpeg"
                  onChange={(file) => {
                      addImage(file, false, true);
                  }}
               >
                  <Button
                      style={{
                          width: "100%",
                          borderRadius: 0,
                          borderLeftWidth: 0,
                          padding: 0,
                      }}
                      icon={<SiGooglemaps className="anticon" />}
                  ></Button>
               </CreateFile>
            </Tooltip>

            <Tooltip title="Add Image shapes">
               <CreateFile
                  accept=".jpg, .png, .jpeg"
                  onChange={(file) => {
                      addImage(file, false, false, true);
                  }}
               >
                  <Button
                      style={{
                          width: "100%",
                          borderRadius: 0,
                          borderLeftWidth: 0,
                          padding: 0,
                      }}
                      icon={<FaShapes className="anticon" />}
                  ></Button>
               </CreateFile>
            </Tooltip>

              <Tooltip title="Add Image Star Map Sky">
                  <CreateFile
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                          addImage(file, false, false, false, true);
                      }}
                  >
                      <Button
                          style={{
                              width: "100%",
                              borderRadius: 0,
                              borderLeftWidth: 0,
                              padding: 0,
                          }}
                          icon={<GiStarSwirl className="anticon" />}
                      ></Button>
                  </CreateFile>
              </Tooltip>

              <Tooltip title="Add Image Cut Face">
                  <CreateFile
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                          addImage(file, false, false, false, false, true);
                      }}
                  >
                      <Button
                          style={{
                              width: "100%",
                              borderRadius: 0,
                              borderLeftWidth: 0,
                              padding: 0,
                          }}
                          icon={<BsPersonBoundingBox className="anticon" />}
                      ></Button>
                  </CreateFile>
              </Tooltip>

              <Tooltip title="Add Image Remove Background">
                  <CreateFile
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                          addImage(file, false, false, false, false, false, true);
                      }}
                  >
                      <Button
                          style={{
                              width: "100%",
                              borderRadius: 0,
                              borderLeftWidth: 0,
                              padding: 0,
                          }}
                          icon={<ImMagicWand className="anticon" />}
                      ></Button>
                  </CreateFile>
              </Tooltip>

              <Tooltip title="Add Image Crossword Puzzle">
                  <CreateFile
                      accept=".jpg, .png, .jpeg"
                      onChange={(file) => {
                          addImageScrabble(file);
                      }}
                  >
                      <Button
                          style={{
                              width: "100%",
                              borderRadius: 0,
                              borderLeftWidth: 0,
                              padding: 0,
                          }}
                          icon={<SiSahibinden className="anticon" />}
                      ></Button>
                  </CreateFile>
              </Tooltip>

              {/*hoang*/}
              <Tooltip title="Add label">
                  <Button
                      style={{ width: "100%" }}
                      icon={<BsChatSquareText className="anticon" />}
                      onClick={addLabel}
                  />
              </Tooltip>
          </Button.Group>
          <LayerSettings />
        </Scrollbars>

          {/*axit*/}
          <div className="button-hidden-group">
              <CreateFile
                  accept=".jpg, .png, .jpeg"
                  onChange={(file) => {
                      addImage(file, true, false, false);
                  }}
              >
                  <Button
                      style={{
                          width: "100%",
                          borderRadius: 0,
                          borderLeftWidth: 0,
                          padding: 0,
                      }}
                      icon={<FaShapes className="anticon" />}
                      ref={CreateFileRef}
                  ></Button>
              </CreateFile>
          </div>
          <Modal
              title={[
                  <div style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                  }} key={5}>
                      <div style={{
                          width: "30%"
                      }}>Song lyric layer</div>
                      <Steps style={{
                          width: "60%"
                      }} size="small" current={onNextChooseLyric - 1}>
                          <Step title="Choose style" />
                          <Step title="Spiral Type" />
                          <Step title="Configure" />
                      </Steps>
                  </div>
              ]}
              visible={visibleModelChooseTypeLyric}
              className="modal-choose-type-lyric"
              onCancel={handleCancelModelChooseTypeLyric}
              width={750}

              footer={[
                  <Button key="back" onClick={handleCancelModelChooseTypeLyric}>
                      Cancel
                  </Button>,
                  <div key={5}>
                      {onNextChooseLyric == 1 ?
                          <Button key="submit" type="primary" onClick={handleNextChooseType}>
                              Next
                          </Button>
                          : null }
                      {onNextChooseLyric == 2 ?
                          <div key={6}>
                              <Button key="prev1" type="primary" onClick={handlePrev1ChooseType}>
                                  Prev
                              </Button>
                              <Button key="submit" type="primary" onClick={handleNextSpiralChooseType}>
                                  Next
                              </Button>
                          </div>
                          : null}
                      {onNextChooseLyric == 3 ?
                          <div key={7}>
                              <Button disabled={loadingUpload} key="prev2" type="primary" onClick={handlePrev2ChooseType}>
                                  Prev
                              </Button>
                              {typeSpiralLyric == 5 ?
                                  <Button loading={loadingApply} disabled={loadingUpload || songLyric === "" || svgUrlLyric.value === ""} key="submit" type="primary" onClick={handleApplySpiralChooseType}>
                                      Apply
                                  </Button>
                              :
                                  <Button loading={loadingApply} disabled={loadingUpload || songLyric === ""} key="submit" type="primary" onClick={handleApplySpiralChooseType}>
                                      Apply
                                  </Button>
                              }
                          </div>
                          : null}

                  </div>,
              ]}
          >
              <div>
                  {onNextChooseLyric == 1 ?
                      <div className="box-modal-choose-spiral-type-lyric">
                          <Radio.Group onChange={handChangeTypeLyricChoose} defaultValue={typeLyricChooseModal} buttonStyle="solid">
                              <Radio.Button key={7} className="box-modal-choose-type-lyric-item" value="default">
                                  <img className="img-type-lyric-choose" src={"https://d7re1xv4rs2gf.cloudfront.net/upload-custom/uNFLhJ2w66zytN4DXDysc1.png"} />
                                  <div className="div-title-type-lyric">
                                      Line
                                  </div>
                              </Radio.Button>
                              <Radio.Button key={8} className="box-modal-choose-type-lyric-item" value="spiral">
                                  <img className="img-type-lyric-choose" src={"https://d7re1xv4rs2gf.cloudfront.net/upload-custom/iwLFF1113DMbHw2KQjw1sv.png"} />
                                  <div className="div-title-type-lyric">
                                      Spiral
                                  </div>
                              </Radio.Button>
                          </Radio.Group>
                      </div>
                      : null}
                  {onNextChooseLyric == 2 ?
                      <div className="box-modal-choose-spiral-type-lyric">
                          <Radio.Group onChange={handChangeTypeSpiral} defaultValue={typeSpiralLyric} buttonStyle="solid">
                              {typesSpiral.map((item, index)=> (
                                  <Radio.Button key={index} className="box-modal-choose-type-lyric-item" value={item.value}>
                                      <img className="img-type-lyric-choose" src={item.src} />
                                      <div className="div-title-type-lyric">
                                          {item.label}
                                      </div>
                                  </Radio.Button>
                              ) )}
                          </Radio.Group>
                      </div>
                      :null}
                  {onNextChooseLyric == 3 ?
                      <div>
                          <div className="box-control-lyric-modal">
                              <div className="box-color-lyrix">
                                  <ColorField value={colorTextLyric} onChange={handleOnchangeColorLyricText} />
                              </div>
                              {typeSpiralLyric == "5" ? <FontSizeChooseLyric value={fontSizeLyric} onChange={handChangeFontSizeLyric} /> : null}
                              <UploadFontLyric onChangeLoading={handleOnChangeLoadingUpload} value={fontTextLyric} onChange={handleOnSelectFontFamilyLyric} />
                              {typeSpiralLyric == "5" ? <UploadSVGLyric onChangeLoading={handleOnChangeLoadingUpload} value={svgUrlLyric} onChange={handleChangeUrlSvgLyric} /> : null}
                          </div>

                          <SongLyric min={minCharacters} max={maxCharacters} onChangeMaxCharacters={handleChangeMaxCharacters} onChangeMinCharacters={handleChangeMinCharacters}  value={songLyric} onChange={OnChangeSongLyric} />
                      </div>
                      :null}
              </div>
          </Modal>

        <ContextMenu id="item-layer" style={{ zIndex: 99 }}>
          {menuVisible && (
              <div className="ant-popover-inner">
                <Menu
                    style={{
                      borderRight: "none",
                      width: 150,
                      borderRadius: 2,
                    }}
                    onClick={handleMenuClick}
                >
                  <Menu.Item icon={<CopyOutlined />} key="duplicate">
                    Duplicate
                  </Menu.Item>
                  <Menu.Item
                      hidden={selectedLayer?.type !== "Image"}
                      icon={<RetweetOutlined />}
                      key="convert"
                  >
                    Convert
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                      icon={<DeleteOutlined />}
                      style={{ color: "red" }}
                      key="delete"
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              </div>
          )}
        </ContextMenu>
      </Container>
  );
};
export default connect((state) => ({
  workspace: state.artwork.workspace,
  currentUser: state.app.currentUser,
}))(Layers);
