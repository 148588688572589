import {
  Button,
  PageHeader,
  Table,
  Skeleton,
  Image,
  notification,
  Popconfirm,
  Tooltip,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomizeSearch from "../CustomizeSearch";
import EditTitle from "./EditTitle";
import { CLIPARTS } from "../../graphql/queries/clipartsQuery";
import { useMutation, useQuery } from "@apollo/client";
import ImportFromFolder from "./ImportFromFolder";
import UploadClipart from "./UploadClipart";
import CreateFile from "../upload/CreateFile";
import ButtonUnlockUser from "../ButtonUnlockUser";
import { USER_ID_BLOCKED } from "../../config";
import {
  UPDATE_CLIPART_TITLE,
  DELETE_CLIPARTS,
  UPDATE_CLIPART_THUMBNAIL
} from "../../graphql/mutation/clipartMutation";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { CLIPART_CATEGORY } from "../../graphql/queries/clipartsCategoryQuery";
import { sum } from "lodash";
import Scrollbars from "react-custom-scrollbars";
import ModalPreviewimage from "../ModalPreviewimage";
import { history } from "../../history";
import { CLOUDFRONT_URL } from "../../config";
import { connect } from "react-redux";
import {RiImageEditFill} from "react-icons/ri";

const Container = styled.div`
  border-left: 0.1rem solid rgb(223, 227, 232);
`;
const ClipartList = (props) => {
  const { categoryID, currentUser } = props;
  const [filter, setFilter] = useState({
    search: null,
    pageSize: 20,
    page: 1,
    categoryID,
    userID: null,
  });
  // console.log(currentUser);

  const seller = currentUser?.roles?.find((role) => role.name === "Seller");
  const { data, loading, refetch } = useQuery(CLIPARTS, {
    variables: {
      ...filter,
      userID: seller ? currentUser.id : null,
      categoryID: categoryID ? [categoryID] : null,
    },
    fetchPolicy: "network-only",
  });
  // console.log(data);
  const [edit, setEdit] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);

  const dataSource = data?.cliparts?.hits;
  const { data: dataCaterory } = useQuery(CLIPART_CATEGORY, {
    variables: categoryID ? { id: categoryID.toString() } : {},
    skip: !categoryID,
  });
  const [UpdateTitle] = useMutation(UPDATE_CLIPART_TITLE);
  const [DeleteClipart] = useMutation(DELETE_CLIPARTS);
  const updateName = (id, title) => {
    UpdateTitle({
      variables: { id, title },
    })
      .then(() => {
        notification.success({ message: "Update title success!" });
        refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  const hanldleDelete = (id) => {
    DeleteClipart({
      variables: { id: id },
    })
      .then(() => {
        notification.success({ message: "Delete success!" });
        refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  useEffect(() => {
    if (categoryID) {
      setFilter({
        ...filter,
        page: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryID]);

  const [UpdateClipartThumbnail] = useMutation(UPDATE_CLIPART_THUMBNAIL)

  const addImage = (data, id ,file) => {
    UpdateClipartThumbnail({
      variables: {
        id: id,
        thumbnail_id: file.id
      }
    }).then(() => {
      notification.success({ message: "Update thumbnail success!" });
      refetch();
    })
        .catch((err) => notification.error({ message: err.message }));
  }

  const columns = [
    {
      title: "File",
      key: "name",
      render: (record) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "90px calc(100% - 90px)",
            alignItems: "center",
          }}
        >
          <div className="cp-image">
            <Image
              style={{ backgroundColor: "#f5f5f5", objectFit: "contain" }}
              width="100px"
              height="100px"
              src={`${CLOUDFRONT_URL}/200xauto/${record.file.key}`}
              preview={false}
              fallback={"../../../public/NO_IMG.png"}
            />
            {record.file.key ? (
              <div
                className="cp-prewview-image"
                onClick={() => {
                  setPreviewImage([record.file.key]);
                }}
              >
                <EyeOutlined style={{ marginRight: 5 }} />
                Preview
              </div>
            ) : null}
          </div>

          <span className="ml-15">
            <EditTitle
              updateName={(id, title) => updateName(id, title)}
              edit={edit}
              setEdit={setEdit}
              defaultValue={record.title}
              record={record}
            />
          </span>
        </div>
      ),
      width: 200,
    },
    {
      title: "Thumbnail",
      key: "name",
      render: (record) => (
          <div
              style={{
                display: "flex"
              }}
          >
            {record.thumbnail != null ?
            <Image
                style={{ backgroundColor: "#f5f5f5", objectFit: "contain", margin: "0 -5px" }}
                width="50px"
                height="50px"
                src={`${CLOUDFRONT_URL}100/${record.thumbnail?.key}`}
                preview={false}
                fallback={"../../../public/NO_IMG.png"}
                loading="true"
            /> : null }
            <CreateFile
                accept=".jpg, .png, .jpeg"
                onChange={(file) => {
                  addImage(record, record.id, file);
                }}
                loading="true"
                className="ml-15"
            >
              <Button
                  style={{
                    width: "145px",
                    borderRadius: 2,
                    padding: 0,
                  }}
                  icon={<RiImageEditFill className="anticon" />}
              >Upload file</Button>
            </CreateFile>
          </div>
      ),
      width: 200,
    },
    // {
    //   title: "Author",
    //   key: "author",
    //   dataIndex: "author",
    //   render: (author) => (
    //     <div>
    //       <Avatar>
    //         {author?.first_name.charAt(0)}
    //         {author?.last_name.charAt(0)}
    //       </Avatar>{" "}
    //       {author?.first_name} {author?.last_name}
    //     </div>
    //   ),
    //   width: 130,
    // },
    // {
    //   title: "Date",
    //   key: "date",
    //   dataIndex: "createdAt",
    //   render: (date) => moment(date).format("MMM DD, YYYY"),
    //   width: 120,
    // },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (record) => (
      <>
      {(currentUser.id == USER_ID_BLOCKED) ? <ButtonUnlockUser 
        buttonChild={<DeleteOutlined />}
        propsButton={{
          size: 'small',
          className: 'delete-button-color'
        }}
        onConfirm={() => hanldleDelete(record.id)}
      /> : 
        <Tooltip title="Delete clipart">
          <Popconfirm
            title="Are you sure delete this clipart?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => hanldleDelete(record.id)}
          >
            <Button size="small" className="delete-button-color">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
        }
      </>        
      ),
      width: 100,
    },
  ];
  const pagination = {
    current: filter.page,
    pageSize: filter.pageSize,
    total: data?.cliparts?.count,
    onChange: (page, pageSize) => {
      setFilter({ ...filter, page, pageSize });
    },
    showTotal: (total, range, a) =>
      `${range[0]} to ${range[1]} of ${total} items`,
  };
  const tableWidth = sum(columns.map((c) => c.width));
  return (
    <Container className="p-15-24 p-cliparts">
      <div>
        <PageHeader
          title={
            dataCaterory && categoryID.length > 0
              ? dataCaterory?.clipartCategory?.title
              : "All Categories"
          }
          style={{ padding: 0, marginBottom: 7 }}
          onBack={() => {
            history.goBack();
          }}
          extra={
            <div className="flex">
              <UploadClipart
                disable={categoryID?.length > 0 ? false : true}
                refetch={refetch}
                categoryID={categoryID?.toString()}
              />

              <ImportFromFolder
                refetch={refetch}
                parent={categoryID?.toString()}
              />
            </div>
          }
        />
      </div>
      <CustomizeSearch filter={filter} setFilter={setFilter} />
      {loading ? (
        <Skeleton />
      ) : (
        <Scrollbars
          autoHeight
          autoHeightMax={"calc(100vh - 220px)"}
          style={{
            marginTop: 24,
            // width: "100%",
            // height: "calc ( 100% - 300px)",
          }}
        >
          <Table
            scroll={{ x: tableWidth }}
            pagination={pagination}
            columns={columns}
            dataSource={dataSource}
            rowKey={(record) => record.id}
          />
        </Scrollbars>
      )}
      {previewImage && (
        <Modal
          width={800}
          title="Preview Image"
          visible={previewImage ? true : false}
          onCancel={() => setPreviewImage(false)}
          footer={false}
        >
          <ModalPreviewimage keys={previewImage} />
        </Modal>
      )}
    </Container>
  );
};

export default connect((state) => ({ currentUser: state.app.currentUser }))(
  ClipartList
);
