import { useMutation } from "@apollo/client";
import { Button, notification, message } from "antd";
import React, { useState } from "react";
import { CREATE_FILE } from "../../graphql/mutation/createFile";
import Upload from "../../components/upload/Upload";
import { CREATE_CLIPARTS } from "../../graphql/mutation/clipartMutation";
import styled from "styled-components";
const Container = styled.div`
  .ant-upload {
    .ant-upload-btn {
      padding: 0 !important;
      border: 0 !important;
    }
  }
`;
const UploadClipart = ({ categoryID, refetch, disable }) => {
  const [createFileMutation] = useMutation(CREATE_FILE);
  const [CreateClipart] = useMutation(CREATE_CLIPARTS);
  const [fileUploads, setFileUploads] = useState([]);
  const handleUpload = (files) => {
    Promise.all(
      files?.map(
        (file) =>
          new Promise((resolve, reject) => {
            createFileMutation({
              variables: {
                input: {
                  key: file.key,
                  file_name: file.name,
                  file_mime: file.type,
                  file_size: file.size,
                  source: "aws",
                },
              },
            })
              .then((res) => {
                resolve(res.data.createFile);
                if (res.data.createFile) {
                }
              })
              .catch((err) => reject(err));
          })
      )
    )
      .then((data) => {
        if (data) {
          return data?.forEach((file) => {
            CreateClipart({
              variables: {
                title: file.file_name,
                file: {
                  id: file.id,
                  key: file.key,
                  file_name: file.file_name,
                  file_mime: file.file_mime,
                  file_size: file.file_size,
                  source: "aws",
                },
                categoryID,
              },
            }).then(() => {
              notification.success({ message: "The clipart saved success!" });
              setFileUploads([]);
              refetch();
            });
          });
        }
      })
      .catch((err) => {
        setFileUploads([]);
      });
  };
  return (
    <Container>
      <Upload
        showUploadList={false}
        onUpload={handleUpload}
        multiple={true}
        accept=".jpg, .png, .jpeg"
        fileList={fileUploads}
        onChange={(file) => {
          setFileUploads(file)
          const key = 'uploading_photos';
          setTimeout(() => {
            message.success({ content: 'Uploading image successfully!', key, duration: 2 });
          }, 1000);
        }
      }
      >
        <Button disabled={disable} type="primary">
          Add New
        </Button>
      </Upload>
    </Container>
  );
};

export default UploadClipart;
