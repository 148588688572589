import gql from "graphql-tag";

export const COLLECTION = gql`
  query collections($filter: CollectionFilter) {
    collections(filter: $filter) {
      count
      hits {
        id
        title
        description
      }
    }
  }
`;
